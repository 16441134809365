export const getLocalStorageData = _ =>
  JSON.parse(sessionStorage.getItem("utmData") || "{}");

export const getReffereUrl = ()=>{
 return JSON.parse(localStorage.getItem("referrerUrl") || "");
} 
export const getHTMLStringwithLSdata = data =>{
  const { medium, campaign, source ,term,content,keyword,matchtype,device,placement} = data;

  return `<div>campaign:${ campaign || ""} | medium: ${
    medium || ""
 } | source: ${source || ""}  | term: ${term || ""} | content: ${content || ""} | keyword: ${keyword || ""} | matchtype: ${matchtype || ""} | device: ${device || ""} | placement: ${placement || ""}</div>`;
 
}
export const getHTMLStringwithGaConnectordata = (data)=>{
  const {GA_Client_ID,fc_medium,fc_campaign,fc_landing,fc_referrer,fc_source,country} = data;
  return `<br><div>GA Client ID: ${GA_Client_ID || ""} | First Click Medium: ${fc_medium || ""} | First Click Campaign: ${fc_campaign || ""} | First Click Landing Page: ${fc_landing || ""} | First Click Referrer: ${fc_referrer || ""} | First Click Source: ${fc_source || ""} | Country Name: ${country} </div`
}  
