import React from "react";
import PropTypes from "prop-types";

import PrimaryButton from "../../../shared/primaryButton";

import {
  MainContainer,
  Row,
  Image,
  Content,
  ContentItem,
  HeadlineContainer,
  ImageContainer,
  MobileContainer,
  DesktopContainer,
  MobileInnerContainer,
  BulletsWrapper,
  Bullets
} from "./style";

import H2 from "../../../shared/h2/index";
import H3 from "../../../shared/h3/index";
import Para from "../../../shared/para-alt/index";
import Spacer from "../../../shared/spacer/index";

function ImageWithContent({ data }) {
  return (
    <MainContainer>
      <DesktopContainer>
        {data.map((item, index) => {
          return (
            <Row key={index}>
              <ImageContainer order={index % 2 !== 0 ? "order" : null}>
                <Image
                  src={item.url}
                  order={index % 2 !== 0 ? "order" : null}
                />
              </ImageContainer>
              <Content order={index === 1 ? "order" : null}>
                <HeadlineContainer>
                  <H2>{item.headline}</H2>
                </HeadlineContainer>
                {item.features &&
                  item.features.map((feature, index) => {
                    return (
                      <ContentItem key={index}>
                        <H3>{feature.headline}</H3>
                        <Para>{feature.content}</Para>
                      </ContentItem>
                    );
                  })}
                

                {
                  item.points ? (
                    <React.Fragment>
                      <Spacer m={30} />
                      <BulletsWrapper>
                        {
                          item.points.map((value, index) => <Bullets key={index}>{value}</Bullets>)
                        }
                      </BulletsWrapper>
                    </React.Fragment>
                  ) : null
                }

                <Spacer m={40} />

                <PrimaryButton
                  link={item["free-trial"].link}
                  title={item["free-trial"].title}
                  isExternal={item["free-trial"].isExternal}
                >
                  {item["free-trial"].text}
                </PrimaryButton>
              </Content>
            </Row>
          );
        })}
      </DesktopContainer>
      <MobileContainer>
        {data.map((item, index) => {
          return (
            <MobileInnerContainer key={index}>
              <HeadlineContainer>
                <H2>{item.headline}</H2>
              </HeadlineContainer>
              <Image src={item.url} />
              {item.features &&
                item.features.map((feature, index) => {
                  return (
                    <ContentItem key={index}>
                      <H3>{feature.headline}</H3>
                      <Para>{feature.content}</Para>
                    </ContentItem>
                  );
                })}

                {
                  item.points ? (
                    <React.Fragment>
                      <Spacer m={30} />
                      <BulletsWrapper>
                        {
                          item.points.map((value, index) => <Bullets key={index}>{value}</Bullets>)
                        }
                      </BulletsWrapper>
                      <Spacer m={30} />
                    </React.Fragment>
                  ) : null
                }

              <PrimaryButton
                link={item["free-trial"].link}
                title={item["free-trial"].title}
                isExternal={item["free-trial"].isExternal}
              >
                {item["free-trial"].text}
              </PrimaryButton>
            </MobileInnerContainer>
          );
        })}
      </MobileContainer>
    </MainContainer>
  );
}

ImageWithContent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object)
};

export default ImageWithContent;
