import React from "react";
import PropTypes from "prop-types";

import {
  Container,
  InnerContainer,
  ContentContainer,
  ImageContainer,
  Image,
  ButtonContainer,
  TopContainer
} from "./style";

import H3 from "../../shared/h3/index";
import Para from "../../shared/para-alt/index";
import Spacer from "../../shared/spacer/index";

import PrimaryButton from "../../shared/primaryButton/index";

function ContentWithImage({ data, bg }) {
  const { headline, text, isButtonPresent, imageUrl, alt, buttonData } = data;
  return (
    <TopContainer>
      <Container bg={bg}>
        <InnerContainer>
          <ContentContainer>
            <H3>{headline}</H3>
            <Spacer m={16} />
            <Para>{text}</Para>
            {isButtonPresent && (
              <ButtonContainer>
                <PrimaryButton link={buttonData.buttonUrl}>
                  {buttonData.buttonText}
                </PrimaryButton>
              </ButtonContainer>
            )}
          </ContentContainer>

          <ImageContainer>
            <Image src={imageUrl} alt={alt} />
          </ImageContainer>
        </InnerContainer>
      </Container>
    </TopContainer>
  );
}

ContentWithImage.propTypes = {
  data: PropTypes.shape({
    headline: PropTypes.string,
    text: PropTypes.string,
    isButtonPresent: PropTypes.bool,
    imageUrl: PropTypes.string,
    alt: PropTypes.string
  })
};

export default ContentWithImage;
