import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { QuintypeLogo } from "../icons";
import { data } from "./data";
import { arabicdata } from "./arabic-data";
import {
  FooterContainer,
  FooterRow,
  MenuContent,
  MenuWrapper,
  MenuName,
  Items,
  MenuItems,
  ContactInfo,
  Text,
  LinkItem,
  TextLink,
} from "./style";

function Footer() {
  const [id, setId] = useState("");
  const isArabic = useSelector((state) => state);
  const [turnOff, setTurnoff] = useState(true);
  const toggleBox = (index) => {
    id === index ? setId("") : setId(index);
  };
  const path = window.location.pathname;
  useEffect(() => {
    if (
      path === "/case-study/bloombergquint" ||
      path === "/case-study/swarajya" ||
      path === "/case-study/fortune-india" ||
      path === "/case-study/thequint" ||
      path === "/case-study/bettors-insider" ||
      path === "/case-study/vikatan" ||
      path === "/case-study/gto-publishing" ||
      path === "/case-study/prabhat-khabar" ||
      path === "/case-study/e-sakal" ||
      path === "/case-study/matter-news" ||
      path === "/case-study/mimorelia" ||
      path === "/case-study/mimorelia-spanish-version" ||
      path === "/case-study/karjalainen"
    ) {
      // alert("turn of is arabic");
      setTurnoff(false);
    }
  }, [path]);
  return (
    <FooterContainer>
      <FooterRow>
        <MenuContent>
          {isArabic && turnOff
            ? arabicdata.map((item, menuItemIndex) => {
                return (
                  <MenuWrapper key={menuItemIndex}>
                    <MenuName
                      key={menuItemIndex}
                      onClick={() => toggleBox(menuItemIndex)}
                      arrow={id === menuItemIndex ? "open" : null}
                    >
                      {item.name}
                    </MenuName>
                    <MenuItems open={id === menuItemIndex ? "open" : null}>
                      {item.menuList.map((item, menuListIndex) => (
                        <Items key={menuListIndex}>
                          {item.linkType === "internal" ? (
                            <LinkItem to={item.url}>{item.name}</LinkItem>
                          ) : (
                            <TextLink
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item.url}
                            >
                              {item.name}
                            </TextLink>
                          )}
                        </Items>
                      ))}
                    </MenuItems>
                  </MenuWrapper>
                );
              })
            : data.map((item, menuItemIndex) => {
                return (
                  <MenuWrapper key={menuItemIndex}>
                    <MenuName
                      key={menuItemIndex}
                      onClick={() => toggleBox(menuItemIndex)}
                      arrow={id === menuItemIndex ? "open" : null}
                    >
                      {item.name}
                    </MenuName>
                    <MenuItems open={id === menuItemIndex ? "open" : null}>
                      {item.menuList.map((item, menuListIndex) => (
                        <Items key={menuListIndex}>
                          {item.linkType === "internal" ? (
                            <LinkItem to={item.url}>{item.name}</LinkItem>
                          ) : (
                            <TextLink
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item.url}
                            >
                              {item.name}
                            </TextLink>
                          )}
                        </Items>
                      ))}
                    </MenuItems>
                  </MenuWrapper>
                );
              })}
        </MenuContent>
        <ContactInfo>
          <QuintypeLogo />
          <Text>© {new Date().getFullYear()} Quintype. </Text>
        </ContactInfo>
      </FooterRow>
    </FooterContainer>
  );
}

export default Footer;
