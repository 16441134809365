import React from "react";
import { useSelector } from "react-redux";
import { Text, Container, Input } from "./style";
import { NormalLink } from "../../../contact-us/style";

export default function Checkbox({ register, name, errors }) {
  const isArabic = useSelector((state) => state);
  return (
    <Container>
      <Input
        error={errors[name]}
        name={name}
        type="checkbox"
        ref={register({ required: true })}
      />
      {isArabic ? (
        <Text>
          أوافق على
          <NormalLink to="/privacy-policy"> سياسة الخصوصية</NormalLink>
        </Text>
      ) : (
        <Text>
          I agree to
          <NormalLink to="/privacy-policy">privacy policy</NormalLink>
        </Text>
      )}
    </Container>
  );
}
