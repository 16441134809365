export const arabicdata = {
  small_publishers: {
    "top-component": {
      headline: "قم بتنمية عملك معنا",
      info: "احصل على جميع الميزات والمرونة لمنصة نشر على مستوى المؤسسة بتكاليف معقولة. ",
      firstButtonContent: {
        text: "ابدأ الإصدار التجريبي المجاني",
        link: "/sign-up",
        title: " Start Free Trial",
        linkType: "internal",
      },
      logo: {
        url: "",
        alt: "logo",
      },
      secondButtonContent: {
        required: false,
      },
      image: {
        url: "https://images.assettype.com/quintype-website/2021-03/d50c0cc4-d5c9-4f6c-8c6c-aa9e0f6a66da/solutions_for_small_publishers.png",
        alt: "Small Media Organization Solutions",
      },
    },

    "image-with-content": [
      {
        headline: "إنشاء محتوى سهل لفرق أصغر",
        features: [
          {
            headline: "",
            content: "",
          },
        ],
        url: "https://images.assettype.com/quintype-website/2021-04/35459710-2789-48ad-9d0a-676755510767/Screenshot_2021_04_07_at_12_33_03_PM.png",
        alt: "workspace",
        "free-trial": {
          text: "حجز عرض توضيحي",
          link: "/schedule-demo",
          title: " Schedule Demo",
        },
        points: [
          "إنشاء المحتوى وتنظيمه وإدارته بسهولة. تساعدك أدواتنا في إعداد عملية تحرير خالية من الاحتكاك.",
          "استخدم محرر البطاقات الخاص بنا لإنشاء قصص وسائط غنية مع روابط مضمنة ومقاطع فيديو وصور عالية الجودة دون متاعب.",
          "تعاون واعمل مع فريقك من الصحفيين والمستقلين والمحررين لنشر الأخبار بشكل أسرع.",
          "أنتج قصصًا أثناء التنقل من خلال نظام إدارة المحتوى المحمول سريع الاستجابة.",
        ],
      },
    ],

    "image-with-content2": [
      {
        headline: "زيادة وصولك بجهد أقل",
        features: [
          {
            headline: "",
            content: "",
          },
        ],
        url: "https://images.assettype.com/quintype-website/2021-03/fe15eaee-98f1-420e-9427-8b135e4eef0c/Multi_device.png",
        alt: "workspace",
        "free-trial": {
          text: "جدولة العرض",
          link: "/schedule-demo",
          title: " Schedule Demo",
        },
        points: [
          "احصل على المحتوى الخاص بك للجمهور المستهدف على جميع الأجهزة التي لا تحتوي على شاشة أو شاشة.",
          "إدارة البيانات الوصفية والارتزاقات وخرائط الموقع من CMS. يمكن لكتابك التحقق من نتيجة تحسين محركات البحث وتحسين القصص للحصول على ترتيب أفضل.",
          "يمكنك الوصول إلى قرائك بأحدث وأروع القصص مباشرةً على بريدهم الوارد.",
          "قم بتنبيه القراء بقصص جديدة باستخدام الإشعارات الفورية.",
          "أتمتة توزيع قصصك على قنوات التواصل الاجتماعي.",
          "قم بزيادة فرصك في الظهور في أخبار جوجل بالدعم الفني المناسب.",
        ],
      },
    ],

    "image-with-content3": [
      {
        headline: "إدارة سهلة للواجهة الأمامية باستخدام منشئ صفحات",
        features: [
          {
            headline: "",
            content: "",
          },
        ],
        url: "https://gumlet.assettype.com/quintype-website/2019-12/031ea97e-c95f-4d59-b232-a53b553eb5cd/Ahead_1.png?w=640&dpr=1.0",
        alt: "workspace",
        "free-trial": {
          text: "حجز عرض توضيحي",
          link: "/schedule-demo",
          title: " Schedule Demo",
        },
        points: [
          "تمتع بتحكم أفضل في الواجهة الأمامية دون أي معرفة بالترميز.",
          "قم بتخصيص التخطيط والعلامة التجارية لصفحتك الرئيسية وأقسامك وقصصك دون الحاجة إلى فريق موسع. استخدم القوالب المستخدمة في جميع أنحاء الصناعة.",
          "أضف عناصر واجهة مستخدم أو إعلانات أو قصصًا مخصصة دون الحاجة إلى الكود.",
          "لديك موقع ويب يستجيب لكل من الويب والجوال.",
        ],
      },

      {
        headline: "تحليل وإضفاء الطابع الشخصي على المحتوى لقرائك",
        features: [
          {
            headline: "",
            content: "",
          },
        ],
        url: "https://gumlet.assettype.com/quintype-website/2022-09/7b39d914-1d04-4d69-a3bd-83eab3026232/BOLD_Analytics.png",
        alt: "workspace",
        "free-trial": {
          text: "حجز عرض توضيحي",
          link: "/schedule-demo",
          title: "CTA: Schedule Demo",
        },
        points: [
          "سهولة الاكتشاف وزيادة تفاعل الجمهور مع المجموعات.",
          "اتخذ قرارات أفضل من خلال قصتنا ومؤلفنا وتقارير الأداء.",
          "قم بتقديم المحتوى الذي يريده القارئ وفقًا لاختياراته الشخصية.",
          "قدم ما هو ساخن وشائع لقرائك في غضون ساعة.",
        ],
      },

      {
        headline: "أحدث القدرات التقنية للتعامل مع الحجم والجهوزية.",
        features: [
          {
            headline: "",
            content: "",
          },
        ],
        url: "https://images.assettype.com/quintype-website/2021-03/07fff20a-56db-4e05-b20b-1064bc680338/Website_Security_.png",
        alt: "workspace",
        "free-trial": {
          text: "حجز عرض توضيحي",
          link: "/schedule-demo",
          title: "CTA: Schedule Demo",
        },
        points: [
          "أحدث القدرات التقنية للتعامل مع الحجم والجهوزية.",
          "قم بتأمين موقع الويب الخاص بك من هجمات الحرمان من الخدمات واستمتع بحركة المرور المتزايدة دون الحاجة إلى القلق بشأن موقع الويب الخاص بك.",
          "قم بتأمين موقع الويب الخاص بك من هجمات الحرمان من الخدمات واستمتع بحركة المرور المتزايدة دون الحاجة إلى القلق بشأن موقع الويب الخاص بك.",
          "جميع المواقع المستضافة على خوادمنا هي HTTPs افتراضيًا.",
        ],
      },
    ],

    review: {
      headline: "Way more than a bug and issue tracker",
      content: `"يضيف الفريق باستمرار ميزات جديدة لإفادة الناشرين ويتم تصميم المنتجات بذكاء. تم تصميم الواجهة الأمامية لقابلية المرونة لتلبية متطلباتنا المخصصة مما يجعل كوينتيب خيارًا خالٍ من المتاعب بالنسبة لنا "`,
      designation: "سوناكشي سينها ، مدير المنتج",
      "image-url":
        "https://gumlet.assettype.com/quintype-website/2019-12/e410a432-32d0-4aac-94a4-b27f1d2b978c/Client_15.png?w=360",
      alt: "Sonakshi Sinha, Product Manager",
      features: [
        {
          headline: "خدمة مُدارة بالكامل",
          description:
            "يتم الاهتمام بالتحديثات والأمان والنسخ الاحتياطية نيابة عنك.",
        },
        {
          headline: "برهان المستقبل",
          description:
            "يقوم فريقنا بانتظام بتحديث التخطيطات والميزات وعمليات الدمج التي ستبقي موقع الويب الخاص بك في طليعة الحزمة.",
        },
        {
          headline: "الهجرة دون عناء",
          description:
            "بياناتك آمنة ويتم نقلها بسلاسة إلى بولد Bold بدون محرك ترحيل متقدم.",
        },
      ],
    },

    review2: {
      headline: "",
      content: `"يعتبر نظام إدارة المحتوى بولد Bold  و منشئ الصفحات للنشر الرقمي بمثابة حل جيد الإعداد للتوصيل والتشغيل يوفر مرونة في تصميم الواجهة الأمامية لإعداد موقع ويب مخصص بسرعة ، مع واجهة سهلة لإدارة الخلفية للتحديث اليومي وإدارة العملاء."`,
      designation: "جوبين هويدروم ، المسؤول",
      "image-url":
        "https://images.assettype.com/quintype-website/2021-03/7370917f-b4a9-46b0-9df5-79cf0bbe0447/SBN_Logo.png",
      alt: "SteelGuru business review",
      features: [
        {
          headline: "نظام إدارة محتوى ساس SaaS مُدار بالكامل",
          description:
            "يتم الاهتمام بالتحديثات والأتمتة والنسخ الاحتياطي نيابة عنك.",
        },
        {
          headline: "واجهات برمجة تطبيقات – أول نظام إدارة محتوى بدون رأس ",
          description:
            "توفر لك واجهات برمجة تطبيقات REST الموثقة جيدًا تحكمًا كاملاً في الواجهة الأمامية.",
        },
        {
          headline: "تأمين سحابة المؤسسة",
          description:
            "كاملة مع حماية خدمات الحماية من الحرمان و SSL والبنية التحتية للتخزين المؤقت والتوسيع التلقائي.",
        },
      ],
    },
  },
  to_increase_traffic: {
    "top-component": {
      headline: "إجذبالمزيد من حركة المرور إلى المحتوى الخاص بك",
      info: "تساعد مجموعة الأدوات التي نقدمها للناشرين على زيادة مدى وصولهم بكفاءة وبدون عناء.",
      firstButtonContent: {
        text: "حجز عرض توضيحي",
        link: "/sign-up",
        title: "Schedule Demo",
        linkType: "internal",
      },
      logo: {
        url: "",
        alt: "logo",
      },
      secondButtonContent: {
        required: false,
      },
      image: {
        url: "https://images.assettype.com/quintype-website/2021-05/32cb2dc2-2239-435a-9293-887ac66496c3/Solution_to_increase_traffic_illustration_28th_april_04.png",
        alt: "Increase Traffic Solutions",
      },
    },

    "image-with-content": [
      {
        headline: "ضاعف مدى وصولك مع نظام إدارة المحتوى بولد Bold CMS",
        features: [
          {
            headline: "",
            content: "",
          },
        ],
        url: "https://images.assettype.com/quintype-website/2021-04/e28bf592-7029-4794-bbbb-7dda3d0e6f90/Solution_to_increase_traffic_28th_april_03.png",
        alt: "workspace",
        "free-trial": {
          text: "حجز عرض توضيحي",
          link: "/schedule-demo",
          title: " Schedule Demo",
        },
        points: [
          "أنشئ المحتوى الخاص بك بتنسيقات مختلفة لإجذبانتباه القارئ على قنوات مختلفة - الفيديو والمدونات الصوتية والقصص المرئية والمقالات الفورية وقصص الصور والنص العادي.",
          "قم بتوزيع المحتوى على مجمعي الأخبار التابعين لجهات خارجية مثل ديلي هنت، وجيو نيوز، وياهو نيوز، وويبر والمزيد.",
          "قم بإدارة مُحسّنات محرّكات البحث لقصصك من خرائط المواقع إلى المخطط إلى الأداء لتحسين SERPS واكتشاف حركة المرور.",
          "نشر القصص تلقائيًا على حساباتك الشخصية وحسابات الشركة على وسائل التواصل الاجتماعي لتحقيق أقصى قدر من الوضوح.",
        ],
      },
    ],

    "image-with-content2": [
      {
        headline: "إشراك وإبقاء القراء على موقع الويب الخاص بك",
        features: [
          {
            headline: "",
            content: "",
          },
        ],
        url: "https://images.assettype.com/quintype-website/2021-05/35a17259-8525-42e3-b490-6c1d2dda91e8/Solution_to_increase_traffic_28th_april_01.png ",
        alt: "workspace",
        "free-trial": {
          text: "حجز عرض توضيحي",
          link: "/schedule-demo",
          title: " Schedule Demo",
        },
        points: [
          "تمكين محادثات هادفة مع حلول تعليقات ميتايب. المزيد من التعليقات تعني المزيد من الحركة.",
          "احصل على موجز مباشر لقصصك الشائعة والقصص الأكثر تعليقًا ليتمكن القراء من الإمساك بها على موقع الويب.",
          "إدارة تخطيط وتصميم موقع الويب الخاص بك لاختبار A / B وتخصيص المحتوى الخاص بك بناءً على اهتمام القراء .",
        ],
      },
    ],

    "image-with-content3": [
      {
        headline: "أنشئ تطبيقات جوال أصلية من أجل ولاء أقوى للعلامة التجارية",
        features: [
          {
            headline: "",
            content: "",
          },
        ],
        url: "https://images.assettype.com/quintype-website/2021-04/427bd191-c841-4f7b-b31d-f7339fd8aec2/Solution_to_increase_traffic_28th_april_02.png",
        alt: "workspace",
        "free-trial": {
          text: "حجز عرض توضيحي",
          link: "/schedule-demo",
          title: " Schedule Demo",
        },
        points: [
          "قم بتحويل موقع الويب الخاص بك للتفاعل مع التطبيقات الأصلية ومنح القراء المخلصين أفضل تجربة على الهاتف المحمول.",
          "احصل على السرعة والراحة التي يتوقعها القراء على أجهزتهم المحمولة.",
          "ساعد القراء على الوصول إلى المحتوى حتى بسرعات الإنترنت المنخفضة مع وضع عدم الاتصال.",
        ],
      },
    ],

    "image-with-content4": [
      {
        headline: "أحدث القدرات التقنية للتعامل مع الحجم والجهوزية.",
        features: [
          {
            headline: "",
            content: "",
          },
        ],
        url: "https://images.assettype.com/quintype-website/2021-03/07fff20a-56db-4e05-b20b-1064bc680338/Website_Security_.png",
        alt: "workspace",
        "free-trial": {
          text: "حجز عرض توضيحي",
          link: "/schedule-demo",
          title: "CTA: Schedule Demo",
        },
        points: [
          "قم بتحسين توفر المحتوى وأدائه باستخدام CDN الرائد في الصناعة مثل AWSK، و Fastly،  وما إلى ذلك.",
          "أوتو Auto يوازن بنيتك التحتية للتعامل مع الارتفاع المفاجئ في حركة المرور.",
          "جميع المواقع المستضافة على خوادمنا هي HTTPs افتراضيًا.",
          "وصول آمن إلى نظام إدارة محتوى بولد Bold باستخدام مصادقة ثنائية.",
          "مراقبة ودعم على مدار اليوم طوال أيام الأسبوع لجميع التطبيقات والخدمات.",
        ],
      },
    ],

    review: {
      headline: "Way more than a bug and issue tracker",
      content: `"يضيف الفريق باستمرار ميزات جديدة لإفادة للناشرين وقد تم تصميمه بذكاء. تم تصميم الواجهة الأمامية لقابلية المرونة لتلبية متطلباتنا المخصصة مما يجعل كوينتايب خيارًا خالٍ من المتاعب بالنسبة لنا ".`,
      designation: "سوناكشي سينها، مدير المنتج",
      "image-url":
        "https://gumlet.assettype.com/quintype-website/2019-12/16b8d4db-ef60-4144-8c08-dddb03f46b99/FortuneIndia.svg?w=360&dpr=1.0",
      alt: "Sonakshi Sinha, Product Manager",
      features: [
        {
          headline: "خدمة مُدارة بالكامل",
          description:
            "يتم الاهتمام بالتحديثات والأمان والنسخ الاحتياطية نيابة عنك.",
        },
        {
          headline: "برهان المستقبل",
          description:
            "يقوم فريقنا بانتظام بتحديث التخطيطات والميزات وعمليات الدمج التي ستبقي موقع الويب الخاص بك في طليعة الحزمة.",
        },
        {
          headline: "الهجرة دون عناء",
          description:
            "بياناتك آمنة ويتم نقلها بسلاسة إلى Bold بدون محرك ترحيل متقدم.",
        },
      ],
    },

    review2: {
      headline: "",
      content: `"كان من المهم أن يكون لديك نظام إدارة محتوى يساعدنا في الحفاظ على سير عمل فعال. لقد شهدنا نموًا ملحوظًا في مشاهدات الصفحة بعد الانتقال إلى نظام إدارة محتوى بولد Bold CMS. تأتي المنصة محملة بميزات تسويق المحتوى التي تجعل التجربة بأكملها رائعة "`,
      designation: "براكاش كومار، رئيس - التسويق الرقمي",
      "image-url":
        "https://images.assettype.com/quintype-website/2021-05/d8b3ce31-29d5-4063-a3b4-00f60c29298a/logo_bb49fe5acb23c689ef43.svg",
      alt: "Prakash Kumar, Head - Digital Marketing",
      features: [
        {
          headline: "نظام إدارة محتوى ساس Saas CMS مُدار بالكامل",
          description:
            "التحديثات والأتمتة والنسخ الاحتياطية كلها يتم الاعتناء بها نيابة عنك.",
        },
        {
          headline: "ايه بي آي API – أول نظام إدارة محتوى بدون رأس ",
          description:
            "توفر لك واجهات برمجة تطبيقات REST الموثقة جيدًا تحكمًا كاملاً في الواجهة الأمامية.",
        },
        {
          headline: "تأمين سحابة المؤسسة",
          description:
            "كاملة مع حماية DDoS و SSL والبنية التحتية للتخزين المؤقت والتوسيع التلقائي.",
        },
      ],
    },
  },

  monetize_content: {
    "top-component": {
      headline: "استثمر المحتوى الخاص بك",
      info: "أدوات للناشرين لتوليد الإيرادات من خلال المحتوى الخاص بك.",
      firstButtonContent: {
        text: "حجز عرض توضيحي",
        link: "/sign-up",
        title: "Schedule Demo",
        linkType: "internal",
      },
      logo: {
        url: "",
        alt: "logo",
      },
      secondButtonContent: {
        required: false,
      },
      image: {
        url: "https://images.assettype.com/quintype-website/2021-06/7ec97e3a-b939-4586-b625-2dce9e81f3f8/C59DA392_DA7B_4153_AD78_1D4630326630.jpeg",
        alt: "Monetize Content",
      },
    },

    "image-with-content": [],
    review: {
      headline: "Worth every penny",
      content: `"يتطور تحقيق الدخل في أعمالنا باستمرار وقد ساعدنا أكسيس تيب Accesstype في تجربة نماذج اشتراك متعددة لإيجاد التوازن الصحيح. نوصي باستخدام  أكسيس تيب Accesstype لأي شخص يتطلع إلى تحقيق الدخل من المحتوى الخاص به بخلاف الإعلانات ".`,
      designation: "أنكيت ديكشيت ، تسويق رقمي",
      "image-url":
        "https://gumlet.assettype.com/quintype-website/2019-12/3163b395-6987-4d61-8c7f-9fb0ff4c2229/Client_14.png?w=1300&dpr=1.0",
      alt: "Ankit Dikshit, Digital Marketing",
      features: [
        {
          headline: "تسجيل دخول فردي.",
          description: "تكامل مع مجموعة التكنولوجيا الخاصة بك للمصادقة السلسة.",
        },
        {
          headline: "وفر المال في كل معاملة.",
          description:
            "ادفع رسومًا ثابتة شفافة بناءً على الحجم وليس النسب المئوية على كل اشتراك.",
        },
        {
          headline: "متعدد اللغات ومتعدد العملات جاهز.",
          description:
            "خذ إستراتيجية تحقيق الدخل الخاصة بك في جميع أنحاء العالم. تقبل العملات المحلية . بلغتك.",
        },
      ],
    },

    "image-with-content2": [
      {
        headline: "زيادة الإيرادات من خلال الإعلانات الرقمية",
        features: [
          {
            headline: "",
            content: "",
          },
        ],
        url: "https://images.assettype.com/quintype-website/2021-06/1e0f1c00-edb4-44b5-9876-bdddbc1579aa/Ad_slots.png",
        alt: "Monetize content",
        "free-trial": {
          text: "حجز عرض توضيحي",
          link: "/schedule-demo",
          title: " Schedule Demo",
        },
        points: [
          "قم بتوسيع نطاق قصصك واستثمارها عبر جميع الوسائط الرقمية - من أجهزة التلفزيون المتصلة ، إلى مواقع الويب إلى تطبيقات الجوال.",
          "تقديم جميع أنواع الإعلانات الجذابة على مواقع الويب والتطبيقات الخاصة بك. اجعل قرائك راضين عن تجربة غير تدخلية.",
          "لقد عقدنا شراكة مع وكالات تقدم خدمات تسييل الوسائط الرقمية لمساعدة الناشرين في نموهم.",
          "نحن ندعم الإعلانات الأصلية والبرامج مثل اللافتات والإعلانات البينية والمتجاوبة ومقاطع الفيديو.",
        ],
      },
    ],
  },

  for_developers: {
    "top-component": {
      heading: "حرية إملاء الواجهة الأمامية الخاصة بك",
      info: "تقدم كوينتيب حلولاً للمطورين لبدء العمل من خلال نظام إدارة محتوى قوي قائم على البيانات ومستقلللمؤسسات.",
      primaryButtonContent: {
        text: "العرض التوضيحي",
        link: "/schedule-demo",
        linkType: "internal",
      },
      secondaryButtonContent: {
        isRequired: true,
        text: "ستندات المطوريين",
        link: "https://developers.quintype.com/",
        linkType: "external",
      },
      image: {
        url: "https://images.assettype.com/quintype-website/2021-06/63b9a28a-2925-4c83-a97b-ca5f3db70d6e/developer_s_solution_landing_page_2x.png",
        alt: "Workspace",
      },
    },

    "image-with-content": [
      {
        headline: "احمِ التكنولوجيا الخاصة بك في المستقبل",
        features: [
          {
            headline: "",
            content: "",
          },
        ],
        url: "https://images.assettype.com/quintype-website/2021-04/e28bf592-7029-4794-bbbb-7dda3d0e6f90/Solution_to_increase_traffic_28th_april_03.png",
        alt: "payment-gateways",
        "free-trial": {
          text: "جدولة العرض",
          link: "/schedule-demo",
          title: " Schedule Demo",
        },
        points: [
          "يتم تسليم المحتوى عبر واجهات برمجة التطبيقات، مما يمنحك المرونة لإنشاء تجارب رقمية مثل مواقع الويب والتطبيقات وأجهزة الصوت والتقنيات الجديدة.",
          "ميزة التكامل مع العديد من تطبيقات الجهات الخارجية التي ستوفر وقت وجهد فريقك.",
          "قم بتغيير الكود مرة واحدة إلى ايه بي آي API وسوف ينعكس على جميع القنوات.",
        ],
      },
    ],
    review1: {
      content: `"استخدمنا نظام إدارة المحتوى مستقللإطلاق منصتنا الرقمية في عام 2016. من نظام إدارة المحتوى إلى حلول تقييد الوصول، تدير كوينتيب مجموعة التكنولوجيا الكاملة لنا. النظام الأساسي سهل الاستخدام والتخصيص وفقًا لمتطلباتنا ".`,
      designation: "أمارناث جوفينداراجان، الرئيس الرقمي",
      "image-url":
        "https://gumlet.assettype.com/quintype-website/2019-12/db2d7899-378b-4c89-aac3-f6b52163ea8c/swarajya_29.svg?dpr=1.0&q=70&w=480",
      alt: "Amarnath Govindarajan, Digital Head",
      features: [
        {
          headline: "خدمة مُدارة بالكامل",
          description:
            "يتم الاهتمام بالتحديثات والأمان والنسخ الاحتياطية نيابة عنك.",
        },
        {
          headline: "برهان المستقبل",
          description:
            "يقوم فريقنا بانتظام بتحديث التخطيطات والميزات وعمليات الدمج التي ستبقي موقع الويب الخاص بك في طليعة الحزمة.",
        },
        {
          headline: "الهجرة دون عناء",
          description:
            "بياناتك آمنة ويتم نقلها بسلاسة إلى بولد Bold من خلال محرك الترحيل المتقدم الخاص بنا.",
        },
      ],
    },

    "image-with-content2": [
      {
        headline: "نظام إدارة المحتوى التي سيحبها مطوروك",
        features: [
          {
            headline: "",
            content: "",
          },
        ],
        url: "https://images.assettype.com/quintype-website/2021-06/c74d694e-2b51-4ff4-a8fa-222cadc26c0f/ss_3x.png",
        alt: "Workspace",
        "free-trial": {
          text: "حجز عرض توضيحي",
          link: "/schedule-demo",
          title: " Schedule Demo",
        },
        points: [
          "التخصيصات التي لا نهاية لها متاحة على بولد Bold من خلال تضمين أي أكواد نصية جافا.",
          "سيعطي نموذج العمارة المفتوحة والمغلقة القوة لمطوريك على الامتدادات.",
          "Webhooks للتواصل مع تطبيقاتك الخارجية بشأن تقدم حالة قصتك ولتسير عمل الطباعة.",
          "مصادقة  اس اس أو SSO التي يمكن دمجها مع إطار العمل الحالي واستخدامها بشكل مستقل.",
          "يمكن دمج بولد Bold مع منصات الاشتراك مثل أكسيس تايب و بيانو وما إلى ذلك.",
        ],
      },
    ],

    review2: {
      content: `يعد نظام إدارة المحتوى بولد Bold CMS ومنشئ الصفحات Page Builder للنشر الرقمي حلًا رائعًا للتوصيل والتشغيل يوفر مرونة في تصميم الواجهة الأمامية لإعداد موقع ويب مخصص بسرعة، مع سهولة إدارة الواجهة الخلفية للتحديث اليومي وإدارة العملاء. كما نشكر فريق كوينتيب على الدعم في الوقت المناسب لحل المشكلات الأولية بسرعة ومساعدة فريقنا على فهم عمليات الواجهة الخلفية`,
      designation: "جوبين هويدروم، المسؤول (محرر)",
      "image-url":
        "https://images.assettype.com/quintype-website/2021-03/7370917f-b4a9-46b0-9df5-79cf0bbe0447/SBN_Logo.png",
      alt: "Gopen Huidrom, Administrator (edited)",
      features: [
        {
          headline: "خدمة مُدارة بالكامل",
          description:
            "يتم الاهتمام بالتحديثات والأمان والنسخ الاحتياطية نيابة عنك.",
        },
        {
          headline: "برهان المستقبل",
          description:
            "يقوم فريقنا بانتظام بتحديث التخطيطات والميزات وعمليات الدمج التي ستبقي موقع الويب الخاص بك في طليعة الحزمة.",
        },
        {
          headline: "الهجرة دون عناء",
          description:
            "بياناتك آمنة ويتم نقلها بسلاسة إلى بولد Bold من خلال محرك الترحيل المتقدم الخاص بنا.",
        },
      ],
    },

    "image-with-content3": [
      {
        headline: "المزيد من القوة للمطورين بتوعك",
        features: [
          {
            headline: "",
            content: "",
          },
        ],
        url: "https://gumlet.assettype.com/quintype-website/2022-09/a54010e0-3958-4049-ba67-1c43d4e461d6/BOLD_Workspace.png?w=640&dpr=1.0",
        alt: "best headless cms",
        "free-trial": {
          text: "حجز عرض توضيحي",
          link: "/schedule-demo",
          title: " Schedule Demo",
        },
        points: [
          "قم ببناء مواقع الويب والتطبيقات الخاصة بك باستخدام إطار ماليبو Malibu Framework الخاص بنا للحصول على طريقة أسهل لاستهلاك واجهات برمجة التطبيقات.",
          "تحكم أفضل في القياس والتخزين المؤقت.",
          "أداء عالي السرعة للصفحة ومحسّن لـويب كور فايتالزعن طريق تقليل CLS والعملية التي يتم خلالها توليد كود HTML الخاص بتطبيقات جافاسكريبت الحديثة في الخادم عوضا عن المتصفح وتأجيل البرامج النصية الخاصة بطرف ثالث.",
        ],
      },
    ],

    "image-with-content4": [
      {
        headline: "أحدث القدرات التقنية",
        features: [
          {
            headline: "",
            content: "",
          },
        ],
        url: "https://images.assettype.com/quintype-website/2021-06/6d0ed5f8-e128-41bb-8e58-13d9a4a5a637/developer_s_solution_landing_page_3x.png",
        alt: "Workspace",
        "free-trial": {
          text: "حجز عرض توضيحي",
          link: "/schedule-demo",
          title: " Schedule Demo",
        },
        points: [
          "شبكة سي دي إن CDN الرائدة في الصناعة لتحسين توفر المحتوى وأدائه.",
          "أمان واستضافة وأداء على مستوى المؤسسة مع ضمان وقت تشغيل البنية التحتية بنسبة 99.5٪.",
          "لوحة مراقبة التطبيق لمراقبة مقاييس البنية التحتية الخاصة بك - وحدة المعالجة المركزية والذاكرة والسجلات.",
        ],
      },
    ],
  },
  magazine_publishers: {
    "top-component": {
      heading: "أنشئ مجلات رقمية مذهلة",
      info: "انتقل إلى مساحة رقمية وقلل من تكاليف الطباعة والتوزيع اليوم. أنشئ مجلاتك الرقمية وانشرها ووزعها واستثمرها باستخدام نظام إدارة المحتوى بولد Bold CMS",
      primaryButtonContent: {
        text: "ابدأ الإصدار التجريبي المجاني",
        link: "/sign-up",
        title: "free trial",
        linkType: "internal",
      },
      secondaryButtonContent: {
        isRequired: true,
        text: "حجز عرض توضيحي",
        link: "/schedule-demo",
        linkType: "internal",
      },
      image: {
        url: "/magazine-publishers/top-component.png",
        alt: "Digital Magazines",
      },
    },

    benefits: {
      headline: "الفوائد",
      benefit: [
        {
          imageUrl:
            "https://images.assettype.com/quintype-website/2021-08/9cc25e91-4007-4603-8705-64664c1ea7f0/magzine_landing_page.png?w=230",
          alt: "Quintype Benefit",
          text: "أنشئ مرة واحدة، وانشر في كل مكان",
        },
        {
          imageUrl:
            "https://images.assettype.com/quintype-website/2021-08/a3d4d770-a5a9-43e6-8b09-8583908ba6ab/ss.png?w=230",
          alt: "Quintype Benefit",
          text: "تتكامل مع أنظمة الطباعة",
        },
        {
          imageUrl:
            "https://images.assettype.com/quintype-website/2021-08/40d2f451-f465-4efa-b4ca-a390229ae4e5/s.png?w=230",
          alt: "Quintype Benefit",
          text: "تجميع طريقة الاشتراك لإصدارتك المطبوعة و الرقمية",
        },
        {
          imageUrl:
            "https://images.assettype.com/quintype-website/2021-08/6a37ec91-7382-469f-bf75-7a06442e5ca0/magzine_landing_page_2x.png?w=230",
          alt: "Quintype Benefit",
          text: "إنشاء محتوى بكل سهولة ",
        },
        {
          imageUrl:
            "https://images.assettype.com/quintype-website/2021-08/fde0cabe-ceed-4ff9-a7da-d0c4fb37fe80/magzine_landing_page_2x__1_.png?w=230",
          alt: "Quintype Benefit",
          text: "سير العمل التحريري السلس",
        },
        {
          imageUrl:
            "https://images.assettype.com/quintype-website/2021-08/49790d5a-a278-4dfb-b445-a4117fdad526/magzine_landing_page__3_.png?w=230",
          alt: "Quintype Benefit",
          text: "حقق دخل عن طريق الإعلانات و جوجل DFP",
        },
        {
          imageUrl:
            "https://images.assettype.com/quintype-website/2021-08/5147bce3-e516-42a6-b1b1-5c8696b0bc66/magzine_landing_page__1_.png?w=230",
          alt: "Quintype Benefit",
          text: "تنظيم الإصدارات والمجلدات بشكل فعال",
        },
        {
          imageUrl:
            "https://images.assettype.com/quintype-website/2021-08/beadbe95-7235-4123-b36b-730cdc1383af/magzine_landing_page__2_.png?w=230",
          alt: "Quintype Benefit",
          text: "لا مزيد من الترميز أو جولات المراجعة التي لا نهاية لها",
        },
      ],
      buttonContent: {
        text: "ابدأ الإصدار التجريبي المجاني",
        link: "/sign-up",
        title: "free trial",
        linkType: "internal",
      },
    },

    "image-with-content": [
      {
        headline: "حوّل طباعتك إلى رقمية",
        features: [
          {
            headline: "",
            content: "",
          },
        ],
        url: "/magazine-publishers/image-content1.png",
        alt: "Digital Print",
        "free-trial": {
          text: "ابدأ الإصدار التجريبي المجاني",
          link: "/sign-up",
          title: "free trial",
          linkType: "internal",
        },
        points: [
          "اسمح لقرائك بالوصول إلى جميع نسخك في أي وقت وفي أي مكان.",
          "قم بزيادة مستوى رؤية المحتوى الخاص بك عن طريق فهرسة جميع قصصك على بحث جوجل واكتشافها.",
          "تفاعل بشكل أفضل مع القراء من خلال تمكين المحادثات الهادفة الوصول إلى جمهور أوسع.",
        ],
      },
    ],

    "image-with-content2": [
      {
        headline: "استثمر مجلتك الرقمية",
        features: [
          {
            headline: "",
            content: "",
          },
        ],
        url: "/magazine-publishers/image-content2.png",
        alt: "Digital Magazine",
        "free-trial": {
          text: "ابدأ الإصدار التجريبي المجاني",
          link: "/sign-up",
          title: "free trial",
          linkType: "internal",
        },
        points: [
          "انشر القصص على موقع الويب والتطبيقات الخاصة بك من مصدر تأليف واحد. بتزامن بنسبة 100%.    ",
          "قم بتطوير أفضل التطبيقات مثل سي إن إن، و بزفيد وما إلى ذلك بتكلفة 10٪.",
          "استراتيجية الذهاب إلى السوق بشكل أسرع لتحقيق الدخل.",
        ],
      },
    ],
    "client-awards": {
      headline:
        "تثق شركات النشر الرقمية الأكثر نجاحًا في العالم في كوينتيب لخدمة المحتوى لمليارات من مستهلكي المحتوى.",
      imageUrl: "/magazine-publishers/client-awards.png",
      alt: "Client Awards",
    },
    faqs: {
      headline: "أسئلة وأجوبة",
      faq: [
        {
          question:
            "ما أنواع المجلات التي يمكنك إنشاؤها باستخدام نظام إدارة المحتوى بولد Bold CMS؟",
          answer:
            "يمكنك إنشاء جميع أنواع المجلات الرقمية باستخدام نظام إدارة المحتوى بولد Bold CMS. نحن نلبي احتياجات المجلات الاستهلاكية والتجارية والمهنية والداخلية. يتم أيضًا دعم مجالات مثل - الأخبار، والتصوير الفوتوغرافي، والطعام، والسفر، والزفاف، ورعاية الأطفال، وما إلى ذلك .",
        },
        {
          question: "ما الذي يصنع مجلة رقمية؟",
          answer:
            "احصل على محتوى مجلتك المطبوعة على الإنترنت من خلال مجلة رقمية. قم بتحديث المشكلات والسماح للقراء بالوصول إلى المحتوى الخاص بك في أي مكان وفي أي وقت.",
        },
        {
          question: "كيف تصنع مجلة رقمية مع نظام إدارة المحتوى بولد Bold CMS ؟",
          answer:
            "ابدأ مع نظام إدارة المحتوى بولد Bold CMS اليوم وقم بإدارة مجلتك المطبوعة والرقمية من خلال مثيل واحد. استمتع بميزات نظام إدارة محتوى عالية الجودة المعتمدة من الصناعة ولا تدع الترميز يعيق الطريق. استمتع بسير العمل السهل وقوالب القصة والوسائط المتعددة الغنية والمزيد.",
        },
        {
          question:
            "هل أحتاج إلى مهارات التصميم أو البرمجة لاستخدام صانع مجلتك؟",
          answer:
            "نعتقد أنه لا ينبغي أن يُتوقع من صانعي المحتوى القيام بالمهمة التقنية الثقيلة. يمكنك إنشاء مجلتك دون الحاجة إلى تعلم كيفية البرمجة. أداة تخصيص التخطيط - أداة إنشاء الصفحات تسمح لك بالاختيار من بين مجموعة كبيرة من القوالب. الواجهة سهلة الاستخدام ومصممة خصيصًا لتناسب احتياجات المحتوى الخاص بك.",
        },
        {
          question: "ما هي فوائد استخدام حلول النشر الرقمي للمجلات؟",
          answer:
            "يمكنك تقليل تكاليف الطباعة والتوزيع بهامش كبير. استفد من نطاق أوسع من الجمهور لم يسبق له مثيل. لديك كل ما تبذلونه من القضايا المتاحة للشراء. جرب خيارات الاشتراك المختلفة دون الحاجة إلى القلق بشأن التوزيع.",
        },
        {
          question:
            "ما محتويات المجلات التي يدعمها نظام إدارة المحتوى بولد Bold CMS؟",
          answer:
            "يحتوي نظام إدارة المحتوى بولد Bold CMS على ميزات معتمدة من الصناعة تساعد منشئي المحتوى على الإنشاء دون أي احتكاك. سواء كان الأمر يتعلق بالعمل أو السفر أو التصوير الفوتوغرافي أو الموضة أو المجلات الإخبارية، فإننا ندعم جميع أنواع المجلات.",
        },
        {
          question:
            "المجلات عبر الإنترنت التي تم إنشاؤها بواسطة  نظام إدارة المحتوى بولد Bold CMS مناسبة للجوّال؟",
          answer:
            "نحن نتفهم حاجتك إلى تلبية احتياجات جمهور الجوال أولاً. يضمن نظام إدارة المحتوى بولد Bold CMS إمكانية الوصول إلى المحتوى الخاص بك على جميع الأجهزة - سطح المكتب والجوال دون أي مساومة على الأداء والتصميم.",
        },
        {
          question: "هل لديك خطة مجانية؟",
          answer:
            "هل تعلم أنه يمكنك الحصول على مسار مجاني لمدة 14 يومًا باستخدام نظام إدارة المحتوى بولد Bold CMS. قم بجدولة عرض توضيحي معنا وتعرف على كل الميزات التي تم تحميلها. اكتشف تجربة إدارة المحتوى المتميزة اليوم.",
        },
      ],
    },
  },
};

export const arabiclastImageComentComponentData = {
  headline: "التكلفة الإجمالية المنخفضة للملكية",
  text: "تحصل على نظام إدارة محتوى ممتاز دون الحاجة إلى تحمل تكلفة تطويره. نحن نشارك سعر تطوير وترقية دعمنا الفني عبر عملائنا. هذا يعني أنه يمكنك الحصول على أفضل نظام إدارة محتوى يتطور باستمرار دون الحاجة إلى دفع كامل الأموال التي يتم إنفاقها على الموارد.",
  imageUrl:
    "https://images.assettype.com/quintype-website/2021-03/2dbb3597-b7b8-4126-a622-69a3c6ad1312/Low_total_CMS_solution_for_small_business_publishers.png",
  isButtonPresent: false,
  alt: "Low Total Cost of Ownership",
};

export const arabictrafficTestimonialSolutions = {
  headline:
    "نجح برابهات خبر في توسيع نطاق طموحاته الرقمية باستخدام نظام إدارة المحتوى بولد Bold CMS من كوينتايب . ففي غضون 30 يومًا من الإطلاق، زادت مشاهدات الصفحة بأكثر من 35٪ من الزائرين من البحث والقنوات الاجتماعية واستمرت في النمو أكثر من 6 أضعاف في الربعين المقبلين.",

  "read-more": {
    text: "تحميل",
    url: "/case-study/prabhat-khabar",
    title: "case study section",
  },
};

export const arabicmonetizeTestimonialSolutions = {
  headline:
    "قامت كوينتيب سواراجيا المنصة لتعزيز نفسها والوصول إلى مسار النمو من خلال الاشتراكات والمشاركة والمرونة تمامًا في الفضاء الرقمي المليء بالمعلومات اليوم. قم بتنزيل دراسة الحالة لمعرفة كيفية القيام بذلك.",

  "read-more": {
    text: "تحميل",
    url: "/case-study/swarajya",
    title: "case study section",
  },
};
