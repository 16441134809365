import styled from "styled-components";

export const ThankyouPageWrapper = styled.div`
    max-width: ${props => props.theme.containerWidth};
    margin: 0 auto;
    padding: 32px 24px;
    @media (min-width: ${props => props.theme.breakPoints.tablet}) {
        padding: 80px 24px;
    }
`;

export const Heading = styled.h1`
    font-family: aktiv-grotesk;
    font-size: 1.75rem;
    font-weight: 700;
    color: rgb(36, 36, 36);
    text-align: center;
    line-height: 1.2;
    @media (min-width: ${props => props.theme.breakPoints.desktop}){
        font-size: 2.5rem;
    }
`

export const SubHeading = styled.p`
    font-family: aktiv-grotesk; 
    font-weight: 400;
    color: rgb(40, 40, 40);
    text-align: center;
    @media (min-width: ${props => props.theme.breakPoints.desktop}){
        font-size: 1.25rem;
    }
`

export const SuccessWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
    @media (min-width: ${props => props.theme.breakPoints.desktop}){
        flex-direction: row;
        justify-content: space-between;
        padding: 4rem 0;
    }
`
export const SuccessBox = styled.a`
    width: 300px;
    height: 150px;
    color: rgb(36,36,36);
    margin-bottom: 0.5rem;
    padding 0.5rem 1rem;
    border: 1.5px solid black;
    border-radius: 8px;
`
export const StoryHead = styled.div`
    font-family: aktiv-grotesk;
    font-weight: 700;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
`
export const Story = styled.div`
    font-family: aktiv-grotesk;
    font-weight: 400;
    font-size: 1.2rem;
`