import React from "react";

const Facebook = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 12 21"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Facebook</title>

      <defs></defs>
      <g
        id="Facebook"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="home"
          transform="translate(-1118.000000, -5646.000000)"
          fill="#535B6C"
        >
          <path
            d="M1126.57828,5649.6442 C1126.48387,5649.6442 1126.38293,5649.67525 1126.27328,5649.73594 C1126.16363,5649.79663 1126.06995,5649.8799 1125.99225,5649.98646 C1125.91383,5650.09231 1125.85138,5650.22145 1125.8049,5650.37318 C1125.7577,5650.5249 1125.73446,5650.69215 1125.73446,5650.87422 L1125.73446,5653.28839 L1130,5653.28839 L1130,5656.70536 L1125.73446,5656.70536 L1125.73446,5667 L1121.65628,5667 L1121.65628,5656.70536 L1118,5656.70536 L1118,5653.28839 L1121.65628,5653.28839 L1121.65628,5651.23906 C1121.65628,5650.54043 1121.78118,5649.87214 1122.03098,5649.23419 C1122.28079,5648.59695 1122.62499,5648.03522 1123.06215,5647.549 C1123.50003,5647.06348 1124.01561,5646.68382 1124.6089,5646.41001 C1125.2029,5646.1369 1125.85936,5646 1126.57828,5646 L1130,5646 L1130,5649.6442 L1126.57828,5649.6442"
            id="fb-copy"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default Facebook;
