import styled from "styled-components";

export const Container = styled.section`
  background-image: ${(props) => props.theme.color.background};
  padding: 80px 0;
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  color: ${(props) => props.theme.color.gray2};
`;

export const InnerContainer = styled.div`
  padding: 0 24px;
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
    max-width: ${(props) => props.theme.containerWidth};
    margin: auto;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }
`;

export const HeadlineContainer = styled.div`
  grid-column: 3/11;
  padding-bottom: 32px;
  @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
    padding-bottom: 64px;
  }
`;

export const LinkItem = styled.div`
  text-align: center;
  grid-column: 1/13;
  padding-top: 24px;
`;
