export const getDemoData = {
  title: "Stop wrestling with technology",
  content:
    "Create, manage, distribute and monetize content better. Our solutions for digital publishing have helped media organizations like yours do more with less. Schedule a demo of our platform and learn how you can:",
  pageviews: [
    { label: "less than 5 million", value: "Less than 5 Million" },
    { label: "5-10 million", value: "5 - 10 Million" },
    { label: "10-25 million", value: "10 - 25 Million" },
    { label: "25-50 mllion", value: "25 - 50 Million" },
    { label: "50+ million", value: "50+ Million" },
  ],
  items: [
    { label: "Exploring CMS options", value: "Exploring CMS options" },
    { label: "Looking for a demo", value: "Looking for a demo" },
    { label: "Ready to switch", value: "Ready to switch" }
  ],
  contentlistData: [
    "Manage content better",
    "Increase editorial efficiency",
    "Reduce technology costs",
    "Increase content reach",
    "Explore reader revenue strategies",
    "Improve audience engagement"
  ]
};
