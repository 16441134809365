import React from 'react'
import { useLocation } from 'react-router-dom';

import { ThankyouPageWrapper,Heading,SubHeading,SuccessWrapper,SuccessBox,StoryHead,Story } from './style'
function ThankYou() {
  const location = useLocation();
  // eslint-disable-next-line no-mixed-operators
  const { message } = location.state && location.state.data || "Thank You for requesting a live demo!";
  return (
    <ThankyouPageWrapper>
       <Heading>{message}</Heading>
       <SubHeading>One of our solution experts will be in touch shortly. In the meantime, feel free to check out the latest Quintype success stories and helpful resources. </SubHeading>
       <SuccessWrapper>
        <SuccessBox href="https://www.quintype.com/case-study/bloombergquint">
            <StoryHead>Customer Story</StoryHead>
            <Story>How Bloomberg Quint achieved a tremendous yearly growth of 300%</Story>
        </SuccessBox>
        <SuccessBox href="https://www.quintype.com/blog/tech/newspaper-cms-everything-you-need-to-know">
            <StoryHead>Blog</StoryHead>
            <Story>Newspaper CMS: Everything you need to know</Story>
        </SuccessBox>
        <SuccessBox href="https://www.quintype.com/blog/people/awards">
            <StoryHead>Awards</StoryHead>
            <Story>Quintype has won numerous awards for it's innovative technology</Story>
        </SuccessBox>
       </SuccessWrapper>
    </ThankyouPageWrapper>
  )
}

export default ThankYou