/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import axios from "axios";
import useForm from "react-hook-form";
import { useHistory } from "react-router-dom";
import {
  getHTMLStringwithLSdata,
  getLocalStorageData,
  getReffereUrl,
} from "../../../utils/local-storage";
import {
  Wrapper,
  ContentWrapper,
  FormWrapper,
  Form,
  List,
  Item,
  TitleContainer,
  Message
} from "../../get-demo/style";
import PrimaryButton from "../../shared/primaryButton/index";
import loader from "../../icons/loader.svg";
import { PhoneInputField } from "../../form-controls/phone-input/index";
import { TextField, EmailField } from "../../form-controls/index";
import { ListTitle, TrialButtonWrapper } from "../../free-trial/style";
import CheckBox from "./checkbox/index";
import { publisherData } from "../../../config/publisher";

import H1 from '../../shared/h1/index'
import Para from '../../shared/para-reg/index'

import { individualCaseStudyData } from "./data";

const { MANDRILL_API_KEY } = publisherData;

export default function ClientCaseStudy({ data, match }) {
  const { register, handleSubmit, errors, setValue, clearError } = useForm();
  const [showSuccessMessage, setSuccessMessage] = useState(false);
  const [emailBeingSent, updateEmailBeingSent] = useState(false);
  const [phoneNumber, updatePhoneNumber] = useState("");
  const { title, content, listData, listTitle, bottomContent } = data;
  const history = useHistory();
  useEffect(() => {
    register({ name: "phoneNumber" });
  }, [register]);

  const {GA_Client_ID,fc_medium,fc_campaign,fc_landing,fc_referrer,fc_source,country_code} = gaconnector.getCookieValues();
  const onSubmit = async data => {
    updateEmailBeingSent(true);
    let { name, email, designation, company, phoneNumber, contactMe } = data;

    phoneNumber = phoneNumber ? phoneNumber : "Nil";
    company = company ? company : "Nil";

    contactMe = contactMe ? "Yes" : "No";
    const utmData = getLocalStorageData();
    const referrerUrl = getReffereUrl();
    
    const utmPresentableData = utmData && getHTMLStringwithLSdata(utmData);
    const marketingInsights = utmPresentableData;
    let source = "";
    let medium = "";
    let campaign = "";
    let term = "";
    if( utmData)
    {
      source = utmData.source || "";
      medium = utmData.medium || "";
      campaign = utmData.campaign || "";
      term = utmData.term || "";
    }

    let customMessage = `Downloaded case study name: ${individualCaseStudyData[match.params.id].title}`;
    const listData = {
      email_address: email,
      merge_fields:{
        FNAME: name,
        PHONE: phoneNumber,
        MERGE6: company,
        MERGE8: customMessage,
        MERGE9: source,
        MERGE10: medium,
        MERGE11: campaign,
        MMERGE12: term,
        FORMURL: window.location.href,
        REFURL: referrerUrl,
        FC_SOURCE: fc_source,
        FC_MEDIUM: fc_medium,
        FC_CAMPAIG: fc_campaign,
        FC_LP: fc_landing,
        FC_REF: fc_referrer,
        GA_CID: GA_Client_ID, 
        COUNTRY_IP: gaconnector.getCookieValues().country,
        LEAD_TYPE: "Case Study"
      },
      status: "subscribed",
    };
    console.log("listdata", listData);
    axios
      .post("/subscribe", listData)
      .then(async (res) => {
      console.log(res)
      if(res.status === 200){
        updateEmailBeingSent(false);
        setSuccessMessage(true);
        const secondResponse = await axios({
          method: "post",
          url: "https://mandrillapp.com/api/1.0/messages/send-template.json",
          data: {
            key: MANDRILL_API_KEY,
            template_name: "self-service-attempted-to-sign-up",
            template_content: [
              {
                name: "main",
                content: `<div>Hi ${name}</div><a href=${
                  individualCaseStudyData[match.params.id].pdf
                }>Here</a> is the link to the case study you had requested. <p>Thank you for taking the time to download this document. Want to learn more about how Quintype can help your digital publishing business? Hit reply to schedule a demo with our product specialists.</p> </div>`
              }
            ],
            message: {
              text: "",
              subject: `Download Case Study`,
              from_email: "sales@quintype.com",
              from_name: "Quintype",
              to: [
                {
                  email,
                  type: "to"
                }
              ]
            }
          }
        });
        history.push('/thank-you',{ data: { message: 'Thank you for downloading the case study!' } });
      }
    }).catch( async (err)=>{
      const text = err.response.data.response.text
      const {title} = JSON.parse(text);
      if(title === "Member Exists"){
        axios.put("/update",listData).then(async (res)=>{
          if(res.status === 200)
          {
            updateEmailBeingSent(false);
            setSuccessMessage(true);
            const secondResponse = await axios({
              method: "post",
              url: "https://mandrillapp.com/api/1.0/messages/send-template.json",
              data: {
                key: MANDRILL_API_KEY,
                template_name: "self-service-attempted-to-sign-up",
                template_content: [
                  {
                    name: "main",
                    content: `<div>Hi ${name}</div><a href=${
                      individualCaseStudyData[match.params.id].pdf
                    }>Here</a> is the link to the case study you had requested. <p>Thank you for taking the time to download this document. Want to learn more about how Quintype can help your digital publishing business? Hit reply to schedule a demo with our product specialists.</p> </div>`
                  }
                ],
                message: {
                  text: "",
                  subject: `Download Case Study`,
                  from_email: "sales@quintype.com",
                  from_name: "Quintype",
                  to: [
                    {
                      email,
                      type: "to"
                    }
                  ]
                }
              }
            });
            history.push('/thank-you',{ data: { message: 'Thank you for downloading the case study!' } });
          }
        }).catch(async (e)=>{
          console.log("error", e.response)
          try {
            console.log("mailchimp error", err);
            const response = await axios({
              method: "post",
              url: "https://mandrillapp.com/api/1.0/messages/send-template.json",
              data: {
                key: MANDRILL_API_KEY,
                template_name: "self-service-attempted-to-sign-up",
                template_content: [
                  {
                    name: "main",
                    content: `<div>Name: ${name}</div><div>Email: ${email}</div><div>Mobile: ${phoneNumber}</div><div>Designation: ${designation}</div><div>Company: ${company}</div><div>Other data:  ${marketingInsights} </div><div>Contact me: ${contactMe}</div><div>Case study downloaded: ${
                      individualCaseStudyData[match.params.id].title
                    }</div>
                      `
                  }
                ],
                message: {
                  text: "",
                  subject: `Case Study Download: ${name}`,
                  from_email: "sales@quintype.com",
                  from_name: "Sales",
                  to: [
                    {
                      email: "sales@quintype.com",
                      type: "to"
                    }
                  ]
                }
              }
            });
            if (response.status === 200) {
              updateEmailBeingSent(false);
              setSuccessMessage(true);
              history.push('/thank-you',{ data: { message: 'Thank you for downloading the case study!' } });
            }
          } catch (e) {
            updateEmailBeingSent(false);
          }
        })
      }
     
    })
   
  };

  const buttonContent = emailBeingSent ? (
    <img src={loader} alt="loader" />
  ) : showSuccessMessage ? (
    "DONE"
  ) : (
    "SUBMIT"
  );

  const buttonDisabledState = emailBeingSent
    ? true
    : showSuccessMessage
    ? true
    : false;
  return (
    <Wrapper>
      <ContentWrapper>
        <TitleContainer><H1 small={true}>{title}</H1></TitleContainer>
        <Para>{content}</Para>
        {listTitle && <ListTitle>{listTitle}</ListTitle>}
        {listData && (
          <List>
            {listData.map((data, index) => {
              return <Item key={index}>{data}</Item>;
            })}
          </List>
        )}
        {bottomContent && <Para>{bottomContent}</Para>}
      </ContentWrapper>
      <FormWrapper>
        <Form id='casestudy' onSubmit={handleSubmit(onSubmit)}>
          <TextField
            label="Name"
            name="name"
            register={register}
            required
            errors={errors}
          />
          <EmailField
            label="Email"
            name="email"
            register={register}
            required
            errors={errors}
          />
          <TextField
            label="Designation"
            name="designation"
            register={register}
            required
            errors={errors}
          />
          <TextField
            label="Company"
            name="company"
            register={register}
            errors={errors}
          />
          <PhoneInputField
            name="phoneNumber"
            country="in"
            label="Phone Number"
            value={phoneNumber}
            onChange={text => {
              setValue("phoneNumber", text);
              updatePhoneNumber(text);
              clearError("phoneNumber");
            }}
            errors={errors}
          />
          <CheckBox
            text="Contact me to schedule a demo"
            name="contactMe"
            register={register}
          />

          <TrialButtonWrapper>
            <PrimaryButton disabled={buttonDisabledState}>
              {buttonContent}
            </PrimaryButton>
          </TrialButtonWrapper>
          {Object.keys(errors).length > 0 && (
            <Message type="error">
              Oops! Please fill the mandatory fields. Some information filled is
              invalid.
            </Message>
          )}
          {showSuccessMessage && (
            <Message id="success" type="success">
              Thank you.
              <br />
              We have sent this case study to your email. Feel free to contact
              us at sales@quintype.com for more information.
            </Message>
          )}
        </Form>
      </FormWrapper>
    </Wrapper>
  );
}
