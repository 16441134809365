import React from "react";
import PropTypes from "prop-types";

import {
    Container,
    InnerContainer,
    HeadlineContainer,
    ImageContainer,
    Image,
} from "./style";

import H2 from "../../../shared/h2";

function ClientAwards({ data }) {
    return (
        <Container>
            <InnerContainer>
                <HeadlineContainer>
                    <H2> {data.headline}</H2>
                </HeadlineContainer>
                <ImageContainer>
                    <Image src={data.imageUrl} alt={data.alt} />{" "}
                </ImageContainer>
            </InnerContainer>
        </Container>
    );
}

ClientAwards.propTypes = {
    data: PropTypes.shape({
        headline: PropTypes.string,
        imageUrl: PropTypes.string,
        alt: PropTypes.string,
    })
};

export default ClientAwards;
