import aWeber from "./images/aWeber.png";
import alea from "./images/alea.png";
import anchorFm from "./images/anchorFm.png";
import appleNews from "./images/appleNews.png";
import asana from "./images/asana.png";
import bitgravity from "./images/bitgravity.png";
import brightcove from "./images/brightcove.png";
import cheetahmobile from "./images/cheetahmobile.png";
import convertKit from "./images/convertKit.png";
import copyscape from "./images/copyscape.png";
import dailymotion from "./images/dailymotion.png";
import dailyhunt from "./images/dailyhunt.png";
import dilmotQa from "./images/dilmotQa.png";
import disqus from "./images/disqus.png";
import drip from "./images/drip.png";
import dsPhotoEditorSdk from "./images/dsPhotoEditorSdk.png";
import fb from "./images/fb.png";
import gettyImages from "./images/gettyImages.png";
import googleAmp from "./images/googleAmp.png";
import googleAnalytics from "./images/googleAnalytics.png";
import googleAssistant from "./images/googleAssistant.png";
import googleForms from "./images/googleForms.png";
import googleNew from "./images/googleNew.png";
import googleReviews from "./images/googleReviews.png";
import googleTagManager from "./images/googleTagManager.png";
import ians from "./images/ians.png";
import insta from "./images/insta.png";
import integrationIconSurveyMonkey from "./images/integrationIconSurveyMonkey.png";
import iZooto from "./images/iZooto.png";
import jwPlayer from "./images/jwPlayer.png";
import linkedin from "./images/linkedin.png";
import mailchimp from "./images/mailchimp.png";
import mandrill from "./images/mandrill.png";
import metype from "./images/metype.png";
import moengage from "./images/moengage.png";
import onesignal from "./images/onesignal.png";
import pinterest from "./images/pinterest.png";
import polly from "./images/polly.png";
import qUora from "./images/qUora.png";
import rssFeed from "./images/rssFeed.png";
import sendGrid from "./images/sendGrid.png";
import soundCloud from "./images/soundCloud.png";
import spotify from "./images/spotify.png";
import ted from "./images/ted.png";
import twitter from "./images/twitter.png";
import vimeo from "./images/vimeo.png";
import whatsapp from "./images/whatsapp.png";
import giphyIcon from "./images/giphy.png";
import youtube from "./images/youtube.png";
import accesstype from "./images/accesstype.png";
import audioboom from "./images/audioboom.png";
import fbAmp from "./images/fbAmp.png";
import googleDfp from "./images/googleDfp.png";
import yahooNews from "./images/yahoo-news.png"

export const title = "Apps and integrations";

export const subTitle = "We Love.";

export const integrations = [
{
  image: polly,
  category: "create",
  displayName: "Amazon Polly",
},  
{
  image: appleNews,
  category: "distribute",
  displayName: "Apple News",
},
{
  image: asana,
  category: "manage",
  displayName: "Asana",
},
{
  image: alea,
  category: "distribute",
  displayName: "Alexa",
},
{
  image: anchorFm,
  category: "create",
  displayName: "Anchor FM",
},
{
  image: aWeber,
  category: "distribute",
  displayName: "AWeber",
},
{
  image: bitgravity,
  category: "create",
  displayName: "Bitgravity",
},
{
  image: brightcove,
  category: "create",
  displayName: "Brightcove",
},
{
  image: cheetahmobile,
  category: "distribute",
  displayName: "Cheetah Mobile",
},
{
  image: copyscape,
  category: "create",
  displayName: "Copyscape",
},
{
  image: convertKit,
  category: "engage",
  displayName: "ConvertKit",
},
{
  image: drip,
  category: "engage",
  displayName: "Drip",
},
{
  image: dailymotion,
  category: "create",
  displayName: "Dailymotion",
},
{
  image: dailyhunt,
  category: "distribute",
  displayName: "Dailyhunt",
},
{
  image: dilmotQa,
  category: "create",
  displayName: "DilmotQa",
},
{
  image: disqus,
  category: "engage",
  displayName: "Disqus",
},
{
  image: dsPhotoEditorSdk,
  category: "create",
  displayName: "DS Photo Editor SDK"
},
{
  image: fb,
  category: "distribute",
  displayName: "Facebook",
},
{
  image: gettyImages,
  category: "create",
  displayName: "Getty Images",
},
{
  image: giphyIcon,
  category: "create",
  displayName: "Giphy",
},

{
  image: googleAnalytics,
  category: "manage",
  displayName: "Google Analytics",
},
{
  image: googleForms,
  category: "create",
  displayName: "Google Forms",
},
{
  image: googleNew,
  category: "distribute",
  displayName: "Google News",
},
{
  image: googleAmp,
  category: "distribute",
  displayName: "Google AMP",
},
{
  image: googleTagManager,
  category: "manage",
  displayName: "Google Tag Manager",
},
{
  image: googleAssistant,
  category: "distribute",
  displayName: "Google Assistant",
},
{
  image: googleReviews,
  category: "distribute",
  displayName: "Google Reviews",
},
{
  image: ians,
  category: "distribute",
  displayName: "Indo-Asian News Service",
},
{
  image: insta,
  category: "distribute",
  displayName: "Instagram",
},
{
  image: iZooto,
  category: "distribute",
  displayName: "iZooto"
},
{
  image: jwPlayer,
  category: "create",
  displayName: "JW Player",
},
{
  image: linkedin,
  category: "distribute",
  displayName: "Linkedin",
},
{
  image: mandrill,
  category: "engage",
  displayName: "Mandrill",
},
{
  image: mailchimp,
  category: "engage",
  displayName: "Mailchimp",
},
{
  image: metype,
  category: "engage",
  displayName: "Metype",
},
{
  image: moengage,
  category: "distribute",
  displayName: "Moengage",
},
{
  image: onesignal,
  category: "distribute",
  displayName: "Onesignal",
},
{
  image: pinterest,
  category: "distribute",
  displayName: "Pinterest",
},
{
  image: qUora,
  category: "all",
  displayName: "Quora",
},
{
  image: rssFeed,
  category: "distribute",
  displayName: "RSS Feed",
},
{
  image: sendGrid,
  category: "engage",
  displayName: "SendGrid",
},
{
  image: soundCloud,
  category: "create",
  displayName: "SoundCloud",
},
{
  image: spotify,
  category: "distribute",
  displayName: "Spotify",
},
{
  image: ted,
  category: "create",
  displayName: "TED",
},
{
  image: twitter,
  category: "distribute",
  displayName: "Twitter",
},
{
  image: integrationIconSurveyMonkey,
  category: "create",
  displayName: "Survey Monkey",
},
{
  image: vimeo,
  category: "create",
  displayName: "Vimeo",
},
{
  image: whatsapp,
  category: "distribute",
  displayName: "Whatsapp",
},
{
  image: youtube,
  category: "create",
  displayName: "Youtube",
},

{
  image: accesstype,
  category: "monetize",
  displayName: "Accesstype",
},

{
  image: audioboom,
  category: "create",
  displayName: "Audioboom",
},

{
  image: googleDfp,
  category: "monetize",
  displayName: "Google DFP",
},

{
  image: fbAmp,
  category: "distribute",
  displayName: "Facebook Instant Articles",
},
{
  image: yahooNews,
  category: "distribute",
  displayName: "Yahoo News",
}
]
