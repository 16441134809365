export const publisherData =  {
  "MANDRILL_API_KEY": "gTnHpoQHND2qWd3eXhfnJw",
  "ATTEMPT_SIGNUP_RECIPIENT": "sales@quintype.com",
  "ATTEMT_SIGNUP_RECIPIENT1": "quintypenewsignup@robot.zapier.com",
  "GET_IN_TOUCH_RECIPIENT": "sales@quintype.com",
  "JOB_APPLICATION_RECIPIENT": "careers@quintype.com",
  "LEAD_PAGES_SENDER": "sriharsha.nallamilli@quintype.com",
  "gtmId": "GTM-T6ZDK2V",
  "MAILCHIMP_API_KEY": "c92e5bea3c9f15e43491ada769ec6a55-us18",
  "MAILCHIMP_LIST_ID": "c2ec62ed66",
  "SERVER_PREFIX": "us18",
}

export const emailRecipientData = {
  "Human Resources": "careers@quintype.com",
  "Customer Support": "sales@quintype.com",
  "Sales":"sales@quintype.com",
  "Marketing": "sales@quintype.com",
  "Partnerships": "sales@quintype.com"
}
