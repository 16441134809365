import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const ImageContainer = styled.div`
  background: url(${props => props.bgUrl}) no-repeat center / cover;
  width: 100%;
  height: 300px;
`;

export const MainContainer = styled.section`
  padding: 80px 24px;
  @media (min-width: ${props => props.theme.breakPoints.tablet}) {
    max-width: ${props => props.theme.containerWidth};
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    padding: 32px 0 80px;
    margin: auto;
  }
`;

export const SubHeadlineContainer = styled.div`
 margin-bottom: 8px;
 
`

export const InnerContainer = styled.div`
  grid-column: 3/10;
`;

export const HeadlineContainer = styled.div`
  margin: 0;
  padding-bottom: 8px;
`;

export const TextContainer = styled.div`
  margin-bottom: 48px;
`;

export const CaseStudyWrapper = styled.div`
  display: grid;
  @media (min-width: ${props => props.theme.breakPoints.tablet}) {
    grid-template-columns: repeat(2, 6fr);
  }
`;

export const Image = styled.img`
  max-width: 100%;
  margin-bottom: 8px;
`;

export const Title = styled.h3`
  font-family: ${props => props.theme.fontFamily.primary};
  font-size: ${props => props.theme.fontSize.m};
  color: ${props => props.theme.color.black};
  font-weight: ${props => props.theme.fontWeight.bold};
  line-height: ${props => props.theme.lineHeight.lh3};

  @media (min-width: ${props => props.theme.breakPoints.tablet}) {
    font-size: ${props => props.theme.fontSize.l};
  }
`;

const LinkStyles = css`
  display: block;
  padding-bottom: 32px;

  @media (min-width: ${props => props.theme.breakPoints.tablet}) {
    padding-bottom: 48px;

    &:nth-of-type(odd) {
      padding-right: 15px;
    }
    &:nth-of-type(even) {
      padding-left: 15px;
    }
  }

  &:hover ${Image} {
    box-shadow: ${props => props.theme.shadow.three};
  }
  &:hover ${Title} {
    color: ${props => props.theme.color.green2};
  }
`;

export const  CaseStudyItemInternal = styled(Link)`
  ${LinkStyles}
`;

export const CaseStudyItemExternal  = styled.a`
  ${LinkStyles}
`;
