export const arabicdata = {
  bold: {
    "top-component": {
      name: "بولد",
      headline: "نظام إدارة محتوي بدون رأس يتفهم المحتوى الخاص بك.",
      info: "النشر الرقمي عالق في العصور المظلمة. كن ناجحًا في مجال الوسائط الحديثة باستخدام نظام إدارة المحتوي بولد - حل فعال لإنشاء المحتوى وتنظيمه وتوزيعه دون عناء. بدءًا من 75 دولارًا / شهريًا",
      firstButtonContent: {
        text: "ابدأ الإصدار التجريبي المجاني",
        link: "/sign-up",
        title: "free trial",
        linkType: "internal",
      },
      logo: {
        url: "https://gumlet.assettype.com/quintype-website/2019-11/23e1f88f-a05f-4b44-ad19-b98eac4de35a/bold.svg",
        alt: "logo",
      },
      secondButtonContent: {
        required: true,
        videoId: "Gls7beGW77c",
        text: "شاهد العرض التوضيحي",
        title: "watch demo",
      },
      image: {
        url: "https://gumlet.assettype.com/quintype-website/2019-12/a2afb724-7961-4de1-8941-35633e7a1f8f/Bold_Product_page.svg",
        alt: "bold illustration",
      },
    },

    "image-with-content": [
      {
        headline: "مساحة عمل منظمة وتجربة تحرير غنية.",
        features: [
          {
            headline: "المعاينة الحية",
            content:
              "انظر كيف يبدو المحتوى الخاص بك قبل نشره. مباشرة. أثناء إنشائه.",
          },
          {
            headline: "مجموعات المحتوى ",
            content:
              "تخط الأقسام والعلامات وقم بتنظيم المحتوى باستخدام الأتمتة والقواعد.",
          },
        ],
        url: "https://gumlet.assettype.com/quintype-website/2019-12/04af4f9d-35ea-4b05-9b70-d4858c7c1b91/Bold_Screenshots_Live.png",
        alt: "workspace",
        "free-trial": {
          text: "ابدأ الإصدار التجريبي المجاني",
          link: "/sign-up",
          title: "free trial",
        },
      },

      {
        headline: "أنشئ مرة واحدة ، وانشر في أي مكان.",
        features: [
          {
            headline: "توزيع متعدد القنوات",
            content:
              "المحتوى الخاص بك جاهز ليتم اكتشافه عبر البحث والتواصل الاجتماعي والجوّال وأجهزة الصوت مع إعدادات قابلة للتهيئة لكل قناة توزيع.",
          },
          {
            headline: "تحسين محركات بحث افضل",
            content:
              "لم يعد مجرد ميتا. احصل على أوقات تحميل سريعة للغاية ، صفحات جوال مسرعة، وأفضل معايير المنارة وتحقق من صحة مُحسنات محركات البحث لصفحتك داخل محررنا. ",
          },
        ],
        url: "https://gumlet.assettype.com/quintype-website/2019-12/40d83988-d7f8-4e94-9349-6234201a0c9f/Bold_Screenshots_SEO.png",
        alt: "workspace",
        "free-trial": {
          text: "ابدأ الإصدار التجريبي المجاني",
          link: "/sign-up",
          title: "free trial",
        },
      },

      {
        headline: "تقديم تجارب رقمية أفضل.",
        features: [
          {
            headline: "تحليلات متعمقة",
            content:
              "عزز حزمة التحليلات الحالية الخاصة بك ببيانات الطرف الأول ذات الصلة بمحتواك والجمهور الخاص بك حتى تتمكن من اتخاذ قرارات أكثر ذكاءً.",
          },
          {
            headline: "إضفاء الطابع الشخصي",
            content:
              "انسجم مع جمهورك بأفضل محتوى لديك باستخدام خوارزميات التعلم الآلي الخاصة بنا والتي تقدم الأجزاء المناسبة من المحتوى إلى القارئ المناسب. ",
          },
        ],
        url: "https://gumlet.assettype.com/quintype-website/2019-12/0a15c81b-c8b6-4684-923a-fba346f3d050/Bold_Screenshots_Analytics.png",
        alt: "workspace",
        "free-trial": {
          text: "ابدأ الإصدار التجريبي المجاني",
          link: "/sign-up",
          title: "free trial",
        },
      },
    ],

    features: {
      headline:
        "كل أدوات تحرير المحتوى التي كنت ترغب فيها. وكل ما لم تحصل عليه بعد.",
      feature: [
        {
          title: "توصيل أفضل للوسائط",
          description:
            "لا مزيد من الصور التي تم اقتصاصها بشكل محرج على شاشات مختلفة!",
          url: "https://gumlet.assettype.com/quintype-website/2019-11/832eed88-f9cf-4f2c-877c-41699e9774fa/Media_Delivery.svg",
          alt: "Better Media Delivery",
        },
        {
          title: "التدوين المباشر",
          description:
            "قم بتحديث القصص في الوقت الفعلي وعكس الترتيب تلقائيًا عند الانتهاء.",
          url: "https://gumlet.assettype.com/quintype-website/2019-11/f39087ac-b251-439a-b6ea-f38b4576b9c7/Live_Blog.svg",
          alt: "Live Blogging",
        },
        {
          title: "فرق",
          description:
            "إنشاء فرق من المؤلفين مع إمكانية الوصول إلى أقسام محددة.",
          url: "https://gumlet.assettype.com/quintype-website/2019-11/b1d4dff0-5abb-4079-949b-10585ba4ad95/Teams.svg",
          alt: "Teams",
        },
        {
          title: "العلامات الجغرافية",
          description: "تنظيم القصص على أساس المواقع.",
          url: "https://gumlet.assettype.com/quintype-website/2019-11/aa7ad015-0eb6-46b6-be32-97c3507006cf/Geo_Tagging.svg",
          alt: "Geo Tagging",
        },
        {
          title: "مهام سير العمل القابلة للتخصيص",
          description: "اجعل بولد يعمل لتسيير العمل الحالي في غرفة الأخبار!",
          url: "https://gumlet.assettype.com/quintype-website/2019-11/cec75a7c-6a11-471a-bfc0-44064224fc0e/Customizable_Workflow.svg",
          alt: "Customizable Workflows",
        },
        {
          title: "منظمات ذات كفاءة",
          description: "تنظيم المحتوى بالعلامات والسمات والكيانات والأقسام.",
          url: "https://gumlet.assettype.com/quintype-website/2019-11/6e5046ef-758a-41a1-8f81-864a6ca4bd8f/Efficient_Organization.svg",
          alt: "Efficient Organizations",
        },
        {
          title: "عمليات التحقق من محسنات محركات البحث",
          description:
            "تحقق من صحة مُحسنات محركات البحث على الصفحة من خلال توصياتنا.",
          url: "https://gumlet.assettype.com/quintype-website/2019-11/38835c70-1f47-4e75-8da5-c656f6f6c5d1/SEO.svg",
          alt: "SEO Validations",
        },
        {
          title: "دعم متعدد اللغات",
          description: "يدعم أكثر من 150 لغة عالمية.",
          url: "https://gumlet.assettype.com/quintype-website/2019-11/a3660218-3e72-4186-964c-38b5117e0132/Multilingual.svg",
          alt: "Multi-lingual support",
        },
      ],
    },
    pricing: {
      headline: "التسعير",
      description:
        "كوينتيب يمنحك حرية النشر بدون الفواق التكنولوجي. لدينا مجموعة من المنتجات للعناية لجميع التفاصيل الدقيقة. نعم الجميع! الآن ، إطلاق العنان لقوة المحتوى بين يديك.",
      plans: [
        {
          title: "نظام إدارة المحتوي بولد ",
          plan: "يبدأ من 75 دولارًا / شهريًا *",
          icon: {
            url: "https://gumlet.assettype.com/quintype-website/2019-12/7f957131-ecf7-4fbd-b3a5-a07c04363f26/group_14.svg",
            description: "headless cms",
          },
          features: [
            "تم تضمين أهيد لبدج بيلدر",
            "محتوى غير محدود",
            "مستخدمون غير محدودين",
            "99.95٪ استعداد",
            "الاستضافة الآمنة على سحابة خدمات أمازون ويب",
            "نسخ احتياطي يومي",
            "تحليلات المحتوى",
            "* يعتمد التسعير على النطاق الترددي",
          ],
        },
        {
          title: "مؤسسة",
          plan: "مخصص",
          icon: {
            url: "https://gumlet.assettype.com/quintype-website/2019-12/c7dc9eba-1a06-43aa-ac70-c6e5608ae3cf/group_9.svg",
            description: "Headless cms + Front End",
          },
          features: [
            "نظام إدارة المحتوي بولد بلس",
            "تصميم مخصص للواجهة الأمامية",
            "تكامل شبكة توصيل المحتوى نيتيف",
            "الحماية من هجمات حجب الخدمة",
            "تكاملات مخصصة",
            "التوسيع التلقائي للبنية التحتية لتوسيع",
            "مدير حساب مخصص",
            "عينات أكواد الواجهة الأمامية",
            "تحسين تحميل الصفحة",
            "وثائق مواصفات ملفات الواجهة المقروءة آليًا (API)",
            " دعم 24/7",
            "دعم برمجيات إرشادات الوصول إلى محتوى الويب 2.0  (WCAG 2.0 ) والمزيد",
          ],
        },
      ],
      "read-more": {
        link: "/schedule-demo",
        title: "GET QUOTE",
        text: "الحصول على عرض اسعار",
      },
    },
    review: {
      headline: "Way more than a bug and issue tracker",
      content:
        "“استخدمنا نظام إدارة المحتوى بدون رأس لإطلاق منصتنا الرقمية في عام 2016. من نظام إدارة المحتوى إلى حلول جدار الدفع، توفر كوينتيب حزمة تكنولوجية كاملة لنا. النظام الأساسي سهل الاستخدام والتخصيص وفقا لمتطلباتنا.”",
      designation: "أمارناث جوفينداراجان ، الرئيس الرقمي",
      "image-url":
        "https://gumlet.assettype.com/quintype-website/2019-12/db2d7899-378b-4c89-aac3-f6b52163ea8c/swarajya_29.svg",
      alt: "Swarajya",
      features: [
        {
          headline: "البرمجيات الخدمية - نظام إدارة المحتوي مُدار بالكامل",
          description:
            "يتم الاهتمام بالتحديثات والأتمتة والنسخ الاحتياطي نيابة عنك.",
        },
        {
          headline:
            "ملفات الواجهة المقروءة آليًا (API) - أول نظام إدارة المحتوي بدون رأس ",
          description:
            "توفر لك واجهات برمجة تطبيقات ريست الموثقة جيدًا تحكمًا كاملاً في الواجهة الأمامية.",
        },
        {
          headline: "تأمين سحابة المؤسسة",
          description:
            "كاملة مع حماية من هجمات الحرمان من الخدمات و طبقة المنافذ الآمنة والبنية التحتية للتخزين المؤقت والتوسيع التلقائي.",
        },
      ],
    },
    faqs: {
      headline: "أسئلة وأجوبة",
      faq: [
        {
          question: "ما هو  نظام إدارة المحتوي بدون رأس ",
          answer:
            "يعد  نظام إدارة المحتوي بدون رأس مستودع محتوى الذي يقوم بتوصيل المحتوى إلى أي نظام أساسي أو جهاز عبر ملفات الواجهة المقروءة آليًا (API) . يتم الاستخدام المقصود لهذا المحتوى بواسطة الأجهزة بتنسيق أولي ، مثل HTML أو JSON ، وليس مخصصًا للاستخدام البشري حتى يتم عرضه على الجهاز النهائي.",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [],
        },
        {
          question: "ما هو  نظام إدارة المحتوي بولد",
          answer:
            "بولد هو تطبيق نظام إدارة محتوي- حماية من هجمات الحرمان من الخدمة بدون رأس مستضاف  يمكّن الناشرين من إنشاء المحتوى الخاص بهم وتنظيمه وتوزيعه عبر الأجهزة والشاشات الحديثة دون عناء من خلال ملفات الواجهة المقروءة آليًا باستخدام أي حزمة تكنولوجية. ",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [],
        },
        {
          question: "هل نظام إدارة المحتوي بولد أداة لإنشاء مواقع الويب؟",
          answer:
            "إذا كنت تقوم بإنشاء موقع ويب ، فيمكنك استخدام بولد لإدارة محتواه. بولد عبارة عن نظام أساسي لإدارة المحتوى حيث يمكنك تحديث محتوى موقع الويب الخاص بك أو تطبيق الجوال أو أي نظام أساسي آخر يعرض المحتوى. نوفر لك الوقت والجهد لإنشاء الواجهة الخلفية الخاصة بك لإدارة المحتوى ، كما أنه يجعل من السهل حقا إنشاء موقع ويب أو تطبيق.",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [],
        },
        {
          question:
            "كيف يمكنني استيراد بياناتي الحالية إلى  نظام إدارة المحتوي بولد؟",
          answer:
            "لقد قمنا بنقل المحتوى من أنظمة إدارة المحتوى الأكثر شيوعًا مثل وورد بريس و دروبال و جوملا. كما ندعم الترحيل من أنظمة إدارة المحتوى المخصصة الأخرى. لدينا تطبيق ترحيل يقوم بأتمتة عملية استيراد البيانات من أنظمة  إدارة المحتوى الأخرى إلى  نظام إدارة المحتوي بولد.",
          linkText: "Learn more about the migrator here",
          linkType: true,
          additionalLink: "https://developers.quintype.com/migrator/",
          additionalPoints: [],
        },
        {
          question: "ما مدى أمان نظام إدارة المحتوي بولد؟",
          answer:
            "نظام إدارة المحتوي بولد آمن للغاية ويضمن وقت تشغيل بنسبة 99.5٪. يتم إحتواء جميع التطبيقات وتشغيلها على كوبيرنيتيس مع تمكين التحجيم التلقائي للتعامل مع الحجم. علاوة على ذلك ، فإن جميع التطبيقات خلف شبكة توزيع المحتوى. نحن نستعين بفاستلي و / أو كلود فلار كموفري شبكات توزيع المحتوي التابعين لنا. With the enterprise plan provided by Cloudflare/Fastly we are able to provide:",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [
            "Level 3 and Level 7 DDOS protection (No matter the size and frequency of the DDOS attack)",
            "Web Application Firewall with WAF rules for the threats that apply to the large portion of our customers maintained and applied automatically.",
          ],
        },
        {
          question:
            "كيف يتم عمل النسخ الاحتياطية؟ ما هو تكرار النسخ الاحتياطي؟",
          answer:
            "لقد قمنا بدمج نظام النسخ الاحتياطي اليومي مع النسخ المتماثل متعدد المناطق ، مما يعني أن خدمة قواعد البيانات العلائقية من أمازون تنشئ تلقائيًا نموذج قاعدة بيانات أساسي وتنسخ البيانات بشكل متزامن إلى نموذج في وضع الاستعداد في منطقة توافر مختلفة   (AZ) نأخذ أيضًا نسخًا احتياطيًا يدويًا للبيانات إلى حساب خدمات أمازون ويب آخر مرة واحدة يوميًا.",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [],
        },
        {
          question: "السيطرة على المحتوى والتخوف من الاستضافة؟",
          answer:
            "المحتوى ملك للناشرين ولا تمتلك كوينتيب المحتوى بأي شكل من الأشكال. ",
          linkText: "عرض الشروط والأحكام الخاصة بنا.",
          linkType: false,
          additionalLink: "/privacy-policy",
          additionalPoints: [],
        },
        {
          question: "هل تقدمون الدعم الفني المستمر؟",
          answer:
            "لدينا دعم من خلال الدردشة على مدار 24 ساعة طوال أيام الأسبوع ضمن المحرر. يمكننا مساعدتك في ميزات ووظائف نظام إدارة المحتوي بولد ومنتجاتنا. ومع ذلك ، فإننا لا نقدم الدعم الفني لإدارة الواجهة الأمامية. لدينا شراكة مع عدد قليل من الخدمات التقنية لمساعدتك في إدارة الواجهة الأمامية.",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [],
        },
        {
          question: "ما الموارد أو التدريب الذي تقدمه؟",
          answer:
            "بمجرد انضمامك، سوف يقدم فريقنا جلسة مباشرة حول كيفية بدء استخدام نظام إدارة المحتوى بدون رأس.  لدينا أيضًا وثائق المساعدة المتاحة على الرابط التالي.",
          linkText: "وثائق المساعدة",
          linkType: true,
          additionalLink: "https://help.quintype.com/",
          additionalPoints: [],
        },
        {
          question: "هل هناك خيارات تسعير مخصصة متاحة؟",
          answer: "نعم ، تتوفر خيارات التسعير المخصص لمستخدمي المؤسسات وغيرهم.",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [],
        },
        {
          question: "هل تقدم نسخة تجريبية مجانية لجميع الميزات؟",
          answer:
            "نعم ، نحن نقدم لك عرضًا تجريبيًا مجانيًا لمدة 14 يومًا للوصول إلى جميع ميزات منتجاتنا.",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [],
        },
      ],
    },
  },
  ahead: {
    "top-component": {
      name: "أهيد",

      headline: "عرض قوي لمحتواك القوي.",
      logo: {
        url: "https://gumlet.assettype.com/quintype-website/2019-11/761d8dcd-eca2-4fef-8282-03fd714cb1cf/ahead.svg",
        alt: "logo",
      },
      info: "احصل على عرض تقديمي قوي لمحتواك القوي. ركز على استراتيجية المحتوى الخاصة بك ولا تتقيد بالتكنولوجيا. أهيد حزمة كاملة لناشرين المحتوى المتزايدين.",
      firstButtonContent: {
        text: "ابدأ الإصدار التجريبي المجاني",
        link: "/sign-up",
        title: "free trial",
        linkType: "internal",
      },
      secondButtonContent: {
        required: false,
        text: "Watch video",
        title: "watch-video",
      },
      image: {
        url: "https://gumlet.assettype.com/quintype-website/2020-01/bf382eb1-12df-4cf4-b450-e097122ed608/Ahead_06.svg",
        alt: "ahead illustration",
      },
    },

    "image-with-content": [
      {
        headline: "احك قصصك بشكل أفضل ، أو أنشئ موقعًا إخباريًا",
        features: [
          {
            content:
              "اجذب جمهورك بقائمتنا المتزايدة من تنسيقات القصص. مثالي للقصص الطويلة أو القصيرة أو المرئية.",
          },
        ],
        url: "https://gumlet.assettype.com/quintype-website/2019-12/ff430d9b-f695-4500-b58e-f633221ca9d4/Ahead_2.png",
        alt: "workspace",
        "free-trial": {
          text: "ابدأ الإصدار التجريبي المجاني",
          link: "/sign-up",
          title: "free trial",
        },
      },

      {
        headline: "منشئ صفحة قابلة للتخصيص",
        features: [
          {
            headline: "",
            content:
              "جرب واكتشف علامتك التجارية من خلال نظام التصميم القائم على الصفوف الذي يمنحك المرونة لاختيار صفحتك الرئيسية وتعديلها وطلبها.",
          },
        ],
        url: "https://gumlet.assettype.com/quintype-website/2019-12/031ea97e-c95f-4d59-b232-a53b553eb5cd/Ahead_1.png",
        alt: "workspace",
        "free-trial": {
          text: "ابدأ الإصدار التجريبي المجاني",
          link: "/sign-up",
          title: "free trial",
        },
      },
      {
        headline: "تكامل مع حزمة التقنيات الخاصة بك",
        features: [
          {
            headline: "",
            content:
              "يتصل بالتحليلات القياسية للصناعة ، وأدوات قياس البيانات وصنعها لجمع البيانات بدقة وإشراك الجمهور.",
          },
        ],
        url: "https://gumlet.assettype.com/quintype-website/2019-12/92220f14-76de-4d80-adf7-eae1a6cac736/Ahead_3.png",
        alt: "workspace",
        "free-trial": {
          text: "ابدأ الإصدار التجريبي المجاني",
          link: "/sign-up",
          title: "free trial",
        },
      },
      {
        headline: "استثمر المحتوى الخاص بك",
        features: [
          {
            headline: "",
            content:
              "حقق الدخل بسرعة من خلال شبكات الإعلانات الشهيرة أو الاشتراكات أو التبرعات من خلال دعمنا المدمج لـ برمجيات أكسيس تايب",
          },
        ],
        url: "https://gumlet.assettype.com/quintype-website/2019-12/8918917d-3a55-431b-81bb-b93f7315c46d/Ahead_4.png",
        alt: "workspace",
        "free-trial": {
          text: "ابدأ الإصدار التجريبي المجاني",
          link: "/sign-up",
          title: "free trial",
        },
      },
    ],
    features: {
      headline: "Way more than a bug and issue tracker",
      feature: [
        {
          title: "Fully Managed Service",
          description:
            "Updates, security, backups are all taken care of on your behalf.",
          url: "https://dummyimage.com/75x75/f0f0f0/fff",
          alt: "Better Media Delivery",
        },
        {
          title: "Future-proof",
          description:
            "Our team regularly updates layouts, features and integrations that will keep your website way ahead of the pack.",
          url: "https://dummyimage.com/75x75/f0f0f0/fff",
          alt: "future-proof CMS",
        },
        {
          title: "Migrate Effortlessly",
          description:
            "Your data is safe and smoothly transferred to Bold with our advanced migration engine.",
          url: "https://dummyimage.com/75x75/f0f0f0/fff",
          alt: "Migration",
        },
      ],
    },

    review: {
      content:
        "يعد تصميم تجربة المستخدم سهلة الاستخدام والخفيفة الخاصة بـ بكوينتيب  نقطة إضافة كبيرة. فالواجهة الخلفية سهلة الاستخدام وقد تم تصميمها خصيصًا لتلائم ناشري المحتوى .",
      designation: "شروتي بهاتاشاريا ، مدير رقمي",
      "image-url":
        "https://images.assettype.com/quintype-website/2020-03/6967c460-24ec-45eb-8f87-b2ab7577fced/East_Mojo_Logo_01.svg",
      alt: "eastmojo",
      features: [
        {
          headline: "خدمة منظمة بالكامل",
          description:
            "يتم الاهتمام بالتحديثات والأمان والنسخ الاحتياطي نيابة عنك.",
        },
        {
          headline: "فيوتشر بروف",
          description:
            "يقوم فريقنا بانتظام بتحديث التخطيطات والميزات وعمليات الدمج التي ستبقي موقع الويب الخاص بك في الطليعة.",
        },
        {
          headline: "النقل دون عناء ",
          description:
            "بياناتك آمنة ويتم نقلها بسلاسة إلى بولد من خلال محرك النقل المتقدم الخاص بنا.",
        },
      ],
    },

    faqs: {
      headline: "أسئلة وأجوبة",
      faq: [
        {
          question: "ما هو أهيد؟",
          answer:
            "أهيد هو إطار الواجهة الأمامية لنظام إدارة المحتوي بولد الذي يوفر عرضًا قويًا لمحتواك القوي. غالبًا ما يصارع الناشرون الرقميون النامين مع التكنولوجيا وغالبًا ما تكون التكنولوجيا عقبة أكثر من كونها عامل تمكين. في المستقبل ، يسهل على الناشرين الرقميين تثبيت حضورهم عبر الإنترنت باستخدام أداة إنشاء صفحات/بيدج بيلدر قابلة للتخصيص.",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [],
        },
        {
          question: "ما هو البيدج بيلدر؟",
          answer:
            "بيدج بيلدر عبارة عن طبقة أعلى أهيد تسمح للناشرين بتجربة تخطيطات وأنماط مرئية مختلفة بواجهة بدون تعليمات برمجية. تخطيطات بيدج بيلدر خفيفة على جافا سكريبت وتم إنشاؤها باستخدام أفضل الممارسات في أداء الويب.",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [],
        },
        {
          question: "ما هو الفرق بين بي/دبليو أهيد و بيدج بيلدر؟",
          answer:
            "أهيد هو إطار الواجهة الأمامية لنظام إدارة المحتوي بولد الذي يوفر عرضًا تقديميًا قويًا لمحتواك القوي. تم تصميم بيدج بيلدر على بصورة مدمجة مع حزمة أهيد ويساعدك على تخصيص الواجهة الأمامية باستخدام أنظمة التصميم القائمة على الصفوف بدون رمز.",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [],
        },
        {
          question:
            "كم من الوقت يستغرق إنشاء موقع ويب باستخدام أهيد و بيدج بيلدر؟",
          answer: "يمكن نشر مواقع الويب الجديدة في غضون أسبوعين.",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [],
        },
        {
          question:
            "هل أحتاج إلى أداة بيدج بيلدر لـ لنظام إدارة المحتوي بولد ؟",
          answer:
            "غير مطلوب ، إذا كان لديك فريق تطوير ويب قوي ، فيمكنك إنشاء واجهة أمامية مخصصة. يعتبر هذا الحل مفيدًا جدًا لمنشئي المحتوى من الصغار إلى المتوسطين.",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [],
        },
        {
          question: "هل تعمل بيدج بيلدر على أي نظام إدارة محتوى؟",
          answer:
            "لا ، بيدج بيلدر هو الأكثر توافقًا مع نظام إدارة المحتوي بولد  في الوقت الحالي.",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [],
        },
        {
          question: "هل أداة بيدج بيلدر مستقلة عن نظام إدارة المحتوي بولد ؟",
          answer:
            "لا ، إن أداة بيدج بيلدر و  نظام إدارة المحتوي بولد  متصلان بعضهما البعض لأن بولد عبارة عن نظام إدارة محتوي بدون رأس وتعمل أداة بيدج بيلدر أعلى نظام إدارة المحتوي كموجه لنظام إدارة المحتوي. تتيح لك  أداة بيدج بيلدر تخصيص تخطيط موقع الويب وإضافة عناصر واجهة مستخدم أو شرائح إعلانية أو قصص بناءً على تفضيلاتك.",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [],
        },
        {
          question:
            "من الذي يجب عليه أن يستخدم  أداة بيدج بيلدر؟ / هل يجب علي استخدام أداة بيدج بيلدر؟",
          answer:
            "تعد  أداة بيدج بيلدر مثالية لأصحاب الأعمال والمدونين والناشرين والمصممين وأي شخص آخر يريد تخصيص تصميم مواقع الويب الخاصة بهم دون أي معرفة بالشفرات.",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [],
        },
        {
          question: "كم التكلفة؟",
          answer:
            "تأتي  أداة بيدج بيلدر و أهيد جنبًا إلى جنب مع نظام إدارة المحتوي بولد ويبدأ السعر من 100 دولار",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [],
        },
      ],
    },
  },
  metype: {
    "top-component": {
      name: "برمجيات ميتايب",
      headline: "اجعل الناس يتحدثون.",

      logo: {
        url: "https://gumlet.assettype.com/quintype-website/2019-11/97f7cf02-0e7d-446a-943f-7acd485043f7/metype.svg",
        alt: "logo",
      },
      info: "حلول لمشاركة الجمهور تلهم بمحادثات أفضل. القراء يصبحون متابعين. الجماهير المشاركة تنمو إلي المجتمعات.",
      firstButtonContent: {
        text: "معرفة المزيد",
        link: "https://www.metype.com",
        title: "Visit metype.com",
        linkType: "external",
      },
      secondButtonContent: {
        required: true,
        text: "شاهد الفيديو",
        videoId: "yFuS_cBhUqA",
        link: "/video",
        title: "watch-video",
      },
      image: {
        url: "https://gumlet.assettype.com/quintype-website/2020-01/05fdff3e-8f80-4562-ad5e-22ed629c98f3/Metype_01.svg",
        alt: "metype illustration",
      },
    },
    trialData: {
      headline: "ابدأ مجانًا",
      content:
        "يمكن أن تتكامل ميتايب مع أي واجهة أمامية. ابدأ بحسابك المجاني على metype.com",
      buttonData: {
        text: "معرفة المزيد",
        link: "https://www.metype.com",
        title: "visit metype.com",
        isExternal: true,
      },
    },

    "image-with-content": [
      {
        headline: "إشراك الجمهور",
        features: [
          {
            content:
              "استضف محادثات هادفة تسعى إلى إنشاء مجتمع من شأنه أن يشعل الأفكار من خلال وجهات نظر مختلفة.",
          },
        ],
        url: "https://gumlet.assettype.com/quintype-website/2019-12/e23fe513-c94b-4f90-94b5-f23b83f9b794/Metype_1.png",
        alt: "metype commenting widget",
        "free-trial": {
          text: "معرفة المزيد",
          link: "https://www.metype.com",
          title: "Visit metype.com",
          isExternal: true,
        },
      },
      {
        headline: "التعديل المدعوم بالذكاء الاصطناعي",
        features: [
          {
            content:
              "لا تدع المتصيدون أحرارًا. يساعد الاعتدال المدفوع بالذكاء الاصطناعي الناشرين على تعديل التعليقات المؤذية.",
          },
        ],
        url: "https://gumlet.assettype.com/quintype-website/2019-12/c4759269-fe35-40d1-bb54-386016081f34/Metype_2.png",
        alt: "workspace",
        "free-trial": {
          text: "معرفة المزيد",
          link: "https://www.metype.com",
          title: "visit metype.com",
          isExternal: true,
        },
      },
      {
        headline: "تكوينات مخصصة",
        features: [
          {
            content: "تهيئة وتخصيص أدوات ماي تايب لتناسب أسلوبك في التعبير.",
          },
        ],
        url: "https://gumlet.assettype.com/quintype-website/2019-12/786e81da-f29e-48cc-bd4f-021ec82f6ccd/Metype_3.png",
        alt: "workspace",
        "free-trial": {
          text: "معرفة المزيد",
          link: "https://www.metype.com",
          title: "visit metype.com",
          isExternal: true,
        },
      },
    ],
    review: {
      headline: "Get started with a free account today",
      content:
        "تساعد ميتايب فريقنا على التفاعل مع جمهورنا الشاب مع ضمان محادثات عالية الجودة من خلال مقياس السمية الخاص بهم. ",
      designation: "أمارناث جوفينداراجان ، الرئيس الرقمي",
      "image-url":
        "https://gumlet.assettype.com/quintype-website/2019-12/db2d7899-378b-4c89-aac3-f6b52163ea8c/swarajya_29.svg",
      alt: "Swarajya",
      features: [
        {
          headline: "تسجيل الدخول الأحادي",
          description:
            "تتكامل مع الحزمة التكنولوجية الخاصة بك للحصول على مصادقة سلسة.",
        },
        {
          headline: "إضافة أدوات المشاركة في لمح البصر",
          description:
            "اختر أداة ، أضف كود جافا سكريبت إلى موقع الويب الخاص بك وها أنت!",
        },
        {
          headline: "أنت تملك المحتوى الخاص بك",
          description: "لديك ملكية كاملة لأي محتوى منشور على ممتلكاتك.",
        },
      ],
    },
    faqs: {
      headline: "أسئلة وأجوبة",
      faq: [
        {
          question: "ما هو ميتايب؟",
          answer:
            "ميتايب هو حل لمشاركة الجمهور للناشرين الذين يرغبون في تشجيع المحادثات الهادفة مع مجتمعهم من خلال المحتوى الذي ينشئه المستخدمون. يتكامل ميتايب مع حلول نظام إدارة المحتوي الشائعة من خلال تضمين جافا سكريبت ويمكن أن يتكامل مع أدوات المصادقة التابعة لجهات خارجية باستخدام تسجيل الدخول الأحادي الخاص بنا. الميزات الأساسية لـ ميتايب هي:",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [
            "محتوى ناتج من مستخدم",
            "تعليقات القصة",
            "التفاعل مع القصة",
            "الردود المباشرة",
            "الإشارات المرجعية",
          ],
        },
        {
          question: "ما هي تكلفة استخدام ميتايب؟",
          answer:
            "ميتايب مجاني الاستخدام لمعظم المواقع (مثل المدونات الشخصية ، والمؤسسات غير الربحية ، والتعليم). يمكنك استخدام الإصدار المدفوع لإزالة الإعلانات من الأداة أو إذا كان النطاق الترددي أكثر من مليون مشاهدة للصفحة.",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [],
        },
        {
          question: "ما هي المنصات التي تدعمها؟",
          answer:
            "يدعم ميتايب مجموعة متنوعة من الأنظمة الأساسية. تحقق من عملية التثبيت الخاصة بنا لـ وورد بريس و بلوجر مع المزيد على طول الطريق!",
          linkText: "انقر هنا",
          linkType: true,
          additionalLink:
            "https://developers.quintype.com/metype/#install-metype-on-wordpress",
          additionalPoints: [],
        },
        {
          question: "ماذا سيحدث للتعليقات الموجودة على موقعي؟",
          answer: "يمكنك اختيار ترحيل تعليقاتك بتكلفة إضافية.",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [],
        },
        {
          question: "هل توقف تعليقات ميتايب البريد العشوائي؟",
          answer:
            "نعم ، سيتعرف محرك الذكاء الاصطناعي لدينا على الكلمات الرئيسية السلبية التي ترغب في تجنبها على موقع الويب الخاص بك ويوجه هذه التعليقات للمراجعة.",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [],
        },
        {
          question: "هل التعليقات متوفرة على الصفحات وكذلك في المنشورات؟",
          answer: "نعم ، يمكنك إضافة ميتايب إلى أي صفحات ومنشورات أيضًا.",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [],
        },
        {
          question: "هل توفرون تسجيل الدخول الأحادي(SSO)؟",
          answer:
            "تسجيل الدخول الأحادي (SSO) متاح حاليًا للمستخدمين الذين لديهم ميتايب .",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [],
        },
        {
          question: "هل ستكون تعليقاتي قابلة للفهرسة بواسطة جوجل؟",
          answer: "نعم ، ستتم فهرسة تعليقاتك بواسطة جوجل.",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [],
        },
        {
          question: "ما هو تأثير ميتايب على أداء موقعي؟",
          answer:
            "يتم تحميل ميتايب بشكل غير متزامن ، لذلك لن يؤثر على أداء صفحتك. For more detail, لمزيد من التفاصيل ، يرجى الاطلاع على مستند المساعدة الخاص بنا حول أداء تحميل الصفحة.",
          linkText: "هنا",
          linkType: true,
          additionalLink:
            "https://developers.quintype.com/metype/#18-oct-2018-release-notes-metype",
          additionalPoints: [],
        },
      ],
    },
  },
  accesstype: {
    "top-component": {
      name: "اكسيس تايب",
      headline: "منصة قوية لتنقيد المحتوى.",
      logo: {
        url: "https://gumlet.assettype.com/quintype-website/2019-11/96390937-5b53-44b0-9ecc-5353241e885e/group_14.svg",
        alt: "logo",
      },
      info: "هل ما زلت تلاحق نموذج البنس لكل نقرة؟ حقق أرباحًا متوقعة للقارئ باستخدام اكسيس تايب- نظام أساسي ذكي لإدارة الاشتراكات للاستفادة من القوة المخفية للاشتراكات.",
      firstButtonContent: {
        text: "معرفة المزيد",
        link: "/contact-us",
        title: "contact us page",
        linkType: "internal",
      },
      secondButtonContent: {
        required: true,
        videoId: "y-nTXl6QmTs",
        text: "شاهد الفيديو",
        link: "/video",
        title: "watch-video",
      },
      image: {
        url: "https://gumlet.assettype.com/quintype-website/2019-12/238e2d96-57eb-4ce0-a0e1-4937f3e06354/Accesstype.svg",
        alt: "metype illustration",
      },
    },

    "image-with-content": [
      {
        headline: "الوصول المقنن",
        features: [
          {
            content:
              "يضمن الوصول المقنن إلي المحتوى الخاص بك إغراء المشتركين الجدد. من السهل إعداد مستويات الوصول مباشرة من المحرر فى نظام إدارة المحتوي بولد مما يجعل من السهل ضبط الوصول المقنن للقراء.",
          },
        ],
        url: "https://gumlet.assettype.com/quintype-website/2019-12/cdffec9e-2fdf-4c24-9651-d596fe0364f7/Atype_Screenshot_Metered.png",
        alt: "workspace",
        "free-trial": {
          text: "معرفة المزيد",
          link: "/contact-us",
          title: "contact us page",
          isExternal: false,
        },
      },
      {
        headline:
          "عرض ، تصفية ، وتصدير معلومات المشترك الخاصة بك ، ومعالجة المعاملات مع محاولات والولوج وأكثر من ذلك.",
        features: [
          {
            content:
              "تكاما سريع للحزمة التكنولوجية الموجود لديك مع واجهات برمجة تطبيقات ريست. تقارير حدسية وعمليات تكامل مرنة",
          },
        ],
        url: "https://gumlet.assettype.com/quintype-website/2019-12/6a0fb7c7-2f3d-4709-8798-3ab682ae4f5b/Atype_Screenshot_Report.png",
        alt: "workspace",
        "free-trial": {
          text: "معرفة المزيد",
          link: "/contact-us",
          title: "contact us page",
          isExternal: false,
        },
      },
      {
        headline: "تكاملات سريعة هيكل الرسوم معقول",
        features: [
          {
            content:
              "يمكن أن يتكامل أكسيس تايب مع أنظمة إدارة المحتوي الأكثر شيوعًا في السوق اليوم ويأتي برسوم معقولة تبدأ من 99 دولارًا.",
          },
        ],
        url: "https://gumlet.assettype.com/quintype-website/2019-12/dd0e4b29-025c-49e6-b8fc-41af1234fed3/Atype__Integration_25.png",
        alt: "workspace",
        "free-trial": {
          text: "معرفة المزيد",
          link: "/contact-us",
          title: "contact us page",
          isExternal: false,
        },
      },
    ],
    review: {
      headline:
        "Paywall and Subscription solutions for modern content publishers",
      content:
        "يتطور تحقيق الدخل في أعمالنا باستمرار وساعدنا نظام أكسيس تايب في تجربة نماذج اشتراك متعددة للوصول إلي التوازن الصحيح. نوصي باستخدام أكسيس تايب لأي شخص يتطلع إلى استثمار المحتوى الخاص به بخلاف الإعلانات.",
      designation: "- عنكيت دكشيت، تسويق الكتروني",
      "image-url":
        "https://gumlet.assettype.com/quintype-website/2019-12/3163b395-6987-4d61-8c7f-9fb0ff4c2229/Client_14.png",
      alt: "BloombergQuint",
      features: [
        {
          headline: "تسجيل الدخول الأحادي",
          description:
            "تكامل مع الحزمة التكنولوجية الخاصة بك للحصول على مصادقة سلسة.",
        },
        {
          headline: "توفير للأموال في كل معاملة",
          description:
            "ادفع رسومًا ثابتة معقولة بناءً على الحجم وليس النسب المئوية على كل اشتراك.",
        },
        {
          headline: "متعدد اللغات ومتعدد العملات - مستعد",
          description:
            "انطلق بإستراتيجية تحقيق الدخل الخاصة بك في جميع أنحاء العالم. تقبل العملات المحلية.",
        },
      ],
    },
  },
  react_native_mobile: {
    "top-component": {
      name: "تطبيق ريآكت نيتف لناشري المحتوى",
      headline: "تطبيقات المحتوى المعتمدة على الجوال للناشرين الرقميين",
      info: "توفر تطبيقات نيتف الخاصة بنا إعدادات سهلة لنظامي التشغيل iOS و Android، مع تكامل سلس مع نظام إدارة المحتوي بولد الخاص بنا. قم بتشغيل تطبيق المحتوى الخاص بك بدءًا من 500 دولار/ شهر.",
      firstButtonContent: {
        text: " جدولة عرض",
        link: "/schedule-demo",
        title: " Schedule Demo",
        linkType: "internal",
      },
      logo: {
        url: "",
        alt: "logo",
      },
      secondButtonContent: {
        required: true,
        text: "ابدأ الإصدار التجريبي المجاني",
        link: "/rn-free-trial",
        title: "free trial",
        linkType: "internal",
      },
      image: {
        url: "https://images.assettype.com/quintype-website/2020-12/aabf48f1-4f9c-40d2-9f32-242b40bb7639/Accesstype_14.png",
        alt: "react native mobile app",
      },
    },

    "image-with-content": [
      {
        headline:
          "قم بتشغيل تطبيق نيتف الخاص بك للجوال  باستخدام نظام إدارة المحتوي بولد ",
        features: [
          {
            headline: "",
            content:
              "وفر وقتك وثق بفريقنا في نقل منشوراتك الرقمية إلى جمهور الجوال الأول. مزامنة المحتوى مع موقع الويب الخاص بك وتطبيق الهاتف المحمول من نظام إدارة محتوى واحد. قم بالتوصيل والتشغيل بدون صيانة وبدون تكاليف تقنية إضافية.",
          },
        ],
        url: "https://gumlet.assettype.com/quintype-website/2019-12/04af4f9d-35ea-4b05-9b70-d4858c7c1b91/Bold_Screenshots_Live.png",
        alt: "workspace",
        "free-trial": {
          text: "جدولة عرض",
          link: "/schedule-demo",
          title: " Schedule Demo",
        },
      },

      {
        headline: "مشاركة الجمهور علي بعد نقرة واحدة",
        features: [
          {
            headline: "",
            content:
              "بالنسبة لأولئك الذين يعملون على مدار الساعة للوصول إلى الجماهير في جميع أنحاء العالم ، إليك حل سهل لإطلاق تطبيق متوافق مع الأجهزة الجوّالة. بفضل تجربة المستخدم الفائقة ، تواصل مع جمهورك على مستوى متكامل.",
          },
        ],
        url: "https://images.assettype.com/quintype-website/2020-11/d24d27d9-7f63-41ed-94cf-459fdc8cd310/Bar_and_Bench___mobile_mockups__1_.png",
        alt: "workspace",
        "free-trial": {
          text: " جدولة عرض",
          link: "/schedule-demo",
          title: " Schedule Demo",
        },
        points: [
          "دفع الإخطارات",
          "بث مباشر للفيديو",
          "كويك- مثل سرعة البرق. لأننا نعلم أنك تقدر جمهورك",
          "تسجيل الدخول الأحادي - للحصول على تجربة سلسة ، قم بتفعيل تسجيل دخول المستخدم باستخدام حزمة البرمجيات الخاص بك.",
          "تكامل برنامج FCM ",
          "مرونة الفتحات الإعلانية المتعددة",
        ],
      },

      {
        headline: "توليد الإيرادات مصمم خصيصًا لك",
        features: [
          {
            headline: "",
            content: "",
          },
        ],
        url: "https://images.assettype.com/quintype-website/2020-11/1978d0bb-f18c-4ef1-8b5c-fbb1770b8555/mockup_livehistoryindia.jpg",
        alt: "workspace",
        "free-trial": {
          text: "جدولة عرض",
          link: "/schedule-demo",
          title: " Schedule Demo",
        },
        points: [
          "تكامل الإعلانات - احصل على محتوى دعائي وإعلانات عبر تطبيقك. ",
          "الاشتراكات / جدار الدفع - لم يكن إعداد نظام حظر الاشتراك غير المدفوع أسهل من أي وقت مضى، قم بضبط التهيئة، جدار دفع حر أو مقيد!",
          "تجربة ويب سلسة باستخدام نظام إدارة المحتوي بولد ، المصمم خصيصًا للناشرين الرقميين اليوم.",
        ],
      },

      {
        headline: "فوائد التكلفة تناسب جميع الناشرين",
        features: [
          {
            headline: "",
            content:
              "يساعدك إطار العمل المبني مسبقًا على توفير الوقت والمال خلال إنشاء تطبيق المحتوى الخاص بك.",
          },
        ],
        url: "https://gumlet.assettype.com/quintype-website/2019-12/238e2d96-57eb-4ce0-a0e1-4937f3e06354/Accesstype.svg",
        alt: "workspace",
        "free-trial": {
          text: "جدولة عرض",
          link: "/schedule-demo",
          title: "CTA: Schedule Demo",
        },
        points: [
          "تكاليف معقولة - لا مزيد من الفواتير الإضافية على توظيف فريق تقني أو فحوصات الصيانة الدورية",
          "بدون صيانة - يمكن أن يكون تطوير تطبيقات الجوال نيتف مهمة مرهقة للناشرين ، مع كوينتايب، يمكنك ترك مخاوفك للخبراء.",
        ],
      },
    ],

    features: {
      headline: "نقاط هامة أخرى",
      feature: [
        {
          title: "زمن تشغيل مرتفع",
          description:
            "زمن تشغيل بنسبة 99.73٪ حتى لا تعرض المستخدمين لديك لتعطل التطبيق!",
          url: "https://gumlet.assettype.com/quintype-website/2020-12/f0907322-bec7-44ed-9384-99777058cf98/Feature_Icons_1.svg",
          alt: "react native mobile app",
        },
        {
          title: "عمليات الشراء داخل التطبيق لنظام Android",
          description: "احصل على معاملات سهلة للمستخدمين",
          url: "https://gumlet.assettype.com/quintype-website/2020-12/fc7c0828-56d1-4866-8275-78cf7b2e474a/Feature_Icons_2.svg",
          alt: "react native mobile app",
        },
        {
          title: "التطبيقات التي يتم تحديثها بشكل متكرر",
          description: "لا تهتم بمواكبة التحديثات ، لقد قمنا بتغطيتك.",
          url: "https://gumlet.assettype.com/quintype-website/2020-12/eaf21f19-4988-4595-9e87-f8d22c601970/Feature_Icons_3.svg",
          alt: "react native mobile app",
        },
        {
          title: "تخصيص حجم خط المستخدم",
          description: "لتناسب كل قارئ",
          url: "https://gumlet.assettype.com/quintype-website/2020-12/df05a5ad-20a0-4af6-8f78-5ed1d056f7f7/Feature_Icons_4.svg",
          alt: "react native mobile app",
        },
        {
          title: "افهم جمهورك",
          description: "دعم تحليلات جوجل",
          url: "https://gumlet.assettype.com/quintype-website/2020-12/a543d067-38a1-4c4c-bb81-230d6065a2b8/Feature_Icons_5.svg",
          alt: "react native mobile app",
        },
        {
          title: "اقرأ لاحقا",
          description: "الإشارة المرجعية للوصول دون اتصال",
          url: "https://gumlet.assettype.com/quintype-website/2020-12/e9b9e480-ed65-41b4-9336-408a7c59c789/Feature_Icons_6.svg",
          alt: "react native mobile app",
        },
        {
          title: "الوضع المظلم",
          description: "لزيادة وقت الشاشة وللمساعدة في القراءة",
          url: "https://images.assettype.com/quintype-website/2020-12/8813e2ed-3496-44ec-b790-8d7e55257706/Feature_Icons_7.svg",
          alt: "react native mobile app",
        },
        {
          title: "وضع عدم الاتصال بالشبكة",
          description:
            "نحن نتفهم مستخدمي الإنترنت وحاجتهم للخروج عن الرادار بين الحين والآخر. قريبا.",
          url: "https://images.assettype.com/quintype-website/2020-12/27d4069b-2728-4d40-bd63-140c497ff5de/Feature_Icons_8.svg",
          alt: "react native mobile app",
        },
      ],
    },

    review: {
      headline: "Way more than a bug and issue tracker",
      content:
        "”استخدمنا نظام إدارة المحتوى بدون رأس لإطلاق منصتنا الرقمية في عام 2016. من نظام إدارة المحتوى إلى حلول جدار الدفع، تدير كوينتايب الحزمة التقنية الكاملة لنا. النظام الأساسي سهل الاستخدام والتخصيص وفقًا لمتطلباتنا”",
      designation: "أمارناث جوفينداراجان ، الرئيس الرقمي",
      "image-url":
        "https://gumlet.assettype.com/quintype-website/2019-12/db2d7899-378b-4c89-aac3-f6b52163ea8c/swarajya_29.svg",
      alt: "Swarajya",
      features: [
        {
          headline: "برمجيات خدمية لنظام إدارة محتوي مُدار بالكامل",
          description:
            "يتم الاهتمام بالتحديثات والأتمتة والنسخ الاحتياطي نيابة عنك.",
        },
        {
          headline: "أول واجهة برمجة التطبيقات لنظام إدارة المحتوي بدون رأس",
          description:
            "توفر لك واجهات برمجة تطبيقات ريست الموثقة جيدًا تحكمًا كاملاً في الواجهة الأمامية.",
        },
        {
          headline: "تأمين سحابة المؤسسة",
          description:
            "حماية كاملة من هجمات حجب الخدمة و طبقة منافذ آمنة، بنية تحتية للتخزين المؤقت ذات توسيع التلقائي.",
        },
      ],
    },
  },
};
