import styled, { css } from "styled-components";

const titleStyles = css`
  font-family: ${props => props.theme.fontFamily.primary};
  font-weight: ${props => props.theme.fontWeight.regular};
  font-size: ${props => props.theme.fontSize.xs};
  line-height: ${props => props.theme.lineHeight.lh5};
  color: ${props => props.theme.color.gray2};
  margin-left: 5px;
  padding-right: 5px;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    font-size: ${props => props.theme.fontSize.s};
  }
`;

export const Title = styled.h2`
  font-family: ${props => props.theme.fontFamily.primary};
  font-size: ${props => props.theme.fontSize.xl};
  font-weight: ${props => props.theme.fontWeight.bold};
  line-height: ${props => props.theme.lineHeight.lh2};
  text-align: center;
  color: ${props => props.theme.color.gray2};
  margin: 75px 0 36px 0;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    margin: 130px 0 48px 0;
    font-size: ${props => props.theme.fontSize.xxxl};
  }
`;

export const Wrapper = styled.div`
  max-width: ${props => props.theme.containerWidth};
  margin: 0 auto;
  @media (max-width: ${props => props.theme.breakPoints.desktop}) {
    padding: 0 24px;
  }
`;

export const Headline = styled.h1`
  font-family: ${props => props.theme.fontFamily.primary};
  font-size: ${props => props.theme.fontSize.xxl};
  font-weight: ${props => props.theme.fontWeight.bold};
  line-height: ${props => props.theme.lineHeight.lh1};
  color: ${props => props.theme.color.gray2};
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    font-size: ${props => props.theme.fontSize.giga};
  }
`;

export const Content = styled.p`
  font-size: ${props => props.theme.fontSize.m};
  line-height: ${props => props.theme.lineHeight.lh4};
  color: ${props => props.theme.color.gray2};
  font-family: ${props => props.theme.fontFamily.primary};
  padding: 0 0 30px 0;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    font-size: ${props => props.theme.fontSize.l};
    padding: 0 50px 54px 0;
    width:553px;
  }
`;

export const PartnersContainer = styled.div`
  margin-top: 45px;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 24px;
    margin-top: 65px;
  }
`;

export const Partners = styled.div`
  margin: 15px 0 30px 10px;
`;

export const PartnersName = styled.a`
  font-size: ${props => props.theme.fontSize.s};
  font-family: ${props => props.theme.fontFamily.primary};
  font-weight: ${props => props.theme.fontWeight.bold};
  line-height: ${props => props.theme.lineHeight.lh5};
  color: ${props => props.theme.color.green1};
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    font-size: ${props => props.theme.fontSize.m};
  }
`;

export const Description = styled.p`
  font-size: ${props => props.theme.fontSize.m};
  font-family: ${props => props.theme.fontFamily.primary};
  line-height: ${props => props.theme.lineHeight.lh3};
  color: ${props => props.theme.color.gray2};
  margin: 20px 0 30px 0;  
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    font-size: ${props => props.theme.fontSize.l};
    margin: 25px 0 60px 0;
    line-height: 1.5;
    text-align:justify;
  }
`;

export const Info = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 9px;  
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 6fr;
`;

export const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 4fr;  
  grid-row-gap: 17px;  
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    display: grid;
    grid-template-columns: 4fr 2fr;  
    grid-row-gap: 17px;  
  }  
`;

export const Details = styled.div`
  display: flex;
  align-items: center;
  &:first-child {
    padding: 0 20px 0 0;
  }
  @media (min-width: 320px) and (max-width: 520px) {
    &:first-child {
      margin-bottom: 16px;
    }
  }
`;

export const ReasonContainer = styled.div`
  display: grid;
  grid-template-columns: 6fr;
  row-gap: 45px;
  @media (min-width: ${props => props.theme.breakPoints.desktop}){
    display: grid;
    grid-template-columns: 6fr 6fr;
    column-gap: 60px;
  }  
`;

export const ReasonContent = styled.div`
  font-family: ${props => props.theme.fontFamily.primary};
  color: ${props => props.theme.color.gray2};
  font-size: ${props => props.theme.fontSize.m};
  padding-bottom: 24px;
  font-weight: ${props => props.theme.fontWeight.regular};
  line-height: ${props => props.theme.lineHeight.lh3};
`;

export const ReasonHeadline = styled.h5`
  color: ${props => props.theme.color.green1};
  font-size: ${props => props.theme.fontSize.m};
  font-weight: ${props => props.theme.fontWeight.regular};
`;

export const Logo = styled.img`
  width: 60px;
  height: 60px;
`;

export const TopLogo = styled.img`
  width: 320px;
  margin:15px 0;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    width: 709px;
    height: 452px;    
  }  
`;

export const Icon = styled.img``;

export const Location = styled.h4`
  ${titleStyles}
`;

export const Search = styled.a`
  ${titleStyles}
`;

export const AdvantageContainer = styled.div`
  margin:0;
  padding:0;
  box-sizing:border-box;
  @media (min-width: ${props => props.theme.breakPoints.tablet}) {
    grid-column: 1/13;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap; 
    padding:0 40px;   
  }
`;

export const AdvantageItem = styled.div`
  background-color: ${props => props.theme.color.white};
  box-shadow: ${props => props.theme.shadow.two};
  border-radius: 5px;
  padding: 32px;
  margin-bottom: 8px;
  text-align: center;
  @media (min-width: ${props => props.theme.breakPoints.tablet}) {
    width: 22%;
    padding: 24px 24px;
    margin-bottom: 24px;
  }
  @media (max-width: ${props => props.theme.breakPoints.tablet}) {
    display: flex;
    align-items: center;
    text-align: left;
  }
`;

export const AdvantageContent = styled.div`
  @media (max-width: ${props => props.theme.breakPoints.tablet}) {
    padding-left: 16px;
  }
`;

export const AdvantageImage = styled.img`
  max-width: 100%;
  padding-bottom: 12px;
`;

export const AdvantageSubHeadline = styled.h4`
  color: ${props => props.theme.color.green1};
  font-size: ${props => props.theme.fontSize.m};
  line-height: ${props => props.theme.lineHeight.lh3};
  font-weight: ${props => props.theme.fontWeight.bold};
  padding-bottom: 8px;
`;

export const CEOContainer = styled.section`
  background-image: ${props => props.theme.color.background};
  margin-top:60px;
  font-family: ${props => props.theme.fontFamily.primary};
  color: ${props => props.theme.color.gray2};  

  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    padding: 80px 30px;
  }
`;

export const CEOInnerContainer = styled.div`
  padding: 0 24px;
  text-align: center;

  @media (min-width: ${props => props.theme.breakPoints.tablet}) {
    max-width: ${props => props.theme.containerWidth};
    margin: auto;
    padding: 0;
    display: grid;
    grid-template-columns: 4fr 8fr;
  }
`;

export const CEOLogo = styled.img` 
  width: 285px;
  height: 325px;
  border-radius:50%; 
  margin-top:15px;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    width: 285px;
    height: 325px;
    border-radius:50%;  
  }  
`;

export const CEODetailsContainer = styled.section`
  display:grid;
  grid-template-columns:1fr; 
`;

export const CEOName = styled.p`
  font-size: ${props => props.theme.fontSize.s};
  font-weight: ${props => props.theme.fontWeight.bold};
  font-family: ${props => props.theme.fontFamily.primary};
  line-height: ${props => props.theme.lineHeight.lh3};
  color: ${props => props.theme.color.gray2};
  margin: 20px 0 0px 0;
  text-align: center;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    font-size: ${props => props.theme.fontSize.l};    
    margin: 8px 0 0px 0;
    text-align: justify;
    line-height: 1.5;
  }
`;

export const CEOCompany = styled.p`
  font-size: ${props => props.theme.fontSize.s};
  font-family: ${props => props.theme.fontFamily.primary};
  color: ${props => props.theme.color.gray2};
  text-align: center;
  margin-bottom: 15px;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    font-size: ${props => props.theme.fontSize.s};    
    text-align: justify;   
  }
`;

export const PartnerButtonContainer = styled.div`
  margin:20px 0px;
  text-align:center;
  @media (min-width: ${props => props.theme.breakPoints.tablet}) {
    margin:60px 0px;
    text-align:center;
  }
`;

