import React from "react";
import {
  Wrapper,
  TitleContainer,
  Description,
  TopContainer,
  ContentWrapper,
  VideoWrapper,
  HyperLink
} from "./style";
import { title, boldYoutubeLink } from "./data";

import H1 from "../shared/h1/index";
import ParaReg from "../shared/para-reg/index";

function GetBoldCMSDemo() {
  return (
    <Wrapper>
      <TopContainer>
        <TitleContainer>
          <H1>{title}</H1>
        </TitleContainer>
        <Description>
          <ParaReg>
            <HyperLink to="/sign-up" isIcon={false}>Click here</HyperLink>&nbsp;
            to sign up for a free trial or &nbsp;
            <HyperLink to="/schedule-demo" isIcon={false}>schedule a session</HyperLink>&nbsp;
            with our consultants a hands-on demo.
          </ParaReg>
        </Description>
      </TopContainer>

      <ContentWrapper>
        <VideoWrapper
          src={boldYoutubeLink}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen=""
        ></VideoWrapper>
      </ContentWrapper>
    </Wrapper>
  );
}
export default GetBoldCMSDemo;
