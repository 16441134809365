import React from "react";
import PropTypes from "prop-types";

import {
  Container,
  InnerContainer,
  HeadlineContainer,
  LinkItem,
} from "./style.js";

import ArrowLink from "../../../shared/arrow-link/index";
import H2 from "../../../shared/h2/index";

function TestimonialSolutions({ data }) {
  return (
    <Container>
      <InnerContainer>
        <HeadlineContainer>
          <H2> {data.headline}</H2>
        </HeadlineContainer>
        <LinkItem>
          <ArrowLink isExternal={true} url={data["read-more"].url}>
            {data["read-more"].text}
          </ArrowLink>
        </LinkItem>
      </InnerContainer>
    </Container>
  );
}

TestimonialSolutions.propTypes = {
  data: PropTypes.shape({
    headline: PropTypes.string,
    logo: PropTypes.array,
    "read-more": PropTypes.shape({
      url: PropTypes.string,
      title: PropTypes.string,
      text: PropTypes.string,
    }),
  }),
};

export default TestimonialSolutions;
