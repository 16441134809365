import styled from "styled-components";

export const MainContainer = styled.section`
  font-family: ${(props) => props.theme.fontFamily.primary};
  padding: 32px 24px;
  @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
    max-width: ${(props) => props.theme.containerWidth};
    padding: 58px 0;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 24px;
  }
`;

export const Headline = styled.h3`
  color: ${(props) => props.theme.color.gray2};
  font-size: ${(props) => props.theme.fontSize.m};
  line-height: ${(props) => props.theme.lineHeight.lh2};
  letter-spacing: 1.67px;
  padding: 0 16px 16px;
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
    font-size: ${(props) => props.theme.fontSize.xxxl};
    padding-bottom: 32px;
    grid-column: 2/12;
  }
`;

export const InnerContiner = styled.div`
  grid-column: 2/12;

  @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
    display: flex;
    justify-content: space-between;
  }
`;

export const Content = styled.div`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-weight: ${(props) => props.theme.fontWeight.regular};
  color: ${(props) => props.theme.color.gray2};
  font-size: ${(props) => props.theme.fontSize.s};
  line-height: ${(props) => props.theme.lineHeight.lh3};
  padding-bottom: 16px;
  text-align: ${(props) => (props.isArabic ? "right" : "center")};

  @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
    font-size: ${(props) => props.theme.fontSize.xl};
    font-weight: ${(props) => props.theme.fontWeight.bold};
    text-align: ${(props) => (props.isArabic ? "right" : "left")};
  }
`;

export const ReviewItem = styled.div`
  text-align: ${(props) => (props.isArabic ? "right" : "center")};
  @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
    width: 47%;
    text-align: ${(props) => (props.isArabic ? "right" : "left")};
  }
`;

export const Designation = styled.div`
  text-align: center;
  padding-bottom: 16px;
  font-weight: ${(props) => props.theme.fontWeight.bold};

  @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
    text-align: right;
    color: ${(props) => props.theme.color.black};
    font-size: ${(props) => props.theme.fontSize.m};
  }
`;

export const ImageContainer = styled.div`
  display: block;
  padding-bottom: 24px;
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
    text-align: right;
  }
`;

export const Image = styled.img`
  max-width: 150px;
`;

export const FeatureContent = styled.div`
  font-family: ${(props) => props.theme.fontFamily.primary};
  color: ${(props) => props.theme.color.gray2};
  font-size: ${(props) => props.theme.fontSize.m};
  padding-bottom: 24px;
  font-weight: ${(props) => props.theme.fontWeight.regular};
  line-height: ${(props) => props.theme.lineHeight.lh3};
`;

export const FeatureHeadline = styled.h5`
  color: ${(props) => props.theme.color.green1};
  font-size: ${(props) => props.theme.fontSize.m};
  padding-bottom: 8px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
`;
