import React from "react";

import { Container, CenterContainer } from "./style";

import { Headline, FeatureContent } from "../image-with-content/style";
import PrimaryButton from "../../../shared/primaryButton/index";

export default function Trial({ data }) {
  const { headline, content, buttonData } = data;
  const { text, link, isExternal } = buttonData;
  return (
    <Container>
      <CenterContainer>
        <Headline>{headline}</Headline>
      
      <FeatureContent>{content}</FeatureContent>
      <PrimaryButton link={link} isExternal={isExternal}>
        {text}
      </PrimaryButton>
      </CenterContainer>
    </Container>
  );
}
