import React, { useState } from "react";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";
import { useSelector } from "react-redux";
import "../../../../node_modules/react-modal-video/css/modal-video.min.css";
import { mainContentData } from "../home-data";
import { arabicmainContentData } from "../home-arabic-data";
import PrimaryButton from "../../shared/primaryButton";
import SecondaryButton from "../../shared/secondaryButton";

import {
  Wrapper,
  TitleContainer,
  InfoContainer,
  ImageContainer,
  Image,
  ButtonContainer,
  InnerWrapper,
  Container,
  MobileContainer
} from "./style";

import H2 from "../../shared/h2/index";
import Para from "../../shared/para-reg/index";

function TopHomePageContent() {
  const [showModal, updateModalState] = useState(false);
  const isArabic = useSelector((state)=>state);
  return (
    <Wrapper>
      <InnerWrapper>
        <MobileContainer>
          <TitleContainer>
            <H2 big={true}>{isArabic ?arabicmainContentData.heading : mainContentData.heading}</H2>
          </TitleContainer>
        </MobileContainer>
        <Container>
          <TitleContainer>
            <H2 big={true}>{isArabic ? arabicmainContentData.heading : mainContentData.heading}</H2>
          </TitleContainer>
          <InfoContainer>
            <Para>{isArabic ? arabicmainContentData.heading : mainContentData.info}</Para>
          </InfoContainer>

          <ButtonContainer>
            <Link to="/sign-up">
              <PrimaryButton>{isArabic ? arabicmainContentData.primaryButtonText : mainContentData.primaryButtonText}</PrimaryButton>
            </Link>
            <SecondaryButton onClick={() => updateModalState(true)}>
              {isArabic ? arabicmainContentData.secondaryButtonText : mainContentData.secondaryButtonText}
            </SecondaryButton>
          </ButtonContainer>
        </Container>

        <ImageContainer>
          <Image
            data-src="https://gumlet.assettype.com/quintype-website/2020-01/5d475a7c-bd81-440e-91f8-642df85dbfa9/artboard_1_copy_7.svg"
            alt="Let the Content Renaissance begin."
          />
        </ImageContainer>
        <MobileContainer>
          <InfoContainer>
            <Para>{isArabic ? arabicmainContentData.info : mainContentData.info}</Para>
          </InfoContainer>

          <ButtonContainer>
            <Link to="/sign-up">
              <PrimaryButton>{isArabic ? arabicmainContentData.primaryButtonText : mainContentData.primaryButtonText}</PrimaryButton>
            </Link>
            <SecondaryButton onClick={() => updateModalState(true)}>
              { isArabic ? arabicmainContentData.secondaryButtonText : mainContentData.secondaryButtonText}
            </SecondaryButton>
          </ButtonContainer>
        </MobileContainer>
      </InnerWrapper>
        {/* <ModalVideo
          channel="youtube"
          isOpen={showModal}
          videoId="mXzlMw8Zfk4"
          rel={0}
          autoplay=	{true}
          onClose={() => updateModalState(false)}
        /> */}
        <ModalVideo
        channel="youtube" // Specify the video platform (e.g., "youtube", "vimeo", etc.)
        isOpen={showModal} // Control whether the modal is open or closed
        videoId="mXzlMw8Zfk4" // Specify the ID of the video to play
        autoplay={true} // Set to `true` to autoplay the video
        onClose={() => updateModalState(false)} // Callback function to handle closing the modal
      >
        <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
          <iframe
            src={`https://www.youtube.com/embed/mXzlMw8Zfk4?autoplay=1&rel=0`}
            title="Video Player"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            frameBorder="0"
            allowFullScreen
          />
        </div>
      </ModalVideo>
    </Wrapper>
  );
}

export default TopHomePageContent;