import styled from "styled-components";
import { ButtonWrapper } from "../get-demo/style";

export const TrialButtonWrapper = styled(ButtonWrapper)`
  margin-top: 12px;
  margin-bottom:0;
`;

export const ListTitle = styled.div`
  padding: 16px 0;
`;

export const AccountNamePara = styled.p`
  font-family: ${props => props.theme.fontFamily.primary};
  overflow-wrap: break-word;
  font-size: ${props => props.theme.fontSize.s};
  margin-bottom: 8px;
`;

export const AccountNameSpan = styled.span`
  font-weight: ${props => props.theme.fontWeight.bold};
`;
