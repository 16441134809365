import styled from "styled-components";

export const SocialLinksContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
`;

export const SocialLink = styled.a`
  padding: ${props => props.isTargetting ==="desktop"? '0': "10px 10px"};
  &:not(:last-child) {
    margin-right: ${props => props.isTargetting ==="desktop"? '30px': "20px"};
  }
`;
