import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 32px 24px;
  @media (min-width: ${(props) => props.theme.breakPoints.desktop}) {
    max-width: ${(props) => props.theme.containerWidth};
    margin: auto;
    padding: 56px 0;
  }
`;
export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${(props) => props.theme.breakPoints.desktop}) {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: repeat(12, 1fr);
  }
`;

export const Container = styled.div`
  display: none;
  @media (min-width: ${(props) => props.theme.breakPoints.desktop}) {
    display: block;
    grid-column: 1/7;
    margin-top: 40px;
    grid-row-start: 1;
  }
`;

export const MobileContainer = styled.div`
  @media (min-width: ${(props) => props.theme.breakPoints.desktop}) {
    display: none;
  }
`;

export const TitleContainer = styled.div`
  margin: 0 0 16px 0;
  @media (min-width: ${(props) => props.theme.breakPoints.desktop}) {
    grid-column: 1/7;
    grid-row-start: 1;
  }
`;

export const InfoContainer = styled.div`
  margin: 0 0 24px 0;
  @media (min-width: ${(props) => props.theme.breakPoints.desktop}) {
    grid-column: 1/6;
    width: 90%;
    margin: 0 0 40px 0;
  }
`;

export const Image = styled.img`
  max-width: 90%;
  @media (min-width: ${(props) => props.theme.breakPoints.desktop}) {
    max-width: 100%;
  }
`;

export const ImageContainer = styled.div`
  text-align: center;
  margin-top: 52px;
  @media (min-width: ${(props) => props.theme.breakPoints.desktop}) {
    grid-column: 7/13;
    grid-row-start: 1;
    width: 558px;
    height: 468px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  text-align: center;
  z-index: ${(props) => props.theme.zIndex.two};
  button:first-child {
    margin: 0 9px 0 0;
  }
  @media (min-width: ${(props) => props.theme.breakPoints.desktop}) {
    grid-column: 1/5;
    text-align: left;
    button:first-child {
      margin: 0 24px 0 0;
    }
  }
`;
