import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const FooterContainer = styled.footer`
  background-image: ${props=> props.theme.color.background};
  padding: 80px 0;
  font-family: ${props=> props.theme.fontFamily.primary};
  color: ${props=> props.theme.color.gray3};
  font-weight: ${props=> props.theme.fontWeight.bold};
  margin-top: 72px;
`;

export const FooterRow = styled.div`
  padding: 0 24px;
  @media (min-width: ${props=> props.theme.breakPoints.tablet}) {
    max-width: ${props=> props.theme.containerWidth};
    margin: auto;
    display: grid;
    grid-template-columns: 3fr 1fr;
  }
`;

export const MenuContent = styled.div`
  @media (min-width: ${props=> props.theme.breakPoints.tablet}) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const MenuWrapper = styled.div`
  @media (max-width: ${props=> props.theme.breakPoints.tablet}) {
    padding-bottom: 20px;
  }
`;

export const MenuItems = styled.ul`
  list-style: none;
  padding-top: 10px;

  @media (max-width: ${props=> props.theme.breakPoints.tablet}) {
    ${props =>
      props.open
        ? css`
            display: block;
            padding-left: 16px;
          `
        : css`
            display: none;
          `}
  }
`;

export const MenuName = styled.h5`
  font-size: ${props=> props.theme.fontSize.s};
  color: ${props=> props.theme.color.green1};

  @media (max-width: ${props=> props.theme.breakPoints.tablet}) {
    display: flex;
    justify-content: space-between;
    ${props =>
      props.arrow
        ? css`
            &::after {
              content:url(https://gumlet.assettype.com/quintype-website/2019-11/0a72e4fa-7877-4fb8-abc8-1043c5b36a03/Arrow_up.svg);
              font-size: ${props=> props.theme.fontSize.m};
            }
          `
        : css`
            &::after {
              content: url(https://gumlet.assettype.com/quintype-website/2019-11/55c6bcd2-7778-43c9-84f6-b12de5af6041/Arrow_down.svg);
              font-size: ${props=> props.theme.fontSize.m};
            }
          `}
  }
`;

export const Items = styled.li`
  font-size: ${props=> props.theme.fontSize.s};
  padding-bottom: 16px;
`;

export const ContactInfo = styled.div`
  @media (max-width: ${props=> props.theme.breakPoints.tablet}) {
    text-align: center;
    padding-top: 32px;
  }
`;

export const LinkItem = styled(Link)`
  cursor: pointer;
  color: ${props=> props.theme.color.gray3};
`;


export const Text = styled.div`
  padding: 16px 0 8px;
`;

export const TextLink = styled.a `
  cursor: pointer;
  color: ${props=> props.theme.color.gray3};
`;