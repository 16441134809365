import styled from "styled-components"


export const Container = styled.section`
 padding: 40px 24px;
 max-width: 1140px;
 margin: 0 auto;
 text-align: center;
`

export const CenterContainer = styled.div`
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;

`