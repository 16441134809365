import React from "react";
import { useSelector } from "react-redux";
import TopComponent from "./shared/top-component";
import ImageWithContent from "./shared/image-with-content";
import ClientReview from "./shared/client-review";
import { data } from "./data";
import {arabicdata} from "./arabic-data";

function Aceesstype() {
  const isArabic = useSelector((state)=>state);
  return (
    <React.Fragment>
      <TopComponent data={isArabic ? arabicdata.accesstype["top-component"] : data.accesstype["top-component"]} />
      <ImageWithContent data={isArabic ? arabicdata.accesstype["image-with-content"] : data.accesstype["image-with-content"]} />
      <ClientReview data={isArabic ? arabicdata.accesstype.review : data.accesstype.review} />
    </React.Fragment>
  );
}

export default Aceesstype;