import React from "react";
import { useSelector } from "react-redux";
import TopComponent  from "./shared/top-component";
import ImageWithContent from "./shared/image-with-content";
import Features from "./shared/features";
import Pricing  from "./shared/pricing";
import ClientReview from "./shared/client-review";
import Accordion from "./shared/accordion"
import { data } from "./data";
import { arabicdata } from "./arabic-data";

function Bold () {
  const isArabic = useSelector((state)=>state);
  return (
    <React.Fragment>
      <TopComponent data={isArabic ? arabicdata.bold["top-component"] : data.bold["top-component"]}/>
      <ImageWithContent data={isArabic ? arabicdata.bold["image-with-content"] : data.bold["image-with-content"]} />
      <Features  data={isArabic ? arabicdata.bold.features : data.bold.features}/>
      <Pricing  data={isArabic ? arabicdata.bold.pricing : data.bold.pricing} />
      <ClientReview data={isArabic ? arabicdata.bold.review : data.bold.review} />
      <Accordion data={isArabic ? arabicdata.bold.faqs : data.bold.faqs}/>
    </React.Fragment>
  );
}

export default Bold;  