import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useSelector } from "react-redux";
import { navBarData } from "./data";
import { arabicnavBarData } from "./arabic-data";
import MobileViewContainer from "./mobile-view-container/index";
import DesktopViewContainer from "./desktop-view-container/index";

function Header() {
  const [isIntersecting, setIntersection] = useState(false);
  const isMounted = useRef();
  const isArabic = useSelector((state) => state);
  const [turnOff, setTurnoff] = useState(true);
  const [referrerUrl,setReferrerUrl] = useState(document.referrer);
  function referrerUrlSave(){
    const localStorageDataKey = "referrerUrl";
    if(!localStorage.getItem(localStorageDataKey))
    {
      const initialData = "";
      localStorage.setItem(localStorageDataKey,JSON.stringify(initialData))
    }
    let referrerUrldata = JSON.parse(localStorage.getItem(localStorageDataKey));
    referrerUrldata = referrerUrl;
    localStorage.setItem(localStorageDataKey,JSON.stringify(referrerUrldata));
  }


  function retrieveUrlDataAndSave() {
    const { utm_source, utm_medium, utm_campaign, utm_term,utm_content,keyword,matchtype,device,placement, } =
      queryString.parse(search);
    //if nothing is present no need to retrieve values from localstorage
    //should return

    if (!utm_source && !utm_campaign && !utm_medium && !utm_term) {
      return;
    }

    const localStorageDataKey = "utmData";
    //initialise  localstorage data 
    if (!localStorage.getItem(localStorageDataKey)) {
      const initalData = {};

      sessionStorage.setItem(localStorageDataKey, JSON.stringify(initalData));
    }
    // let utmData = JSON.parse(localStorage.getItem(localStorageDataKey));

    let utmData = { 
      source: utm_source || "",
      campaign: utm_campaign || "",
      medium: utm_medium || "",
      term: utm_term || "",
      content: utm_content || "",
      keyword: keyword || "",
      matchtype: matchtype || "",
      device: device || "",
      placement: placement || ""
    };
     
    // the  local storage data will be read and sent  when one of the page forms
    // is submitted
    console.log("utm data", utmData);
    sessionStorage.setItem(localStorageDataKey, JSON.stringify(utmData));
   
  }

  useEffect(() => {
    // to avoid memory leaks
    isMounted.current = true;
   
    // referrerUrlSave();
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 20 && isMounted.current) {
        setIntersection(true);
      } else {
        isMounted.current && setIntersection(false);
      }
    });
  }, []);

  useEffect(()=>{
    console.log("header rendered");
    setReferrerUrl(document.referrer);
    referrerUrlSave();
    retrieveUrlDataAndSave();
    return ()=>{
      console.log("header unmount")
    }
  }, []);

  const path = window.location.pathname;
  useEffect(() => {
    if (
      path === "/case-study/bloombergquint" ||
      path === "/case-study/swarajya" ||
      path === "/case-study/fortune-india" ||
      path === "/case-study/thequint" ||
      path === "/case-study/bettors-insider" ||
      path === "/case-study/vikatan" ||
      path === "/case-study/gto-publishing" ||
      path === "/case-study/prabhat-khabar" ||
      path === "/case-study/e-sakal" ||
      path === "/case-study/matter-news" ||
      path === "/case-study/mimorelia" ||
      path === "/case-study/mimorelia-spanish-version" ||
      path === "/case-study/karjalainen"
    ) {
      // alert("turn of is arabic");
      setTurnoff(false);
    }
  }, [path]);
  const { search } = useLocation();

  var compProps = {};

  isArabic && turnOff
    ? (compProps = {
        arabicnavBarData,
        isIntersecting,
      })
    : (compProps = { navBarData, isIntersecting });

  return (
    <>
      <MobileViewContainer {...compProps} />
      <DesktopViewContainer {...compProps} />
    </>
  );
}

export default Header;
