import styled from "styled-components";

export const ImageContainer = styled.div`
  text-align: center;
  margin: 32px 0 0 0;
  width: 545px;
  height: 412px;
  @media (min-width: 670px) and (max-width: 1023px) {
    max-width: 100%;
    width: 700px;
    margin: 0 auto;

  }
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    grid-area: 1/2/5/5;
    margin: 0;
    width: 885px;
    height: 400px;
  }
`;

export const Image = styled.img`
  margin-top: 20px;
  max-width: 100%;
`;

export const ButtonContainer = styled.div`
  text-align: center;

  z-index: ${props => props.theme.zIndex.two};
  button:first-child {
    margin: 0 9px 0 0;
  }

  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    grid-area: 1/1/2/4;
    text-align: left;
    button:first-child {
      margin: 0 24px 0 0;
    }
  }
`;

export const ButtonImageContainer = styled.div`
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: repeat(4, 100px);
  }
`;

export const ProductButtonImageContainer = styled(ButtonImageContainer)`
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    grid-column: 1/13;
  }
`;

export const TopContainer = styled.section`
  padding: 40px 24px;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    max-width: ${props => props.theme.containerWidth};
    padding: 56px 0 94px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 24px;
  }
`;

export const LogoWrapper = styled.div`
  grid-column: 1/9;
`;

export const Logo = styled.img`
width: 32px;
height: 32px;
margin-right: 16px;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    margin-right: 14px;
    width: 48px;
    height: 48px;
  }
`;

export const ProductIntro = styled.div`
  font-family: ${props => props.theme.fontFamily.primary};
`;

export const ProductName = styled.div`
  display: flex;
  align-items: center;
  letter-spacing: 1.67px;
  padding-bottom: 16px;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    padding-bottom: 32px;
  }
`;

export const ProductDescriptionContainer = styled.div`
  margin: 0;
  padding-bottom: 14px;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
  padding-bottom: 24px;
  }
`;

export const ProductInfo = styled.div`
  padding-bottom: 24px;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    grid-column: 1/9;
    padding-bottom: 40px;
  }
`;

export const LinkWrapper = styled.div`
  text-align: center;
  & :first-child {
    margin-right: 12px;
  }
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    grid-column: 2/10;
    display: flex;
    & :first-child {
      margin-right: 40px;
    }
  }
`;
