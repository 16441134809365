import React ,{useContext} from "react";
import { ThemeContext } from 'styled-components';
import { useSelector } from "react-redux";
import TopHomePageContent from "./top-homepage-content/top-homepage-content";
import HomePageProducts from "./home-page-products";
import AwardsAndPublisherLogos from "../home/awards-and-publisher-logos";
import ClientAwards from "./client-awards/index";
import ContentWithImage from "../home/content-with-image";

import {
  clientAwards,
  quinAwards,
  contentWithImage,
  contentWithButton,
  belowTopComponentData
} from "../home/data";

import {
  arabicclientAwards,
  arabicquinAwards,
  arabiccontentWithImage,
  arabiccontentWithButton,
  arabicbelowTopComponentData
} from "../home/arabic-data";

function Home() {
  const themeContext = useContext(ThemeContext);
  const isArabic = useSelector((state)=>state)
  return (
    <div>
      <TopHomePageContent />
      <ContentWithImage bg={themeContext.color.background} data={isArabic ? arabicbelowTopComponentData : belowTopComponentData}/>
      <HomePageProducts />
      <ClientAwards data={isArabic ? arabicclientAwards : clientAwards} />
      <ContentWithImage data={isArabic ? arabiccontentWithImage :contentWithImage} />
      <AwardsAndPublisherLogos data={isArabic ? arabicquinAwards : quinAwards} />
      <ContentWithImage data={isArabic ? arabiccontentWithButton : contentWithButton} />
    </div>
  );
}

export default Home;