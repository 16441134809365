import BoldLogo from "../../components/icons/bold.svg";
import AheadLogo from "../../components/icons/ahead.svg";
import MetypeLogo from "../../components/icons/metype.svg";
import AccesstypeLogo from "../../components/icons/accesstype.svg";

export const arabicmainContentData = {
  heading: "فلتبدأ نهضة المحتوى.",
  info: "كفي صراع مع نظام إدارة المحتوي الخاص بك. توفر كوينتايب مجموعة كاملة من حلول النشر الرقمي القوية وسهلة الاستخدام والتي يمكن الاعتماد عليها.",
  primaryButtonText: "ابدأ الإصدار التجريبي المجاني",
  secondaryButtonText: "شاهد العرض التوضيحي",

  subHeading:
    "How can you start a renaissance if you are too caught up figuring tech?",
  subHeadingdescription:
    "Quintype gives you the freedom to publish, distribute and monetize content sans the tech hiccups. We have a suite of products to take care of every tiny detail. Unleash the power of your content on our platform.",
};

export const arabicproductListHeading =
  "حلول النشر الرقمي لدينا، هي مجموعة من المنتجات المؤهلة";

export const arabicproductsData = [
  {
    productTitle: "بولد",
    productDescription:
      "جميع أدوات تحرير المحتوى التي ما رغبت بها دوما. وكل من لم تفعله بعد.",
    src: BoldLogo,
    productContent:
      "يضيف الفريق باستمرار ميزات جديدة لإفادة الناشرين مع تصميم المنتجات بذكاء. تم تصميم الواجهة الأمامية لتوفر المرونة لتلبية متطلباتنا المخصصة مما يجعل كوينتايب خيارًا خالٍ من المتاعب بالنسبة لنا.",
    productEditor: "سوناكشي سينها ، مدير المنتج",
    productDetail: "نظام إدارة محتوي يفهم المحتوى الخاص بك",
    editorImage:
      "https://gumlet.assettype.com/quintype-website/2019-12/e410a432-32d0-4aac-94a4-b27f1d2b978c/Client_15.png?w=160&dpr=1.0",
    productScreenshot:
      "https://gumlet.assettype.com/quintype-website/2019-11/9ddf00eb-8b1d-4f30-9dbe-a2878355bd91/Bold_headless_cms.png",
    altText: "headless cms",
    readMore: {
      url: "/products/bold",
      text: "اكتشف المميزات",
      title: "explore features",
    },
  },
  {
    productTitle: "حزمة أهيد",
    productDescription: "عرض كبير ومتميز لمحتواك القوي",
    src: AheadLogo,
    productContent:
      "تعد واجهة المستخدم / تصميم تجربة المستخدم الخاصة بـ كوينتايب وسهلة الاستخدام للزوار نقطة إضافة كبيرة. الواجهة الخلفية سهلة الاستخدام وقد تم تصميمها خصيصًا مع مراعاة ناشري المحتوى.",
    productEditor: "شروتي بهات شاريا، رئيس الخدمات الرقمية",
    productDetail:
      "الواجهة الأمامية الأولى للمحتوى من أجل حضور مؤثر على الإنترنت",
    editorImage:
      "https://images.assettype.com/quintype-website/2020-03/6967c460-24ec-45eb-8f87-b2ab7577fced/East_Mojo_Logo_01.svg",
    productScreenshot:
      "https://gumlet.assettype.com/quintype-website/2019-12/f96908f7-7e17-43de-83a7-af84ecb57caa/ahead_product.png",
    altText: "cms page builder",
    readMore: {
      url: "/products/ahead",
      text: "تعلم أكثر",
      title: "learn more ahead",
    },
  },
  {
    productTitle: "ماي تايب",
    productDescription: "اجعل الناس يتحدثون",
    src: MetypeLogo,
    productContent:
      "يساعد نوع التعريف فريقنا على التفاعل مع جمهورنا الشاب مع ضمان محادثات عالية الجودة من خلال مقياس السمية.",
    productEditor: "أمارناث جوفينداراجان، رئيس الخدمات الرقمية",
    productDetail: "المشاركة المطلقة للجمهور ",
    editorImage:
      "https://gumlet.assettype.com/quintype-website/2019-12/db2d7899-378b-4c89-aac3-f6b52163ea8c/swarajya_29.svg",
    productScreenshot:
      "https://gumlet.assettype.com/quintype-website/2019-11/5ec3de77-e3d8-4e74-9b43-2efde28329f1/metype_audience_engagement.png",
    altText: "metype",
    readMore: {
      url: "/products/metype",
      text: "أنظر كيف",
      title: "see how metype",
    },
  },
  {
    productTitle: "برنامج اكسيس تايب",
    productDescription: "يحول القراء إلى أتباع مخلصين.",
    src: AccesstypeLogo,
    buttonText: "Explore",
    productContent:
      "يتطور تحقيق الدخل في أعمالنا باستمرار وقد ساعدنا نظام إدارة الوصول أكسيزتيب في تجربة نماذج اشتراك متعددة للوصول على التوازن الصحيح. نوصي باستخدام نظام غدارة الوصول أكسيزتيب لأي شخص يتطلع إلى تحقيق الدخل من المحتوى الخاص به بخلاف التطلع إلى تحقيق الدخل من المحتوى الخاص به بخلاف الإعلانات.",
    productEditor: "عنكيت دكشيت، مدير التسويق الرقمي",
    productDetail: "نقدنة محتوى قوي",
    editorImage:
      "https://gumlet.assettype.com/quintype-website/2019-12/3163b395-6987-4d61-8c7f-9fb0ff4c2229/Client_14.png",
    productScreenshot:
      "https://gumlet.assettype.com/quintype-website/2019-12/c34f1743-3202-42d1-b099-419b719ef8dd/accesstype_product.png",
    altText: "accesstype",
    readMore: {
      url: "/products/accesstype",
      text: "استكشف",
      title: "explore accesstype",
    },
  },
];
