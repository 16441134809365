import styled from "styled-components"



export const Para = styled.p`
font-family: ${props => props.theme.fontFamily.primary};
font-weight: ${props => props.theme.fontWeight.regular};
line-height: ${props => props.theme.lineHeight.lh4};
color: ${props => props.theme.color.typeSubtle};
font-size: ${props => props.theme.fontSize.xs};
@media (min-width: ${props => props.theme.breakPoints.desktop}) {
    font-size: ${props => props.theme.fontSize.m};
  }

`
