import { normalize } from 'styled-normalize'

export default function globalStyles(theme) {
  return `
    ${normalize}
   
    body {
           font-family: ${theme.fontFamily.fallback};
           font-size: ${theme.fontSize.baseFontSize};
           -webkit-font-smoothing: antialiased;
           -moz-osx-font-smoothing: grayscale;
       }
  
       *,
       *::after,
       *::before {
           margin:0;
           padding:0;
           box-sizing: border-box;
       }
  
      a {
         text-decoration: none;
     }
    `;
}
