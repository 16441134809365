import styled from "styled-components";

export const QuintypeSvg = styled.svg`
`;


export const HamburgerContainer =  styled.div`
cursor: pointer;
`;

export const MiddleLine = styled.span`
  display: inline-block;
  width: 32px;
  height: 2px;
  position: relative;
  background-color: #111;
  &::before, &::after  {
    content: "";
    display: inline-block;
    position: absolute;
    background-color: #111;
    width: 32px;
    height: 2px;
    left: 0;

  }
  &::before {
    top: -11px;

  }
  &::after {
    top: 11px;
  }
`;