import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Axios from "axios";
import { ToggleWrapper, CheckBoxLabel, Checkbox, TextSpan } from "./style";

function Toggle() {
  const isArabic = useSelector((state) => state);
  const [active, setActive] = useState(isArabic);
  const [showButton, setShowButton] = useState(false);
  const dispatch = useDispatch();
  const path = window.location.pathname;
  useEffect(() => {
    Axios.get("https://ipapi.co/json/")
      .then((res) => {
        console.log("User country location", res.data.country_code);
        // res.data.country_code !== "IN" ? setShowButton(true): setShowButton(false);
        const country = res.data.country_code;
        if (
          country === "CY" ||
          country === "LB" ||
          country === "SY" ||
          country === "IQ" ||
          country === "IR" ||
          country === "IL" ||
          country === "JO" ||
          country === "SA" ||
          country === "KW" ||
          country === "QA" ||
          country === "BH" ||
          country === "AE" ||
          country === "OM" ||
          country === "YE"
        ) {
          if (
            path === "/" ||
            path === "/products/bold" ||
            path === "/products/ahead" ||
            path === "/products/metype" ||
            path === "/products/accesstype" ||
            path === "/products/react-native-news-app-ios-android" ||
            path === "/case-study" ||
            path === "/schedule-demo" ||
            path === "/sign-up" ||
            path === "/privacy-policy" ||
            path === "/terms" ||
            path === "/solutions/new-and-modern-publishers" ||
            path === "/solutions/magazine-publishers-app" ||
            path === "/solutions/to-increase-traffic" ||
            path === "/solutions/monetize-content" ||
            path === "/solutions/for-developers"
          ) {
            setShowButton(true);
          } else {
            setShowButton(false);
          }
        } else {
          setShowButton(false);
        }
      })
      .catch((err) => console.log(err));
  }, [path]);

  const HandleToggled = (e) => {
    if (e.target.checked) {
      dispatch({ type: "active", payload: true });
      setActive(true);
    } else {
      dispatch({ type: "deactive", payload: false });
      setActive(false);
    }
  };

  if (showButton) {
    return (
      <ToggleWrapper>
        <CheckBoxLabel>
          <Checkbox type="checkbox" onChange={HandleToggled} checked={active} />
          <TextSpan>En</TextSpan>
        </CheckBoxLabel>
      </ToggleWrapper>
    );
  }
  return null;
}

export default Toggle;
