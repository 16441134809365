import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { productListHeading, productsData } from "../home-data";
import {
  arabicproductListHeading,
  arabicproductsData,
} from "../home-arabic-data";
import ArrowLink from "../../shared/arrow-link/index";
import Spacer from "../../shared/spacer/index";

import {
  ProductsContainer,
  Title,
  ProductsList,
  DesktopProduct,
  DesktopProductLogo,
  ProductListTitle,
  ProductListContent,
  Wrapper,
  Products,
  ProductTitle,
  ProductInfo,
  ProductImage,
  ProductContent,
  ContentContainer,
  Editor,
  Image,
  LogoImage,
  ClientLogo,
  GrayLine,
  DesktopContainer,
  MobileContainer,
  MobileLogo,
  CenterContainer,
  MobileInnerContainer,
} from "./style";

function HomePageProducts() {
  const [selectedProduct, updateSelectedProduct] = useState("bold");
  const isArabic = useSelector((state) => state);
  useEffect(() => {
    if (isArabic) {
      updateSelectedProduct("بولد");
    } else {
      updateSelectedProduct("bold");
    }
  }, [isArabic]);
  return (
    <ProductsContainer>
      <Title>{isArabic ? arabicproductListHeading : productListHeading}</Title>
      <ProductsList>
        {isArabic
          ? arabicproductsData.map((data, index) => {
              return (
                <DesktopProduct
                  key={index}
                  selectProduct={selectedProduct === data.productTitle}
                  onClick={() => {
                    updateSelectedProduct(data.productTitle);
                  }}
                >
                  <DesktopProductLogo>
                    <img src={data.src} alt={data.altText} />
                  </DesktopProductLogo>
                  <ProductListTitle>{data.productTitle}</ProductListTitle>
                  <ProductListContent>{data.productDetail}</ProductListContent>
                </DesktopProduct>
              );
            })
          : productsData.map((data, index) => {
              return (
                <DesktopProduct
                  key={index}
                  selectProduct={selectedProduct === data.productTitle}
                  onClick={() => {
                    updateSelectedProduct(data.productTitle);
                  }}
                >
                  <DesktopProductLogo>
                    <img src={data.src} alt={data.altText} />
                  </DesktopProductLogo>
                  <ProductListTitle>{data.productTitle}</ProductListTitle>
                  <ProductListContent>{data.productDetail}</ProductListContent>
                </DesktopProduct>
              );
            })}
      </ProductsList>
      <DesktopContainer>
        {isArabic
          ? arabicproductsData.map((data, index) => {
              return (
                <Wrapper
                  key={index}
                  selectProduct={selectedProduct === data.productTitle}
                >
                  <ContentContainer>
                    <Products>
                      <div>
                        <ProductTitle>{data.productTitle}</ProductTitle>
                        <ProductInfo>{data.productDescription}</ProductInfo>
                      </div>
                    </Products>

                    <ArrowLink url={data.readMore.url} isExternal={false}>
                      {data.readMore.text}
                    </ArrowLink>
                    <Spacer m={48} />
                    <GrayLine />
                    <Spacer m={16} />
                    <ProductContent>{`"${data.productContent}"`}</ProductContent>
                    <Editor>{data.productEditor}</Editor>
                    <ClientLogo>
                      <LogoImage
                        src={data.editorImage}
                        alt={data.productTitle}
                      ></LogoImage>
                    </ClientLogo>
                  </ContentContainer>
                  <ProductImage>
                    <Image
                      src={data.productScreenshot}
                      alt={data.altText}
                    ></Image>
                  </ProductImage>
                </Wrapper>
              );
            })
          : productsData.map((data, index) => {
              return (
                <Wrapper
                  key={index}
                  selectProduct={selectedProduct === data.productTitle}
                >
                  <ContentContainer>
                    <Products>
                      <div>
                        <ProductTitle>{data.productTitle}</ProductTitle>
                        <ProductInfo>{data.productDescription}</ProductInfo>
                      </div>
                    </Products>

                    <ArrowLink url={data.readMore.url} isExternal={false}>
                      {data.readMore.text}
                    </ArrowLink>
                    <Spacer m={48} />
                    <GrayLine />
                    <Spacer m={16} />
                    <ProductContent>{`"${data.productContent}"`}</ProductContent>
                    <Editor>{data.productEditor}</Editor>
                    <ClientLogo>
                      <LogoImage
                        src={data.editorImage}
                        alt={data.productTitle}
                      ></LogoImage>
                    </ClientLogo>
                  </ContentContainer>
                  <ProductImage>
                    <Image
                      src={data.productScreenshot}
                      alt={data.altText}
                    ></Image>
                  </ProductImage>
                </Wrapper>
              );
            })}
      </DesktopContainer>
      <MobileContainer>
        {isArabic
          ? arabicproductsData.map((data, index) => {
              return (
                <MobileInnerContainer key={index}>
                  <ProductTitle>
                    <MobileLogo src={data.src} alt={data.altText} />{" "}
                    {data.productTitle}
                  </ProductTitle>
                  <ProductInfo>{data.productDescription}</ProductInfo>
                  <ProductImage>
                    <Image
                      src={data.productScreenshot}
                      alt={data.altText}
                    ></Image>
                  </ProductImage>
                  <CenterContainer>
                    <ArrowLink url={data.readMore.url} isExternal={false}>
                      {data.readMore.text}
                    </ArrowLink>
                  </CenterContainer>
                  <ProductContent>{`"${data.productContent}"`}</ProductContent>
                  <Editor>{data.productEditor}</Editor>
                  <ClientLogo>
                    <LogoImage
                      src={data.editorImage}
                      alt={data.productTitle}
                    ></LogoImage>
                  </ClientLogo>
                </MobileInnerContainer>
              );
            })
          : productsData.map((data, index) => {
              return (
                <MobileInnerContainer key={index}>
                  <ProductTitle>
                    <MobileLogo src={data.src} alt={data.altText} />{" "}
                    {data.productTitle}
                  </ProductTitle>
                  <ProductInfo>{data.productDescription}</ProductInfo>
                  <ProductImage>
                    <Image
                      src={data.productScreenshot}
                      alt={data.altText}
                    ></Image>
                  </ProductImage>
                  <CenterContainer>
                    <ArrowLink url={data.readMore.url} isExternal={false}>
                      {data.readMore.text}
                    </ArrowLink>
                  </CenterContainer>
                  <ProductContent>{`"${data.productContent}"`}</ProductContent>
                  <Editor>{data.productEditor}</Editor>
                  <ClientLogo>
                    <LogoImage
                      src={data.editorImage}
                      alt={data.productTitle}
                    ></LogoImage>
                  </ClientLogo>
                </MobileInnerContainer>
              );
            })}
      </MobileContainer>
    </ProductsContainer>
  );
}

export default HomePageProducts;
