import styled from "styled-components";

export const Icon = styled.img`
  width: 38px;
  height: 38px;
`;
export const IconContainer = styled.div`
  position: absolute;
  top: -18px;
  padding-left: 8px;
  background-color: #fff;
`;

export const MainContainer = styled.section`
  padding: 62px 24px;
  @media (min-width: ${props => props.theme.breakPoints.tablet}) {
    max-width: ${props => props.theme.containerWidth};
    margin: auto;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 24px;
    padding-top: 80px;
  }
`;

export const InnerContainer = styled.div`
  grid-column: 2/12;

  @media (min-width: ${props => props.theme.breakPoints.tablet}) {
    display: flex;
    justify-content: space-between;
  }
`;

export const HeadlineContainer = styled.div`
  letter-spacing: 1.67px;
  text-align: center;
  grid-column: 1/13;

  @media (min-width: ${props => props.theme.breakPoints.tablet}) {
    padding-bottom: 16px;
  }
`;

export const Description = styled.div`
  grid-column: 3/11;
  text-align: center;
  padding-bottom: 88px;
`;

export const Plan = styled.div`
  background-color: ${props => props.theme.color.white};
  border: solid 2px ${props => props.theme.color.gray6};

  padding: 48px 32px 32px;
  margin-bottom: 48px;
  position: relative;
  @media (min-width: ${props => props.theme.breakPoints.tablet}) {
    width: 48%;
  }
`;

export const Title = styled.div`
  font-family: ${props => props.theme.fontFamily.primary};
  font-size: ${props => props.theme.fontSize.xl};
  color: ${props => props.theme.color.gray2};
  padding-bottom: 16px;
  @media (min-width: ${props => props.theme.breakPoints.tablet}) {
    font-size: ${props => props.theme.fontSize.xxxl};
  }
`;

export const FeatureWrapper = styled.ul`
  padding-left: 20px;
`;

export const Feature = styled.li`
  font-family: ${props => props.theme.fontFamily.primary};
  font-size: ${props => props.theme.fontSize.m};
  color: ${props => props.theme.color.gray2};
  line-height: ${props => props.theme.lineHeight.lh3};

  @media (min-width: ${props => props.theme.breakPoints.tablet}) {
    font-size: ${props => props.theme.fontSize.l};
  }
`;

export const PricingHeadline = styled.div`
  position: absolute;
  top: -12px;
  background-color: #fff;
  padding: 0 8px;
  margin-left: 46px;
  font-family: ${props => props.theme.fontFamily.primary};
  color: ${props => props.theme.color.green1};
  font-size: ${props => props.theme.fontSize.m};
  font-weight: ${props => props.theme.fontWeight.bold};

  @media (min-width: ${props => props.theme.breakPoints.tablet}) {
    font-size: ${props => props.theme.fontSize.l};
  }
`;

export const ButtonWrapper = styled.div`
  grid-column: 1/13;
  text-align: center;
  margin-bottom: 40px;
`;
