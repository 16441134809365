import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Container, HamburgerIconContainer, LogoLink, Header } from "./style";
import { HamburgerIcon } from "../../icons/index";
import { QuintypeLogo } from "../../icons/index";
import NavBar from "./nav-bar/index";

function MobileViewContainer(props) {
  const [showNav, setNavVisbility] = useState(false);
  const isArabic = useSelector((state) => state);
  const [turnOff, setTurnoff] = useState(true);
  const [thankyou,setThankyou] = useState(false);
  const [navBarData,setNavbarData] = useState(props.navBarData);
  const path = window.location.pathname;
  useEffect(() => {
    if (
      path === "/case-study/bloombergquint" ||
      path === "/case-study/swarajya" ||
      path === "/case-study/fortune-india" ||
      path === "/case-study/thequint" ||
      path === "/case-study/bettors-insider" ||
      path === "/case-study/vikatan" ||
      path === "/case-study/gto-publishing" ||
      path === "/case-study/prabhat-khabar" ||
      path === "/case-study/e-sakal" ||
      path === "/case-study/matter-news" ||
      path === "/case-study/mimorelia" ||
      path === "/case-study/mimorelia-spanish-version" ||
      path === "/case-study/karjalainen"
    ) {
      // alert("turn of is arabic");
      setTurnoff(false);
    }
  }, [path]);

  useEffect(()=>{
    if(path === "/thank-you")
    {
      setThankyou(true);
      const data = props.navBarData.filter((data)=>data.url !== "/schedule-demo")
      data.push({
        type: "link",
        name: "Free Trial",
        url: "/sign-up",
      },)
      setNavbarData(data);
    }
  },[])
  return (
    <Header isIntersecting={props.isIntersecting}>
      <Container>
        <LogoLink to="/" title="Home page">
          <QuintypeLogo />
        </LogoLink>

        <HamburgerIconContainer>
          <HamburgerIcon
            displayNav={() => {
              setNavVisbility(true);
            }}
          />
        </HamburgerIconContainer>
        <NavBar
          showNav={showNav}
          hideNav={() => {
            setNavVisbility(false);
          }}
          navBarData={
            isArabic && turnOff ? props.arabicnavBarData : navBarData
          }
        />
      </Container>
    </Header>
  );
}

MobileViewContainer.propTypes = {
  navBarData: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string,
    }),
    PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string,
      listItems: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          url: PropTypes.string,
        })
      ),
    })
  ),
  isIntersecting: PropTypes.bool,
};

export default MobileViewContainer;
