import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

const linkStyles = css`
  font-family: ${props => props.theme.fontFamily.primary};
  font-size: ${props => props.theme.fontSize.m};
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  padding: 14px 0;
  font-weight: ${props => props.theme.fontWeight.bold};
  color: ${props => props.theme.color.green1};
`;
export const InternalLink = styled(Link)`
  ${linkStyles}
`;

export const ExternalLink = styled.a`
  ${linkStyles}
`;
