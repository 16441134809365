import React from "react";
import PropTypes from "prop-types"

import { SocialLinksContainer, SocialLink } from "./style";


function SocialLinksComp({ data, target }) {

  return (
    <SocialLinksContainer>
      {data.map(item => {
        const { name, url, Icon } = item;
       
        return (
          <SocialLink
            key={name}
            href={url}
            isTargetting={target}
            target="_blank"
            rel="noopener noreferrer"
          >
           <Icon />
          </SocialLink>
        );
      })}
    </SocialLinksContainer>
  );
}

SocialLinksComp.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
    Icon: PropTypes.func
  })),
  target: PropTypes.string
}

export default SocialLinksComp;
