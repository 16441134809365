import styled from "styled-components";

export const ImageContainer = styled.div`
  grid-column: 1/13;
  display: grid;
  padding: 32px;
  justify-items: center;
  align-items: center;
  grid-template-columns: 1fr;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 20px;
    padding: 0;
  }
`;

export const SecondRowImageContainer = styled.div`
  display: none;

  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    display: grid;
    grid-column: 1/13;
    grid-template-columns: 1fr;
    justify-items: center;
    align-items: center;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 20px;
    padding: 0;
  }
`;

export const Item = styled.div`
  width: 200px;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    grid-column: ${props => props.startColumn} / span 1;
    width: 100%;
  }
`;

export const Image = styled.img`
  max-width: 100%;
`;
