import React from "react";
import { Link } from "react-router-dom";

import PrimaryButton from "../../../shared/primaryButton";
import SecondaryButton from "../../../shared/secondaryButton";

import {
  Wrapper,
  TitleContainer,
  InfoContainer,
  ImageContainer,
  Image,
  ButtonContainer,
  InnerWrapper,
  Container,
  MobileContainer
} from "./style";

import H1 from "../../../shared/h1/index";
import Para from "../../../shared/para-reg/index";

function TopComponentAltType({ data }) {
  return (
    <Wrapper>
      <InnerWrapper>
        <MobileContainer>
          <TitleContainer>
            <H1>{data.heading}</H1>
          </TitleContainer>
        </MobileContainer>
        <Container>
          <TitleContainer>
            <H1>{data.heading}</H1>
          </TitleContainer>
          <InfoContainer>
            <Para>{data.info}</Para>
          </InfoContainer>

          <ButtonContainer>
            <Link to={data.primaryButtonContent.link}>
              <PrimaryButton>{data.primaryButtonContent.text}</PrimaryButton>
            </Link>
            {
              data.secondaryButtonContent.isRequired ?
                <SecondaryButton isExternal={true} link={data.secondaryButtonContent.link}>
                  {data.secondaryButtonContent.text}
                </SecondaryButton>
                : <></>
            }
          </ButtonContainer>
        </Container>
        <MobileContainer>
          <InfoContainer>
            <Para>{data.info}</Para>
          </InfoContainer>

          <ButtonContainer>
            <Link to={data.primaryButtonContent.link}>
              <PrimaryButton>{data.primaryButtonContent.text}</PrimaryButton>
            </Link>
            {
              data.secondaryButtonContent.isRequired ?
                <SecondaryButton isExternal={true} link={data.secondaryButtonContent.link}>
                  {data.secondaryButtonContent.text}
                </SecondaryButton>
                : <></>
            }
          </ButtonContainer>
        </MobileContainer>
        <ImageContainer>
          <Image
            data-src={data.image.url}
            alt={data.image.alt}
            height={data.image.height || "auto"}
          />
        </ImageContainer>
      </InnerWrapper>
    </Wrapper>
  );
}

export default TopComponentAltType;
