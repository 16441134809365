import BoldLogo from "../../components/icons/bold.svg";
import AheadLogo from "../../components/icons/ahead.svg";
import MetypeLogo from "../../components/icons/metype.svg";
import AccesstypeLogo from "../../components/icons/accesstype.svg";

export const mainContentData = {
  heading: "Let the Content Renaissance begin. ",
  info:
    "Enough fighting with your CMS. Quintype provides a complete suite of digital publishing solutions that are powerful, easy to use and dependable. ",
  primaryButtonText: "Start Free Trial",
  secondaryButtonText: "Watch Demo",

  subHeading:
    "How can you start a renaissance if you are too caught up figuring tech?",
  subHeadingdescription:
    "Quintype gives you the freedom to publish, distribute and monetize content sans the tech hiccups. We have a suite of products to take care of every tiny detail. Unleash the power of your content on our platform."
};

export const productListHeading = "Our Digital Publishing Solutions, An Empowering Suite Of Products";

export const productsData = [
  {
    productTitle: "bold",
    productDescription:
      "All the content liberation tools you have wished for. And, all those you haven’t yet.",
    src: BoldLogo,
    productContent:
      "The team consistently adds new features to benefit publishers and the products are designed intelligently. The front-end is designed for malleability to meet our customized requirements making Quintype a hassle-free choice for us.",
    productEditor: "Sonakshi Sinha, Product Manager",
    productDetail: "CMS that understands your content",
    editorImage: "https://gumlet.assettype.com/quintype-website/2019-12/e410a432-32d0-4aac-94a4-b27f1d2b978c/Client_15.png?w=160&dpr=1.0",
    productScreenshot: "https://gumlet.assettype.com/quintype-website/2022-09/a54010e0-3958-4049-ba67-1c43d4e461d6/BOLD_Workspace.png",
    altText: "headless cms",
    readMore: {
      url: "/products/bold",
      text: "Explore Features",
      title: "explore features"
    }
  },
  {
    productTitle: "ahead",
    productDescription: "Powerful presentation for your powerful content.",
    src: AheadLogo,
    productContent:
      "Quintype's minimalistic and easy-to-use UI/UX for visitors is a big plus point. The backend is easy to use and has been specifically designed keeping content publishers in mind.",
    productEditor: "Shruty Bhattacharyya, Digital Manager",
    productDetail: "Content first front-end for a riveting online presence",
    editorImage: "https://images.assettype.com/quintype-website/2020-03/6967c460-24ec-45eb-8f87-b2ab7577fced/East_Mojo_Logo_01.svg",
    productScreenshot: "https://gumlet.assettype.com/quintype-website/2019-12/f96908f7-7e17-43de-83a7-af84ecb57caa/ahead_product.png",
    altText: "cms page builder",
    readMore: {
      url: "/products/ahead",
      text: "Learn More",
      title: "learn more ahead"
    }
  },
  {
    productTitle: "metype",
    productDescription: "Get people talking.",
    src: MetypeLogo,
    productContent:
      "Metype helps our team to engage with our young audience while ensuring high quality conversations through their toxicity meter.",
    productEditor: "Amarnath Govindarajan, Digital Head",
    productDetail: "Absolute audience engagement",
    editorImage: "https://gumlet.assettype.com/quintype-website/2019-12/db2d7899-378b-4c89-aac3-f6b52163ea8c/swarajya_29.svg",
    productScreenshot: "https://gumlet.assettype.com/quintype-website/2019-11/5ec3de77-e3d8-4e74-9b43-2efde28329f1/metype_audience_engagement.png",
    altText: "metype",
    readMore: {
      url: "/products/metype",
      text: "See How",
      title: "see how metype"
    }
  },
  {
    productTitle: "accesstype",
    productDescription: "Turn readers into faithful followers.",
    src: AccesstypeLogo,
    buttonText: "Explore",
    productContent:
      "Monetization in our business is constantly evolving and Accesstype helped us experiment with multiple subscription models to find the right balance. We would recommend Accesstype for anyone looking to monetize their content beyond advertising.",
    productEditor: "Ankit Dikshit, Digital Marketing",
    productDetail: "Robust content monetization",
    editorImage: "https://gumlet.assettype.com/quintype-website/2019-12/3163b395-6987-4d61-8c7f-9fb0ff4c2229/Client_14.png",
    productScreenshot: "https://gumlet.assettype.com/quintype-website/2019-12/c34f1743-3202-42d1-b099-419b719ef8dd/accesstype_product.png",
    altText: "accesstype",
    readMore: {
      url: "/products/accesstype",
      text: "Explore",
      title: "explore accesstype"
    }
  }
];
