import styled from "styled-components";

export const MainContainer = styled.section`
  font-family: ${props => props.theme.fontFamily.primary};
  @media (min-width: ${props => props.theme.breakPoints.tablet}) {
    max-width: ${props => props.theme.containerWidth};
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    padding-top: 58px;
    margin: auto;
  }
`;

export const HeadlineContainer = styled.div`
  padding: 0 16px 16px;
  text-align: center;

  @media (min-width: ${props => props.theme.breakPoints.tablet}) {
    grid-column: 2/11;
    padding-bottom: 32px;
  }
`;

export const FeatureContainer = styled.div`
  @media (min-width: ${props => props.theme.breakPoints.tablet}) {
    grid-column: 1/13;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
`;

export const FeatureItem = styled.div`
  background-color: ${props => props.theme.color.white};
  box-shadow: ${props => props.theme.shadow.two};
  border-radius: 5px;
  padding: 32px;
  margin-bottom: 8px;
  text-align: center;
  @media (min-width: ${props => props.theme.breakPoints.tablet}) {
    width: 22%;
    padding: 24px 24px;
    margin-bottom: 24px;
  }
  @media (max-width: ${props => props.theme.breakPoints.tablet}) {
    display: flex;
    align-items: center;
    text-align: left;
  }
`;

export const Content = styled.div`
  @media (max-width: ${props => props.theme.breakPoints.tablet}) {
    padding-left: 16px;
  }
`;

export const Image = styled.img`
  max-width: 100%;
  padding-bottom: 12px;
`;

export const SubHeadline = styled.h4`
  color: ${props => props.theme.color.green1};
  font-size: ${props => props.theme.fontSize.s};
  line-height: ${props => props.theme.lineHeight.lh3};
  font-weight: ${props => props.theme.fontWeight.bold};
  padding-bottom: 8px;
`;

export const Description = styled.div`
  font-family: ${props => props.theme.fontFamily.primary};
  color: ${props => props.theme.color.gray2};
  font-size: ${props => props.theme.fontSize.s};
  line-height: ${props => props.theme.lineHeight.lh3};
  @media (min-width: ${props => props.theme.breakPoints.tablet}) {
    font-size: ${props => props.theme.fontSize.s};
  }
`;
