import styled from "styled-components";

export const IntegrationCardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  grid-gap: 24px;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const Card = styled.div`
  padding: 20px 0;
  box-shadow: ${props => props.theme.shadow.two};
  display: inline-block;
  text-align: center;
  width: 100%;
  max-width: 160px;
  border-radius: 5px;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    padding: 40px 0 20px;
    width: auto;
    min-width: 240px;
    max-width: 270px;
  }
  &:hover {
    transform: scale(1.03);
  }
`;

export const Image = styled.img`
    height: 80px;
    width: 80px;
    @media (min-width: ${props => props.theme.breakPoints.desktop}) {
      height: 100px;
      width: 100px;
    }
`;

export const DisplayName = styled.h2`
  font-family: ${props => props.theme.fontFamily.primary};
  font-size: ${props => props.theme.fontSize.xxs};
  color: ${props => props.theme.color.gray2};
  font-weight: ${props => props.theme.fontWeight.bold};
  line-height: ${props => props.theme.lineHeight.lh4};
  text-align: center;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    margin: 20px 0;
    font-size: ${props => props.theme.fontSize.s};
  }
`;