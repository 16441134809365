import styled, { css } from "styled-components";

export const DesktopContainer = styled.div`
 display: none;
 @media (min-width: ${props => props.theme.breakPoints.desktop}) {
   display: block;
 }

`

export const MobileContainer = styled.div`
 padding: 0 10px;
 @media (min-width: ${props => props.theme.breakPoints.desktop}) {
   display: none;
 }

`



export const ProductsContainer = styled.section`
padding: 40px 20px;
 @media (min-width: ${props => props.theme.breakPoints.desktop}) {
  padding: 136px 24px 60px 24px;
  max-width: ${props => props.theme.containerWidth};
  margin: auto;
 }
`;

export const Title = styled.h1`
  font-family: ${props => props.theme.fontFamily.primary};
  font-size: ${props => props.theme.fontSize.s};
  font-weight: ${props => props.theme.fontWeight.bold};
  line-height: ${props => props.theme.lineHeight.lh2};
  text-align: center;
  color: ${props => props.theme.color.gray2};
  text-transform: uppercase;
  margin: 0 0 36px 0;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    margin: 0 0 48px 0;
    font-size: ${props => props.theme.fontSize.xxxl};
  }
`;

export const ProductsList = styled.div`
  display: none;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    display: flex;
    justify-content: center;
    border-radius: 10px;
    background-image: ${props => props.theme.color.background};
    margin: 0 0 40px 0;
  }
`;

export const DesktopProduct = styled.div`
  cursor: pointer;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    padding: 40px 20px 20px 20px;
    max-width: 25%;
    ${props =>
      props.selectProduct &&
      css`
        border-radius: 10px;
        box-shadow: ${props => props.theme.shadow.one};
        transition: 0.3s;
        background-color: ${props => props.theme.color.white};
      `}
  }
`;

export const DesktopProductLogo = styled.div`
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    text-align: center;
  }
`;

export const ProductListTitle = styled.h2`
  font-family: ${props => props.theme.fontFamily.primary};
  font-size: ${props => props.theme.fontSize.xxs};
  font-weight: ${props => props.theme.fontWeight.bold};
  line-height: ${props => props.theme.lineHeight.lh3};
  text-align: center;
  color: ${props => props.theme.color.gray2};
  text-transform: uppercase;
  margin: 30px 0 0 0;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    font-size: ${props => props.theme.fontSize.s};
  }
`;

export const ProductListContent = styled.p`
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    font-family: ${props => props.theme.fontFamily.primary};
    font-size: ${props => props.theme.fontSize.s};
    font-weight: ${props => props.theme.fontWeight.regular};
    line-height: ${props => props.theme.lineHeight.lh2};
    text-align: center;
    color: ${props => props.theme.color.gray2};
  }
`;

export const Wrapper = styled.div`
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    display: none;
    ${props =>
      props.selectProduct &&
      css`
        display: grid;
        grid-template-columns: repeat(12,1fr);
        padding: 0 0 50px 0;
        grid-column-gap: 24px;
      `}
  }
`;


export const ContentContainer = styled.div`
  grid-column: 1/5;
`;



export const Products = styled.div`
  display: flex;
  align-items: center;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    display: block;
  }
`;

export const ProductTitle = styled.h2`
  display: flex;
  align-items: center;
  font-family: ${props => props.theme.fontFamily.primary};
  font-size: ${props => props.theme.fontSize.xs};
  font-weight: ${props => props.theme.fontWeight.bold};
  line-height: ${props => props.theme.lineHeight.lh1};
  color: ${props => props.theme.color.gray2};
  text-transform: uppercase;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    display: none;
  }
`;

export const ProductInfo = styled.p`
  font-family: ${props => props.theme.fontFamily.primary};
  font-size: ${props => props.theme.fontSize.m};
  font-weight: ${props => props.theme.fontWeight.bold};
  line-height: ${props => props.theme.lineHeight.lh2};
  color: ${props => props.theme.color.gray2};
  margin: 12px 0 24px 0;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    font-size: ${props => props.theme.fontSize.l};
    line-height: ${props => props.theme.lineHeight.lh4};
    text-align: left;
    margin: 0;
  }
`;
export const ProductImage = styled.div`
  text-align: center;
  padding: 0 10px;
  @media (min-width: 670px) and (max-width: 1023px) {
    max-width: 100%;
    width: 560px;
    margin: 0 auto;

  }
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
   grid-column: 6/13;
   padding:0;
  }
`;

export const ProductContent = styled.p`
  font-family: ${props => props.theme.fontFamily.primary};
  font-size: ${props => props.theme.fontSize.s};
  line-height: ${props => props.theme.lineHeight.lh4};
  text-align: center;
  color: ${props => props.theme.color.typeSubtle};
  margin: 0 0 16px 0;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    font-size: ${props => props.theme.fontSize.m};
    text-align: left;
    margin: 10px 0 16px 0;
    font-style: italic;
  }
`;

export const Image = styled.img`
  max-width: 100%;
`;

export const LogoImage = styled.img`
  max-width: 100%;
  width: 150px;
`;

export const Editor = styled.p`
  font-family: ${props => props.theme.fontFamily.primary};
  font-size: ${props => props.theme.fontSize.xs};
  font-weight: ${props => props.theme.fontWeight.bold};
  line-height: ${props => props.theme.lineHeight.lh5};
  color: ${props => props.theme.color.black1};
  text-align: center;
  margin-bottom: 8px;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    font-size: ${props => props.theme.fontSize.s};
    text-align: left;
  }
`;

export const ClientLogo = styled.div`
  text-align: center;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    text-align: left;
  }
`;

export const GrayLine = styled.div`
 height: 1px;
 background: ${props => props.theme.color.gray5};
`

//mobile styles

export const MobileLogo = styled.img`
 width: 24px;
 height: 24px;
 margin-right: 12px;
`

export const CenterContainer = styled.div`
 display: flex;
 justify-content: center;
 margin: 24px 0 16px 0;
`

export const MobileInnerContainer = styled.div`
margin-bottom: 56px;
`
