import React from "react";
import { useSelector } from "react-redux";
import TopComponent  from "./shared/top-component";
import ImageWithContent from "./shared/image-with-content";
import Features from "./shared/features";
import ClientReview from "./shared/client-review";
import { data } from "./data";
import {arabicdata} from "./arabic-data";
function ReactNativeMobile () {
  const isArabic = useSelector((state)=>state); 
  return (
    <React.Fragment>
      <TopComponent data={isArabic ? arabicdata.react_native_mobile["top-component"] :data.react_native_mobile["top-component"]}/>
      <ImageWithContent data={isArabic ? arabicdata.react_native_mobile["image-with-content"]:data.react_native_mobile["image-with-content"]} />
      <Features data={isArabic ? arabicdata.react_native_mobile.features:data.react_native_mobile.features}/>
      <ClientReview data={isArabic ? arabicdata.react_native_mobile.review:data.react_native_mobile.review} />
    </React.Fragment>
  );
}

export default ReactNativeMobile;