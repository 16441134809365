import styled from "styled-components";

export const ToggleWrapper = styled.div`
  max-width: 72px;
  max-height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    margin-left: 0px
  }
`
export const CheckBoxLabel = styled.label`
  position: relative;
  width: 72px;
  height: 32px;
  border-radius: 4px;
  overflow: hidden;
  perspective: 1px;
  cursor: pointer;  
`;


export const TextSpan = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 38px;
  height: 32px;
  background: #000000;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 16px;

  &:after{
    content: "ش";
    position: absolute;
    top: 0;
    left: 38px;
    width: 38px;
    height: 32px;
    background: #F0F1F5;
    color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 16px;

  }
`;

export const Checkbox = styled.input`
  appearance: none;
  &:checked + ${TextSpan}{
    background-color:#F0F1F5;
    color: #000000;

    &:after{
      background: #000000;
      color: #FFFFFF;
      position: absolute;
      top: 0;
      left: 38px;
      width: 38px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      font-size: 16px;
    }
  }


`;