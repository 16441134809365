import styled from "styled-components";


export const AccordionContainer = styled.div`
  background-image: ${props=> props.theme.color.background};
  display:flex;
  flex-direction:column;
  align-items:center;
  justfify-content:center;
  position:relative;      
  font-family: ${props=> props.theme.fontFamily.primary};
  color: ${props=> props.theme.color.gray1};
  font-weight: ${props=> props.theme.fontWeight.regular};
  margin-top: 72px;

  @media (min-width: ${props => props.theme.breakPoints.tablet}) {
    max-width: ${props => props.theme.containerWidth};    
    padding-top: 58px;
    margin: auto;
  }
`;

export const HeadlineContainer = styled.div`
  letter-spacing: 1.67px;
  text-align: center;
  grid-column: 1/13;

  @media (min-width: ${props => props.theme.breakPoints.tablet}) {
    padding-bottom: 16px;
  }
`;

export const Heading = styled.h2`
font-size:20px;
color:${props=> props.theme.color.green1};
margin: 0.67em 0;
`

export const Container = styled.div`
top:30%;
padding: 10px 25px;
max-width: ${props => props.theme.containerWidth};  
`;

export const ContentContainer = styled.div`
border-top:1px solid #979797;
padding-bottom: 10px;

`;

export const Wrapper = styled.div`
  display:flex;
  justify-content:space-between;
  align-items:center; 
  cursor:pointer;
  max-width: ${props => props.theme.containerWidth};  
`;

export const Span = styled.span`
font-size:22px;
color:${props=> props.theme.color.green1};
`

export const DropDown = styled.div`
max-width: 600px;  
`;

export const FeatureWrapper = styled.ul`
  padding-left: 20px;
`;

export const Feature = styled.li`
  font-family: ${props=> props.theme.fontFamily.primary};  
  line-height: ${props => props.theme.lineHeight.lh3};

  @media (min-width: ${props => props.theme.breakPoints.tablet}) {
    font-family: ${props=> props.theme.fontFamily.primary};
  }
`;

export const LinkItem = styled.div`
  text-align: left;
  grid-column: 1/13;
  padding-top: 6px;

`;