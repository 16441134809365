import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

const sharedLinkStyles = css`
  border-radius: 30px;
  background-color: ${props => props.theme.color.white};
  border: 2px solid ${props => props.theme.color.green1};
  color: ${props => props.theme.color.green1};
  font-family: ${props => props.theme.fontFamily.primary};
  font-size: ${props => props.theme.fontSize.xs};
  font-weight: ${props => props.theme.fontWeight.bold};
  letter-spacing: ${props => props.theme.letterSpacing.ls1};
  padding: 16px 12px;
  text-align: center;
  cursor: pointer;
  @media (min-width: ${props => props.theme.breakPoints.tablet}) {
    font-size: ${props => props.theme.fontSize.s};
    padding: 16px 30px;
  }
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    border-radius: 40px;
    font-size: ${props => props.theme.fontSize.m};
    padding: 18px 40px;
  }
`;

export const SecondaryButtonContainer = styled.button`
  ${sharedLinkStyles}
`;

export const SecondaryLinkContainer = styled(Link)`
  ${sharedLinkStyles}
`;

export const LinkItem = styled.a  `
  ${sharedLinkStyles}
`; 
