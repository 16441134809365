import styled from "styled-components";

export const TermsWrapper = styled.div`
  padding: 0 24px;
  margin: 120px auto 0 auto;
  @media (min-width: ${(props) => props.theme.breakPoints.desktop}) {
    max-width: ${(props) => props.theme.containerWidth};
    padding: 0;
  }
`;

export const MainHeading = styled.h1`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-size: ${(props) => props.theme.fontSize.m};
  text-transform: uppercase;
  margin: 25px 0;
  text-align: ${(props) => (props.isArabic ? `right` : `center`)};
  @media (min-width: ${(props) => props.theme.breakPoints.desktop}) {
    font-size: ${(props) => props.theme.fontSize.l};
  }
`;

export const Title = styled.h3`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-size: ${(props) => props.theme.fontSize.m};
  text-transform: uppercase;
  margin: 25px 0;
  text-align: ${(props) => (props.isArabic ? `right` : `center`)};
  @media (min-width: ${(props) => props.theme.breakPoints.desktop}) {
    font-size: ${(props) => props.theme.fontSize.l};
  }
`;

export const Heading = styled(Title)``;

export const LastUpdated = styled(Title)``;

export const SubHeading = styled(Title)`
  font-weight: 300;
  text-transform: capitalize;
`;

export const Content = styled.p`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-size: ${(props) => props.theme.fontSize.xs};
  line-height: ${(props) => props.theme.lineHeight.lh6};
  text-align: ${(props) => (props.isArabic ? `right` : `center`)};
  margin: 0 0 30px 0;
  color: ${(props) => props.theme.color.black};
`;

export const TermsCondition = styled.ul`
  padding: 0 10px;
  @media (min-width: ${(props) => props.theme.breakPoints.desktop}) {
    padding: 0 0 0 40px;
    margin: 16px 0;
  }
`;

export const TermsConditionList = styled.li`
  font-family: ${(props) => props.theme.fontFamily.primary};
  list-style: decimal;
  font-size: ${(props) => props.theme.fontSize.xs};
  line-height: ${(props) => props.theme.lineHeight.lh6};
  color: ${(props) => props.theme.color.black};
  text-align: ${(props) => (props.isArabic ? `right` : `left`)};
`;

export const IncludeFeatures = styled.h3`
  font-family: ${(props) => props.theme.fontFamily.primary};
  text-align: ${(props) => (props.isArabic ? `right` : `center`)};
`;

export const FeaturesWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 10px 0 30px 0;
  padding: 0 20px;
  @media (max-width: ${(props) => props.theme.breakPoints.desktop}) {
    flex-direction: column;
  }
`;

export const PlatformFeatures = styled.ul``;

export const ListOfFeatures = styled.li`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-size: ${(props) => props.theme.fontSize.xs};
  line-height: ${(props) => props.theme.lineHeight.lh6};
  color: ${(props) => props.theme.color.black};
  text-align: ${(props) => (props.isArabic ? `right` : `left`)};
`;

export const TableWrapper = styled.div`
  overflow-x: auto;
`;

export const Table = styled.table`
  border: 1px solid ${(props) => props.theme.color.gray5};
  margin: 20px 0;
  text-align: ${(props) => (props.isArabic ? `right` : `center`)};
`;

export const TableRow = styled.tr`
  background-color: ${(props) => props.theme.color.gray4};
  border: 1px solid ${(props) => props.theme.color.white};
  padding: 10px;
`;

export const TableHeading = styled.th`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-size: ${(props) => props.theme.fontSize.xs};
  letter-spacing: 0.1em;
  text-transform: uppercase;
  padding: 10px;
`;

export const TableData = styled.td`
  font-family: ${(props) => props.theme.fontFamily.primary};
  padding: 10px;
  font-size: ${(props) => props.theme.fontSize.xs};
  text-align: ${(props) => (props.isArabic ? `right` : `center`)};
`;
