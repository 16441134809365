import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";

import PrimaryButton from "../../../shared/primaryButton";
import SecondaryButton from "../../../shared/secondaryButton";

import {
  TopContainer,
  ProductIntro,
  Logo,
  ProductName,
  ProductDescriptionContainer,
  LogoWrapper,
  ProductInfo,
  ProductButtonImageContainer,
  ButtonContainer,
  ImageContainer,
  Image
} from "./style";

import H1 from "../../../shared/h1/index";
import H2 from "../../../shared/h2/index";
import Para from "../../../shared/para-reg/index";

function TopComponent({ data }) {
  const [showModal, updateModalState] = useState(false);
  return (
    <TopContainer>
      <LogoWrapper>
        <ProductIntro>
          <ProductName>
            {
              data.logo.url ? <Logo src={data.logo.url} alt={data.logo.alt} /> : null
            }
            <H2>{data.name} </H2>
          </ProductName>
          <ProductDescriptionContainer>
            <H1>{data.headline}</H1>
          </ProductDescriptionContainer>
        </ProductIntro>
      </LogoWrapper>
      <ProductInfo>
        <Para> {data.info}</Para>
      </ProductInfo>
      <ProductButtonImageContainer>
        <ButtonContainer>
          {data.firstButtonContent.linkType === "internal" ? (
            <Link to={data.firstButtonContent.link}>
              <PrimaryButton>{data.firstButtonContent.text}</PrimaryButton>
            </Link>
          ) : (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={data.firstButtonContent.link}
            >
              <PrimaryButton>{data.firstButtonContent.text}</PrimaryButton>
            </a>
          )}
          {data.secondButtonContent.required && (
            data.secondButtonContent.linkType === "internal" ? (
              <Link to={data.secondButtonContent.link}>
                <SecondaryButton>{data.secondButtonContent.text}</SecondaryButton>
              </Link>
            ) : (
            <SecondaryButton onClick={() => updateModalState(true)}>
              {data.secondButtonContent.text}
            </SecondaryButton>
          ))}
        </ButtonContainer>
        <ImageContainer>
          <Image
            data-src={data.image.url}
            alt="Let the Content Renaissance begin."
          />
        </ImageContainer>
      </ProductButtonImageContainer>
      <ModalVideo
        channel="youtube"
        isOpen={showModal}
        rel={0}
        autoplay=	{true}
        videoId={data.secondButtonContent.videoId}
        onClose={() => updateModalState(false)}
      />
    </TopContainer>
  );
}

TopComponent.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    headline: PropTypes.string,
    info: PropTypes.string,
    "free-trial": PropTypes.shape({
      link: PropTypes.string,
      title: PropTypes.string,
      text: PropTypes.string
    }),
    "watch-video": PropTypes.shape({
      link: PropTypes.string,
      title: PropTypes.string,
      text: PropTypes.string
    })
  })
};

export default TopComponent;
