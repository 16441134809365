export const title =
  "For all those wanting to be the next big publishing idea, how can we help?";

export const description =
  "Big ideas only get big when they turn into reality. We are here to support, consult and root for you in all things tech and non-tech.";

export const consultingData = [
  {
    heading: "Content Experience Consulting",
    detailsOne:
      "Building great content experiences has always been the core our work. From layouts and typography to user experience and content consumption trends, we have built solutions for some of the largest publishers. Leverage the skills of our in-house design practice to consult, build and analyze your next project.",
    detailsTwo:
      "Our team has built their own content layouting design system that includes the best practices in building visually appealing, mobile-first, configurable web components. Talk to us to learn more."
  },
  {
    heading: "Custom Front-end Developement and Integrations",
    detailsOne:
      "Our front-end development team that’s well-versed in integrations and development of all kinds for a variety of news and content publishers, is up for grabs!",
    detailsTwo:
      "They can help you build a high-performance front-end so you can go live faster. And, our secret is our own reusable code frameworks and understanding of Bold’s API."
  },
  {
    heading: "Digital Publishing Consulting",
    detailsOne:
      "We give you a wealth of industry experience and a strong, committed support team to navigate the challenges of digital publishing. And, we love a good challenge!",
    detailsTwo:
      "Talk to us about Content distribution, Audience engagement, Revenue strategies and integrations with emerging technology."
  }
];

export const features = {
  feature: [
    {
      title: "24/7 on-call infrastructure monitoring",
      url:
        "https://gumlet.assettype.com/quintype-website/2019-11/4500dcc7-e54d-428d-bed4-1c274bf43abe/Consultation_1.svg",
      alt: "24/7 on-call"
    },
    {
      title: "21/7 email/chat support",
      url:
        "https://gumlet.assettype.com/quintype-website/2019-11/076a566e-183a-4419-9f20-9d6a16f29af5/Consultation_2.svg",
      alt: "21/7 email"
    },
    {
      title: "Dedicated Account Manager",
      url:
        "https://gumlet.assettype.com/quintype-website/2019-11/1b7adf3f-f53c-4eeb-bcd0-eec64bc641a0/Consultation_3.svg",
      alt: "Dedicated Account Manager"
    },
    {
      title: "Industry trends Update",
      url:
        "https://gumlet.assettype.com/quintype-website/2019-11/81b8304a-2081-462a-8d82-ff010dd8ff41/Consultation_4_11_copy_3.svg",
      alt: "Update"
    }
  ]
};
