import React from "react";
import { useSelector } from "react-redux";

import {
  Label,
  Input,
  Select,
  Option,
  TextArea,
  CheckboxInput,
  Container
} from "./style";

function TextField({ label, name, register, required, errors }) {
  return (
    <React.Fragment>
      <Label>{label}</Label>
      <Input
        name={name}
        ref={register({ required: required })}
        error={errors[`${name}`]}
      />
    </React.Fragment>
  );
}



export { TextField };

function EmailField({ label, name, register, required, errors }) {
  return (
    <React.Fragment>
      <Label>{label}</Label>
      <Input
        name={name}
        ref={register({
          required: required,
          pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/
        })}
        error={errors[`${name}`]}
      />
    </React.Fragment>
  );
}

export { EmailField };

function DropDown({ label, options, register,name}) {
  return (
    <React.Fragment>
      <Label>{label}</Label>
      <Select ref={register} name={name}>
      {
          options.map((item,index)=>{
          return <Option key={index} svalue={item}>{item}</Option>
          })
      }
      </Select>
    </React.Fragment>
  );
}

export { DropDown };

function TextAreaField({ label ,name, register}) {
  return (
    <React.Fragment>
      <Label>{label}</Label>
      <TextArea ref={register} name={name}></TextArea>
    </React.Fragment>
  );
}

export { TextAreaField };

function CheckBox({ label,register }) {
  const isArabic = useSelector((state)=>state);
  return (
    <React.Fragment>
      <Label>{label}</Label>
      <Container >
        <CheckboxInput type="checkbox" name="contact_via_phone" ref={register}/>
        {isArabic ? "الهاتف" : "Phone"}
      </Container>
      <Container style={{marginRight:"0px"}}>
        <CheckboxInput type="checkbox" name="contact_via_email" ref={register} />
        {isArabic ? "البريد الإلكتروني" : "Email"}
      </Container>
    </React.Fragment>
  );
}

export { CheckBox };

