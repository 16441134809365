export const data = [
  {
    name: "Company",
    menuList: [
      {
        name: "About",
        url: "/about",
        title: "about quintype",
        linkType: "internal",
      },

      {
        name: "Consulting",
        url: "/consulting",
        title: "consulting",
        linkType: "internal",
      },

      {
        name: "Careers",
        url: "https://quintype.zohorecruit.com/jobs/Careers",
        title: "Careers",
        linkType: "external",
      },
      {
        name: "Contact Us",
        url: "/contact-us",
        title: "Contact us",
        linkType: "internal",
      },
      {
        name: "Privacy Policy",
        url: "/privacy-policy",
        title: "Privacy Policy",
        linkType: "internal",
      },
    ],
  },

  {
    name: "Support",
    menuList: [
      {
        name: "Help Docs",
        url: "https://help.quintype.com",
        title: "Help Docs",
        linkType: "external",
      },

      {
        name: "Developer Docs",
        url: "https://developers.quintype.com/",
        title: "Developer Docs",
        linkType: "external",
      },
      {
        name: "Schedule demo",
        url: "/schedule-demo",
        title: "schedule demo",
        linkType: "internal",
      },
      {
        name: "Free trial",
        url: "/sign-up",
        title: "free trial",
        linkType: "internal",
      },
      {
        name: "Status",
        url: "https://status.quintype.com/",
        title: "Status page",
        linkType: "external",
      },
      {
        name: "Terms",
        url: "/terms",
        title: "Terms",
        linkType: "internal",
      },
    ],
  },

  {
    name: "Products",
    menuList: [
      {
        name: "Bold",
        url: "/products/bold",
        title: "product-bold",
        linkType: "internal",
      },

      {
        name: "Ahead",
        url: "/products/ahead",
        title: "product-ahead",
        linkType: "internal",
      },

      {
        name: "Metype",
        url: "/products/metype",
        title: "product-metype",
        linkType: "internal",
      },

      {
        name: "Accesstype",
        url: "/products/accesstype",
        title: "product-accesstype",
        linkType: "internal",
      },
    ],
  },

  {
    name: "Resources",
    menuList: [
      {
        name: "Blog",
        url: "https://www.quintype.com/blog",
        title: "blog",
        linkType: "external",
      },
      {
        name: "Case Studies",
        url: "/case-study",
        title: "case study",
        linkType: "internal",
      },
      {
        name: "Integrations",
        url: "/integrations",
        linkType: "internal",
      },
      {
        name: "Essence",
        url: "/essence",
        title: "Essence",
        linkType: "internal",
      },

      {
        name: "Partners",
        url: "/partners",
        title: "Partners",
        linkType: "internal",
      },
      {
        name: "Guide",
        url: "/guides",
        title: "Guide page",
        linkType: "internal",
      },
      {
        name: "Press Release",
        url: "https://www.quintype.com/blog/press-release",
        title: "Press Release",
        linkType: "external",
      },
      {
        name: "Awards",
        url: "https://www.quintype.com/blog/people/awards",
        title: "Awards",
        linkType: "external",
      },
    ],
  },

  {
    name: "Solutions",
    menuList: [
      {
        name: "New and Modern Publishers",
        url: "/solutions/new-and-modern-publishers",
        title: "New and Modern Publishers",
        linkType: "internal",
      },
      {
        name: "Magazine Publishers",
        url: "/solutions/magazine-publishers-app",
        title: "Magazine Publishers",
        linkType: "internal",
      },
      {
        name: "To Increase Traffic",
        url: "/solutions/to-increase-traffic",
        title: "To Increase Traffic",
        linkType: "internal",
      },
      {
        name: "Monetize your content",
        url: "/solutions/monetize-content",
        title: "Monetize your content",
        linkType: "internal",
      },
      {
        name: "For Developers",
        url: "/solutions/for-developers",
        title: "For Developers",
        linkType: "internal",
      },
    ],
  },
];
