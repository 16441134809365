import React from "react";
import { useSelector } from "react-redux";
import TopComponent from "./shared/top-component";
import ImageWithContent from "./shared/image-with-content";
import ClientReview from "./shared/client-review";
import FreeTrial from "./shared/trial/index";
import Accordion from "./shared/accordion";
import { data } from "./data";
import {arabicdata} from "./arabic-data";

function Metype() {
  const isArabic = useSelector((state)=>state);
  return (
    <React.Fragment>
      <TopComponent data={isArabic ? arabicdata.metype["top-component"] : data.metype["top-component"]} />
      <ImageWithContent data={isArabic ? arabicdata.metype["image-with-content"] : data.metype["image-with-content"]} />
      <ClientReview data={isArabic ? arabicdata.metype.review : data.metype.review} />
      <FreeTrial data={isArabic ? arabicdata.metype.trialData : data.metype.trialData}/>
      <Accordion data={isArabic ? arabicdata.metype.faqs : data.metype.faqs}/>
    </React.Fragment>
  );
}

export default Metype;