import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

const sharedLinkStyles = css`
  border-radius: 30px;
  background-color: ${props => props.theme.color.green1};
  border: 1px solid transparent;
  color: ${props => props.theme.color.white};
  font-family: ${props => props.theme.fontFamily.primary};
  font-size: ${props => props.theme.fontSize.xs};
  font-weight: ${props => props.theme.fontWeight.bold};
  letter-spacing: ${props => props.theme.letterSpacing.ls1};
  padding: 16px 12px;
  text-align: center;
  text-transform: capitalize;
  cursor: pointer;
  @media (min-width: ${props => props.theme.breakPoints.tablet}) {
    font-size: ${props => props.theme.fontSize.s};
    padding: 16px 30px;
  }
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    border-radius: 60px;
    font-size: ${props => props.theme.fontSize.m};
    padding: 18px 40px;
  }
`;
export const PrimaryButtonContainer = styled.button.attrs(props =>({className: props.className}))`
  ${sharedLinkStyles}
  transition: width 0.4s ease-in;
`;

export const PrimaryLinkContainer = styled(Link)`
  ${sharedLinkStyles};
  display: inline-block;
`;

export const LinkItem = styled.a`
  ${sharedLinkStyles};
  display: inline-block;
`;
