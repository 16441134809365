import React from "react";
import PropTypes from "prop-types";

import {
  ToolTipContainer,
  ToolTipItem,
  ToolTipLink,
  InnerContainer,
  ToolTipExternalLink
} from "./styles";

function ToolTip({ list }) {
  return (
    <ToolTipContainer>
      <InnerContainer>
        {list.map(item => (
          <ToolTipItem key={item.name}>
            {item.type === "external" ? (
              <ToolTipExternalLink title={`${item.name} page`} href={item.url} target="_blank">
                {item.name}
              </ToolTipExternalLink>
            ) : (
              <ToolTipLink to={item.url} title={`${item.name} page`}>
                {item.name}
              </ToolTipLink>
            )}
          </ToolTipItem>
        ))}
      </InnerContainer>
    </ToolTipContainer>
  );
}

ToolTip.propTypes = {
  data: PropTypes.arrayOf([
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string
    })
  ])
};

export default ToolTip;
