import React from "react";
import { Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  ROOT,
  ABOUT,
  TRIALSIGNUP,
  RN_TRIALSIGNUP,
  TERMS,
  INTEGRATIONS,
  PRIVACYPOLICY,
  CASESTUDY,
  CONSULTING,
  ESSENCE,
  BOLD,
  AHEAD,
  METYPE,
  ACCESSTYPE,
  REACT_NATIVE_MOBILE_APP,
  PARTNERS,
  CONTACTUS,
  GETDEMO,
  COMPARISON,
  INDIVIDUALCASESTUDY,
  CONFIRMSIGNUP,
  GUIDES,
  BOLD_CMS_DEMO,
  SOLUTIONS,
  INCREASETRAFFIC,
  MONETIZECONTENT,
  DEVELOPERS,
  MAGAZINE_PUBLISHERS,
  THANKYOU,
} from "./utils/RouteNames";

import { individualCaseStudyData } from "./components/case-study/individual-case-form/data";
import {
  caseStudydata,
  guideData,
  essenceData,
} from "./components/case-study/data";
import {arabiccaseStudydata} from "./components/case-study/arabic-data";
import Home from "./components/home";
import CaseStudy from "./components/case-study";

import ConfirmSignup from "./components/confirm-signup/index";
import ConsultingPage from "./components/consulting";
import Bold from "./components/product-page/bold";
import Ahead from "./components/product-page/ahead";
import Metype from "./components/product-page/metype";
import Accesstype from "./components/product-page/accesstype";
import ReactNativeMobile from "./components/product-page/react-native-mobile";
import GetDemoPage from "./components/get-demo/index";
import GetBoldCMSDemoPage from "./components/bold-cms-demo/index";
import ContactPage from "./components/contact-us/index";
import AboutPage from "./components/About";
import PartnersPage from "./components/partners";
import TrialSignupPage from "./components/free-trial/sign-up";
import RNTrialSignupPage from "./components/free-trial/rn-sign-up";
import Comparison from "./components/comparison";
import TermsAndConditions from "./components/terms-and-conditions";
import PrivacyPolicy from "./components/privacy-policy";
import IndividualCaseStudy from "./components/case-study/individual-case-form/index";
import NotFound from "./components/not-found/index";
import IntegrationsPage from "./components/integrations/index";
import SolutionsPage from "./components/solutions-page/small-publishers";
import IncreaseTrafficPage from "./components/solutions-page/to-increase-traffic";
import MonetizeContentPage from "./components/solutions-page/monetize-content";
import ForDevelopers from "./components/solutions-page/for-developers";
import MagazinePublishers from "./components/solutions-page/magazine-publishers";
import ThankYou from "./components/thank-you";

const Routing = () => {
  const isArabic = useSelector((state)=>state);
  return(
    <Switch>
      <Route path={ROOT} component={Home} exact={true} />
      <Route path={CONSULTING} component={ConsultingPage} />
      <Route
        path={CASESTUDY}
        render={(props) => <CaseStudy data={isArabic ? arabiccaseStudydata:caseStudydata} {...props} />}
        exact
      />
      <Route
        path={ESSENCE}
        render={(props) => <CaseStudy data={essenceData} {...props} />}
      />
      <Route
        path={GUIDES}
        render={(props) => <CaseStudy data={guideData} {...props} />}
      />
      <Route path={ABOUT} component={AboutPage} />
      <Route path={INTEGRATIONS} component={IntegrationsPage} />
  
      <Route path={BOLD} component={Bold} />
      <Route path={AHEAD} component={Ahead} />
      <Route path={METYPE} component={Metype} />
      <Route path={ACCESSTYPE} component={Accesstype} />
      <Route path={REACT_NATIVE_MOBILE_APP} component={ReactNativeMobile} />
      <Route path={ABOUT} component={AboutPage} />
      <Route path={PARTNERS} component={PartnersPage} />
      <Route path={GETDEMO} component={GetDemoPage} />
      <Route path={CONTACTUS} component={ContactPage} />
      <Route path={COMPARISON} component={Comparison} />
      <Route path={PRIVACYPOLICY} component={PrivacyPolicy} />
      <Route path={TRIALSIGNUP} component={TrialSignupPage} exact={true} />
      <Route path={RN_TRIALSIGNUP} component={RNTrialSignupPage} exact={true} />
      <Route
        path={INDIVIDUALCASESTUDY}
        render={(props) => {
          return (
            <IndividualCaseStudy
              data={individualCaseStudyData[props.match.params.id]}
              {...props}
            />
          );
        }}
      />
      <Route path={TRIALSIGNUP} component={TrialSignupPage} exact={true} />
      <Route path={INTEGRATIONS} component={IntegrationsPage} />
      <Route path={TERMS} component={TermsAndConditions} />
      <Route path={BOLD_CMS_DEMO} component={GetBoldCMSDemoPage} />
      <Route path={SOLUTIONS} component={SolutionsPage} />
      <Route path={MAGAZINE_PUBLISHERS} component={MagazinePublishers} />
      <Route path={INCREASETRAFFIC} component={IncreaseTrafficPage} />
      <Route path={MONETIZECONTENT} component={MonetizeContentPage} />
      <Route path={DEVELOPERS} component={ForDevelopers} />
      <Route path={CONFIRMSIGNUP} component={ConfirmSignup} />
      <Route path={THANKYOU} component={ThankYou}/>
      <Route component={NotFound} />
    </Switch>
  );

}


export default Routing;