import React from "react";
import {Link} from "react-router-dom";
import {
  Wrapper,
  Headline,
  Title,
  Content,
  PartnersContainer,
  Logo,
  Partners,
  PartnersName,
  Description,
  Info,
  Icon,
  Location,
  Search,
  Container,
  Details,
  HeaderContainer,
  TopLogo,
  ReasonContainer,
  ReasonContent,
  ReasonHeadline,
  AdvantageContainer,
  AdvantageItem,
  AdvantageContent,
  AdvantageImage,
  AdvantageSubHeadline,
  CEOContainer,
  CEOInnerContainer,
  CEOLogo,
  PartnerButtonContainer,
  CEODetailsContainer,
  CEOName,
  CEOCompany,
} from "./style";
import { partners, partnersInfo, partnerHeadings, partnerReasons, advantageInfo, CEOData } from "./data";
import PrimaryButton from "../../components/shared/primaryButton";

function PartnersPage() {
  return (
    <React.Fragment>
    <Wrapper>
      <Headline>{partners.headline}</Headline>
      <Content>{partners.text}</Content>
      <HeaderContainer>
        <Link to="/contact-us">
                <PrimaryButton>{partners.primaryButtonText}</PrimaryButton>
        </Link>
        <TopLogo src={partners.titleIcon}  alt={partners.altText} />
      </HeaderContainer>
      
      <Title>{partnerHeadings.methodHeader}</Title>
      <ReasonContainer>
        {partnerReasons.map((data,index) => (
          <Info key={index}>
          <ReasonHeadline>{data.headline}</ReasonHeadline>
          <ReasonContent>{data.content}</ReasonContent>
          </Info>
        ))}        
      </ReasonContainer>
      </Wrapper>
            
      <Title>{partnerHeadings.whyPartnerHeader}</Title>
      <AdvantageContainer>
        {advantageInfo.map((item, index) => (
            <AdvantageItem key={index}>
              <AdvantageImage src={item.url} alt={item.alt}></AdvantageImage>
              <AdvantageContent>
                <AdvantageSubHeadline>{item.title}</AdvantageSubHeadline>                
              </AdvantageContent>
            </AdvantageItem>
          ))}
      </AdvantageContainer>

      <Wrapper>
      <CEOContainer>
        <CEOInnerContainer>
          <CEOLogo src={CEOData.url}  alt={CEOData.alt}/>
          <CEODetailsContainer>
            <Description>{CEOData.description}</Description>
            <CEOName>{CEOData.name}</CEOName>
            <CEOCompany>{CEOData.company}</CEOCompany>
          </CEODetailsContainer>
          
        </CEOInnerContainer>
      </CEOContainer>

      <Title>{partnerHeadings.partnerHeader}</Title>
      <PartnersContainer>
        {partnersInfo.map((data, index) => (
            <Container key={index}>
              <Logo src={data.logo} alt={data.name} />
              <Partners>
                <PartnersName href={data.link} title={data.name} target="_blank">{data.name}</PartnersName>
                <Description>{data.description}</Description>
                <Info>
                  <Details>
                    <Icon src={data.locationIcon}  alt={data.location} />
                    <Location>{data.location}</Location>
                  </Details>
                  <Details>
                   <Icon src={data.searchIcon}  alt={data.location} />
                    <Search title="Search" href={data.link} target="_blank">{data.search}</Search>
                  </Details>
                </Info>
              </Partners>
            </Container>
          ))}
      </PartnersContainer>

      <PartnerButtonContainer>
        <Link to="/contact-us">
            <PrimaryButton>{partners.primaryButtonText}</PrimaryButton>
        </Link>
      </PartnerButtonContainer>      
    </Wrapper>
    </React.Fragment>
  );
}

export default PartnersPage;
