import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const Wrapper = styled.div`
 max-width: ${props => props.theme.containerWidth};
    margin: 0 auto;
    padding: 0;
  @media (max-width: ${props => props.theme.breakPoints.desktop}) {
   padding: 0 24px;
  }
`;

export const TitleContainer = styled.div`
  margin: 0 0 20px 0;


`;

export const Description = styled.div`
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
  }
`;

export const TopContainer = styled.div`
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    grid-template-columns: repeat(12,1fr);
    margin: 50px 0 0 0;
  }
`;

export const ContentWrapper = styled.div`
  margin: 50px auto 0;
  text-align: center;
`;

export const VideoWrapper = styled.iframe`
  width: 100%;
  height: 260px;

  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    width: 700px;
    height: 400px;
  }
`;

const linkStyles = css`
  color: ${props => props.theme.color.green1};
`;

export const HyperLink = styled(Link)`
  ${linkStyles}
`;