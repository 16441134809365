export const data = {
  small_publishers: {
    "top-component": {
      headline: "Grow your business with us",
      info: "Get all the features and flexibility of an enterprise-level publishing platform at affordable costs. ",
      firstButtonContent: {
        text: " Start Free Trial",
        link: "/sign-up",
        title: " Start Free Trial",
        linkType: "internal",
      },
      logo: {
        url: "",
        alt: "logo",
      },
      secondButtonContent: {
        required: false,
      },
      image: {
        url: "https://images.assettype.com/quintype-website/2021-03/d50c0cc4-d5c9-4f6c-8c6c-aa9e0f6a66da/solutions_for_small_publishers.png",
        alt: "Small Media Organization Solutions",
      },
    },

    "image-with-content": [
      {
        headline: "Easy content creation for smaller teams",
        features: [
          {
            headline: "",
            content: "",
          },
        ],
        url: "https://images.assettype.com/quintype-website/2021-04/35459710-2789-48ad-9d0a-676755510767/Screenshot_2021_04_07_at_12_33_03_PM.png",
        alt: "workspace",
        "free-trial": {
          text: " Schedule Demo",
          link: "/schedule-demo",
          title: " Schedule Demo",
        },
        points: [
          "Create, curate and manage content with ease. Our tools help you set up a frictionless editorial process.",
          "Use our cardified editor to create rich media stories with embed links, videos and high quality images without hassle.",
          "Collaborate and work with your team of journalists, freelancers and editors to break news faster.",
          "Produce stories on the go with our responsive mobile CMS.",
        ],
      },
    ],

    "image-with-content2": [
      {
        headline: "Increase your reach with less effort",
        features: [
          {
            headline: "",
            content: "",
          },
        ],
        url: "https://images.assettype.com/quintype-website/2021-03/fe15eaee-98f1-420e-9427-8b135e4eef0c/Multi_device.png",
        alt: "workspace",
        "free-trial": {
          text: " Schedule Demo",
          link: "/schedule-demo",
          title: " Schedule Demo",
        },
        points: [
          "Get your content to the target audience on all screen and screenless devices.",
          "Manage metadata, slugs and sitemaps from the CMS. Your writers can check the SEO score and optimize the stories to rank better.",
          "Reach your readers with the latest and greatest stories directly on their inbox.",
          "Alert your readers with new stories using push notifications.",
          "Automate distribution of your stories on social media channels.",
          "Increase your chances of appearing in google news with the right tech support.",
        ],
      },
    ],

    "image-with-content3": [
      {
        headline: "Easy front end management with Page Builder ",
        features: [
          {
            headline: "",
            content: "",
          },
        ],
        url: "https://gumlet.assettype.com/quintype-website/2019-12/031ea97e-c95f-4d59-b232-a53b553eb5cd/Ahead_1.png?w=640&dpr=1.0",
        alt: "workspace",
        "free-trial": {
          text: " Schedule Demo",
          link: "/schedule-demo",
          title: " Schedule Demo",
        },
        points: [
          "Have better control on your front end without any coding knowledge.",
          "Customise the layout and branding for your homepage, sections and stories without the need for an extended team. Use templates used across the industry.",
          "Add custom widgets, advertisements, or stories without any need to code.",
          "Have a website that is responsive on both web and mobile.",
        ],
      },

      {
        headline: "Analyze and Personalize content for your readers",
        features: [
          {
            headline: "",
            content: "",
          },
        ],
        url: "https://gumlet.assettype.com/quintype-website/2022-09/7b39d914-1d04-4d69-a3bd-83eab3026232/BOLD_Analytics.png",
        alt: "workspace",
        "free-trial": {
          text: " Schedule Demo",
          link: "/schedule-demo",
          title: "CTA: Schedule Demo",
        },
        points: [
          "Easy discoverability and higher audience engagement with Collections.",
          "Make better decisions with our story, author and performance reports.",
          "Serve the content your reader wants, served to their personal choices.",
          "Present what's hot and trending to your readers within the hour.",
        ],
      },

      {
        headline:
          "State of the art technical capabilities to handle scale and uptime.",
        features: [
          {
            headline: "",
            content: "",
          },
        ],
        url: "https://images.assettype.com/quintype-website/2021-03/07fff20a-56db-4e05-b20b-1064bc680338/Website_Security_.png",
        alt: "workspace",
        "free-trial": {
          text: " Schedule Demo",
          link: "/schedule-demo",
          title: "CTA: Schedule Demo",
        },
        points: [
          "Secure your website from DDOS attacks and enjoy your growing traffic without having to worry about your website.",
          "Industry leading CDN to improve content availability and performance.",
          "All the websites hosted on our servers are HTTPS by default.",
          "The accounts provide secure access to the editor using two-factor authentication.",
        ],
      },
    ],

    review: {
      headline: "Way more than a bug and issue tracker",
      content:
        "“The team consistently adds new features to benefit publishers and the products are designed intelligently. The front-end is designed for malleability to meet our customized requirements making Quintype a hassle-free choice for us.”",
      designation: "Sonakshi Sinha, Product Manager",
      "image-url":
        "https://gumlet.assettype.com/quintype-website/2019-12/e410a432-32d0-4aac-94a4-b27f1d2b978c/Client_15.png?w=360",
      alt: "Sonakshi Sinha, Product Manager",
      features: [
        {
          headline: "Fully Managed Service",
          description:
            "Updates, security, backups are all taken care of on your behalf.",
        },
        {
          headline: "Future proof",
          description:
            "Our team regularly updates layouts, features and integrations that will keep your website way ahead of the pack.",
        },
        {
          headline: "Migrate Effortlessly",
          description:
            "Your data is safe and smoothly transferred to Bold with out advanced migration engine.",
        },
      ],
    },

    review2: {
      headline: "",
      content:
        "“Bold CMS and PageBuilder for digital publishing is a very well crafted plug & play solution providing front end design flexibility for setting up a customized website quickly, with an easy to manage backend for daily updation & managing clients.”",
      designation: "Gopen Huidrom, Administrator",
      "image-url":
        "https://images.assettype.com/quintype-website/2021-03/7370917f-b4a9-46b0-9df5-79cf0bbe0447/SBN_Logo.png",
      alt: "SteelGuru business review",
      features: [
        {
          headline: "Fully Managed SaaS CMS",
          description:
            "Updates, automations, backups are all taken care of on your behalf.",
        },
        {
          headline: "API-first Headless CMS",
          description:
            "Our well documented REST APIs provides you with total control on the front-end.",
        },
        {
          headline: "Secure Enterprise Cloud",
          description:
            "Complete with DDoS protection, SSL, caching and auto-scaling infrastructure.",
        },
      ],
    },
  },
  to_increase_traffic: {
    "top-component": {
      headline: "Drive more traffic to your content",
      info: "Our suite of tools help publishers to increase their reach efficiently and effortlessly.",
      firstButtonContent: {
        text: "Schedule Demo",
        link: "/sign-up",
        title: "Schedule Demo",
        linkType: "internal",
      },
      logo: {
        url: "",
        alt: "logo",
      },
      secondButtonContent: {
        required: false,
      },
      image: {
        url: "https://images.assettype.com/quintype-website/2021-05/32cb2dc2-2239-435a-9293-887ac66496c3/Solution_to_increase_traffic_illustration_28th_april_04.png",
        alt: "Increase Traffic Solutions",
      },
    },

    "image-with-content": [
      {
        headline: "Maximize your reach with Bold CMS",
        features: [
          {
            headline: "",
            content: "",
          },
        ],
        url: "https://images.assettype.com/quintype-website/2021-04/e28bf592-7029-4794-bbbb-7dda3d0e6f90/Solution_to_increase_traffic_28th_april_03.png",
        alt: "workspace",
        "free-trial": {
          text: " Schedule Demo",
          link: "/schedule-demo",
          title: " Schedule Demo",
        },
        points: [
          "Create  your content in different formats to catch your reader's attention on different channels - Video, podcasts, visual stories, instant articles, image stories and plain text.",
          "Distribute content on 3rd party news aggregators like Dailyhunt, jio news, Yahoo news, aWeber, and more.",
          "Manage SEO for your stories from sitemaps to schema to performance for better SERPS and discover traffic.",
          "Auto Post stories to your personal and company social accounts to gain maximum visibility.",
        ],
      },
    ],

    "image-with-content2": [
      {
        headline: "Engage and keep your readers on your website",
        features: [
          {
            headline: "",
            content: "",
          },
        ],
        url: "https://images.assettype.com/quintype-website/2021-05/35a17259-8525-42e3-b490-6c1d2dda91e8/Solution_to_increase_traffic_28th_april_01.png ",
        alt: "workspace",
        "free-trial": {
          text: " Schedule Demo",
          link: "/schedule-demo",
          title: " Schedule Demo",
        },
        points: [
          "Enable meaningful conversations with Metype commenting solutions. More comments mean more traffic.",
          "Get a live feed of your trending stories and most commented stories for your readers to latch onto the website.",
          "Manage the layout and design of your website to A/B test and personalize your content based on your readers interest.",
        ],
      },
    ],

    "image-with-content3": [
      {
        headline: "Build native mobile apps for stronger brand loyalty",
        features: [
          {
            headline: "",
            content: "",
          },
        ],
        url: "https://images.assettype.com/quintype-website/2021-04/427bd191-c841-4f7b-b31d-f7339fd8aec2/Solution_to_increase_traffic_28th_april_02.png",
        alt: "workspace",
        "free-trial": {
          text: " Schedule Demo",
          link: "/schedule-demo",
          title: " Schedule Demo",
        },
        points: [
          "Convert your website to react native apps and give your loyal readers the best experience on mobile.",
          "Get the speed and convenience that readers expect on their mobile devices.",
          "Help your readers to access content even at low internet speeds with offline mode.",
        ],
      },
    ],

    "image-with-content4": [
      {
        headline:
          "State of the art technical capabilities to handle scale and uptime.",
        features: [
          {
            headline: "",
            content: "",
          },
        ],
        url: "https://images.assettype.com/quintype-website/2021-03/07fff20a-56db-4e05-b20b-1064bc680338/Website_Security_.png",
        alt: "workspace",
        "free-trial": {
          text: " Schedule Demo",
          link: "/schedule-demo",
          title: "CTA: Schedule Demo",
        },
        points: [
          "Improve content availability and performance with industry’s leading CDN like AWS, Fastly, etc.",
          "Autoscales your infrastructure to handle spikes in traffic.",
          "All the websites hosted on our servers are HTTPS by default.",
          "Secured access to Bold CMS using two-factor authentication.",
          "24x7 monitoring and support of all the applications and services.",
        ],
      },
    ],

    review: {
      headline: "Way more than a bug and issue tracker",
      content:
        "“The team consistently adds new features to benefit publishers and is designed intelligently. The front-end is designed for malleability to meet our customized requirements making Quintype a hassle-free choice for us.”",
      designation: "Sonakshi Sinha, Product Manager",
      "image-url":
        "https://gumlet.assettype.com/quintype-website/2019-12/16b8d4db-ef60-4144-8c08-dddb03f46b99/FortuneIndia.svg?w=360&dpr=1.0",
      alt: "Sonakshi Sinha, Product Manager",
      features: [
        {
          headline: "Fully Managed Service",
          description:
            "Updates, security, backups are all taken care of on your behalf.",
        },
        {
          headline: "Future proof",
          description:
            "Our team regularly updates layouts, features and integrations that will keep your website way ahead of the pack.",
        },
        {
          headline: "Migrate Effortlessly",
          description:
            "Your data is safe and smoothly transferred to Bold with out advanced migration engine.",
        },
      ],
    },

    review2: {
      headline: "",
      content:
        "“It was important to have a CMS that helped us maintain an effective workflow. We’ve seen remarkable growth in page views after moving to Bold CMS.  The platform comes loaded with content marketing features which makes the entire experience outstanding”",
      designation: "Prakash Kumar, Head - Digital Marketing",
      "image-url":
        "https://images.assettype.com/quintype-website/2021-05/d8b3ce31-29d5-4063-a3b4-00f60c29298a/logo_bb49fe5acb23c689ef43.svg",
      alt: "Prakash Kumar, Head - Digital Marketing",
      features: [
        {
          headline: "Fully Managed SaaS CMS",
          description:
            "Updatess, automations, backups are all taken care of on your behalf.",
        },
        {
          headline: "API-first Headless CMS",
          description:
            "Our well documented REST APIs provides you with total control on the front-end.",
        },
        {
          headline: "Secure Enterprise Cloud",
          description:
            "Complete with DDoS protection, SSL, caching and auto-scaling infrastructure.",
        },
      ],
    },
  },

  monetize_content: {
    "top-component": {
      headline: "Monetize your content",
      info: "Tools for publishers to generate revenue through your content. ",
      firstButtonContent: {
        text: "Schedule Demo",
        link: "/sign-up",
        title: "Schedule Demo",
        linkType: "internal",
      },
      logo: {
        url: "",
        alt: "logo",
      },
      secondButtonContent: {
        required: false,
      },
      image: {
        url: "https://images.assettype.com/quintype-website/2021-06/7ec97e3a-b939-4586-b625-2dce9e81f3f8/C59DA392_DA7B_4153_AD78_1D4630326630.jpeg",
        alt: "Monetize Content",
      },
    },

    "image-with-content": [
      
    ],
    review: {
      headline: "Worth every penny",
      content:
        "“Monetization in our business is constantly evolving and Accesstype helped us experiment with multiple subscription models to find the right balance. We would recommend Accesstype for anyone looking to monetize their content beyond advertising.”",
      designation: "Ankit Dikshit, Digital Marketing",
      "image-url":
        "https://gumlet.assettype.com/quintype-website/2019-12/3163b395-6987-4d61-8c7f-9fb0ff4c2229/Client_14.png?w=1300&dpr=1.0",
      alt: "Ankit Dikshit, Digital Marketing",
      features: [
        {
          headline: "Single Sign-on.",
          description:
            "Integrate with your own technology stack for seamless authentication.",
        },
        {
          headline: "Save money on every transaction.",
          description:
            "Pay a transparent flat fee based on volume, not percentages on every subscription.",
        },
        {
          headline: "Multi-language and Multi-currency ready.",
          description:
            "Take your monetization strategy across the globe. Accept local curency. In your language.",
        },
      ],
    },

    "image-with-content2": [
      {
        headline: "Drive revenue through digital advertising",
        features: [
          {
            headline: "",
            content: "",
          },
        ],
        url: "https://images.assettype.com/quintype-website/2021-06/1e0f1c00-edb4-44b5-9876-bdddbc1579aa/Ad_slots.png",
        alt: "Monetize content",
        "free-trial": {
          text: " Schedule Demo",
          link: "/schedule-demo",
          title: " Schedule Demo",
        },
        points: [
          "Scale and monetize your stories across all digital mediums — from connected TVs, to websites to mobile apps.",
          "Deliver all kinds of compelling ads on your websites and apps. Keep your readers satisfied with a non-intrusive experience.",
          "We’ve partnered with agencies that offer digital media monetization services to help publishers with their growth.",
          "We support native and programmatic advertising like banners, interstitial, responsive and videos.",
        ],
      },
    ],
  },

  for_developers: {
    "top-component": {
      heading: "Freedom to dictate your own front-end",
      info: "Quintype offers solutions to developers to hit the ground running with a powerful, data-driven, enterprise headless CMS.",
      primaryButtonContent: {
        text: "Schedule Demo",
        link: "/schedule-demo",
        linkType: "internal",
      },
      secondaryButtonContent: {
        isRequired: true,
        text: "Developer Doc",
        link: "https://developers.quintype.com/",
        linkType: "external",
      },
      image: {
        url: "https://images.assettype.com/quintype-website/2021-06/63b9a28a-2925-4c83-a97b-ca5f3db70d6e/developer_s_solution_landing_page_2x.png",
        alt: "Workspace",
      },
    },

    "image-with-content": [
      {
        headline: "Future-proof your tech stack",
        features: [
          {
            headline: "",
            content: "",
          },
        ],
        url: "https://images.assettype.com/quintype-website/2021-04/e28bf592-7029-4794-bbbb-7dda3d0e6f90/Solution_to_increase_traffic_28th_april_03.png",
        alt: "payment-gateways",
        "free-trial": {
          text: " Schedule Demo",
          link: "/schedule-demo",
          title: " Schedule Demo",
        },
        points: [
          "Content is delivered via APIs, giving you the flexibility to create digital experiences like websites, apps, voice devices, and new technologies.",
          "Integrates with numerous 3rd party apps that will save your team's time and effort.",
          "Make code change once to the API and it will reflect on all the channels."
        ],
      },
    ],
    review1: {
      content:
        "“We used the headless CMS to launch our digital platform in 2016. From CMS to paywall solutions, Quintype manages the full tech stack for us. The platform is easy to use and customize for our requirements.”",
      designation: "Amarnath Govindarajan, Digital Head",
      "image-url":
        "https://gumlet.assettype.com/quintype-website/2019-12/db2d7899-378b-4c89-aac3-f6b52163ea8c/swarajya_29.svg?dpr=1.0&q=70&w=480",
      alt: "Amarnath Govindarajan, Digital Head",
      features: [
        {
          headline: "Fully Managed Service",
          description:"Updates, security, backups are all taken care of on your behalf.",
        },
        {
          headline: "Future proof",
          description:
            "Our team regularly updates layouts, features and integrations that will keep your website way ahead of the pack.",
        },
        {
          headline: "Migrate Effortlessly",
          description:
            "Your data is safe and smoothly transferred to Bold with our advanced migration engine.",
        },
      ],
    },

    "image-with-content2": [
      {
        headline: "A CMS that your developers will love",
        features: [
          {
            headline: "",
            content: "",
          },
        ],
        url: "https://images.assettype.com/quintype-website/2021-06/c74d694e-2b51-4ff4-a8fa-222cadc26c0f/ss_3x.png",
        alt: "Workspace",
        "free-trial": {
          text: " Schedule Demo",
          link: "/schedule-demo",
          title: " Schedule Demo",
        },
        points: [
          "Endless customizations available on Bold by embedding any javascript codes.",
          "Open-close architecture model will give the power to your developers on extensions.",
          "Webhooks to communicate with your external applications on the progress of your story state and for print workflows.",
          "SSO Authentication that can be integrated with the current framework and used independently.",
          "Bold can be integrated with subscription platforms like Accesstype, Piano, etc."
        ],
      },
    ],

    review2: {
      content:
        "Bold CMS and PageBuilder for digital publishing is very well crafted plug & play solution providing front end design flexibility for setting up a customized website quickly, with an easy to manage backend for daily updation & managing clients. We also thank Quintype team for timely support to resolve the initial issues quickly and helping our team to understand the backend operations",
      designation: "Gopen Huidrom, Administrator (edited)",
      "image-url":
        "https://images.assettype.com/quintype-website/2021-03/7370917f-b4a9-46b0-9df5-79cf0bbe0447/SBN_Logo.png",
      alt: "Gopen Huidrom, Administrator (edited)",
      features: [
        {
          headline: "Fully Managed Service",
          description:"Updates, security, backups are all taken care of on your behalf.",
        },
        {
          headline: "Future proof",
          description:
            "Our team regularly updates layouts, features and integrations that will keep your website way ahead of the pack.",
        },
        {
          headline: "Migrate Effortlessly",
          description:
            "Your data is safe and smoothly transferred to Bold with our advanced migration engine.",
        },
      ],
    },

    "image-with-content3": [
      {
        headline: "More power to your developers",
        features: [
          {
            headline: "",
            content: "",
          },
        ],
        url: "https://gumlet.assettype.com/quintype-website/2022-09/a54010e0-3958-4049-ba67-1c43d4e461d6/BOLD_Workspace.png?w=640&dpr=1.0",
        alt: "best headless cms",
        "free-trial": {
          text: " Schedule Demo",
          link: "/schedule-demo",
          title: " Schedule Demo",
        },
        points: [
          "Build your own websites and apps with our Malibu Framework for an easier way to consume APIs.", 
          "Finer control over scaling and caching.",
          "High page speed performance and optimized for the Web Core Vitals by reducing CLS, server side rendering and deferring 3rd party scripts."
        ],
      },
    ],

    "image-with-content4": [
      {
        headline: "State-of-the-art technical capabilities",
        features: [
          {
            headline: "",
            content: "",
          },
        ],
        url: "https://images.assettype.com/quintype-website/2021-06/6d0ed5f8-e128-41bb-8e58-13d9a4a5a637/developer_s_solution_landing_page_3x.png",
        alt: "Workspace",
        "free-trial": {
          text: " Schedule Demo",
          link: "/schedule-demo",
          title: " Schedule Demo",
        },
        points: [
          "Industry leading CDN to improve content availability and performance.",
          "Enterprise grade security, hosting and performance with guaranteed 99.5% infrastructure uptime.",
          "Application monitoring dashboard to monitor your infrastructure metrics - CPU, memory and logs."
        ],
      },
    ],
  },
  magazine_publishers: {
    "top-component": {
      heading: "Build amazing digital magazines",
      info: "Move to a digital space and reduce your printing and distribution costs today. Create, publish, distribute and monetize your digital magazines using Bold CMS",
      primaryButtonContent: {
        text: "Start Free Trial",
        link: "/sign-up",
        title: "free trial",
        linkType: "internal",
      },
      secondaryButtonContent: {
        isRequired: true,
        text: "Schedule Demo",
        link: "/schedule-demo",
        linkType: "internal",
      },
      image: {
        url: "/magazine-publishers/top-component.png",
        alt: "Digital Magazines",
      },
    },

    benefits: {
      headline: "Benefits",
      benefit: [
        {
          imageUrl: "https://images.assettype.com/quintype-website/2021-08/9cc25e91-4007-4603-8705-64664c1ea7f0/magzine_landing_page.png?w=230",
          alt: "Quintype Benefit",
          text: "Create once, publish everywhere",
        },
        {
          imageUrl: "https://images.assettype.com/quintype-website/2021-08/a3d4d770-a5a9-43e6-8b09-8583908ba6ab/ss.png?w=230",
          alt: "Quintype Benefit",
          text: "Integrate with print systems",
        },
        {
          imageUrl: "https://images.assettype.com/quintype-website/2021-08/40d2f451-f465-4efa-b4ca-a390229ae4e5/s.png?w=230",
          alt: "Quintype Benefit",
          text: "Bundling your print and digital issues for easy subscriptions",
        },
        {
          imageUrl: "https://images.assettype.com/quintype-website/2021-08/6a37ec91-7382-469f-bf75-7a06442e5ca0/magzine_landing_page_2x.png?w=230",
          alt: "Quintype Benefit",
          text: "Easy content creation ",
        },
        {
          imageUrl: "https://images.assettype.com/quintype-website/2021-08/fde0cabe-ceed-4ff9-a7da-d0c4fb37fe80/magzine_landing_page_2x__1_.png?w=230",
          alt: "Quintype Benefit",
          text: "Smooth editorial workflows",
        },
        {
          imageUrl: "https://images.assettype.com/quintype-website/2021-08/49790d5a-a278-4dfb-b445-a4117fdad526/magzine_landing_page__3_.png?w=230",
          alt: "Quintype Benefit",
          text: "Monetize through ad and DFP",
        },
        {
          imageUrl: "https://images.assettype.com/quintype-website/2021-08/5147bce3-e516-42a6-b1b1-5c8696b0bc66/magzine_landing_page__1_.png?w=230",
          alt: "Quintype Benefit",
          text: "Organize issues and volumes effectively",
        },
        {
          imageUrl: "https://images.assettype.com/quintype-website/2021-08/beadbe95-7235-4123-b36b-730cdc1383af/magzine_landing_page__2_.png?w=230",
          alt: "Quintype Benefit",
          text: "No more coding or endless review rounds",
        },
      ],
      buttonContent: {
        text: "Start Free Trial",
        link: "/sign-up",
        title: "free trial",
        linkType: "internal",
      }
    },

    "image-with-content": [
      {
        headline: "Turn your print into digital",
        features: [
          {
            headline: "",
            content: "",
          },
        ],
        url: "/magazine-publishers/image-content1.png",
        alt: "Digital Print",
        "free-trial": {
          text: "Start Free Trial",
          link: "/sign-up",
          title: "free trial",
          linkType: "internal",
        },
        points: [
          "Let your readers have access to all your copies anytime, anywhere.",  
          "Increase the visibility of your content by indexing all your stories on google search and discover.",
          "Engage better with your readers by enabling meaningful conversations Reach a wider audience.",
        ],
      },
    ],

    "image-with-content2": [
      {
        headline: "Monetize your digital magazine",
        features: [
          {
            headline: "",
            content: "",
          },
        ],
        url: "/magazine-publishers/image-content2.png",
        alt: "Digital Magazine",
        "free-trial": {
          text: "Start Free Trial",
          link: "/sign-up",
          title: "free trial",
          linkType: "internal",
        },
        points: [
          "Publish stories to your website and apps from a single authoring source. Syncs 100%.",
          "Develop top-worthy apps like CNN, Buzzfeed, etc at 10% of the cost.",
          "Faster go-to-market strategy to monetize.",
        ],
      },
    ],
    "client-awards": {
      headline:
      "World’s most successful digital publishing companies trust Quintype to serve content to billions of content consumers.",
      imageUrl: "/magazine-publishers/client-awards.png",
      alt: "Client Awards",
    },
    faqs: {
      headline: "FAQS",
      faq: [
        {
          question:"What kinds of magazines can you build with Bold CMS?",
          answer: "You can build all kinds of digital magazines with the Bold CMS. We cater to consumer, trade, professional and in-house magazines. Niches like - news, photography, food, travel, wedding, child-care, etc are also supported."
        },
        {
          question: "What makes a digital magazine?",
          answer:
            "Have your print magazine content on the internet through a digital magazine. Update issues and allow readers to access your content anywhere, anytime.",
        },
        {
          question: "How to make a digital magazine with Bold cms?",
          answer:
            "Get started with the Bold CMS today and manage both your print and digital magazine through a single instance. Enjoy premium quality CMS features that are industry approved and don’t let coding get in the way. Enjoy easy workflow, story templates, rich multimedia and more.",
        },
        {
          question: "Do I need design or coding skills to use your magazine maker?",
          answer:
          "We believe that content creators shouldn’t be expected to do the technical heavy lifting. You get to create your magazine without having to learn how to code. Our layout customization tool - Page builder allows you to choose from a wide array of templates. The interface is user friendly and tailor made to fit your content needs.",
        },
        {
          question: "What are the benefits of Using Digital Publishing Solutions for Magazines?",
          answer:
          "You can reduce your printing and distribution costs by a large margin. Benefit from the wider range of audience like never before. Have all your issues available for purchase. Experiment with the different subscription options without having to worry about distribution.",
        },
        {
          question: "What magazine contents are supported by Bold CMS?",
          answer:
          "Bold CMS has industry approved features that help content creators create without any friction. Be it business, travel, photography, fashion, news magazines, we support all types of magazines.",
        },
        {
          question: "Online magazines that are created by Bold cms are mobile friendly?",
          answer:
          "We understand your need to cater to the mobile-first audience. Bold CMS ensures that your content is accessible on all devices - desktop and mobile without any compromise on performance and design.",
        },
        {
          question: "Do you have a free plan?",
          answer:
          "Did you know that you can have a free 14-day trail with Bold CMS. Schedule a demo with us and learn all about the features loaded up. Explore the premium content management experience today.",
        },
      ]
    }
  },
};

export const lastImageComentComponentData = {
  headline: "Low Total Cost of Ownership",
  text: "You get a premium CMS without having to bear the cost of developing one.We share the price of developing and upgrading our tech support across our clientele. This means you can have the best CMS that is constantly evolving without having to pay for the entire money spent on the resources.",
  imageUrl:
    "https://images.assettype.com/quintype-website/2021-03/2dbb3597-b7b8-4126-a622-69a3c6ad1312/Low_total_CMS_solution_for_small_business_publishers.png",
  isButtonPresent: false,
  alt: "Low Total Cost of Ownership",
};

export const trafficTestimonialSolutions = {
  headline:
    "Prabhat Khabar was successfully able to scale its digital ambitions with Bold CMS from Quintype. Within 30 days of launch, the page views increased by over 35% visitors from search and social channels and went on to grow over 6X in the next 2 quarters.",

  "read-more": {
    text: "DOWNLOAD",
    url: "/case-study/prabhat-khabar",
    title: "case study section",
  },
};

export const monetizeTestimonialSolutions = {
  headline:
    "Quintype has given Swarajya the platform to boost itself and get on a growth trajectory with subscriptions, engagement and flexibility altogether in today’s information packed digital space. Download the case study to learn how.",

  "read-more": {
    text: "DOWNLOAD",
    url: "/case-study/swarajya",
    title: "case study section",
  },
};
