export const arabicclientAwards = {
    headline:
      "تثق شركات النشر الرقمية الأكثر نجاحًا في العالم في كوينتايب لتقديم المحتوى لمليارات من مستهلكي المحتوى. ",
  
    firstRowlogos: [
      {
        url: "https://gumlet.assettype.com/quintype-website/2019-12/e410a432-32d0-4aac-94a4-b27f1d2b978c/Client_15.png",
        alt: "Fortune india",
      },
  
      {
        url: "https://gumlet.assettype.com/quintype-website/2019-12/3163b395-6987-4d61-8c7f-9fb0ff4c2229/Client_14.png",
        alt: "Bloomberg quint",
      },
  
      {
        url: "https://gumlet.assettype.com/quintype-website/2019-12/16dfdad4-5b86-4a82-b2ff-0665227335fc/Vikatan_logo_English__1_.svg",
        alt: "Vikatan",
      },
  
      {
        url: "https://www.quintype.com/karjalainen.svg",
        alt: "Karjalainen",
      },
      {
        url: "https://gumlet.assettype.com/quintype-website/2019-12/a194b215-f712-4280-acfd-4c3f6bcbae1c/Client_19.png",
        alt: "Freepress journal",
      },
    ],
  
    secondRowLogos: [
      {
        url: "https://gumlet.assettype.com/quintype-website/2019-12/4b0e9d2b-4317-4ce2-92e0-2b0da654f632/Client_18.png",
        alt: "the quint",
      },
      {
        url: "https://www.quintype.com/sakal.jpg",
        alt: "Sakal Media Group",
      },
      {
        url: "https://gumlet.assettype.com/quintype-website/2019-12/912fc8de-0c32-4e3c-8903-eb280cadd495/afaqs.svg",
        alt: "afaqs",
      },
      {
        url: "https://images.assettype.com/quintype-website/2020-09/a659941f-1093-4bb7-a73e-d81d0a110e46/prothomalo.svg",
        alt: "Prothom Alo",
      },
    ],
  
    "read-more": {
      text: "شاهد كل قصص النجاح",
      url: "/case-study",
      title: "case study page",
    },
  };
  export const arabicquinAwards = {
    headline:
      "الجوائز والأوسمة التي تحصدها حلولنا تأتي من وضع لعملائنا في المرتبة الأولى",
  
    logos: [
      {
        url: "https://www.quintype.com/Award1.svg",
        alt: "CMSCritic Choice Awards",
      },
  
      {
        url: "https://www.quintype.com/Award3.svg",
        alt: "Social media engagement Award",
      },
  
      {
        url: "https://www.quintype.com/Award4.svg",
        alt: "Reader revenue initiative Award",
      },
  
      {
        url: "https://www.quintype.com/Award-10.svg",
        alt: "wan infra award for best in reader revenue initiaive",
      },
    ],
  
    "read-more": {
      text: "تحقق من خزانة الجوائز الخاصة بنا",
      url: "https://blog.quintype.com/people/awards",
      title: "All awards",
    },
  };
  export const arabiccontentWithImage = {
    headline: "كم عدد الميزات التي توفر الحرية المطلقة للنشر؟",
    text: "نحن نفكر مثل صانعي المحتوى ، ولهذا السبب نجعل التكنولوجيا تعمل من أجلك وليس العكس. أدواتنا جاهزة لكل شيء - النشر والأعمال وحتى التطور التكنولوجي السريع. بحيث يكون تركيزك هو المحتوى الخاص بك. وعلى إحداث تأثير! نقدم لك كل المزايا التي تمنحك الحرية المطلقة لنشر ما تريد ، كيفما تريد.",
    imageUrl:
      "https://gumlet.assettype.com/quintype-website/2019-12/996decc0-56f5-45dc-9a26-5b456955d219/Bold_Solution.svg",
    isButtonPresent: false,
    alt: "freedom to publish",
  };
  
  export const arabiccontentWithButton = {
    headline: "إذا كان النشر أسهل ، ألن يتم سرد المزيد من القصص؟",
    text: "لقد قمنا بتغطيتك ، من البداية إلى النهاية. إذا كان لديك متطلبات محددة ، فمن المحتمل أن يكون لدينا بالفعل هذا المنتج أو الميزة. أو أنه قيد التنفيذ. و ، إذا كنت بحاجة إلى تصميم مخصص ، فسوف نقوم بتطويره!",
    imageUrl:
      "https://gumlet.assettype.com/quintype-website/2019-12/fcae94ec-6529-44ec-82eb-0c7154358703/Contact_Us_02.svg",
    alt: "Covers all use case",
    isButtonPresent: true,
    buttonData: {
      buttonText: "جدولة عرض",
      buttonUrl: "/schedule-demo",
    },
  };
  
  export const arabicbelowTopComponentData = {
    headline: "كيف يمكنك بدء نهضة إذا كنت عالقًا في اكتشاف التكنولوجيا؟",
    text: "يمنحك كوينتايب حرية نشر المحتوى وتوزيعه وتحقيق الدخل منه بدون العوائق التقنية. لدينا مجموعة من المنتجات للاهتمام بكل التفاصيل الدقيقة. أطلق العنان لقوة المحتوى الخاص بك على منصتنا.",
    imageUrl:
      "https://gumlet.assettype.com/quintype-website/2020-01/d08a5e7e-c13e-4192-b917-bba44efb4ca5/figuring_tech.svg",
    isButtonPresent: false,
    alt: "Start the Renaissance Content",
  };