import styled from "styled-components";

import { Input } from "../../../form-controls/style";

export const InputWrapper = styled.div`
  position: relative;
`;

export const AvailabityText = styled.span`
  position: absolute;
  font-size: ${props => props.theme.fontSize.xs};
  font-family: ${props => props.theme.fontFamily.primary};
  color: ${props => {
    if (props.color === "green") {
      return props.theme.color.green1;
    } else return props.theme.color.red;
  }};
  right: 9px;
  top: 12px;
`;

export const ControlledInput = styled(Input)`
  padding-right: 100px;
`;
