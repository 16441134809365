import styled from "styled-components";

export const Title = styled.h2`
  font-family: ${props => props.theme.fontFamily.primary};
  font-weight: ${props => props.theme.fontWeight.bold};
  font-size: ${props => props.big? props.theme.fontSize.xxxl: props.theme.fontSize.l};
  color: ${props => props.theme.color.typeRegular};
  line-height: ${props => props.big? props.theme.lineHeight.lh1: props.theme.lineHeight.lh2};
  margin:0;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    font-size: ${props => props.big? props.theme.fontSize.giga: props.theme.fontSize.xxxl};
  }
`;
