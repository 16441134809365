export const arabicdata = [
  {
    name: "الشركة",
    menuList: [
      {
        name: "اتصل بنا",
        url: "/contact-us",
        title: "Contact us",
        linkType: "internal",
      },
      {
        name: "سياسة الخصوصية",
        url: "/privacy-policy",
        title: "Privacy Policy",
        linkType: "internal",
      },
    ],
  },
  {
    name: "الدعم",
    menuList: [
      {
        name: "جدولة عرض",
        url: "/schedule-demo",
        title: "schedule demo",
        linkType: "internal",
      },
      {
        name: "الشروط",
        url: "/terms",
        title: "Terms",
        linkType: "internal",
      },
    ],
  },

  {
    name: "المنتجات",
    menuList: [
      {
        name: "بولد",
        url: "/products/bold",
        title: "product-bold",
        linkType: "internal",
      },

      {
        name: "حزمة أهيد",
        url: "/products/ahead",
        title: "product-ahead",
        linkType: "internal",
      },

      {
        name: "ميتايب",
        url: "/products/metype",
        title: "product-metype",
        linkType: "internal",
      },

      {
        name: "برنامج أكسيس تايب",
        url: "/products/accesstype",
        title: "product-accesstype",
        linkType: "internal",
      },
    ],
  },

  {
    name: "الموارد",
    menuList: [
      {
        name: "مدونة",
        url: "https://www.quintype.com/blog",
        title: "blog",
        linkType: "external",
      },
      {
        name: "دراسات الحالة",
        url: "/case-study",
        title: "case study",
        linkType: "internal",
      },

      {
        name: "خبر صحفى",
        url: "https://www.quintype.com/blog/press-release",
        title: "Press Release",
        linkType: "external",
      },
    ],
  },
  {
    name: "الحلول",
    menuList: [
      {
        name: "ناشرون جدد وعصريون",
        url: "/solutions/new-and-modern-publishers",
        title: "New and Modern Publishers",
        linkType: "internal",
      },
      {
        name: "ناشرو المجلات",
        url: "/solutions/magazine-publishers-app",
        title: "Magazine Publishers",
        linkType: "internal",
      },
      {
        name: "لزيادة الحركة ",
        url: "/solutions/to-increase-traffic",
        title: "To Increase Traffic",
        linkType: "internal",
      },
      {
        name: "استثمر المحتوى الخاص بك",
        url: "/solutions/monetize-content",
        title: "Monetize your content",
        linkType: "internal",
      },
      {
        name: "خاص للمطور",
        url: "/solutions/for-developers",
        title: "For Developers",
        linkType: "internal",
      },
    ],
  },
];
