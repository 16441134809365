import React from "react";
import PropTypes from "prop-types";

import {
  Container,
  InnerContainer,
  HeadlineContainer,
  LinkItem
} from "../awards-and-publisher-logos/style";
import { ImageContainer, Image, Item, SecondRowImageContainer } from "./style";

import ArrowLink from "../../shared/arrow-link/index";
import H2 from "../../shared/h2/index";

function ClientAwards({ data }) {
  return (
    <Container>
      <InnerContainer>
        <HeadlineContainer>
          <H2> {data.headline}</H2>
        </HeadlineContainer>
        <ImageContainer>
          {data.firstRowlogos.map((logo, index) => {
            const startColumn = index + 2;
            return (
              <Item startColumn={startColumn} key={index}>
                <Image src={logo.url} alt={logo.alt} loading="lazy" />
              </Item>
            );
          })}
        </ImageContainer>
        <SecondRowImageContainer>
          {data.secondRowLogos.map((logo, index) => {
            const startColumn = index + 2;
            return (
              <Item startColumn={startColumn} key={index}>
                <Image src={logo.url} alt={logo.alt} loading="lazy" />{" "}
              </Item>
            );
          })}
        </SecondRowImageContainer>
        <LinkItem>
          <ArrowLink isExternal={true} url={data["read-more"].url}>
            {data["read-more"].text}
          </ArrowLink>
        </LinkItem>
      </InnerContainer>
    </Container>
  );
}

ClientAwards.propTypes = {
  data: PropTypes.shape({
    headline: PropTypes.string,
    logo: PropTypes.array,
    "read-more": PropTypes.shape({
      url: PropTypes.string,
      title: PropTypes.string,
      text: PropTypes.string
    })
  })
};

export default ClientAwards;
