import React from "react";
import YouTube from "react-youtube";
import axios from "axios";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { Link } from "react-router-dom";

import SmallSpinner from "../small-spinner";

import "./confirmsignup.css";

import ArrowLink from "../shared/arrow-link/index";

const YoutubeContainer = styled.div`
  max-width: 100%;
  margin: 16px 0;
`;

const LinkContainer = styled.span`
  display: inline-block;
  margin-right: 8px;
`;

const LinksContainer = styled.div`
  text-align: center;
`;

const handleSpaceInString = (inputString = "") =>
  inputString.replace(/ /g, "-");

class ConfirmSignupPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSuccessMessage: false,
      errors: {},
      mailsent: true,
      isError: null,
      enableLoader: false,
      invalidOTP: true,
      parsedDomain: null,
      success: false,
    };
    this.openFrontEndWindow = this.openFrontEndWindow.bind(this);
    this.openEditorWindow = this.openEditorWindow.bind(this);
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);

    if (params.has("tokenKey")) {
      const decodedString = atob(params.get("tokenKey"));
      const parsed = queryString.parse(decodedString);
      const { code, domain, stamp, email, mobile, name, company } = parsed;

      this.setState({ parsedDomain: domain });
      axios
        .post("/auth/validateHash", {
          hash: `${code}~~${domain}~~${stamp}~~${email}~~${mobile}~~${name}`,
        })
        .then((response) => {
          if (response && response.data && response.data.result === "pass") {
            if (email && domain && name) {
              const headers = {};
              return axios
                .get(`/api/v1/publisher/check?domain=${domain.toLowerCase()}`, {
                  headers: headers,
                })
                .then((response) => {
                  return axios
                  .post("/create/createPublisher", {
                    name: domain,
                    "created-by": {
                      name: name,
                      email: email,
                    },
                    "theme-attributes": {
                      lang: "en",
                    },
                    "extra-params": {
                      name: name,
                      company: company,
                      domain: domain,
                      email: email,
                      mobile: mobile,
                    },
                  })
                  .then((response) => {
                    // handle success
                    this.setState({ enableLoader: false });
                    if (
                      response.data.statusCode === 200 ||
                      response.data.statusCode === 201
                    ) {
                      this.setState({ success: true, isError: false });
                    } else {
                      this.setState({
                        isError: true,
                        invalidOTP: "invalid",
                        enableLoader: false,
                      });
                    }
                  })
                  .catch((error) => {
                    // handle error
                    this.setState({
                      isError: true,
                      invalidOTP: "invalid",
                      enableLoader: false,
                    });
                  });
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          } else {
            this.setState({
              isError: true,
              invalidOTP: "invalid",
              enableLoader: false,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      this.setState({
        isError: true,
        invalidOTP: "invalid",
        enableLoader: false,
      });
    }
  }

  openFrontEndWindow() {
    window.open(
      `https://${handleSpaceInString(
        this.state.parsedDomain
      )}.madrid.quintype.io`,
      "_blank"
    );
  }

  openEditorWindow() {
    window.open(
      `https://${handleSpaceInString(this.state.parsedDomain)}.quintype.com`,
      "_blank"
    );
  }

  render() {
    return (
      <div
        className={`confirm-sign-up ${
          this.state.isError ? "expired-space" : null
        }`}
      >
        <div className="services_page-feature no-border">
          {this.state.isError !== null ? (
            <h2 className="services_page-headings">
              {this.state.isError
                ? "Link Expired!"
                : "Your account is active now"}
            </h2>
          ) : (
            <SmallSpinner width="125" height="125" className="large-spinner" />
          )}
        </div>
        {this.state.success && !this.state.isError && (
          <div className="trial-cta">
            <p className="services_page-description success-alert-activated">
              Credentials to access your account have been sent to your email.
            </p>
            <p className="services_page-description buttons-align">
              <LinksContainer>
                <LinkContainer>
                  <ArrowLink
                    url={`https://${handleSpaceInString(
                      this.state.parsedDomain
                    )}.quintype.com`}
                    isExternal={true}
                  >
                    Open Editor
                  </ArrowLink>
                </LinkContainer>
                <ArrowLink
                  url={`https://${handleSpaceInString(
                    this.state.parsedDomain
                  )}.madrid.quintype.io`}
                  isExternal={true}
                >
                  Open Website
                </ArrowLink>
              </LinksContainer>
            </p>
            <div className="services_page-description success-alert-activated embed-resize">
              <p>Here is a detailed Demonstration of our Bold CMS. </p>
              <YoutubeContainer>
                <YouTube opts={{ width: "100%" }} videoId="GpfAzh-B9tk" />
              </YoutubeContainer>
            </div>
            <p className="services_page-description contact-us-text">
              You can access the help documention at{" "}
              <a
                href="https://help.quintype.com"
                rel="noopener noreferrer"
                target="_blank"
              >
                help.quintype.com
              </a>
            </p>
          </div>
        )}
        {this.state.isError && (
          <p className="services_page-description link-expired">
            The validation link has expired.{" "}
            <Link to="/sign-up">Click here</Link> to sign up again.
          </p>
        )}
      </div>
    );
  }
}

export default withRouter(ConfirmSignupPage);
