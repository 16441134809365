import Location from "./location.svg";
import SearchIcon from "./search.svg";
import GroupIcon from "./group.svg";
export const partners = {
  headline: "Grow with Quintype's Partnership Program",
  text: "Join a community of recognized organizations to successfully build, implement and manage your digital presence using our products.",
  primaryButtonText: "Become a partner",
  titleIcon: GroupIcon,
  altText: "Grop Icon",
};

export const partnerHeadings = {
  methodHeader: "Ways you can partner with us",
  whyPartnerHeader: "Why become our partner?",
  partnerHeader: "Our Partners",
};

export const partnerReasons = [
  {
    headline: "Solutions Partnerships",
    content:
      "Bundle your solution with Quintype’s suite of products to drive your business and meet the needs of your customers.",
  },
  {
    headline: "Value added Reseller Partnerships",
    content:
      "Collaborate and receive access to our suite of products to market and resell the product within a region.",
  },
];

export const partnersInfo = [
  {
    logo: "https://gumlet.assettype.com/quintype-website/2020-10/40efbb44-d46c-408c-ae0a-9cdbcc0bbf4a/fiare.png",
    description:
      "Fiare helps media companies to run successful businesses by enabling them to unlock the value of their content and audience. Our solutions have been trusted by prominent media groups such as Otava Group, Springer Nature, and Funke Mediengruppe.",
    name: "Fiare",
    location: "Helsinki, Finland",
    locationIcon: Location,
    link: "https://fiareconsulting.com/",
    search: "www.FiareConsulting.com",
    searchIcon: SearchIcon,
  },
  {
    logo: "https://gumlet.assettype.com/quintype-website/2023-01/f784e46b-dd0e-49e3-aab6-0a80ed9a7d96/suventure_logo.jpeg",
    description: `Suventure is a Technology and Digital Services company with over a decade of presence with its global offices in India, USA, Denmark, Netherlands and Canada. With a focus on providing high end consulting and Digital Adoption to global companies, Suventure provides Software Development Services for Enterprises and Digital Growth services including Digital Adoption & Transformation, Marketing and Analytics. We align ourselves with our customer's "Build, Grow and Run" stages and aptly help companies to achieve their growth goals and objectives in a continuous and frugal manner.`,
    name: "Suventure",
    location: "Bangalore, India",
    locationIcon: Location,
    link: "https://suventure.in/",
    search: "www.suventure.in",
    searchIcon: SearchIcon,
  },
  {
    logo: "/dailymotion.svg",
    description:
      "Founded in 2005, Dailymotion is a global video streaming service that connects over 350 million entertainment-seekers to their personal world of news and entertainment. Built on a clever player, intuitive algorithm, and on carefully-selected recommendations made by our experts who really love great videos, Dailymotion is the one-stop place for enjoying stories from the best creators around in one heightened video experience. Dailymotion is owned by Vivendi and headquartered in Paris with offices in New York, Singapore and Marseille.",
    name: "Dailymotion",
    location: "Melbourne, Australia",
    locationIcon: Location,
    link: "https://dailymotion.com/in",
    search: "www.dailymotion.com",
    searchIcon: SearchIcon,
  },
  {
    logo: "https://gumlet.assettype.com/quintype-website/2019-11/c6549d15-22ca-4807-89ca-a08c2c5f425a/geekyants.jpg",
    description:
      "GeekyAnts is a colony of highly skilled developers and designers. We are huge contributors in the open-source community. We don’t just consider ourselves as coders or designers, but as innovators who love to come with new stuff that helps not only us but everyone.",
    name: "GeekyAnts",
    location: "Bangalore, India",
    locationIcon: Location,
    link: "https://geekyants.com/",
    search: "www.geekyants.com",
    searchIcon: SearchIcon,
  },
  {
    logo: "https://gumlet.assettype.com/quintype-website/2019-11/262d0461-7b04-4742-9a1d-180a2dee7969/fivefriday.jpg",
    description:
      "FiveFriday’s culture encourages people – employees and customers alike – to do great things. FiveFriday has provided outsourcing services and support to UK companies for the past 7 years, we are strongly placed among leaders in the South African outsourcing industry and have offices in Johannesburg and Cape Town.",
    name: "FiveFriday",
    location: "Cape Town, South Africa",
    locationIcon: Location,
    link: "https://fivefriday.com/",
    search: "www.fivefriday.com",
    searchIcon: SearchIcon,
  },
  {
    logo: "https://gumlet.assettype.com/quintype-website/2021-01/354e5d4d-89bf-447b-a30f-073cd49a3089/VK_LOGO.jpg",
    description:
      "VisionKraft is a niche & focused IT and software solutions consulting firm working closely with startups,SMBs and enterprises across verticals in emerging markets across APAC,SEA & EMEA region specializing in providing practical, efficient and result driven digital enablement solutions & services framework to support and empower our clients through Digital Transformation & Change Management initiatives with an Artificial Intelligence enabled approach to maximize ROI from digital adoption.",
    name: "VisionKraft",
    location: "India, Singapore",
    locationIcon: Location,
    link: "https://visionkraftasia.com",
    search: "www.visionkraftasia.com",
    searchIcon: SearchIcon,
  },
  {
    logo: "https://images.assettype.com/quintype-website/2019-09/d60d78b0-d2d4-4813-886d-ced34cdc286d/izooto_250_x_250_2.png",
    description:
      "iZooto is an owned audience marketing solution that leverages push notification as a channel to help publishers build, own, and engage their audience. iZooto enables publishers to take charge of their audience and reduce their dependence on Google and Facebook. Publishers engage their audience with personalized and automated notifications to build repeat traffic and earn more from their current display ads. Publishers can also push ads to their audience using notifications and generate incremental revenue.",
    name: "iZooto",
    location: "Seattle, Washington",
    locationIcon: Location,
    link: "https://izooto.com",
    search: "www.izooto.com",
    searchIcon: SearchIcon,
  },
  {
    logo: "https://images.assettype.com/quintype-website/2021-02/85c5bc06-6fb9-441c-ac7e-f78840cce944/Cobalt_Partners.jpeg",
    description:
      "The Cobalt Partners is an Advisory firm focused on the growth agenda of African businesses. We believe that this is Africa's century and there are tremendous opportunities across industries. We aim to assist businesses and social sector leaders capture and harness those opportunities for growth in a sustainable manner. We are organizational improvement professionals with a keen eye and talent for operational efficiency and customer effectiveness in the continent. As catalysts of business, we bring the best of Cobalt in a custom configuration for each client.",
    name: "Cobalt Partners",
    location: "Cape Town, South Africa",
    locationIcon: Location,
    link: "https://thecobaltpartners.com",
    search: "www.thecobaltpartners.com",
    searchIcon: SearchIcon,
  },
];

export const advantageInfo = [
  {
    title: "Extensive sales & marketing benefits",
    url: " https://images.assettype.com/quintype-website/2021-02/f952a4a6-fbb5-4c7a-8c03-8747c3e089a8/money_01_01.png",
    alt: "Extensive sales & marketing benefits",
  },
  {
    title: "Access to Partner events",
    url: "https://gumlet.assettype.com/quintype-website/2019-11/6e5046ef-758a-41a1-8f81-864a6ca4bd8f/Efficient_Organization.svg?w=300&dpr=1.0",
    alt: "Access to Partner events",
  },
  {
    title: "Stand out with the uniqueness and creation of your tools",
    url: "https://gumlet.assettype.com/quintype-website/2020-12/a543d067-38a1-4c4c-bb81-230d6065a2b8/Feature_Icons_5.svg?w=300&dpr=1.0",
    alt: "Stand out with the uniqueness and creation of your tools",
  },
  {
    title: "Be a part of a robust partner community",
    url: "https://gumlet.assettype.com/quintype-website/2019-11/b1d4dff0-5abb-4079-949b-10585ba4ad95/Teams.svg?w=300&dpr=1.0",
    alt: "Be a part of a robust partner community",
  },
];

export const CEOData = {
  description:
    "“Quintype empowers digital publishers with SaaS solutions that solve tech challenges in content management, audience engagement and monetization. The benefits of using a solution built for digital newsrooms translates to tremendous improvements in editorial efficiency and content distribution capabilities for organizations. Fiare is excited to partner with Quintype to offer our customers a powerful digital publishing tech stack”",
  name: "Tommi Heikkonen, CEO",
  company: "Fiare Consulting Oy.",
  url: "https://images.assettype.com/quintype-website/2021-02/b5c7be7b-3349-484b-9180-9b42b52c32ea/TommiHeikkonen_kuva_omaan__2___1_.jpg",
  alt: "Tommi Heikkonen, CEO",
};
