import React from "react";
import { useSelector } from "react-redux";
import {
  PrivacyWrapper,
  Heading,
  Content,
  Title,
  PrivacyPolicyList,
  PrivacyPolicyItem,
} from "./style";

function PrivacyPolicy() {
  const isArabic = useSelector((state) => state);
  return (
    <PrivacyWrapper>
      <Heading>{isArabic ? `سياسة الخصوصية` : `PRIVACY POLICY`}</Heading>

      <Content>
        {isArabic
          ? `تحدد سياسة الخصوصية هذه ("السياسة") ممارسة شركة كوينتايب تكنولوجيز الهند الخاصة المحدودة والتي تُذكر اختصاراً ("كوينتايب" أو "نحن" أو "إنا") فيما يتعلق بتخزين البيانات الشخصية التي اخترت مشاركتها معها واستخدامها ومعالجتها والكشف عنها لنا عندما تصل إلى المنتجات والخدمات التي توفرها شركة كوينتايب ("الخدمات") أو تستخدمها بأي طريقة أخرى.`
          : `This privacy policy (“Policy”) outlines Quintype Technologies India
        Private Limited’s (“Quintype”, “we” or “us”) practice in relation to the
        storage, use, processing, and disclosure of personal data that you have
        chosen to share with us when you access or otherwise use the products
        and services made available by Quintype (“Services”).`}
      </Content>
      <Content>
        {isArabic
          ? `في شركة كوينتايب تكنولوجيز الهند الخاصة المحدودة، نحن ملتزمون بحماية بياناتك الشخصية واحترام خصوصيتك. لذا، يرجى قراءة الشروط والأحكام التالية لسياسة الخصوصية بعناية لفهم ممارساتنا المتعلقة ببياناتك الشخصية وكيف سنتعامل معها. تحدد هذه السياسة الأساس الذي بناءً عليه نقوم بمعالجة أي بيانات شخصية نجمعها منك أو نجمعها عنك أو التي تقدمها لنا.`
          : ` At Quintype, we are committed to protecting your personal data and
        respecting your privacy. Please read the following terms of the Policy
        carefully to understand our practices regarding your personal data and
        how we will treat it. This policy sets out the basis on which any
        personal data we collect from you, we collect about you, or that you
        provide to us, will be processed by us.`}
      </Content>
      <Content>
        {isArabic
          ? `يجب أن يكون للكلمات المكتوبة بحروف كبيرة في السياسة نفس المعنى المنسوب لها في الشروط والأحكام ("الشروط والأحكام ")، المتوفرة على [https://www.quintype.com/terms-of-service]. يرجى قراءة هذه السياسة بما يتوافق مع هذه الشروط والأحكام.`
          : `Capitalised words in the Policy shall have the same meaning ascribed to
        them in the Terms and Conditions (“Terms”), available at
        [https://www.quintype.com/terms-of-service]. Please read this Policy in
        consonance with the Terms.`}
      </Content>
      <Title>
        {isArabic ? `الغرض من هذه السياسة` : `1. PURPOSE OF THIS POLICY`}
      </Title>
      <Content>
        {isArabic
          ? `تهدف سياسة الخصوصية هذه إلى تزويدك بمعلومات حول كيفية قيام شركة كوينتايب تكنولوجيز الهند الخاصة المحدودة بجمع ومعالجة بياناتك الشخصية من خلال استخدامك للخدمات. من المهم أن تقرأ سياسة الخصوصية هذه بالإضافة إلى أي إشعارات خصوصية أخرى قد نقدمها في مناسبات او احداث محددة عندما نقوم بجمع أو معالجة بيانات شخصية عنك حتى تكون على دراية كاملة بكيفية ولماذا نستخدم بياناتك.`
          : ` This Policy aims to give you information on how Quintype collects and
        processes your personal data through your use of the Services. It is
        important that you read this Policy together with any other privacy
        notices we may provide on specific occasions when we are collecting or
        processing personal data about you so that you are fully aware of how
        and why we are using your data.`}
      </Content>
      <Title>
        {isArabic
          ? `البيانات التي نجمعها عنك`
          : `2. THE DATA WE COLLECT ABOUT YOU`}{" "}
      </Title>
      <Content>
        {isArabic
          ? `البيانات الشخصية يُقصد بها أي معلومات عن أي شخص أو مستخدم أو فرد يمكن من خلالها التعرف عليه. لا تشمل البيانات التي ليس لها هوية – على سبيل المثال، البيانات مجهولة المصدر.`
          : `(a) Personal data means any information about an individual from which
        that person can be identified. It does not include data where the
        identity has been removed – for example, anonymous data.`}
      </Content>
      <Content>
        {isArabic
          ? `يجوز لنا جمع أنواع مختلفة من البيانات الشخصية الخاصة بك واستخدامها وتخزينها ونقلها لتزويدك بالخدمات أو فيما يتعلق بها. تتضمن هذه البيانات الشخصية ما يلي:`
          : `(b) We may collect, use, store and transfer different kinds of personal
        data about you to provide you with or in connection with the Services.
        Such personal data includes:`}
      </Content>
      <Content>
        <PrivacyPolicyList>
          <PrivacyPolicyItem>
            {isArabic
              ? `البيانات المتعلقة بهويتك والبيانات المتعلقة بملفك الشخصي، مثل الاسم الأول والأخير واسم المستخدم أو المعرفات المماثلة والجنس والمسمى الوظيفي وكلمات المرور والمشتريات أو الطلبات والتعليقات والردود على الاستبيانات، وما إلى ذلك؛`
              : `data pertaining to your identity and profile-related data, such as
            your first and last name, username or similar identifiers, gender,
            title, passwords, purchases or orders, feedback, survey responses,
            etc.;`}
          </PrivacyPolicyItem>
          <PrivacyPolicyItem>
            {isArabic
              ? `تفاصيل الاتصال، بما في ذلك عناوين البريد الإلكتروني وأرقام الهواتف وعناوين التسليم وعناوين العمل وما إلى ذلك؛`
              : `contact details, including email addresses, phone numbers, delivery
            addresses, business addresses, etc.;`}
          </PrivacyPolicyItem>
          <PrivacyPolicyItem>
            {isArabic
              ? `بيانات المعاملات، بما في ذلك تفاصيل المنتجات والخدمات التي قمت بشرائها أو بيعها؛`
              : ` transaction data, including details of products and services you
            have purchased or sold;`}
          </PrivacyPolicyItem>
          <PrivacyPolicyItem>
            {isArabic
              ? `تتضمن البيانات الفنية عناوين برتوكولات الانترنت وأنواع المتصفح وإصداراته وإعدادات المنطقة الزمنية والمواقع وأنظمة التشغيل والتقنيات الأخرى على الأجهزة التي تستخدمها للوصول إلى الخدمات؛`
              : `technical data includes IP addresses, browser types and versions,
            time zone settings and locations, operating systems, and other
            technology on the devices you use to access the Services;`}
          </PrivacyPolicyItem>
          <PrivacyPolicyItem>
            {isArabic
              ? `بيانات الاستخدام بما في ذلك معلومات حول كيفية استخدامك لخدماتنا أو تفاعلك معها؛ و`
              : ` usage data including information about how you use or interact with
            our Services; and`}
          </PrivacyPolicyItem>
          <PrivacyPolicyItem>
            {isArabic
              ? `تتضمن بيانات التسويق والاتصالات تفضيلاتك في تلقي التسويق من جانبنا ومن الجهات الخارجية وتفضيلات الاتصال الخاصة بك.`
              : `marketing and communications data includes your preferences in
            receiving marketing from us and our third parties and your
            communication preferences.`}
          </PrivacyPolicyItem>
        </PrivacyPolicyList>
      </Content>
      <Content>
        {isArabic
          ? `نقوم أيضًا بجمع واستخدام ومشاركة البيانات المجمعة مثل البيانات الإحصائية أو الديموغرافية لأي غرض. يمكن اشتقاق البيانات المجمعة من بياناتك الشخصية ولكنها لا تعتبر بيانات شخصية بموجب القوانين المعمول بها. على سبيل المثال، قد نقوم بتجميع بيانات الاستخدام الخاصة بك لحساب النسبة المئوية للمستخدمين الذين يصلون إلى ميزة معينة من الخدمات.`
          : `(c) We also collect, use, and share aggregated data such as statistical
        or demographic data for any purpose. Aggregated data could be derived
        from your personal data but is not considered personal data under
        applicable laws. For example, we may aggregate your usage data to
        calculate the percentage of users accessing a specific feature of the
        Services.`}
      </Content>
      <Content>
        {isArabic
          ? `عندما نحتاج إلى جمع البيانات الشخصية بموجب القانون أو بموجب شروط الاتفاقيات الذي أبرمناه معك، وفشلت في تقديم تلك البيانات عند الطلب، فقد لا نتمكن من تنفيذ التزاماتنا بموجب القانون أو بموجب شروط الاتفاقيات الذي أبرمناه معك (على سبيل المثال، لتزويدك بميزات الخدمات). في هذه الحالة، قد نضطر إلى إلغاء أو تقييد وصولك إلى الخدمات، لكننا سنخطرك إذا كان هذا هو الحال حينها.`
          : ` (d) Where we need to collect personal data by law, or under the terms of
        the arrangement we have with you, and you fail to provide that data when
        requested, we may not be able to perform our obligations under the
        arrangement we have with you or are trying to enter into with you (for
        example, to provide you with features of the Services). In this case, we
        may have to cancel or limit your access to the Services, but we will
        notify you if this is the case at the time.`}
      </Content>
      <Title>
        {isArabic
          ? `كيف يتم جمع معلوماتك الشخصية؟`
          : `3. HOW IS YOUR PERSONAL INFORMATION COLLECTED?`}
      </Title>
      <Content>
        {isArabic
          ? `نحن نستخدم طرقًا مختلفة لجمع البيانات الخاصة بك ومعالجتها، منها على سبيل المثال ما يلي:`
          : ` (a)We use different methods to collect and process data about you.`}
        <br />
        {isArabic
          ? `من خلال الاعتماد على المعلومات التي تقدمها إلينا - هذه هي المعلومات (بما في ذلك الهوية وجهات الاتصال وبيانات التسويق والاتصالات) التي توافق على إعطائنا إياها عنك عندما تتفاعل معنا أو تتراسل معنا (على سبيل المثال، عبر البريد الإلكتروني أو الدردشة). وهي تشمل المعلومات التي تقدمها عند التسجيل لاستخدام الخدمات أو استخدام ميزة معينة من الخدمات أو مشاركة البيانات من خلال الخدمات أو من خلال الأنشطة الأخرى التي يتم إجراؤها بشكل شائع فيما يتعلق بالخدمات وعندما تبلغ عن مشكلة في الخدمات التي نقدمها لك. وفي حال إذا اتصلت بنا، فسنحتفظ بسجل للمعلومات التي تمت مشاركتها أثناء المراسلات. `
          : `(i)Information you give us – This is the information (including
        identity, contact, and marketing and communications data) you consent to
        giving us about you when you interact or correspond with us (for
        example, by email or chat). It includes information you provide when you
        register to use the Services, use a specific feature of the Services,
        share data through the Services or through other activities commonly
        carried out in connection with the Services, and when you report a
        problem with the Services. If you contact us, we will keep a record of
        the information shared during the correspondence.`}
        <br />
        {isArabic
          ? `المعلومات التي قد يُسمح لمستخدم الخدمات بمشاركتها معنا - قد نجمع بيانات ومعلومات عنك تفوض من خلالها تاجرًا أو موردًا للسلع أو مزود الخدمات التي طلبتها أو اشتريتها أو تنوي طلبها أو شرائها وتتم مشاركتها معنا .`
          : `(ii)Information that you authorise a user of the Services to share with
        us – We may collect data and information about you that you authorise a
        merchant or supplier of goods or services you have sought or purchased,
        or intend to seek or purchase, to share with us. `}
        <br />
        {isArabic
          ? `المعلومات التي نجمعها عنك وعن جهازك - في كل مرة تستخدم فيها إحدى خدماتنا، سنجمع تلقائيًا البيانات الشخصية بما في ذلك بيانات الجهاز والاستخدام. نقوم بجمع هذه البيانات باستخدام ملفات تعريف الارتباط والتقنيات المماثلة الأخرى.`
          : `(iii)Information we collect about you and your device – Each time you
        use one of our Services, we will automatically collect personal data
        including device and usage data. We collect this data using cookies and
        other similar technologies.`}
        <br />
        {isArabic
          ? `المعلومات التي نتلقاها من مصادر أخرى بما في ذلك الجهات الخارجية والمصادر المتاحة للجمهور - سوف نتلقى بيانات شخصية عنك من جهات خارجية مختلفة ومصادر عامة كما هو موضح أدناه:`
          : `(iv)Information we receive from other sources including third parties
        and publicly available sources – We will receive personal data about you
        from various third parties and public sources as set out below:`}
        <br />
        {isArabic
          ? `البرامج أو المواقع التي توفر تحليلات البيانات مثل جوجل وفيس بوك؛`
          : `A. Analytics providers such as Google and Facebook;`}
        <br />
        {isArabic ? `الشبكات الاعلانية ` : `B. Advertising networks; `}
        <br />
        {isArabic
          ? `موفرو معلومات البحث `
          : ` C. Search information providers; `}
        <br />
        {isArabic
          ? `بيانات الاتصال والبيانات المالية والمعاملات التي يتم إجراؤها مع مقدمي الخدمات الفنية وخدمات الدفع والتسليم `
          : ` D. Contact, financial and transaction data from providers of technical,
        payment, and delivery services; `}
        <br />
        {isArabic
          ? `بيانات الهوية والاتصال من خلال وسطاء البيانات أو المجمعين أو مراكز الالتقاء؛ و`
          : `E. Identity and contact data from data brokers or aggregators; and`}
        <br />
        {isArabic
          ? `بيانات الهوية والاتصال من المصادر المتاحة للجمهور.`
          : `F. Identity and contact data from publicly available sources.`}
        <br />
      </Content>
      <Title>
        {isArabic
          ? `كيف نستخدم المعلومات الشخصية وكيفية الكشف عنها`
          : `4. HOW WE USE AND DISCLOSE PERSONAL INFORMATION `}
      </Title>
      <Content>
        {isArabic
          ? `أسنستخدم بياناتك الشخصية فقط كما هو مفصل هنا. في الحالة الأكثر شيوعًا، سنستخدم بياناتك الشخصية لتزويدك بالخدمات وتوفيرها لك أو عندما نحتاج إلى الامتثال لالتزام قانوني.`
          : `(a) We will only use your personal data as detailed here. Most commonly,
        we will use your personal data to provide you with the Services, or
        where we need to comply with a legal obligation.`}
        <br />
        {isArabic
          ? `أنت تدرك أنه عندما توافق على تزويدنا ببياناتك الشخصية، فإنك توافق أيضًا على مشاركة هذه البيانات والمعلومات الخاصة بك مع أطراف ثالثة. علاوة على ذلك، فأنت تدرك أنه باستخدام خدماتنا والاستفادة منها، فإنك تفوضنا نحن وشركائنا المرتبطين والشركات التابعة لنا بالاتصال بك عبر البريد الإلكتروني أو الهاتف أو غير ذلك.`
          : ` (b) You understand that when you consent to providing us with your
        personal data, you also consent to us sharing this with third parties.
        You are aware that by using our Services, you authorise us, our
        associate partners, and affiliates to contact you via email, phone, or
        otherwise.`}
        <br />
        {isArabic
          ? `إنك تدرك أنه قد يتم جمع أي وجميع المعلومات المتعلقة بك، سواء قمت بتقديمها لنا مباشرة أم لا (عبر الخدمات أو غير ذلك)، بما في ذلك على سبيل المثال لا الحصر المراسلات الشخصية مثل رسائل البريد الإلكتروني والتعليمات منك وما إلى ذلك، والتي قمنا بتجميعها ومشاركتها من أجل تقديم الخدمات وتوفير لك وأنت تفوضنا صراحةً للقيام بذلك. قد يشمل ذلك على سبيل المثال لا الحصر مزودي التخزين وموفري أنظمة الدفع وشركاء التسويق وموفري تحليلات البيانات والمستشارين أو الاستشاريين والمحامين والمراجعين. يجوز لنا أيضًا مشاركة هذه المعلومات مع شركتنا الأم أو الشركات التابعة أو الشركات التابعة أو أي من شركاتها القابضة.`
          : `(c) You are aware that any and all information pertaining to you,
        whether or not you directly provide it to us (via the Services or
        otherwise), including but not limited to personal correspondence such as
        emails, instructions from you etc., may be collected, compiled and
        shared by us in order to render Services to you and you expressly
        authorise us to do so. This may include but not be limited to storage
        providers, payments systems providers, marketing partners, data
        analytics providers, and consultants, lawyers, and auditors. We may also
        share this information with our parent company, subsidiaries, affiliates
        or any of their holding companies. `}
        <br />
        {isArabic
          ? `إنك توافق وتقر بأنه يجوز لنا مشاركة البيانات دون موافقتك عندما يكون ذلك مطلوبًا بموجب القانون أو من قبل أي محكمة أو وكالة حكومية أو سلطة للكشف عن هذه المعلومات. يتم إجراء مثل هذه الإفصاحات أو حالات الكشف عن البيانات بحسن نية واعتقادًا بأنه من الضروري بشكل معقول القيام بذلك لتطبيق هذه السياسة أو الشروط والأحكام الخاصة بها أو من أجل الامتثال لأي قوانين ولوائح سارية ومعمول بها.`
          : ` (d) You agree and acknowledge that we may share data without your
        consent, when it is required by law or by any court or government agency
        or authority to disclose such information. Such disclosures are made in
        good faith and belief that it is reasonably necessary to do so for
        enforcing this Policy or the Terms, or in order to comply with any
        applicable laws and regulations.`}
        <br />
        {isArabic ? (
          <>
            بشكل عام، لن نقوم بالكشف عن البيانات الشخصية إلا وفقًا للغرض أو
            النشاط التالي: <br />
            لتقديم أو توفير الخدمات
          </>
        ) : (
          <>
            (e) In general, we will not disclose personal data except in
            accordance with the following purpose or activity: <br />
            to deliver Services;
          </>
        )}
        <br />
        {isArabic
          ? `من أجل إدارة علاقتنا معك، بما في ذلك إخطارك بالتغييرات التي تطرأ على أي خدمات `
          : `to manage our relationship with you including notifying you of changes`}
        {isArabic ? `` : `to any Services; `}
        <br />
        {isArabic
          ? `من أجل إدارة وحماية أعمالنا والخدمات بما في ذلك استكشاف الأخطاء وإصلاحها وتحليل البيانات واختبار النظام `
          : `to administer and protect our business and the Services including
        troubleshooting, data analysis and system testing;`}
        <br />
        {isArabic
          ? `من أجل تقديم المحتوى والإعلانات لك والتي تلبي رغباتك`
          : `to deliver content and advertisements to you;`}
        <br />
        {isArabic
          ? `من أجل تقديم توصيات لك بشأن السلع أو الخدمات التي قد تهمك أو تنفعك`
          : `to make recommendations to you about goods or services which may interest you;`}
        <br />
        {isArabic
          ? `من أجل قياس وتحليل فعالية الإعلانات التي نخدمك من خلال عرضها أو تقديمها  `
          : `to measure and analyse the effectiveness of the advertising we serve
        you; `}
        <br />
        {isArabic
          ? `من أجل مراقبة الاتجاهات حتى نتمكن من تحسين الخدمات من خلالها `
          : `to monitor trends so we can improve the Services;`}
        <br />
        {isArabic
          ? `لغرض أداء التزاماتنا التي تنشأ عن الترتيب أو الاتفاق الذي نحن على وشك الدخول فيه أو دخلناه معك بالفعل`
          : `to perform our obligations that arise out of the arrangement we are
        about to enter or have entered with you; `}
        <br />
        {isArabic
          ? `من اجل الالتزام بالشروط والحكام الخاصة بنا `
          : `to enforce our Terms;`}
        <br />
        {isArabic
          ? `عندما يكون ذلك ضروريًا لمصالحنا المشروعة (أو مصالح طرف ثالث) ولا تتجاوز مصالحك وحقوقك الأساسية تلك المصالح؛ أو`
          : `where it is necessary for our legitimate interests (or those of a third
        party) and your interests and fundamental rights do not override those
        interests; or `}
        <br />
        {isArabic
          ? `من أجل لامتثال لالتزام قانوني أو تنظيمي.`
          : `to comply with a legal or regulatory obligation.`}
        <br />
        {isArabic
          ? `إنك تصرح لنا بإرسال تنبيهات ورسائل إلكترونية إليك للحصول على التفاصيل المتعلقة بالمتطلبات الناشئة عن توفير الخدمات والإعلان.`
          : `(f) You authorise us to send you electronic alerts and messages for
        details pertaining to requirements arising out of the provision of
        Services, and advertising.`}
        <br />
        {isArabic
          ? `علاوة على ذلك، فإنك توافق على تلقي رسائل البريد الإلكتروني الترويجية وغيرها من أشكال الاتصال التي قد تتم من طرفنا. من خلال هذه الاتصالات، ستتلقى معلومات حول آخر التطورات في الخدمات التي قد نزودك بها أو نوفرها لك. يمكنك إلغاء الاشتراك من قائمتنا البريدية في أي وقت عبر خيار إلغاء الاشتراك الذي نقدمه.`
          : `(g) Further, you agree to receive promotional and other emails and other
        forms of communication from us. Through such communication you will
        receive information about the latest developments on the Services. You
        may unsubscribe from our mailing list at any time via the unsubscribe
        option we offer.`}
      </Content>
      <Title>
        {isArabic
          ? `نقل المعلومات الشخصية`
          : `5. TRANSFER OF PERSONAL INFORMATION`}{" "}
      </Title>
      <Content>
        {isArabic
          ? `كجزء من استخدامك للخدمات، قد يتم نقل المعلومات والبيانات الشخصية التي تزودنا بها وتخزينها في دول أخرى غير تلك التي تقيم فيها. وتشمل بعض هذه البلدان الهند والولايات المتحدة الأمريكية. قد يحدث هذا إذا كان أي من خوادمنا موجودًا من وقت لآخر في الهند أو الولايات المتحدة أو إذا كان أحد مزودي الخدمة لدينا موجودًا في بلد آخر غير الهند أو الولايات المتحدة الأمريكية.`
          : `(a) As a part of your use of the Services, the information and personal
        data you provide to us may be transferred to and stored at countries
        other than the one you are based in. Some of these countries include
        India and the United States of America. This may happen if any of our
        servers are from time to time located in India or the United States, or
        one of our service providers is located in a country other than India or
        the United States.`}
        <br />
        {isArabic
          ? `إذا كنت مقيمًا في الهند، فمن خلال تقديم معلوماتك وبياناتك الشخصية إلينا، فإنك توافق على نقل و / أو تخزين و / أو معالجة هذه المعلومات والبيانات الشخصية خارج الهند بالطريقة الموضحة أعلاه.`
          : `(b) If you are based in India, by submitting your information and
        personal data to us, you agree to the transfer, storage, and/or
        processing of such information and personal data outside India in the
        manner described above.`}
      </Content>
      <Title>
        {isArabic
          ? `روابط الطرف الثالث وخدمات تحسين تجربة المستخدم`
          : `6. THIRD PARTY LINKS AND USER EXPERIENCE IMPROVEMENT SERVICES`}
      </Title>
      <Content>
        {isArabic
          ? `قد تحتوي خدماتنا التي نقدمها لك، من وقت لآخر، على خدمات مقدمة أو روابط من وإلى مواقع الويب لشبكات شركائنا والمعلنين والشركات التابعة لنا ("خدمات الأطراف الثالثة"). يرجى ملاحظة أن خدمات الطرف الثالث، التي يمكن الوصول إليها من خلال خدماتنا، لها سياسات الخصوصية الخاصة بها. نحن لا نتحمل أي مسؤولية أو التزام عن السياسات أو عن أي بيانات شخصية قد يتم جمعها من خلال خدمات الطرف الثالث. لذا، يرجى التحقق من سياساتهم قبل إرسال أي بيانات شخصية إلى هذه المواقع أو استخدام خدماتهم.`
          : `(a) Our Services may, from time to time, contain services provided by or
        links to and from the websites of our partner networks, advertisers and
        affiliates (“Third Party Services”). Please note that the Third Party
        Services, that may be accessible through our Services have their own
        privacy policies. We do not accept any responsibility or liability for
        the policies or for any personal data that may be collected through the
        Third Party Services. Please check their policies before you submit any
        personal data to such websites or use their services.`}
        <br />
        {isArabic
          ? `نحن نستخدم خدمات تحسين تجربة المستخدم من جهات خارجية (بما في ذلك على سبيل المثال لا الحصر تلك التي توفرها شركة جوجل و / أو الشركات التابعة لها) والتطبيقات لفهم سلوكك على الخدمات بشكل أفضل.`
          : `(b) We use third-party user experience improvement services (including
        but not limited to those provided by Google Inc. and/or its affiliates)
        and applications to better understand your behavior on the Services.`}
        <br />
        {isArabic
          ? `تتضمن المعلومات التي تم جمعها ما يلي (على سبيل المثال لا الحصر):`
          : `(c) The information collected includes (but is not limited to):`}
        <br />
        {isArabic ? `العمر` : `age;`}
        <br />
        {isArabic ? `النوع أو الجنس` : `gender;`}
        <br />
        {isArabic ? `التفضيلات الخاصة بالمستخدم` : `preferences; and `}
        <br />
        {isArabic ? `الاهتمامات الخاصة بالمستخدم` : `interests.`}
        <br />
        {isArabic
          ? `علاقتك بهذه الأطراف الثالثة وخدماتها وأدواتها مستقلة عن علاقتك معنا. قد تسمح لك هذه الأطراف الثالثة بالسماح أو تقييد المعلومات التي يتم جمعها. قد يكون من مصلحتك تقييد أو تمكين جمع البيانات بشكل فردي.`
          : `(d) Your relationship with these third parties and their services and
        tools is independent of your relationship with us. These third parties
        may allow you to permit or restrict the information that is collected.
        It may be in your interest to individually restrict or enable such data
        collections.`}
        <br />
        {isArabic
          ? `يعتمد مكان معالجة المعلومات على كل مزود خدمة تابع لجهة خارجية وقد ترغب في التحقق من سياسة الخصوصية لكل من مزودي الخدمة لتحديد البيانات التي تمت مشاركتها والغرض منها. ستخضع لسياسة الخصوصية الخاصة بطرف ثالث إذا اخترت تلقي اتصالات من أطراف ثالثة. لن نكون مسؤولين عن معايير الخصوصية وممارسات الأطراف الثالثة.`
          : `(e) The place of processing information depends on each third-party
        service provider and you may wish to check the privacy policy of each of
        the service providers to identify the data shared and its purpose. You
        will be subject to a third party’s privacy policy if you opt in to
        receive communications from third parties. We will not be responsible
        for the privacy standards and practices of third parties.`}
        <br />
      </Content>
      <Title>{isArabic ? `ملفات تعريف الارتباط` : `7. COOKIES`} </Title>
      <Content>
        {isArabic
          ? `سنستخدم ملفات تعريف الارتباط و / أو تقنيات التتبع الأخرى لتمييزك عن المستخدمين الآخرين للخدمات ولتذكر تفضيلاتك. يساعدنا هذا في تزويدك بتجربة جيدة عند استخدام خدماتنا ويسمح لنا أيضًا بتحسين الخدمات.`
          : `(a) We use cookies and/or other tracking technologies to distinguish you
        from other users of the Services and to remember your preferences. This
        helps us to provide you with a good experience when you use our Services
        and also allows us to improve the Services.`}
        <br />
        {isArabic
          ? `نحن نقوم بجمع البيانات عن طريق "ملفات تعريف الارتباط". ملفات تعريف الارتباط هي ملفات بيانات صغيرة يتم إرسالها إلى متصفحك من الخدمات ويتم تخزينها على جهاز الكمبيوتر أو الجهاز (القرص الصلب). يجب ألا توفر ملفات تعريف الارتباط الوصول إلى البيانات الموجودة في جهاز الكمبيوتر أو الجهاز الخاص بك مثل عناوين البريد الإلكتروني أو أي بيانات أخرى يمكن تتبعها لك شخصيًا. ستسمح لنا البيانات التي يتم جمعها عن طريق ملفات تعريف الارتباط بإدارة الخدمات وتزويدك بخدمة مخصصة وسهلة الاستخدام. تمكّنك ملفات تعريف الارتباط من الوصول إلى بعض ميزات الخدمات. يمكن ضبط معظم متصفحات الويب والأجهزة لإعلامك عند تلقي ملف تعريف ارتباط أو منع إرسال ملفات تعريف الارتباط. إذا قمت بمنع إرسال ملفات تعريف الارتباط، فقد يحد ذلك من الوظائف التي يمكننا توفيرها عند وصولك إلى الخدمات.`
          : `(b) We collect data by way of ‘cookies’. Cookies are small data files
        which are sent to your browser from the Services and are stored on your
        computer or device (hard drive). The cookies shall not provide access to
        data in your computer or device such as email addresses or any other
        data that can be traced to you personally. The data collected by way of
        cookies will allow us to administer the Services and provide you with a
        tailored and user-friendly service. The cookies shall enable you to
        access certain features of the Services. Most web browsers and devices
        can be set to notify when you receive a cookie or prevent cookies from
        being sent. If you do prevent cookies from being sent, it may limit the
        functionality that we can provide when you access the Services.`}
        <br />
        {isArabic
          ? `بالإضافة إلى ذلك، قد تصادف ملفات تعريف الارتباط أو غيرها من الأجهزة المماثلة على صفحات معينة من الخدمات التي يتم وضعها من قبل أطراف ثالثة. نحن لا نتحكم في استخدام ملفات تعريف الارتباط من قبل أطراف ثالثة. إذا أرسلت إلينا مراسلات شخصية، مثل رسائل البريد الإلكتروني أو الرسائل ، أو إذا أرسل لنا مستخدمون آخرون أو أطراف ثالثة مراسلات حول أنشطتك فيما يتعلق بالخدمات، فقد نجمع هذه المعلومات في ملف خاص بك.`
          : `(c) Additionally, you may encounter cookies or other similar devices on
        certain pages of the Services that are placed by third parties. We do
        not control the use of cookies by third parties. If you send us personal
        correspondence, such as emails or letters, or if other users or third
        parties send us correspondence about your activities in relation to the
        Services, we may collect such information into a file specific to you.`}
        <br />
      </Content>
      <Title>{isArabic ? `أمن وحماية البيانات ` : `8. DATA SECURITY`} </Title>
      <Content>
        {isArabic
          ? `نقوم بتنفيذ بعض الإجراءات الأمنية بما في ذلك التشفير وإنشاء جدران الحماية النارية وتقنية طبقة المقابس لحماية معلوماتك الشخصية من الوصول غير المصرح به وهذه الإجراءات الأمنية تتوافق مع ممارسات وإجراءات الأمان على النحو المنصوص عليه في قانون تكنولوجيا المعلومات لعام 2000 م والقواعد المعمول بها (الممارسات والإجراءات الأمنية المعقولة وقواعد البيانات الشخصية أو المعلومات الحساسة لعام 2011 م). ومع ذلك، فإنك توافق وتقر بأن الإجراءات المذكورة أعلاه لا تضمن الحماية المطلقة للمعلومات الشخصية ومن خلال الوصول إلى الخدمات، فإنك توافق على تحمل جميع المخاطر المرتبطة بالكشف عن المعلومات الشخصية الناشئة عن اختراق جدران الحماية وبرامج الخادم الآمنة.`
          : `(a) We implement certain security measures including encryption,
        firewalls, and socket layer technology to protect your personal
        information from unauthorised access and such security measures are in
        compliance with the security practices and procedures as prescribed
        under the Information Technology Act, 2000 and the applicable rules
        (Reasonable Security Practices and Procedures and Sensitive Personal
        Data or Information Rules, 2011). However, you agree and acknowledge
        that the above-mentioned measures do not guarantee absolute protection
        to the personal information and by accessing the Services, you agree to
        assume all risks associated with disclosure of personal information
        arising due to breach of firewalls and secure server software.`}
        <br />
        {isArabic
          ? `عندما نقدم لك (أو حيثما اخترت) كلمة مرور تمكنك من الوصول إلى أجزاء معينة من الخدمات، فإنك مسؤول عن الحفاظ على سرية كلمة المرور هذه، كما نطلب منك عدم مشاركة كلمة المرور مع أي شخص.`
          : `(b) Where we have given you (or where you have chosen) a password that
        enables you to access certain parts of the Services, you are responsible
        for keeping this password confidential. We ask you not to share the
        password with anyone. `}
        <br />
        {isArabic
          ? `سنلتزم بالمتطلبات المنصوص عليها في قانون تقنية المعلومات لعام 2000 م والقواعد الموضوعة بموجبه في حالة وجود مخاطر تتعلق بالبيانات أو الأمان.`
          : `(c) We will comply with the requirements under the Information
        Technology Act, 2000 and the rules made thereunder in the event of a
        data or security risk.`}
        <br />
      </Content>
      <Title>{isArabic ? `الاحتفاظ بالبيانات` : `9. DATA RETENTION `}</Title>
      <Content>
        {isArabic
          ? `إنك تدرك أننا سنستمر في تخزين والاحتفاظ بياناتك الشخصية من قبلنا لفترة معقولة بعد التوقف عن استخدام الخدمات.`
          : `You are aware that your personal data will continue to be stored and
        retained by us for a reasonable period after you cease to use the
        Services.`}
      </Content>
      <Title>
        {isArabic ? `سياسة حذف البيانات` : `10. Data Deletion Policy`}
      </Title>
      <Content>
        {isArabic
          ? `توفر شركة كوينتايب تكنولوجيز الهند الخاصة المحدودة للمستخدمين تحكمًا كاملاً في البيانات الخاصة بهم، بما في ذلك البيانات التي يتم الاحتفاظ بها والبيانات التي يتم حذفها.`
          : ` 1. Quintype provides users with full control over their data, including
        what data is retained and what data is deleted.`}
      </Content>
      <Content>
        {isArabic
          ? `يمكن للمستخدمين النهائيين طلب حذف بياناتهم وحساباتهم عن طريق إرسال بريد إلكتروني إلى support@quintype.com وسيتم معالجة الطلب في غضون يومي عمل.`
          : ` 2.End users can request their data and account to be deleted by sending
        an email to support@quintype.com. The request will be processed within 2
        working days.`}
      </Content>
      <Content>
        {isArabic
          ? `يمكن لعملاء الشركة أو المؤسسة طلب حذف البيانات لجميع المستخدمين أو بعضهم. يمكن بدء الطلب عن طريق إرسال بريد إلكتروني إلى عنوان البريد الإلكتروني المختص وهو: support@quintype.com مع تفاصيل طلب حذف البيانات (نوع البيانات المطلوب حذفها والمستخدمون المطلوب حذفهم والإطار الزمني للحذف، وما إلى ذلك).`
          : `3.Enterprise customers can request data to be deleted for all or some of
        their users. The request can be initiated by sending an email to
        support@quintype.com with details of the data deletion request (type of
        data to delete, users to delete, time frame of deletion, etc).`}
      </Content>
      <Title>
        {isArabic ? `نقل أو تحويل الأعمال` : `11. BUSINESS TRANSITIONS`}{" "}
      </Title>
      <Content>
        {isArabic
          ? `أنت على علم بأنه في حال مررنا بمرحلة انتقالية في العمل، مثل الاندماج أو الاستحواذ من قبل مؤسسة أخرى أو بيع كل أو جزء من أصولنا، فقد تكون بياناتك الشخصية من بين الأصول المنقولة.`
          : `You are aware that in the event we go through a business transition,
        such as a merger, acquisition by another organisation, or sale of all or
        a portion of our assets, your personal data might be among the assets
        transferred.`}
      </Content>
      <Title>
        {isArabic
          ? `التغيير التي قد تطرأ على سياسة الخصوصية`
          : `12. CHANGE IN PRIVACY POLICY`}{" "}
      </Title>
      <Content>
        {isArabic
          ? `نحافظ على سياستنا قيد المراجعة المنتظمة والمستمرة وقد نقوم بتعديل هذه السياسة من وقت لآخر وفقًا لتقديرنا الخاص.`
          : `(a) We keep our Policy under regular review and may amend this Policy
        from time to time, at our sole discretion.`}
        <br />
        {isArabic
          ? `قد تتغير شروط هذه السياسة من وقت لأخر، وإذا حدث ذلك، فسيتم نشر هذه التغييرات على هذه الصفحة وعند الاقتضاء، سيتم إخطارك عبر البريد الإلكتروني. علاوة على ذلك، قد يتم عرض السياسة الجديدة على الشاشة وقد يُطلب منك قراءة التغييرات وقبولها لمواصلة استخدامك للخدمات.`
          : `(b) The terms of this Policy may change and if it does, these changes
        will be posted on this page and, where appropriate, notified to you by
        email. The new Policy may be displayed on-screen and you may be required
        to read and accept the changes to continue your use of the Services.`}
        <br />
      </Content>
      <Title>{isArabic ? `مسؤول التظلمات ` : `13. GRIEVANCE OFFICER`} </Title>
      <Content>
        {isArabic
          ? `يمكنك الاتصال بنا من خلال عنوان البريد الإلكتروني التالي [dpo@quintype.com] بخصوص أي استفسار يتعلق بهذه السياسة أو استفسار يتعلق بمعلوماتك الشخصية (بما في ذلك المراجعة أو التحديث). يمكنك أيضًا القيام بذلك عن طريق الكتابة إلى مسؤول التظلمات لدينا والذي تم إنشاؤه وفقًا لقانون تكنولوجيا المعلومات لعام 2000 م والقواعد الصادرة بموجبه، على العنوان الوارد أدناه:`
          : `You may contact us at [dpo@quintype.com] with any enquiry relating to
        this Policy or an enquiry relating to your personal information
        (including reviewing or updating). You can also do so by writing to our
        Grievance Officer, established in accordance with the Information
        Technology Act, 2000 and rules made thereunder, at the address provided
        below:`}
        <br />
        {isArabic ? `الاسم: [رشمي ميتال]` : `Name: [Rashmi Mittal]`} <br />
        {isArabic
          ? `العنوان: [رقم 29، الطابق رقم 3، طريق المطار القديم، مقابل مجمع كيمبفورت، حي موروجيشباليا، مدينة بنغالور - 0560 017.]`
          : `Address: [#29, 3rd Floor, Old Airport Road, Opp Kempfort Mall,
        Murugeshpalya, Bangalore – 560 017.]`}
        <br />
        {isArabic
          ? `عنوان البريد الإلكتروني: [dpo@quintype.com]`
          : `Email Address: [dpo@quintype.com]`}{" "}
        <br />
      </Content>
    </PrivacyWrapper>
  );
}

export default PrivacyPolicy;
