import styled from "styled-components";

export const ComparisonWrapper = styled.div`
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    max-width: ${props => props.theme.containerWidth};
    margin: 20px auto;
  }
`;

export const Heading = styled.h1`
  font-family: ${props => props.theme.fontFamily.primary};
  font-size: ${props => props.theme.fontSize.xxxl};
  color: ${props => props.theme.color.gray2};
  font-weight: ${props => props.theme.fontWeight.bold};
  text-align: center;
  margin: 3px;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    font-size: ${props => props.theme.fontSize.giga};
  }
`;

export const Title = styled.h2`
  font-family: ${props => props.theme.fontFamily.primary};
  font-size: ${props => props.theme.fontSize.xl};
  color: ${props => props.theme.color.gray2};
  text-align: center;
  margin: 50px 90px;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    font-size: ${props => props.theme.fontSize.xxxl};
  }
`;

export const Table = styled.div`
  border-collapse: collapse;
  border: solid 1px transparent;
`;

export const TableHead = styled.th`
  color: ${props => props.theme.color.gray2};
  border: 1px solid ${props => props.theme.color.gray6};
  background: ${props => props.theme.color.white};
  padding: 0 50px;
  font-family: ${props => props.theme.fontFamily.primary};
  font-size: ${props => props.theme.fontSize.xs};
  :nth-child(-n + 2) {
    color: ${props => props.theme.color.green1};
  }
`;

export const TableData = styled.td`
  border: 1px solid ${props => props.theme.color.gray6};
  background: ${props => props.theme.color.white};
  color: ${props => props.theme.color.gray2};
  text-align: center;
  padding: 5px 8px;
  font-family: ${props => props.theme.fontFamily.primary};
  font-size: ${props => props.theme.fontSize.xs};
  font-weight: ${props => props.theme.fontWeight.bold};
`;

export const Image = styled.img`
  width: 20px;
  height: 20px;
  text-align: center;
`;
export const ComparisionTable = styled.div`
  width: 100%;
  overflow: scroll;
`;

export const MonetizationTable = styled(ComparisionTable)`
  margin-bottom: 40px;
`;

export const Link = styled.a`
  font-family: ${props => props.theme.fontFamily.primary};
  font-size: ${props => props.theme.fontSize.s};
  font-weight: ${props => props.theme.fontWeight.bold};
  text-decoration: underline;
  color: ${props => props.theme.color.green1};
`;

export const Text = styled.span`
  font-family: ${props => props.theme.fontFamily.primary};
  font-size: ${props => props.theme.fontSize.s};
  font-weight: ${props => props.theme.fontWeight.bold};
  color: ${props => props.theme.color.gray2};
  padding: 10px;
`;
