import styled from "styled-components";

export const Title = styled.h3`
  font-family: ${props => props.theme.fontFamily.primary};
  font-weight: ${props => props.theme.fontWeight.bold};
  color: ${props => props.theme.color.typeRegular};
  line-height: ${props => props.theme.lineHeight.lh4};
  font-size: ${props => props.theme.fontSize.m};
  margin:0;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    font-size: ${props => props.theme.fontSize.l};
  }
`;
