import styled from "styled-components";

export const PrivacyWrapper = styled.div`
  padding: 0 24px;
  margin: 0 auto;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    max-width: ${props => props.theme.containerWidth};
    padding: 0;
  }
`;

export const Heading = styled.h1`
  font-family: ${props => props.theme.fontFamily.primary};
  font-size: ${props => props.theme.fontSize.m};
  text-transform: uppercase;
  margin: 25px 0;
  text-align: center;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    font-size: ${props => props.theme.fontSize.l};
  }
`;

export const SubHeading = styled.h3`
  font-family: ${props => props.theme.fontFamily.primary};
  font-size: ${props => props.theme.fontSize.m};
  margin: 25px 0;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    font-size: ${props => props.theme.fontSize.l};
  }
`;

export const Title = styled.h4`
  font-family: ${props => props.theme.fontFamily.primary};
  font-size: ${props => props.theme.fontSize.m};
  text-transform: uppercase;
  margin: 25px 0;
  text-align: center;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    font-size: ${props => props.theme.fontSize.l};
  }
`;

export const Content = styled.p`
  font-family: ${props => props.theme.fontFamily.primary};
  font-size: ${props => props.theme.fontSize.xs};
  line-height: ${props => props.theme.lineHeight.lh6};
  margin: 0 0 30px 0;
  color: ${props => props.theme.color.black};
`;

export const PrivacyPolicyList = styled.ul`
  padding: 0 10px;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    padding: 0 0 0 40px;
    margin: 16px 0;
  }
`;

export const PrivacyPolicyItem = styled.li`
  font-family: ${props => props.theme.fontFamily.primary};
  font-size: ${props => props.theme.fontSize.xs};
  line-height: ${props => props.theme.lineHeight.lh6};
  color: ${props => props.theme.color.black};
`;
