import React from "react";

import {
  ImageContainer,
  MainContainer,
  InnerContainer,
  HeadlineContainer,
  TextContainer,
  CaseStudyWrapper,
  CaseStudyItemInternal,
  CaseStudyItemExternal,
  SubHeadlineContainer,
  Image,
  Title
} from "./style";

import Para from "../shared/para-reg/index";
import H1 from '../shared/h1/index'
import H3 from '../shared/h3/index'


function CaseStudy({ data }) {
  const { headline, text, bgUrl, items } = data;
  return (
    <React.Fragment>
      <ImageContainer bgUrl={bgUrl}></ImageContainer>
      <MainContainer>
        <InnerContainer>
          <HeadlineContainer><H1>{headline}</H1></HeadlineContainer>
 { data.subheadline && <SubHeadlineContainer><H3>{data.subheadline}</H3></SubHeadlineContainer>}
          <TextContainer>
            <Para>{text}</Para>
          </TextContainer>
          <CaseStudyWrapper>
            {items.map((item, index) => {
              if (item.linkType === "internal")
                return (
                  <CaseStudyItemInternal to={item.link} key={index}>
                    <Image src={item.url} alt={item.alt}></Image>
                    <Title>{item.title}</Title>
                  </CaseStudyItemInternal>
                );
              else if (item.linkType === "external")
                return (
                  <CaseStudyItemExternal
                    target="_blank"
                    href={item.link}
                    key={index}
                  >
                    <Image src={item.url} alt={item.alt}></Image>
                    <Title>{item.title}</Title>
                  </CaseStudyItemExternal>
                );
              else return <></>
            })}
          </CaseStudyWrapper>
        </InnerContainer>
      </MainContainer>
    </React.Fragment>
  );
}

export default CaseStudy;
