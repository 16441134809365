import React from "react";
import PropTypes from "prop-types";

import PrimaryButton from "../../../shared/primaryButton";

import {
  MainContainer,
  HeadlineContainer,
  Description,
  InnerContainer,
  Plan,
  Title,
  FeatureWrapper,
  Feature,
  PricingHeadline,
  ButtonWrapper,
  Icon,
  IconContainer
} from "./style";

import H2 from "../../../shared/h2/index";
import Para from "../../../shared/para-alt/index";

function Pricing({ data }) {
  return (
    <MainContainer>
      <HeadlineContainer>
        <H2>{data.headline}</H2>
      </HeadlineContainer>
      <Description>
        <Para>{data.description}</Para>
      </Description>
      <InnerContainer>
        {data.plans.map((item, index) => {
          return (
            <Plan key={index}>
              <IconContainer>
                <Icon src={item.icon.url} alt={item.icon.description} />
              </IconContainer>{" "}
              <PricingHeadline> {item.title}</PricingHeadline>
              <Title>{item.plan}</Title>
              <FeatureWrapper>
                {item.features.map((feature, index) => {
                  return <Feature key={index}>{feature}</Feature>;
                })}
              </FeatureWrapper>
            </Plan>
          );
        })}
      </InnerContainer>
      <ButtonWrapper>
        <PrimaryButton
          link={data["read-more"].link}
          title={data["read-more"].title}
        >
          {data["read-more"].text}
        </PrimaryButton>
      </ButtonWrapper>
    </MainContainer>
  );
}

Pricing.propTypes = {
  data: PropTypes.shape({
    headline: PropTypes.string,
    description: PropTypes.string,
    plans: PropTypes.array,
    "read-more": PropTypes.shape({
      link: PropTypes.string,
      title: PropTypes.string,
      text: PropTypes.string
    })
  })
};

export default Pricing;
