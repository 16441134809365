import React from "react";
import PropTypes from "prop-types";

import {
  MainContainer,
  InnerContiner,
  Content,
  ReviewItem,
  Designation,
  ImageContainer,
  Image,
  FeatureHeadline,
  FeatureContent
} from "./style";

function ClientReview({ data }) {
  return (
    <MainContainer>
      <InnerContiner>
        <ReviewItem>
          <Content>{data.content}</Content>
          <Designation>{data.designation}</Designation>
          <ImageContainer>
            <Image src={data["image-url"]} alt={data.alt}></Image>
          </ImageContainer>
        </ReviewItem>
        <ReviewItem>
          {data.features.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <FeatureHeadline>{item.headline}</FeatureHeadline>
                <FeatureContent>{item.description}</FeatureContent>
              </React.Fragment>
            );
          })}
        </ReviewItem>
      </InnerContiner>
    </MainContainer>
  );
}

ClientReview.propTypes = {
  data: PropTypes.shape({
    headline: PropTypes.string,
    content: PropTypes.string,
    designation: PropTypes.string,
    "image-url": PropTypes.string,
    alt: PropTypes.string,
    features: PropTypes.array
  })
};

export default ClientReview;
