import React from "react";
import { useSelector } from "react-redux";
import {
  MainHeading,
  TermsWrapper,
  Heading,
  LastUpdated,
  Content,
  TermsCondition,
  TermsConditionList,
  SubHeading,
  IncludeFeatures,
  PlatformFeatures,
  ListOfFeatures,
  FeaturesWrapper,
  TableWrapper,
  Table,
  TableRow,
  TableHeading,
  TableData,
} from "./style";

function TermsAndConditions() {
  const isArabic = useSelector((state) => state);
  return (
    <div dir={isArabic ? "rtl" : "ltr"} lang={isArabic ? `ar` : `en`}>
      <TermsWrapper>
        <MainHeading isArabic={isArabic}>
          {isArabic ? `الشروط والأحكام ` : `TERMS AND CONDITIONS`}
        </MainHeading>
        <LastUpdated isArabic={isArabic}>
          {isArabic
            ? `آخر تحديث: [10 يونيو 2020]`
            : `LAST UPDATED: [10th June 2020]`}
        </LastUpdated>
        <Content isArabic={isArabic}>
          {isArabic
            ? `تحكم هذه الشروط والأحكام ("الشروط") استخدام www.quintype.com والخدمات (المحددة أدناه). تتضمن هذه الشروط أيضًا سياسة خصوصية كوينتايب، المتوفرة على [https://www.quintype.com/privacy-policy] ("سياسة الخصوصية")، وأي إرشادات أو شروط أو سياسات أو إخلاء مسؤولية إضافية متاحة أو صادرة عن كوينتايب من من وقت إلى آخر.`
            : `These terms and conditions (“Terms”) govern the use of www.quintype.com
        and the Services (defined below). These Terms also include Quintype’s
        privacy policy, available at [https://www.quintype.com/privacy-policy]
        (“Privacy Policy”), and any guidelines, additional terms, policies, or
        disclaimers made available or issued by Quintype from time to time.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `تشكل هذه الشروط عقدًا قانونيًا ملزمًا وقابل للتنفيذ بين شركة كوينتايب للتكنولوجيات الهندية الخاصة المحدودة والشركات التابعة لها والشركات التابعة لها في جميع أنحاء العالم ("كوينتايب") وأنت (وهذا المصطلح يشمل العملاء والمسؤولين والمستخدمين المعتمدين وأي مستخدم نهائي آخر للخدمات) في فيما له صلة بالخدمات.`
            : `These Terms constitute a binding and enforceable legal contract between
        Quintype Technologies India Private Limited and its affiliates and
        subsidiaries worldwide (“Quintype”) and you (which term shall include
        Customers, Administrators, Authorised Users, and any other end user of
        the Services) in relation to the Services.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `باستخدام الخدمات، فإنك توافق على أنك قد قرأت هذه الشروط وفهمتها والالتزام بها بصيغتها المعدلة من وقت لآخر، وأنك تمتثل للمتطلبات المدرجة هنا. إذا كنت لا توافق على جميع هذه الشروط أو تمتثل للمتطلبات الواردة هنا، فيرجى عدم الوصول إلى الخدمات أو استخدامها.`
            : `By using the Services, you agree that you have read, understood, and to
        be bound by these Terms as amended from time to time, and that you
        comply with the requirements listed herein. If you do not agree to all
        of these Terms or comply with the requirements herein, please do not
        access or use the Services.`}
        </Content>
        <Heading isArabic={isArabic}>
          {isArabic ? `1. الخدمات` : `1. SERVICES`}
        </Heading>
        <Content isArabic={isArabic}>
          {isArabic
            ? `تزود كوينتايب الفرق التعاونية نظامًا أساسيًا ("النظام الأساسي") يساعد في نشر المحتوى الرقمي، والمحركات المخصصة لتتبع تفاعل المستخدم وتوفير التحليلات في الوقت الفعلي، وميزات قابلية التوسع لدفع النمو والاعتماد، وهندسة المكونات الإضافية للتفاعل مع المستخدمين النهائيين. يتم توفير هذه الخدمات من خلال النظام الأساسي والمنتجات والتطبيقات والمكونات الإضافية (يشار إليها مجتمعة باسم "الخدمات"، ويتم تفصيلها بشكل كامل في الملحق أ). تشمل الخدمات الإصدارات التجريبية المجانية والخدمات التجريبية التي قد توفرها كوينتايب من وقت لآخر.`
            : `Quintype provides collaborative teams with a platform (“Platform”) that
        assists with publishing digital content, personalised engines to track
        user engagement and provide realtime analytics, scalability features to
        drive growth and adoption, and plug-in architecture to engage with end
        users. These services are provided through the Platform, products,
        applications, and plug-ins (collectively referred to as “Services”, and
        are more fully detailed in Annexure A). The Services include free trials
        and beta services that Quintype may make available from time to time.`}
          <br /> <br />
          {isArabic
            ? `لاستخدام الخدمات، يجب على أي شخص أو كيان أو مؤسسة ("العميل") اختيار خطة اشتراك للخدمات التي يرغب في الاستفادة منها ("خطة الاشتراك") ودفع الرسوم المطبقة وفقًا لهذه الشروط وتعليمات كوينتايب. ففي بعض الحالات، قد تطلب كوينتايب، وفقًا لتقديرها الخاص، من العميل الدخول في اتفاقية مكتوبة منفصلة ("بيان العمل") لأنواع معينة من الخدمات التي ترغب في الاستفادة منها من كوينتايب . في مثل هذه الحالات، بالإضافة إلى هذه الشروط، تنطبق شروط "بيان العمل" على تقديم الخدمات.`
            : `To use the Services, a person, entity, or organization (“Customer”) will
        have to choose a subscription plan for the Services it wishes to avail
        (“Subscription Plan”) and pay the applicable fees in accordance with
        these Terms and Quintype’s instructions. In some cases, Quintype may, at
        its sole discretion, require a Customer to enter into a separate written
        agreement (“Statement of Work”) for certain types of Services it wishes
        to avail from Quintype. In such cases, in addition to these Terms, the
        terms of the Statement of Work shall apply to the provision of Services.`}
        </Content>
        <Heading isArabic={isArabic}>
          {isArabic ? `2. حسابات المستخدم` : `2. USER ACCOUNTS`}
        </Heading>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(أ) سيتعين على العميل إنشاء حساب وتشغيله على المنصة ("حساب المستخدم"). يطلب من العملاء تقديم معلومات كاملة ودقيقة إلى كوينتايب أثناء إنشاء حسابات المستخدمين.`
            : `(a) A Customer will have to create and operate an account on the
        Platform (“User Account”). Customers are required to provide complete
        and accurate information to Quintype while creating User Accounts.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(ب) يجوز للعميل تعيين فرد واحد أو أكثر كـ "مسؤولين". سيكون المسؤولون مسؤولين عن تشغيل حساب المستخدم. من خلال حساب المستخدم، يجوز للعميل تعيين مسؤولين جدد أو تغيير تفاصيل المسؤولين الحاليين.`
            : `(b) A Customer may designate one or more individuals as
        “Administrators”. Administrators will be responsible for the operation
        of a User Account. Through the User Account, a Customer may appoint new
        Administrators or change details of existing Administrators.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(ج) يحق للمسؤولين إنشاء ملفات تعريف أو منح المستخدمين المصرح لهم باستخدام الخدمات من قبل العميل ("المستخدمون المعتمدون") الوصول إلى النظام الأساسي. يجب على المسؤولين التأكد من امتثال المستخدمين المعتمدين لهذه الشروط.`
            : `(c) Administrators shall have the right to create profiles or grant
        users authorised to use the Services by the Customer (“Authorised
        Users”) access to the Platform. Administrators shall ensure the
        Authorised Users comply with these Terms.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(د) بصفتك مستخدمًا معتمدًا، ( 1 ) فأنت تدخل في هذه الشروط نيابة عن العميل، (2) يكون حسابك مع كوينتايب لصالح العميل ومحتفظ به باسمه (وليس أي فرد)، و (3) لديك الأهلية القانونية الكاملة والسلطة لإلزام نفسك والعميل بهذه الشروط.`
            : `(d) As an Authorised User, (i) you are entering into these Terms on
        behalf of the Customer, (ii) your account with Quintype is for the
        benefit of and held in the name of the Customer (and not any
        individual), and (iii) you have full legal capacity and authority to
        bind yourself and the Customer to these Terms.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(هـ) يجب على العميل التأكد من ( 1 ) محافظة المستخدمين المعتمدين على سرية حسابات المستخدمين وجميع الحسابات بموجبها، بما في ذلك كلمات المرور وأسماء المستخدمين لحسابات المستخدمين أو الحسابات التي تم إنشاؤها للمستخدمين المصرح لهم و (2) جميع الأنشطة التي تحدث في أو من خلال حساب المستخدم قانوني ومتوافق مع هذه الشروط.`
            : `(e) The Customer shall ensure that (i) Authorised Users maintain the
        confidentiality of the User Accounts and all accounts thereunder,
        including passwords and usernames for the User Accounts or accounts
        created for Authorised Users and (ii) all activities that occur on or
        through the User Account are legal and consistent with these Terms.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(و) يكون العميل مسؤولاً ومسؤولاً عن جميع الأنشطة التي تحدث من خلال حسابات المستخدمين أو أي حسابات بموجبها. كوينتايب ليست مسؤولة عن أي وصول غير مصرح به إلى الخدمات، بما في ذلك على سبيل المثال لا الحصر القرصنة والانتهاكات الأمنية. تحتفظ كوينتايب بالحق، وفقًا لتقديرها الخاص، في تعليق حساب المستخدم إذا كان من رأيها أن حساب المستخدم أو أي حسابات بموجبه يتم الوصول إليها من قبل شخص أو أكثر من الأشخاص أو الكيانات غير المصرح لهم.`
            : `(f) The Customer is liable and accountable for all activities that occur
        through the User Accounts or any accounts thereunder. Quintype is not
        liable for any unauthorised access to the Services, including but not
        limited to hacking and security breaches. Quintype reserves the right,
        at its sole discretion, to suspend a User Account if it is of the
        opinion that the User Account or any accounts thereunder are being
        accessed by one or more unauthorised persons or entities.`}
        </Content>
        <Heading isArabic={isArabic}>
          {isArabic ? `3. محتوى المستخدم` : `3. USER CONTENT`}
        </Heading>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(أ) تتيح لك الخدمات إنشاء المواد والمحتوى وتخزينهما ونشرهما وعرضهما وتحليلهما، بما في ذلك، على سبيل المثال لا الحصر، النصوص والصور ومقاطع الفيديو والأصوات والرموز والعناصر الأخرى ("محتوى المستخدم"). أنت بموجب هذا تمنح كوينتايب ترخيصًا غير حصري عالميًا وقابل للترخيص من الباطن الترخيص أثناء استمرار مشاركتك مع كوينتايب لاستخدام محتوى المستخدم (وإنشاء أعمال مشتقة منه) فقط لغرض تقديم محتوى المستخدم وإتاحته للوصول.`
            : `(a) The Services allow you to create, store, publish, display, and
        analyse material and content, which includes, without limitation, text,
        photos, videos, sounds, codes, and other items (“User Content”). You
        hereby grant Quintype a non-exclusive, worldwide and sublicensable
        licence during the subsistence of your engagement with Quintype to use
        the User Content (and create derivative works thereof) solely for the
        purpose of presenting the User Content and making it available for
        access.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(ب) أنت تمنح كوينتايب أيضًا ترخيصًا غير حصري وعالميًا وقابل للترخيص من الباطن وخاليًا من حقوق الملكية أثناء استمرار مشاركتك مع كوينتايب لاستخدام محتوى المستخدم للأغراض المحدودة للإعلان عن الخدمات والترويج لها؛ شريطة أن تستخدم كوينتايب محتوى المستخدم بموجب هذا القسم 3(ب) فقط بموافقتك الخطية المسبقة (للتوضيح، تكفي الموافقة المقدمة عبر البريد الإلكتروني).`
            : `(b) You further grant Quintype a non-exclusive, worldwide,
        sublicensable, and royalty-free licence during the subsistence of your
        engagement with Quintype to use User Content for the limited purposes of
        advertising and promoting the Services; provided that Quintype shall use
        the User Content under this Section 3(b) only with your prior written
        consent (to clarify, consent provided through email shall suffice).`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(ج) فيما يتعلق بهذه الشروط والتراخيص الممنوحة بموجب هذا البند 3، فإنك تتنازل بموجب هذا عن أي مطالبات ضد كوينتايب والتي تنشأ عن أي حقوق معنوية أو حقوق أخرى مماثلة تتعلق بمحتوى المستخدم.`
            : `(c) In connection with these Terms and the licences granted under this
        Clause 3, you hereby waive any claims against Quintype arising out of
        any moral rights or other similar rights relating to the User Content.`}
        </Content>
        <Heading isArabic={isArabic}>
          {isArabic ? `4. مسؤولياتك` : `4. YOUR RESPONSIBILITIES`}
        </Heading>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(أ) تقر وتتعهد بما يلي:`
            : `(a) You represent and warrant that: `}
          <br />
          {isArabic
            ? `( 1 ) أنك تمتلك جميع حقوق الملكية الفكرية (أو حصلت على جميع الأذونات اللازمة) لتوفير محتوى المستخدم ومنح التراخيص بموجب هذه الشروط ؛`
            : `(i) you own all intellectual property rights (or have obtained all
        necessary permissions) to provide User Content and to grant the licences
        under these Terms;`}
          <br />
          {isArabic
            ? `(2) أنت وحدك المسؤول عن جميع الأنشطة التي تحدث في حسابك أو من خلاله على المنصة وجميع محتوى المستخدم ؛`
            : ` (ii) you are solely responsible for all activities that occur on or
        through your account on the Platform and all User Content;`}
          <br />
          {isArabic
            ? `(3) محتوى المستخدم لا ولن ينتهك أيًا من التزاماتك أو مسؤولياتك بموجب الاتفاقيات الأخرى ؛`
            : `(iii) the User Content does not and shall not violate any of your
        obligations or responsibilities under other agreements;`}
          <br />
          {isArabic
            ? `(4) محتوى المستخدم لا ينتهك أو ينتهك أو يختلس أي حق من حقوق الملكية الفكرية أو حقوق الملكية الأخرى، بما في ذلك الحق في الدعاية أو الخصوصية لأي شخص أو كيان ؛`
            : ` (iv) the User Content does not and shall not violate, infringe, or
        misappropriate any intellectual property right or other proprietary
        right, including the right of publicity or privacy, of any person or
        entity;`}
          <br />
          {isArabic
            ? `(5) لا يحتوي محتوى المستخدم ولن يحتوي على أي فيروسات أو بيانات تالفة أو غيرها من الملفات أو المحتويات الضارة أو التخريبية أو المدمرة ؛`
            : `(v) the User Content does not and shall not contain any viruses,
        corrupted data, or other harmful, disruptive, or destructive files or
        content;`}
          <br />
          {isArabic
            ? `(6) يجب ألا ينتهك محتوى المستخدم أي حقوق لطرف ثالث؛ و`
            : `(vi) the User Content shall not violate any third party rights; and`}
          <br />
          {isArabic
            ? `(7) لا يجوز لك نشر أو إتاحة محتوى مستخدم مرفوض أو يقيد أو يمنع أي شخص آخر من استخدام الخدمات أو الاستمتاع بها، أو قد يعرض كوينتايب أو الآخرين لأي ضرر أو مسؤولية من أي نوع.`
            : `(vii) you shall not publish or make available User Content that is
        objectionable or restricts or inhibits any other person from using or
        enjoying the Services, or that may expose Quintype or others to any harm
        or liability of any type.`}
          <br />
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(ب) لا يجوز لك استخدام الخدمات بأي طريقة باستثناء ما هو مسموح به صراحةً في هذه الشروط. بدون الحد من عمومية الجملة السابقة، لا يجوز لك:`
            : `(b) You shall not use the Services in any manner except as expressly
        permitted in these Terms. Without limiting the generality of the
        preceding sentence, you may not: `}
          <br />
          {isArabic
            ? `( 1) انتهاك أي حقوق ملكية، بما في ذلك على سبيل المثال لا الحصر حقوق النشر أو براءات الاختراع أو العلامات التجارية أو الأسرار التجارية لأي طرف ؛`
            : ` (i) infringe any proprietary rights, including but not limited to
        copyrights, patents, trademarks, or trade secrets, of any party;`}
          <br />
          {isArabic
            ? `(2) باستثناء ما هو منصوص عليه أدناه، نسخ أو عرض أو توزيع أو تعديل أو نشر أو إعادة إنتاج أو تخزين أو إرسال أو نشر أو ترجمة أو إنشاء أي أعمال مشتقة أو تأجير أو ترخيص المنصة أو أي جزء منها أو أي خدمات أخرى المقدمة بموجب هذه الشروط ؛`
            : `(ii) except as may be provided hereunder, copy, display, distribute,
        modify, publish, reproduce, store, transmit, post, translate, create any
        derivative works, rent, or license the Platform or any portion thereof,
        or any other Services offered under these Terms;`}
          <br />
          {isArabic
            ? `(3) استخدام الخدمات لنقل أي بيانات أو إرسال أو تحميل أي مواد تحتوي على فيروسات أو فيروسات أحصنة طروادة أو ديدان أو قنابل موقوتة أو مسجلات ضغطات المفاتيح أو برامج تجسس أو برامج إعلانية أو أي برامج ضارة أخرى أو رمز كمبيوتر مشابه مصمم للتأثير سلبًا على تشغيل أي برنامج أو جهاز كمبيوتر ؛`
            : `(iii) use the Services to transmit any data or send or upload any
        material that contains viruses, Trojan horses, worms, time-bombs,
        keystroke loggers, spyware, adware, or any other harmful programmes or
        similar computer code designed to adversely affect the operation of any
        computer software or hardware;`}
          <br />
          {isArabic
            ? `(4) استخدام أي روبوت أو عنكبوت أو أي جهاز آلي آخر أو عملية يدوية لمراقبة أو نسخ الخدمات أو أي جزء منها بخلاف النسخ أو التصدير على النحو الذي تسمح به الشروط ؛`
            : `(iv) use any robot, spider, other automated device, or manual process to
        monitor or copy the Services or any portion thereof other than copying
        or exporting as permitted by the Terms;`}
          <br />
          {isArabic
            ? `(5) عمل أي نسخ احتياطية أو أرشيفية للمنصة أو أي جزء منها ؛`
            : `(v) make any back-up or archival copies of the Platform or any part
        thereof;`}
          <br />
          {isArabic
            ? `(6) استخدام المنصة (أ) بأي طريقة غير قانونية، (ب) للأنشطة الاحتيالية أو الخبيثة، أو (ج) بأي طريقة لا تتوافق مع هذه الشروط ؛ أو`
            : `(vi) use the Platform in (A) any unlawful manner, (B) for fraudulent or
        malicious activities, or (C) in any manner inconsistent with these
        Terms; or `}
          <br />
          {isArabic
            ? `(7) تنتهك القوانين المعمول بها بأي شكل من الأشكال.`
            : `(vii) violate applicable laws in any manner. `}
          <br />
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(ج) سوف تقوم بإسداء كل التعاون الممكن في إعداد الخدمات كما قد تطلبه كوينتايب بشكل معقول من وقت لآخر.`
            : `(c) You will extend all possible co-operation in setting up the Services
        as may be reasonably requested by Quintype from time to time.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(د) يجب عليك الحصول على جميع أجهزة الكمبيوتر والبرامج وأجهزة المودم والاتصالات بشبكة الإنترنت والعناصر الأخرى المطلوبة للوصول إلى الإنترنت واستخدام الخدمات من قِبلك وموقعك الإلكتروني وعملك التجاري والمستخدمون المعتمدون والوكلاء والموظفون.`
            : `(d) You shall obtain and maintain at your own expense all the necessary
        computer hardware, software, modems, connections to the Internet, and
        other items required for the access to the internet, and use of the
        Services by you, your website, business, Authorised Users, and agents
        and employees.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(هـ) أنت توافق على ترقية منشآتك وأنظمتك من أجل الوصول السلس إلى النظام الأساسي عند الاقتضاء حتى تكون متوافقة مع الإصدار المحدث من الخدمات، إن وجد.`
            : ` (e) You agree to upgrade your facilities and systems for the smooth
        access of the Platform as and when required in order to be compatible
        with the upgraded version of the Services, if any.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(و) توفر كوينتايب جميع واجهات برمجة التطبيقات والأدوات والمرافق الأخرى كما هو مطلوب لتمكينك من إجراء تغييرات طفيفة أو تعديل الوظائف الثانوية إلى الواجهة الأمامية لنظامك، عند الاقتضاء. يجب عليك إبقاء كوينتايب على علم بجميع هذه التغييرات المخطط لها مسبقًا، قبل بدء عملية التطوير.`
            : `(f) Quintype shall make available all APIs, tools, and other such
        facilities as required to enable you to make minor changes, or modify
        the minor functionality to the front end of your system, as and when
        required. You shall keep Quintype informed of all such planned changes
        in advance, before starting the development process.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(ز) بينما تبذل كوينتايب جهودًا تجارية معقولة لتزويدك بنسخة احتياطية يومية من كل محتوى المستخدم في تنسيقات قابلة للتبديل بين البيانات المتوافقة مع معايير الصناعة، يجب عليك حفظ محتوى المستخدم الخاص بك ونسخه احتياطيًا وأرشفته بشكل منتظم ومستقل وأي معلومات قد تنشئها أو العملية المتعلقة باستخدامك للخدمات. وفقًا للمادة 7، سيتم نقل أي نسخ احتياطية تم إنشاؤها بواسطة كوينتايب إلى بياناتك المستضافة من خلال خدمات ويب أمازون، التي تقدمها شركة أمازون والشركات التابعة لها.`
            : `(g) While Quintype uses reasonable commercial efforts to provide you
        with a daily backup of all User Content in industry-standard data
        interchangeable formats, you shall regularly and independently save,
        backup, and archive your User Content and any all information that you
        may create or process in connection with your use of the Services.
        Subject to Clause 7, any backups created by Quintype shall be
        transferred to your data that is hosted through Amazon Web Services,
        offered by Amazon Inc. and its affiliates.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(ح) يجب عليك إبلاغ كوينتايب على الفور بأي منتجات أو خدمات جديدة تنوي تقديمها لعملائك النهائيين من خلال الخدمات، أو بأي تغييرات على المنتجات أو الخدمات الحالية أو محتوى المستخدم، والتي قد تؤثر على الخدمات. في مثل هذه الحالات، يجب عليك إبلاغ كوينتايب بأي تغييرات من هذا القبيل. تحتفظ كوينتايب بالحق في تعديل الشروط التي يتم بموجبها تقديم الخدمات لك في حالة حدوث مثل هذه التغييرات.`
            : ` (h) You shall promptly inform Quintype of any new products or services
        you intend to introduce to your end customers through the Services, or
        of any changes to existing products or services or User Content, that
        may affect the Services. In such cases, you should inform Quintype of
        any such changes. Quintype reserves the right to modify the terms on
        which the Services are offered to you in the event of such changes.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `( ط ) يجب عليك التعاون مع كوينتايب في تطوير ومشاركة الشهادات ودراسات الحالة مجهولة المصدر والمواد التسويقية وحسابات العائد على الاستثمار ومعايير القياس للقيمة والفوائد والتوفير في التكاليف المستمدة من الخدمات. أنت توافق على إتاحة كل هذه المحتويات إلى كوينتايب متى طلبت منها كوينتايب.`
            : ` (i) You shall cooperate with Quintype in developing and sharing
        testimonials, anonymous case studies, marketing materials,
        return-on-investment calculations, and measurement criteria for the
        value, benefits, and cost savings derived from the Services. You agree
        to make available all such content to Quintype as and when requested by
        Quintype.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(ي) أنت على دراية بالقوانين واللوائح المعمول بها التي تحكم استخدامك للخدمات. ستكون مسؤولاً بمفردك عن ضمان الامتثال لمختلف القوانين المعمول بها، وستكون مسؤولاً بمفردك عن أي مسؤولية قد تنشأ بسبب خرق التزاماتك في هذا الصدد.`
            : `(j) You are aware of applicable laws and regulations governing your use
        of the Services. You shall be solely responsible for ensuring compliance
        with the various applicable laws, and you shall be solely liable for any
        liability that may arise due to a breach of your obligations in this
        regard.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(ك) يجب عليك توسيع كل أشكال التعاون، على نفقتك الخاصة، إلى كوينتايب في دفاعها عن أي إجراءات قد يتم رفعها ضدها بسبب خرق التزاماتك أو تعهداتك بموجب هذه الشروط.`
            : `(k) You shall extend all cooperation, at your cost, to Quintype in its
        defence of any proceedings that may be initiated against it due to a
        breach of your obligations or covenants under these Terms.`}
        </Content>
        <Heading isArabic={isArabic}>
          {isArabic
            ? `5. الملكية الفكرية لشركة كوينتايب`
            : `5. QUINTYPE’S INTELLECTUAL PROPERTY`}
        </Heading>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(أ) جميع الحقوق والملكية والمصالح في الخدمات والخدمات، بما في ذلك جميع حقوق الملكية الفكرية الناشئة عن الخدمات، مملوكة لشركة كوينتايب أو مرخصة لها . مع مراعاة امتثالك لهذه الشروط، تمنحك كوينتايب ترخيصًا غير حصري وغير قابل للترخيص من الباطن ومحدود لـ (1) استخدام الخدمات التي اشتريتها من كوينتايب من خلال خطة الاشتراك أو بيان العمل (حسب الحالة. تكون) لنشر محتوى المستخدم الخاص بك وتوزيعه وإتاحته للمستخدمين النهائيين، و (2) دمج واستخدام واجهات برمجة التطبيقات الخاصة بـ كوينتايب فقط لأغراض نشر محتوى المستخدم الخاص بك وتوزيعه وإتاحته للمستخدمين النهائيين.`
            : `(a) All rights, title, and interest in and to the Services, including
        all intellectual property rights arising out of the Services, are owned
        by or otherwise licensed to Quintype. Subject to your compliance with
        these Terms, Quintype grants you a non-exclusive, non-sub licensable,
        and limited license to (i) use the Services you have purchased from
        Quintype through the Subscription Plan or the Statement of Work (as the
        case may be) to publish, distribute, and make available your User
        Content to end users, and (ii) integrate and use Quintype’s APIs solely
        for the purposes of publishing, distributing, and making available your
        User Content to end users.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(ب) علاوة على ذلك، وفقًا لالتزامك بهذه الشروط، تمنحك كوينتايب ترخيصًا غير مرخص من الباطن ومحدودًا لاستخدام القوالب التي قد تتيحها من وقت لآخر (والتي يتم إخطارها على وجه التحديد بواسطة كوينتايب كقوالب لاستخدامك) نشر وتوزيع وإتاحة محتوى المستخدم الخاص بك للمستخدمين النهائيين.`
            : ` (b) Further, subject to your compliance with these Terms, Quintype
        grants you a non-sub licensable and limited license to use templates it
        may make available from time to time (and which are specifically
        notified by Quintype as templates for your use) to publish, distribute,
        and make available your User Content to end users.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(ج) باستثناء ما هو مذكور في هذه الشروط، لا ينبغي تفسير أي شيء في هذه الشروط على أنه يمنح أي حق أو ترخيص لحقوق كوينتايب أو أي حقوق فكرية لأي طرف ثالث.`
            : `(c) Except as stated in these Terms, nothing in these Terms should be
        construed as conferring any right in or license to Quintype’s or any
        third party’s intellectual rights.`}
        </Content>
        <Heading isArabic={isArabic}>
          {isArabic ? `6. الرسوم` : `6. FEES`}
        </Heading>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(أ) يخضع استخدام الخدمات لدفع الرسوم المفصلة في خطة الاشتراك أو بيان العمل، حسب الحالة.`
            : `(a) Use of the Services is subject to the payment of the fees detailed
        in the Subscription Plan or the Statement of Work, as the case may be.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(ب) سترفع كوينتايب فاتورة للعميل، والتي يجب أن تكون مستحقة الدفع في أو في غضون 7 أيام من تاريخ الفاتورة. يجب عليك على الفور، وفي موعد لا يتجاوز 7 أيام من تاريخ الفاتورة، قم  بتزويد كوينتايب بإقرار خطي بهذه الفاتورة.`
            : `(b) Quintype will raise an invoice for the Customer, which shall be
        payable on or within 7 days of the date of the invoice. You shall
        promptly, and no later than 7 days from the date of the invoice, provide
        Quintype with a written acknowledgement of such invoice.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(ج) في حالة حدوث أي تأخير في دفع الفاتورة المقدمة من كوينتايب، فستكون مسؤولاً عن دفع فائدة إضافية بنسبة 2٪ شهريًا عن كل يوم تأخير. إذا ظلت الفاتورة غير مدفوعة لأكثر من 15 يومًا، يحق لـ كوينتايب إنهاء الوصول إلى الخدمات وفقًا للبند 8، ولن تكون ملزمة بتوفير الوصول إلى الخدمات.`
            : `(c) In the event of any delay in the payment of an invoice raised by
        Quintype, you shall be liable to pay an additional interest of 2% per
        month for each day of delay. If an invoice remains unpaid for more than
        15 days, Quintype shall have the right to terminate access to the
        Services in accordance with Clause 8, and shall be under no further
        obligation to provide access to the Services.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(د) التغييرات: تحتفظ كوينتايب بالحق في مراجعة رسومها من وقت لآخر. يجب أن تزودك كوينتايب بإخطار مسبق معقول بأي تغييرات تطرأ على رسومها. في حالة عدم موافقتك على الرسوم، يحق لك إلغاء خطة الاشتراك الخاصة بك أو بيان العمل قبل دورة الفوترة التي تلي رفضك للرسوم.`
            : `(d) Changes: Quintype reserves the right to revise its fees from time to
        time. Quintype shall provide you with reasonable advance notice of any
        changes to its fees. Should you not agree to the fees, you have the
        right to cancel your Subscription Plan or the Statement of Work prior to
        the billing cycle that follows your rejection of the fees.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(هـ) المبالغ المستردة: جميع المبالغ والرسوم المذكورة غير قابلة للإلغاء وغير قابلة للاسترداد.`
            : ` (e) Refunds: All amounts and fees stated are non-cancellable and
        non-refundable.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(و) الضرائب: الرسوم المذكورة في خطة الاشتراك أو بيان العمل، حسب مقتضى الحال، لا تشمل الضرائب المطبقة. يجب إضافة هذه إلى فاتورة (فواتير) كوينتايب بالسعر المناسب.`
            : `(f) Taxes: The fees mentioned in the Subscription Plan or the Statement
        of Work, as the case may be, are exclusive of applicable taxes. These
        shall be added to Quintype’s invoice(s) at the appropriate rate.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(ز) معالجات الدفع: قد تستخدم كوينتايب معالج دفع تابعًا لجهة خارجية ("معالج الدفع") لفوترة العملاء من خلال طرق الدفع المحددة الخاصة بهم. ستخضع معالجة المدفوعات لشروط وسياسات معالج الدفع هذا بالإضافة إلى هذه الشروط. لا تتحمل كوينتايب مسؤولية أي خطأ من جانب معالج الدفع. في حالة وجود أي مدفوعات غير ناجحة، يتم إيداع الأموال المخصومة وفقًا لشروط معالج الدفع.`
            : `(g) Payment Processors: Quintype may use a third-party payment processor
        (“Payment Processor”) to bill Customers through their selected modes of
        payment. The processing of payments will be subject to the terms and
        policies of such Payment Processor in addition to these Terms. Quintype
        shall not liable for any error by the Payment Processor. In the event of
        any unsuccessful payments, the money so debited shall be credited in
        accordance with the terms of the Payment Processor.`}
        </Content>
        <Heading isArabic={isArabic}>
          {isArabic ? `7. خدمات الطرف الثالث` : `7. THIRD PARTY SERVICES`}
        </Heading>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(أ) قد تتضمن الخدمات خدمات ومحتوى ووثائق ومعلومات مملوكة أو مرخصة بطريقة أخرى لطرف ثالث ("خدمات الطرف الثالث") أو تحتوي على روابط لخدمات الطرف الثالث. أنت تدرك أن خدمات الطرف الثالث هي مسؤولية الطرف الثالث الذي أنشأها أو قدمها وتقر بأن استخدام خدمات الطرف الثالث هذه هو على مسؤوليتك الخاصة فقط.`
            : ` (a) The Services may include services, content, documents, and
        information owned or otherwise licensed to a third party (“Third Party
        Services”) or contain links to Third Party Services. You understand that
        Third Party Services are the responsibility of the third party that
        created or provided it and acknowledge that the use of such Third Party
        Services is solely at your own risk.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(ب) لا تقدم كوينتايب أي تعهدات وتستبعد جميع الضمانات والمسؤوليات الناشئة عن أو المتعلقة بخدمات الطرف الثالث، بما في ذلك دقتها أو اكتمالها. لا تصادق كوينتايب أو ترعى أي خدمات لأطراف أخرى.`
            : `(b) Quintype makes no representations and excludes all warranties and
        liabilities arising out of or pertaining to such Third Party Services,
        including its accuracy or completeness. Quintype does not endorse or
        sponsor any Third Party Services.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(ج) جميع حقوق الملكية الفكرية في خدمات الأطراف الثالثة هي ملك للأطراف الثالثة المعنية.`
            : `(c) All intellectual property rights in and to Third Party Services are
        the property of the respective third parties.`}
        </Content>
        <Heading isArabic={isArabic}>
          {isArabic ? `8. المدة والإنهاء` : `8. TERM AND TERMINATION`}
        </Heading>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(أ) تظل هذه الشروط سارية المفعول (1 ) ما لم يتم إنهاؤها وفقًا للشروط الواردة أدناه أو (2) حتى انتهاء صلاحية خطة الاشتراك أو بيان العمل، حسب مقتضى الحال. يمكنك اختيار تجديد خطة الاشتراك أو بيان العمل تلقائيًا، حسب مقتضى الحال، عند انتهاء صلاحيتها.`
            : `(a) These Terms shall remain in effect (i) unless terminated in
        accordance with the terms hereunder or (ii) until the expiry of the
        Subscription Plan or the Statement of Work, as the case may be. You may
        choose to automatically renew the Subscription Plan or the Statement of
        Work, as the case may be, on their expiry.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(أ) يجوز لكوينتايب إنهاء وصولك إلى الخدمات، أو أي جزء منها، فورًا وفي أي وقت، وفقًا لتقديرها الخاص إذا انتهكت أو خرقت أيًا من التزاماتك أو مسؤولياتك أو تعهداتك بموجب هذه الشروط، إذا كنت مشتركًا في أي إجراءات إفلاس أو إفلاس، أو إذا كان هناك أي تغيير في سيطرة العميل.`
            : `  (a) Quintype may terminate your access to the Services, or any portion
        thereof, immediately and at any point, at its sole discretion if you
        violate or breach any of your obligations, responsibilities, or
        covenants under these Terms, if you are involved in any bankruptcy or
        insolvency proceedings, or if there is any change in control of the
        Customer.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(أ) عند الإنهاء بموجب البند 8 (ب):`
            : `(a) Upon termination under Clause 8(b):`}
          <br />
          {isArabic
            ? `( ط ) المنصة سوف "تتوقف لانتهاء المهلة". هذا يعني أنك لن تكون قادرًا على استخدامه ؛`
            : ` (i) the Platform will “time-out”. This means you shall not be able to
        use it;`}
          <br />
          {isArabic
            ? `(2) يجب أن تصبح جميع المستحقات غير المسددة واجبة السداد لنا ؛ و`
            : `(ii) all outstanding dues shall become payable to us; and`}
          <br />
          {isArabic
            ? `(3) يجب إنهاء هذه الشروط، باستثناء تلك البنود التي صراحة أو يقصد منها البقاء على قيد الحياة الإنهاء أو انتهاء الصلاحية.`
            : `(iii) these Terms shall terminate, except for those clauses that
        expressly or are intended to survive termination or expiry.`}
        </Content>
        <Heading isArabic={isArabic}>
          {isArabic
            ? `9. إخلاء المسؤولية والضمانات`
            : `9. DISCLAIMERS AND WARRANTIES`}
        </Heading>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(أ) أنت توافق على أن استخدامك للخدمات يكون على مسؤوليتك وحدك.`
            : `(a) You agree that your use of the Services is at
        your sole risk.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(ب) نحن لا نملك أو نتحكم أو نصادق على أي محتوى مستخدم يتم نقله أو تخزينه أو معالجته عبر الخدمات. أنت وحدك المسؤول عن محتوى المستخدم الخاص بك. لا توصي كوينتايب أو تصادق أو تقدم أي تمثيل حول فعالية أو ملاءمة أو ملاءمة أي محتوى مستخدم يتم توفيره من خلال الخدمات وتتنصل من جميع الالتزامات أو الضمانات الناشئة عن أو فيما له صلة بأي محتوى مستخدم.`
            : `(b) We do not own, control, or endorse any User
        Content that is transmitted, stored, or processed via the Services. You
        are solely responsible for your User Content. Quintype does not
        recommend, endorse, or make any representation about the efficacy,
        appropriateness or suitability of any User Content that is made
        available through the Services and disclaims all liabilities or
        warranties arising out of or in connection with any User Content.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(ج) لا تمارس كوينتايب أي حقوق تحريرية على أي محتوى مستخدم أو أي محتوى آخر يتم توفيره من خلال الخدمات.`
            : `(c) Quintype does not exercise any editorial rights
        over any User Content or any other content that is made available
        through the Services.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(د) عدم التدخل: مع مراعاة الشروط الأخرى الواردة أدناه، تحتفظ كوينتايب بالحق في إزالة أو إتاحة محتوى مستخدم غير متاح على المنصة أو من خلالها وفقًا لشروط أمر محكمة ساري المفعول.`
            : `(d) No Interference: Subject to the other terms
        contained hereunder, Quintype reserves the right to remove or make
        unavailable User Content that is made available on or through the
        Platform in accordance with the terms of a valid court order.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(هـ) إذا كنت تريد ترحيل محتوى المستخدم الخاص بك إلى أي منصة أو خادم، فإن كوينتايب ستزودك بأرشيف لمحتوى المستخدم الخاص بك بتنسيق [json]. مع مراعاة دفع رسوم الإضافة التي تحددها كوينتايب، يجوز لكوينتايب، عند الطلب، تزويدك بأرشيفات أو نسخ احتياطية بأي تنسيق آخر.`
            : `(e) If you are to migrate your User Content to any
        platform or server, Quintype shall provide you with an archive of your
        User Content in [Json] format. Subject to the payment of addition fees
        determined by Quintype, Quintype may, upon request, provide you with
        archives or backups in any other format.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(و) إلى الحد الذي يسمح به القانون المعمول به، يتم تقديم الخدمات على أساس "كما هي" و "حسب توفرها".`
            : `(f) To the extent permitted by applicable law, the
        Services are provided on an “as is” and “as available” basis.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(ز) لا تضمن كوينتايب أن تشغيل المنصة أو الخدمات لن ينقطع أو يكون خاليًا من الأخطاء أو أن الوظائف الواردة في الخدمات سوف تفي بمتطلباتك.`
            : `(g) Quintype does not warrant that operation of the
        Platform or the Services will be uninterrupted or error free or that the
        functions contained in the Services will meet your requirements.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(ح) لا تضمن كوينتايب أو تتعهد بأن الخدمات ستكون متوافقة مع أي جهاز أو برنامج تابع لجهة خارجية. تقع على عاتقك مسؤولية ضمان توافق الخدمات قبل الاستخدام. بالإضافة إلى ذلك، لا تتحمل كوينتايب المسؤولية عن أي أضرار فعلية أو عرضية أو تبعية قد تنجم عن أي استخدام أو عدم القدرة على استخدام أي أطراف خارجية مع الخدمات.`
            : `(h) Quintype does not warrant or represent that the
        Services will be compatible with any third party hardware or software.
        It shall your responsibility to ensure compatibility of the Services
        prior to use. Additionally, Quintype shall not be held responsible for
        any actual, incidental or consequential damages that may result from any
        use or inability to use any third-party peripherals with the Services.`}
           
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `( 1 ) إلى أقصى حد مسموح به بموجب القانون المعمول به، تخلي كوينتايب صراحة مسؤوليتها عن جميع الضمانات من أي نوع، صريحة أو ضمنية، الناشئة عن الخدمات، بما في ذلك ضمانات القابلية للتسويق والملاءمة لغرض معين والجودة المرضية والدقة والملكية وغير - التعدي، والتوافق، وقابلية التطبيق، وسهولة الاستخدام، والملاءمة، وأي ضمان قد ينشأ عن سير الأداء، أو سير التعامل، أو استخدام التجارة.`
            : `(i) To the fullest extent permissible under
        applicable law, Quintype expressly disclaims all warranties of any kind,
        express or implied, arising out of the Services, including warranties of
        merchantability, fitness for a particular purpose, satisfactory quality,
        accuracy, title and non-infringement, compatibility, applicability,
        usability, appropriateness, and any warranty that may arise out of
        course of performance, course of dealing, or usage of trade.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(ي) أنت بموجب هذا تقبل المسؤولية الكاملة عن أي عواقب قد تنشأ عن استخدامك للخدمات، وتوافق وتقر صراحةً بأن كوينتايب لن تتحمل أي مسؤولية على الإطلاق فيما يتعلق بذلك.`
            : `(j) You hereby accept full responsibility for any
        consequences that may arise from your use of the Services, and expressly
        agree and acknowledge that Quintype shall have absolutely no liability
        with respect to the same.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(ك) إلى أقصى حد يسمح به القانون، تخلي شركة كوينتايب والشركات التابعة لها والأطراف ذات الصلة مسؤوليتها تجاهك عن أي خسارة أو ضرر ينشأ عن أو بسبب:`
            : `(k) To the fullest extent permissible by law,
        Quintype, its affiliates, and its related parties each disclaim all
        liability to you for any loss or damage arising out of or due to:`}
          <br />
          {isArabic
            ? `(1) استخدامك للخدمات أو عدم قدرتك على استخدامها أو توفرها أو عدم توفرها، بما في ذلك أي خدمات لأطراف أخرى يتم توفيرها من خلال الخدمات ؛`
            : `(i) your use of, inability to use, or availability
        or unavailability of the Services, including any third party services
        made available through the Services;`}{" "}
          <br />
          {isArabic
            ? `(2) حدوث أو وجود أي عيب أو انقطاع أو حذف ملفات أو تأخير في تشغيل أو نقل المعلومات إلى أو من أو من خلال الخدمات أو فشل الاتصالات أو السرقة أو الإتلاف أو الوصول غير المصرح به إلى سجلات كوينتايب أو برامجها أو خدماتها أو الخادم أو البنية التحتية الأخرى المتعلقة بالخدمات ؛`
            : `(ii) the occurrence or existence of any defect,
        interruption, deletion of files, delays in the operation or transmission
        of information to, from, or through the Services, communications
        failure, theft, destruction or unauthorised access to Quintype’s
        records, programs, services, server, or other infrastructure relating to
        the Services; `}
          <br />
          {isArabic
            ? `(3) إصابة الخدمات بأي تعليمات برمجية ضارة أو فيروسات ؛ أو`
            : `(iii) the Services being infected with any malicious
        code or viruses; or `}
          <br />
          {isArabic
            ? `(4) فشل الخدمات في الاستمرار في العمل لأي فترة زمنية.`
            : `(iv) the failure of the Services to remain
        operational for any period of time.`}{" "}
          <br />
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(ل) بغض النظر عن أي شيء يتعارض مع ذلك، فإن الحد الأقصى للمسؤولية الإجمالية لشركة كوينتايب والشركات التابعة لها، عن أي خسارة، يجب ألا يتجاوز الأموال التي تلقتها كوينتايب منك في الاثني عشر شهرًا التي تسبق تاريخ المطالبة بالخسارة أو الضرر.`
            : `(l) Notwithstanding anything to the contrary, the
        maximum aggregate liability of Quintype and its affiliates, for any loss
        shall not exceed the monies received by Quintype from you in the twelve
        months preceding the date of the claim of loss or damage.`}
        </Content>
        <Heading isArabic={isArabic}>
          {isArabic ? `10. التعويض` : `10. INDEMNITY`}
        </Heading>
        <Content isArabic={isArabic}>
          {isArabic
            ? `أنت توافق على تعويض كوينتايب والشركات التابعة لها والشركات التابعة لها والمرخصين التابعين لها ومديريها ومسؤوليها وأعضائها ومديريها وموظفيها ووكلائها من وضد أي وجميع المطالبات والنفقات الناشئة عن استخدامك للخدمات، خرقًا لأي شرط من هذه الشروط من قبلك أو من قبل أي شخص يستخدم حسابك على المنصة، أو خرقًا من جانبك لأي قوانين معمول بها، أو أي مطالبة قد تنشأ بموجب اللوائح العامة لحماية البيانات بسبب أفعالك أو إهمالاتك، أو أي شخص ثالث - مطالبة طرف إلى الحد الذي ينشأ عن ادعاء بأن استخدامك للخدمات وفقًا لهذه الشروط أو يرتبط به ينتهك أي حقوق لطرف ثالث.`
            : `You agree to indemnify and hold harmless Quintype, its affiliates and
        subsidiaries, its licensors, and their respective directors, officers,
        members, managers, employees, and agents from and against any and all
        claims and expenses arising out of your use of the Services, a breach of
        any provision of these Terms by you or any person using your account on
        the Platform, a breach by you of any applicable laws, any claim that may
        arise under the General Data Protection Regulations due to your acts or
        omissions, or any third-party claim to the extent arising from or
        connected with an allegation that your use of the Services in accordance
        with these Terms infringes any rights of a third party. `}
        </Content>
        <Heading isArabic={isArabic}>
          {isArabic
            ? `11. الموافقة على استخدام البيانات`
            : `11. CONSENT TO USE DATA`}{" "}
        </Heading>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(أ) توافق على أنه يجوز لشركة كوينتايب وفقًا لسياسة الخصوصية الخاصة بها جمع واستخدام معلوماتك وبياناتك الفنية والمعلومات ذات الصلة.`
            : `(a) You agree that Quintype may in accordance with its Privacy Policy
        collect and use your information and technical data and related
        information.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(ب) قد تستخدم كوينتايب المعلومات والبيانات المتعلقة باستخدامك للخدمات للتحليلات، وتحديد الاتجاهات، وأغراض الإحصاء لزيادة فعالية وكفاءة الخدمات.`
            : `(b) Quintype may use information and data pertaining
        to your use of the Services for analytics, trends’ identification, and
        purposes of statistics to further enhance the effectiveness and
        efficiency of the Services.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `تصرح بموجب هذا صراحةً  بأن تقوم كوينتايب بالإفصاح عن أي وجميع المعلومات المتعلقة بك في حوزة كوينتايب لأي مسؤول تنفيذي للقانون أو مسؤولين حكوميين آخرين، إذا اعتقدت كوينتايب أن ذلك ضروري أو مناسب فيما يتعلق بالتحقيق أو حل الجرائم المحتملة، بما في ذلك على سبيل المثال لا الحصر هويتك ومعلوماتك التي قدمتها أنت، ومعلوماتك المصرفية، ومراسلاتك. أنت تدرك أيضًا أنه قد يتم توجيه كوينتايب للإفصاح عن أي معلومات قد تعتبر ضرورية لتلبية أي تحقيق أو أمر قضائي أو قانون أو لائحة أو طلب حكومي صالح.`
            : `(c) You hereby expressly authorise Quintype to
        disclose any and all information relating to you in Quintype’s
        possession to any law enforcement or other government officials, if
        Quintype believes it is necessary or appropriate in connection with the
        investigation or resolution of possible crimes, including but not
        limited to your identity, information provided by you, your banking
        information, and your correspondence. You further understand that
        Quintype might be directed to disclose any such information as may be
        deemed necessary to satisfy any investigation, judicial order, law,
        regulation or valid governmental request.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(د) تقر بأنه في حالة حدوث أي خرق أمني للمنصة، فإن كوينتايب ستحتفظ بالحق في إجراء عمليات الكشف اللازمة للسلطات المختصة المنشأة بموجب القانون، بما في ذلك فريق الاستجابة لطوارئ الكمبيوتر الهندي.`
            : `(d) You acknowledge that in the event of any
        security breach on the Platform, Quintype reserves the right to make
        necessary disclosures to applicable authorities established under law,
        including the Indian Computer Emergency Response Team.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(هـ) موافقة المستخدم النهائي: أنت تبين وتضمن ما يلي:`
            : `(e) End User Consent: You represent and warrant
        that:`}{" "}
          <br />
          {isArabic
            ? `(1) يجب عليك الامتثال لجميع قوانين حماية البيانات المعمول بها، بما في ذلك على سبيل المثال لا الحصر قواعد تقنية المعلومات (ممارسات وإجراءات الأمان المعقولة والبيانات أو المعلومات الشخصية الحساسة)، 2011، وأي لوائح وقواعد وإرشادات حماية البيانات أو الخصوصية الأخرى، والإشعارات التي تصبح سارية لجمع واستخدام أي بيانات تتعلق بالمستخدمين النهائيين أو العملاء ("المستخدمون النهائيون")، بما في ذلك على سبيل المثال لا الحصر القواعد واللوائح وتقييم بيانات المستخدمين النهائيين الصادرة عن السلطات التنظيمية ؛ و`
            : `(i) you shall comply with all applicable data
        protection laws, including but not limited to the Information Technology
        (Reasonable Security Practices and Procedures and Sensitive Personal
        Data or Information) Rules, 2011, and any other data protection or
        privacy regulations, rules, guidelines, and notifications that become
        applicable for the collection and use of any data pertaining to your end
        users or customers (“End Users”), including but not limited to rules,
        regulations, and evaluation of End Users’ data issued by regulatory
        authorities; and`}
          <br />
          {isArabic
            ? `(2) أنك، في جميع الأوقات، قد تلقيت موافقات صريحة من المستخدمين على مشاركة بياناتهم مع كوينتايب لتوفير الخدمات.`
            : `(ii)you, at all times, have received End Users’
        explicit consents for you to share their data with Quintype for the
        provision of the Services.`}
          <br />
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(و) أنت توافق وتقر بأنك المسؤول الوحيد عن معالجة بيانات المستخدمين النهائيين، وعليك الحصول على موافقتهم قبل إجراء هذه المعالجة. لا تتحمل كوينتايب أي مسؤولية في هذا الصدد، وعليك تعويض كوينتايب عن أي خسارة أو ضرر أو مطالبات أو نفقات قد تنشأ عن خرقك لالتزامات حماية البيانات ومعالجة البيانات فيما يتعلق ببيانات المستخدمين النهائيين.`
            : `(f) You agree and acknowledge that you are solely
        responsible for the processing of End Users’ data, and you shall seek
        their consent prior to undertaking such processing. Quintype shall have
        no liability in this regard, and you shall indemnify Quintype for any
        loss, damage, claims, and expenses that may arise out of your breach of
        your data protection and data processing obligations in connection with
        End Users’ data.`}
        </Content>
        <Heading isArabic={isArabic}>
          {isArabic ? `12. سياسة حذف البيانات` : `12. Data Deletion Policy`}
        </Heading>
        <Content isArabic={isArabic}>
          {isArabic
            ? `1. توفر كوينتايب للمستخدمين تحكمًا كاملاً في بياناتهم، بما في ذلك البيانات التي يتم الاحتفاظ بها والبيانات التي يتم حذفها.`
            : `1. Quintype provides users with full control over their data, including
        what data is retained and what data is deleted.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `2. يمكن للمستخدمين النهائيين طلب حذف بياناتهم وحساباتهم عن طريق إرسال بريد إلكتروني إلى support@quintype.com. سيتم معالجة الطلب في غضون يومي عمل.`
            : `2.End users can request their data and account to be deleted by sending
        an email to support@quintype.com. The request will be processed within 2
        working days.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `3.يمكن لعملاء المؤسسات طلب حذف البيانات لجميع المستخدمين أو بعضهم. يمكن بدء الطلب عن طريق إرسال بريد إلكتروني إلى support@quintype.com  مع تفاصيل طلب حذف البيانات (نوع البيانات المطلوب حذفها، والمستخدمون المطلوب حذفهم، والإطار الزمني للحذف، وما إلى ذلك ).`
            : `3.Enterprise customers can request data to be
        deleted for all or some of their users. The request can be initiated by
        sending an email to support@quintype.com with details of the data
        deletion request (type of data to delete, users to delete, time frame of
        deletion, etc).`}
        </Content>
        <Heading isArabic={isArabic}>
          {isArabic ? `13. مستويات الخدمة` : `13. SERVICE LEVELS`}
        </Heading>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(أ) الصيانة المجدولة: تحتفظ كوينتايب، وفقًا لتقديرها فقط، بالحق في جعل الخدمات غير متاحة للوصول لمدة تصل إلى [0.5] ساعة مرة واحدة كل ستة أشهر للصيانة المجدولة ولا يتم احتساب وقت التعطل هذا مقابل أي توافر محدد في هذا البند 12. يجب أن تسبق عمليات الصيانة المجدولة بإخطار لا يقل عن 21 يومًا عبر البريد الإلكتروني لك أو يتم نشره على المنصة أو حسابك على المنصة.`
            : `(a) Scheduled Maintenance: Quintype reserves, solely
        at its discretion, the right to make the Services unavailable for access
        for up to [0.5] hours once in every six months for scheduled maintenance
        and such downtime shall not be counted against any availability
        specified in this Clause 12. Scheduled maintenance operations shall be
        preceded by no less than 21 days notification via email to you or posted
        to the Platform or your account on the Platform.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(ب) وقت تعطل غير متوقع: بالإضافة إلى الصيانة المجدولة المذكورة أعلاه، ستبذل كوينتايب جهودًا معقولة تجاريًا لضمان توفر المنصة بحد أدنى [99.5٪] من وقت تشغيل البرنامج، حيث يتم حساب متوسط التوافر الشهري بناءً على إجمالي وقت الانقطاع في سنة معينة. وفقًا للشروط الأخرى الواردة هنا، فإن أي نقص في التوفر أقل من متوسط التوافر البالغ [99.5٪] سيؤدي إلى تمديد تناسبي لفترة مستويات الخدمة بموجب هذا البند.`
            : `(b) Unexpected Downtime: Beyond the aforementioned
        scheduled maintenance, Quintype will use commercially reasonable efforts
        to ensure that the Platform shall be available with a minimum of [99.5%]
        software uptime, wherein the monthly availability average is calculated
        based upon total outage time in a given year. Subject to the other terms
        herein, any lack of availability below the availability average of
        [99.5%] will result in a pro rata extension of the tenure of service
        levels under this Clause.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(ج) الدعم: وفقًا للأحكام الأخرى الواردة في البند 12، ستبذل كوينتايب الجهود المعقولة تجاريًا بين [9:00 صباحًا – 6:00 مساءً بتوقيت الهند الرسمي ("ساعات العمل") لتقديم الخدمات المفصلة في الجدول أدناه. تقدم كوينتايب أيضًا الدعم بموجب هذا البند من خلال خدمات الدردشة والبريد الإلكتروني.`
            : `(c) Support: Subject to the other provisions of
        Clause 12, Quintype will use commercially reasonable efforts between
        [9:00 AM – 6:00 PM IST] (“Business Hours”) to provide the services
        detailed in the table below. Quintype shall also provide support under
        this Clause through chat services and email.`}
        </Content>
        <TableWrapper>
          <Table isArabic={isArabic}>
            <TableRow>
              <TableHeading isArabic={isArabic}>
                {isArabic ? `فئة المشكلة` : `Issue Category`}
              </TableHeading>
              <TableHeading isArabic={isArabic}>
                {isArabic ? `الوصف` : `Description`}
              </TableHeading>
              <TableHeading isArabic={isArabic}>
                {isArabic ? `وقت الحل` : `Resolution Time`}
              </TableHeading>
            </TableRow>
            <TableRow>
              <TableData isArabic={isArabic}>
                {isArabic ? `حرجة` : `Critical`}
              </TableData>
              <TableData isArabic={isArabic}>
                {isArabic
                  ? `وقت تشغيل النظام، الصفحة الرئيسية لا يمكن الوصول إليها، صفحة القصة لا يمكن الوصول إليها، القدرة على سحب القصة`
                  : `System Uptime, home page not accessible, story page not
              accessible, ability to retract a story`}
              </TableData>
              <TableData isArabic={isArabic}>
                {isArabic ? ` 2 ساعة <=` : `<= 2 hours`}
              </TableData>
            </TableRow>
            <TableRow>
              <TableData isArabic={isArabic}>
                {isArabic ? `عالية` : `High`}
              </TableData>
              <TableData isArabic={isArabic}>
                {isArabic
                  ? `أي خطأ متعلق بنظام إدارة المحتوى يؤثر على إنشاء قصة جديدة، ووقت التوقف عن العمل في التحليلات، والجدولة، والنشر، وسير العمل التحريري، وإدارة الاشتراكات، تقييد الوصول، إلخ.`
                  : ` Any CMS related bug that affects creating of a new story, down
              time on analytics, scheduling, publishing, editorial workflow,
              subscriptions management, paywall etc.`}
              </TableData>
              <TableData isArabic={isArabic}>
                {isArabic ? `4 ساعات <=` : `<= 4 hours`}
              </TableData>
            </TableRow>
            <TableRow>
              <TableData isArabic={isArabic}>
                {isArabic ? `متوسطة` : `Medium`}
              </TableData>
              <TableData isArabic={isArabic}>
                {isArabic
                  ? `الميزات المحددة التي لا تعمل مثل نشر SM وتغييرات التنسيق والبحث في قصة وما إلى ذلك.`
                  : `Identified features not working Ex. SM publishing, formatting
              changes, search a story etc.`}
              </TableData>
              <TableData isArabic={isArabic}>
                {isArabic ? `48 ساعة <=` : `<= 48 hours`}
              </TableData>
            </TableRow>
            <TableRow>
              <TableData isArabic={isArabic}>
                {isArabic ? `منخفضة` : `Low`}
              </TableData>
              <TableData isArabic={isArabic}>
                {isArabic
                  ? `أي قضايا لم يتم تناولها أعلاه`
                  : `Any issues not covered in above`}
              </TableData>
              <TableData isArabic={isArabic}>
                {isArabic ? `96 ساعة <=` : `<= 96 hours`}
              </TableData>
            </TableRow>
          </Table>
        </TableWrapper>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(د) الإبلاغ: أنت توافق على الإبلاغ عن أي تعطل غير مجدول في النظام وأي خطأ أو خلل أو عيب في النظام الأساسي عن طريق الكتابة إلى support@quintype.com فور علمك أو تلقي إشعار بوقت تعطل النظام أو الخطأ أو الخطأ أو العيب. `
            : `(d) Reporting: You agree to report any unscheduled system downtime and
        any error, bug, or defect in the Platform by writing to
        support@quintype.com immediately upon becoming aware or receiving notice
        of such system downtime, error, bug, or defect.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(هـ) المراجعة: تخضع مستويات الخدمة بموجب هذا البند لمراجعات دورية وفقًا لتقدير كوينتايب وحدها.`
            : `(e) Review: The service levels under this Clause are subject to periodic
        reviews at Quintype’s sole discretion.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(و) لا تنطبق أحكام هذا البند على أي مشكلات في الأداء ( 1) ناتجة عن عوامل أو ظروف خارجة عن سيطرة كوينتايب المعقولة، بما في ذلك الشروط المفصلة في البند 16 ؛ (2) التي تنتج عن معداتك أو معدات طرف ثالث، أو كليهما ؛ (3) التي تنتج عن مزودي خدمة الإنترنت أو مزودي خدمات الاتصالات أو معداتهم أو خدماتهم ؛ (4) ناتجًا عن إنهاء وصولك إلى الخدمات وفقًا لحقوق كوينتايب بموجب هذه الشروط ؛ أو (5) ناتجًا عن إساءة استخدام الخدمات، بما في ذلك استخدام الخدمات في انتهاك لهذه الشروط أو خطة الاشتراك أو بيان العمل أو بخلاف ما هو متوافق مع التعليمات التي قد توفرها كوينتايب من وقت لآخر.`
            : `(f) The provisions of this Clause shall not apply to any performance
        issues (i) caused by factors or circumstances outside of Quintype’s
        reasonable control, including the conditions detailed in Clause 16; (ii)
        that result from your equipment or third-party equipment, or both; (iii)
        that result from internet service providers, telecommunications service
        providers, or their equipment or services; (iv) caused by the
        termination of your access to the Services pursuant to Quintype’s rights
        under these Terms; or (v) caused by misuse of the Services, including
        use of the Services in breach of these Terms, Subscription Plan,
        Statement of Work, or other than in accordance with instructions
        Quintype may provide from time to time.`}
        </Content>
        <Heading isArabic={isArabic}>
          {isArabic ? `14. تعديل الخدمات` : `14. MODIFICATION OF SERVICES`}{" "}
        </Heading>
        <Content isArabic={isArabic}>
          {isArabic
            ? `تحتفظ كوينتايب بالحق في أي وقت في إضافة أو تعديل أو إيقاف الخدمات (أو أي جزء منها) بشكل مؤقت أو دائم مع أو بدون إشعار أو سبب. أنت توافق وتقر بأن كوينتايب لن تكون مسؤولة تجاهك أو تجاه أي طرف ثالث عن أي إضافة أو تعديل أو تعليق أو وقف للخدمات.`
            : `Quintype reserves the right at any time to add, modify or discontinue,
        temporarily or permanently, the Services (or any part thereof) with or
        without notice or cause. You agree and acknowledge that Quintype shall
        not be liable to you or to any third party for any such addition,
        modification, suspension or discontinuation of the Services.`}
        </Content>
        <Heading isArabic={isArabic}>
          {isArabic ? `15. التحديثات` : `15. UPDATES`}{" "}
        </Heading>
        <Content isArabic={isArabic}>
          {isArabic
            ? `كوينتايب بالحق في تقديم تعديلات طفيفة أو تحسينات، أو إصلاحات أو تصحيحات مؤقتة للبرنامج، إن وجدت، للخدمات ("التحديثات"). أنت توافق على أن إضافة وظائف رئيسية أو ميزات جديدة مهمة إلى الخدمات ليست تحديثًا. يحق لشركة كوينتايب، وفقًا لتقديرها الخاص، تحديد ما يشكل تحديثًا.`
            : `Quintype reserves the right to provide minor
        modifications or enhancements, or programme temporary fixes or patches,
        if any, to the Services (“Updates”). You agree that the addition of
        major functions or significant new features to the Services is not an
        Update. Quintype shall, in its sole discretion, have the right to
        determine what constitutes an Update.`}
        </Content>
        <Heading isArabic={isArabic}>
          {isArabic ? `16. السرية` : `16. CONFIDENTIALITY`}{" "}
        </Heading>
        <Content isArabic={isArabic}>
          {isArabic
            ? `أنت تقر بأن الخدمات تحتوي على أسرار تجارية ومعلومات سرية خاصة بشركة كوينتايب ومرخصيها. أنت توافق على الحفاظ على سرية الخدمات والحفاظ عليها، وعدم تزويد أي شخص آخر بنسخة من الخدمات أو المنصة. أنت توافق على استخدام درجة معقولة من العناية لحماية سرية الخدمات. لن تقوم بإزالة أو تغيير أي من إشعارات ملكية كوينتايب أو المرخصين التابعين لها. تستمر التزاماتك بموجب هذا البند حتى بعد انتهاء صلاحية هذه الشروط أو إنهاؤها. بدون الحد من عمومية ما سبق، فإنك توافق أيضًا على عدم نشر أو توزيع نتائج أي اختبارات قياس الأداء ذات الصلة بالخدمات التي يتم توفيرها لك من قبل كوينتايب والشركات التابعة لها والشركات التابعة لها ومرخصيهم.`
            : `You acknowledge that the Services contain Quintype’s
        and its licensors’ trade secrets and confidential information. You agree
        to hold and maintain the Services in confidence, and not to furnish any
        other person with a copy of the Services or Platform. You agree to use a
        reasonable degree of care to protect the confidentiality of the
        Services. You will not remove or alter any of Quintype’s or its
        licensors’ proprietary notices. Your obligations under this Clause
        continue even after these Terms have expired or been terminated. Without
        limiting the generality of the foregoing, you further agree not to
        publish or otherwise publicly distribute the results of any benchmark
        tests related to the Services that are made available to you by
        Quintype, its affiliates and subsidiaries, and their licensors.`}
        </Content>
        <Heading isArabic={isArabic}>
          {isArabic ? `17. القوة القاهرة` : `17. FORCE MAJEURE`}{" "}
        </Heading>
        <Content isArabic={isArabic}>
          {isArabic
            ? `كوينتايب أي مسؤولية تجاهك إذا تم منعها أو تأخرها في أداء التزاماتها أو من القيام بأعمالها، بسبب الأفعال أو الأحداث أو الإغفالات أو الحوادث الخارجة عن سيطرتها المعقولة، بما في ذلك، على سبيل المثال لا الحصر، الإضرابات أو الإغلاق أو غير ذلك النزاعات الصناعية (سواء كانت تتعلق بالقوى العاملة في كوينتايب أو أي طرف آخر)، أو فشل خدمة المرافق أو شبكة الاتصالات، أو القضاء والقدر، أو الحرب، أو الشغب، أو الاضطرابات المدنية، أو الأضرار الكيدية، أو الامتثال لأي قانون أو أمر حكومي أو قاعدة أو لائحة أو توجيه، حادث، انهيار الآلات، حريق، فيضان، عاصفة أو تقصير من الموردين أو المقاولين من الباطن.`
            : `Quintype shall have no liability to you if it is
        prevented from or delayed in performing its obligations or from carrying
        on its business, by acts, events, omissions or accidents beyond its
        reasonable control, including, without limitation, strikes, lock-outs or
        other industrial disputes (whether involving the Quintype’s workforce or
        any other party), failure of a utility service or telecommunications
        network, act of God, war, riot, civil commotion, malicious damage,
        compliance with any law or governmental order, rule, regulation or
        direction, accident, breakdown of machinery, fire, flood, storm or
        default of suppliers or sub-contractors.`}
        </Content>
        <Heading isArabic={isArabic}>
          {isArabic
            ? `18. الاختصاص القضائي والقوانين المنظمة وحل النزاعات`
            : `18. JURISDICTION, GOVERNING LAWS, AND DISPUTE RESOLUTION`}
        </Heading>
        <Content isArabic={isArabic}>
          {isArabic
            ? `تخضع هذه الشروط ويتم تفسيرها وتنفيذها وفقًا لقوانين الهند. مع مراعاة الأحكام الأخرى الواردة في هذا البند، تتمتع المحاكم في بنغالور بسلطة قضائية حصرية على جميع القضايا الناشئة عن هذه الشروط أو استخدام الخدمات.`
            : `These Terms shall be governed by and construed and enforced in
        accordance with the laws of India. Subject to other provisions in this
        Clause, courts in Bangalore shall have exclusive jurisdiction over all
        issues arising out of these Terms or the use of the Services.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `يتم حل أي خلافات أو نزاعات أو نزاعات أو خلافات تنشأ عن هذه الشروط عن طريق التحكيم في بنغالور وفقًا لقانون التحكيم والتوفيق لعام 1996 في الوقت الحالي والذي يعتبر مدمجًا بالإشارة إليه في هذا البند. يجب أن تتكون هيئة التحكيم من محكم واحد (واحد) معين من قبل كوينتايب . يجب أن تكون لغة التحكيم هي اللغة الإنجليزية.`
            : `Any controversies, conflicts, disputes, or differences arising out of
        these Terms shall be resolved by arbitration in Bangalore in accordance
        with the Arbitration and Conciliation Act, 1996 for the time being in
        force, which is deemed to be incorporated by reference in this Clause.
        The tribunal shall consist of 1 (one) arbitrator appointed by Quintype.
        The language of the arbitration shall be English.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `يجب على أطراف التحكيم الحفاظ على سرية التحكيم وعدم الإفصاح عنه لأي شخص، إلا على أساس الحاجة إلى ذلك، ما لم يُطلب ذلك بموجب القانون. يكون قرار المحكم نهائيًا وملزمًا لجميع الأطراف.`
            : `The parties to the arbitration shall keep the arbitration confidential
        and not disclose to any person, other than on a need to basis, unless
        required to do so by law. The decision of the arbitrator shall be final
        and binding on all the Parties hereto.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `يتحمل كل طرف في التحكيم التكاليف الخاصة به فيما يتعلق بأي نزاع.`
            : `Each party to the arbitration shall bear its own costs with respect to
        any dispute.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(أ) التعديل - تحتفظ كوينتايب بالحق في أي وقت في تعديل هذه الشروط وإضافة شروط أو شروط جديدة أو إضافية على استخدامك للخدمات. سيتم إبلاغك بهذه التعديلات والشروط والأحكام الإضافية، وما لم يتم الرفض صراحةً (والتي تنتهي بموجبها هذه الشروط)، ستكون سارية المفعول على الفور وسيتم دمجها في هذه الشروط. في حالة رفضك قبول هذه التغييرات، سيتم إنهاء هذه الشروط والترخيص .`
            : ` (a) Modification – Quintype reserves the right at any time to modify
        these Terms and to add new or additional terms or conditions on your use
        of the Services. Such modifications and additional terms and conditions
        will be communicated to you and, unless expressly rejected (in which
        these Terms shall terminate), will be effective immediately and will be
        incorporated into these Terms. In the event, you refuse to accept such
        changes, these Terms and licence will terminate.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(ب) القابلية للفصل - إذا قررت أي محكمة أو سلطة مختصة أخرى أن أي شرط من هذه الشروط غير قانوني أو غير قابل للتنفيذ، فستظل الأحكام الأخرى لهذه الشروط سارية المفعول. إذا كان أي حكم غير قانوني أو غير قابل للتنفيذ سيكون قانونيًا أو قابل للتنفيذ إذا تم حذف جزء منه، فسيتم اعتبار هذا الجزء محذوفًا، وسيظل باقي الحكم ساريًا (ما لم يتعارض ذلك مع النية الواضحة للبند، في في هذه الحالة، سيتم اعتبار الحكم ذي الصلة بأكمله محذوفًا).`
            : `(b) Severability - If any provision of these Terms is determined by any
        court or other competent authority to be unlawful or unenforceable, the
        other provisions of these Terms will continue in effect. If any unlawful
        or unenforceable provision would be lawful or enforceable if part of it
        were deleted, that part will be deemed to be deleted, and the rest of
        the provision will continue in effect (unless that would contradict the
        clear intention of the clause, in which case the entirety of the
        relevant provision will be deemed to be deleted).`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `( ج ) التنازل - لا يجوز لك توزيع الخدمات أو ترخيصها أو بيعها أو نقلها أو التنازل عنها للآخرين بأي طريقة دون موافقة كتابية مسبقة من كوينتايب . قد تمنح كوينتايب هذه الموافقة أو تحجبها وفقًا لتقديرها الخاص وتخضع لأي شروط تراها مناسبة. قد تتنازل كوينتايب عن حقوقها إلى أي من الشركات التابعة لها أو الشركات التابعة لها، أو إلى أي شركة وريثة لمصلحة أي عمل مرتبط بالخدمات دون أي إشعار مسبق لك.`
            : ` (c) Assignment - You shall not distribute, licence, sell, transfer or
        assign the Services to others in any manner without Quintype’s prior
        written consent. Quintype may grant or withhold this consent in its sole
        discretion and subject to any conditions it deems appropriate. Quintype
        may assign its rights to any of its affiliates or subsidiaries, or to
        any successor in interest of any business associated with the Services
        without any prior notice to you.`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(د) الإشعارات - يجب إرسال جميع الإشعارات والطلبات والطلبات والقرارات الخاصة بكوينتايب بموجب هذه الشروط (بخلاف الاتصالات التشغيلية الروتينية) إلى admin@quintype.com`
            : `(d) Notices - All notices, requests, demands, and determinations for
        Quintype under these Terms (other than routine operational
        communications) shall be sent to admin@quintype.com`}
        </Content>
        <Content isArabic={isArabic}>
          {isArabic
            ? `(هـ) حقوق الطرف الثالث - لن يكون لأي طرف ثالث أي حقوق لفرض أي شروط واردة في هذه الوثيقة.`
            : `(e) Third Party Rights - No third party shall have any rights to enforce
        any terms contained herein.`}
        </Content>
        <Heading isArabic={isArabic}>
          {isArabic ? `الملحق أ` : `ANNEXURE A`}
        </Heading>
        <Content isArabic={isArabic}>
          {isArabic
            ? `يجب أن تتكون الطلبات من الوحدات التالية:`
            : ` The Applications shall consist of the following modules:`}
        </Content>
        <TermsCondition>
          <TermsConditionList isArabic={isArabic}>
            {isArabic
              ? `منصة كوينتايب (بما في ذلك الوظائف عبر المخطط، المحرر، التحليلات، المجموعات، البحث، مساعدة المحتوى، التسويق عبر البريد الإلكتروني، دفع الإخطارات، والمزيد بمجرد إصدارها). أي تغييرات مطلوب إجراؤها على بيئة العميل، لاستغلال الميزات المذكورة - ستكون مسؤولية العميل.`
              : `The Quintype Platform (including functionality across Planner, Editor,
          Analytics, Collections, Search, Content Assist, Email Marketing, Push
          Notifications, and more as they’re released). Any changes required to
          be made to the Customer’s environment, to exploit the said features –
          would be the responsibility of the Customer.`}
          </TermsConditionList>
          <TermsConditionList isArabic={isArabic}>
            {isArabic
              ? `ويب سريع الاستجابة يعمل بسلاسة عبر الأجهزة المحمولة والأجهزة اللوحية وسطح المكتب. سيتم بناء التصميم على قالب قياسي ويقبله العميل`
              : `Responsive Web that works seamlessly across mobile devices, tablets,
          and desktop. The design will be built on a standard template and is
          accepted by the Customer`}
          </TermsConditionList>
          <TermsConditionList isArabic={isArabic}>
            {isArabic
              ? `ميزات تحقيق الدخل، بما في ذلك على سبيل المثال لا الحصر الإعلان الآلي والمحتوى الأصلي والوحدات الإعلانية للتجارة الإلكترونية.`
              : ` Monetization features, including but not limited to programmatic
          advertising, native content, and e-commerce ad units.`}
          </TermsConditionList>
          <TermsConditionList isArabic={isArabic}>
            {isArabic
              ? `شبكة توصيل محتوى عالمية لتخزين صفحات الواجهة الأمامية مؤقتًا.`
              : `Global CDN to cache front-end pages.`}
          </TermsConditionList>
          <TermsConditionList isArabic={isArabic}>
            {isArabic
              ? `ميزات التوزيع مثل النشر التلقائي على وسائل التواصل الاجتماعي (* فيسبوك و * تويتر)، ودعم فيسبوك * تكامل المقالة الفوري.`
              : ` Distribution features like auto publish on social media (*Facebook and
          *Twitter), support for Facebook *Instant article integration.`}
          </TermsConditionList>
          <TermsConditionList isArabic={isArabic}>
            {isArabic
              ? `خريطة جوجل مدعومة بنموذج قياسي`
              : `Google AMP supported with standard template`}
          </TermsConditionList>
          <TermsConditionList isArabic={isArabic}>
            {isArabic
              ? `ميزات تحسين محركات البحث مثل الكلمات الرئيسية والبيانات الوصفية والإرشادات الأساسية حول إعداد تحسين محركات البحث (يدعم النظام الأساسي إضافة الكلمات الرئيسية والبيانات الوصفية لتحسين محركات البحث)`
              : `SEO features like keywords and metadata and basic guidance on setting
          up SEO (Platform supports adding keywords and metadata for SEO)`}
          </TermsConditionList>
          <TermsConditionList isArabic={isArabic}>
            {isArabic
              ? `نسخة مجانية من وحدة تعليقات ميتايب`
              : `Complimentary version of the Metype commenting module`}
          </TermsConditionList>
          <TermsConditionList isArabic={isArabic}>
            {isArabic
              ? `دعم خارج الصندوق لتطبيق الويب التقدمي (تطبيق الويب التقدمي)`
              : `Out of the box support for PWA (Progressive Web App)`}
          </TermsConditionList>
          <TermsConditionList isArabic={isArabic}>
            {isArabic
              ? `البنية التحتية والاستضافة السحابية والأمان (الترحيل إلى اس اس إل)`
              : ` Infrastructure, cloud hosting and security (migration to SSL)`}
          </TermsConditionList>
          <TermsConditionList isArabic={isArabic}>
            {isArabic
              ? `المساحة الافتراضية للوحدات الإعلانية المتاحة على نماذج التصميم القياسية بمواصفات الحجم المحددة مسبقًا.`
              : `Default space for Ad units available on standard design templates with
          predefined size specifications.`}
          </TermsConditionList>
          <TermsConditionList isArabic={isArabic}>
            {isArabic
              ? `تتوفر واجهات برمجة التطبيقات للمطورين على https://developers.quintype.com/next-version#introduction-to-quintype-apis`
              : `Developer APIs available at
          https://developers.quintype.com/next-version#introduction-to-quintype-apis`}
          </TermsConditionList>
          <TermsConditionList isArabic={isArabic}>
            {isArabic
              ? `دليل مستخدم التعليمات / الدعم متوفر على https://help.quintype.com/`
              : `Help/Support user guide available at https://help.quintype.com/`}
          </TermsConditionList>
          <TermsConditionList isArabic={isArabic}>
            {isArabic
              ? `مواصفات الخادم: استخدام  AWS * معمارية متعددة المستأجرين. التخزين المؤقت على مستويات متعددة من البيانات وواجهات برمجة التطبيقات وصفحات الواجهة الأمامية. استخدام Kubernetes* للصيانة وخوادم التحجيم التلقائي. مراقبة الخادم على مدار الساعة طوال أيام الأسبوع وترقية الخادم وصيانته بانتظام`
              : ` Server specifications: Using AWS* multi-tenant architecture. Caching
          at multiple levels of data, APIs and front-end pages. Use of
          Kubernetes* for maintenance and auto-scaling servers. 24X7 server
          monitoring and regular server upgrades and maintenance`}
          </TermsConditionList>
          <TermsConditionList isArabic={isArabic}>
            {isArabic
              ? `دعم البيئات المرحلية والبيتا لاختبار التغييرات`
              : `Support for staging and beta environments for testing changes`}
          </TermsConditionList>
        </TermsCondition>
        <Content isArabic={isArabic}>
          {isArabic
            ? `جميع مزودي خدمات الطرف الثالث وعمليات تكاملهم عرضة للتغيير بناءً على متطلبات العميل أو العمل. لا تتحمل كوينتايب مسؤولية التأخير أو معالجة / رفض نماذج الطلبات من قبل أطراف ثالثة (مثل جوجل وفيسبوك وتويتر وما إلى ذلك) وفقًا لإرشادات امتثال محتوى الناشر.`
            : ` All third party service providers and their integrations are subject to
        change based on client or business requirements. Quintype does not take
        responsibility for delays or application forms being processed/declined
        by third parties (Ex. Google, Facebook, Twitter etc.) as per publisher’s
        content compliance guidelines.`}
        </Content>
        <Heading isArabic={isArabic}>
          {isArabic ? `ميزات المنصة` : `Platform features`}
        </Heading>
        <SubHeading isArabic={isArabic}>
          {isArabic ? `نموذج قياسي` : `Standard template`}
        </SubHeading>
        <IncludeFeatures isArabic={isArabic}>
          <strong>
            {isArabic ? `الميزات المتضمنة:` : `Features included:`}{" "}
          </strong>
        </IncludeFeatures>
        <FeaturesWrapper>
          <PlatformFeatures>
            <ListOfFeatures isArabic={isArabic}>
              <strong>
                {isArabic ? `محرك سير العمل: ` : `Workflow Engine:`}
              </strong>{" "}
              {isArabic ? `سير عمل قابل للتكوين` : `Configurable workflow`}
            </ListOfFeatures>
            <ListOfFeatures isArabic={isArabic}>
              {isArabic ? `التزويد ببطاقة: ` : `Cardification:`}
              <strong>
                {isArabic ? `محرر مزود ببطاقة` : `Cardified Editor`}
              </strong>
            </ListOfFeatures>
            <ListOfFeatures isArabic={isArabic}>
              {isArabic ? `البطاقة : ` : `Cardification:`}
              <strong>
                {isArabic ? `إعادة استخدام البطاقة` : `Card Re-Use`}
              </strong>
            </ListOfFeatures>
            <ListOfFeatures isArabic={isArabic}>
              <strong>{isArabic ? `عناصر القصة: ` : `Story Elements:`}</strong>
              {isArabic
                ? `دعم من الدرجة الأولى لأنواع وسائط متعددة`
                : `first class support for multiple media types`}
            </ListOfFeatures>
            <ListOfFeatures isArabic={isArabic}>
              <strong>{isArabic ? `ميتايب: ` : `Metype:`}</strong>
              {isArabic
                ? `منصة تفاعل المستخدمين والتعليق في الوقت الفعلي (نسخة مجانية)`
                : `Real time user engagement and commenting
            platform`}
              <strong>{isArabic ? `` : `(Free Version)`}</strong>
            </ListOfFeatures>
            <ListOfFeatures isArabic={isArabic}>
              <strong>
                {isArabic ? `المعاينة المباشرة: ` : `Live Preview:`}
              </strong>
              {isArabic
                ? `الهواتف الذكية والأجهزة اللوحية وسطح المكتب`
                : `Smartphones, Tablets, Desktop`}
            </ListOfFeatures>
            <ListOfFeatures isArabic={isArabic}>
              {isArabic ? `النشر: ` : `Publishing:`}
              <strong>
                {isArabic
                  ? `النشر المباشر والمجدول`
                  : `Live and Scheduled Publishing`}
              </strong>
            </ListOfFeatures>
            <ListOfFeatures isArabic={isArabic}>
              {isArabic ? `وسائل التواصل الاجتماعي: ` : `Social Media:`}
              <strong>
                {isArabic
                  ? `النشر المباشر والمجدول`
                  : `Live and Scheduled posting`}
              </strong>
            </ListOfFeatures>
            <ListOfFeatures isArabic={isArabic}>
              {isArabic ? `وسائل التواصل الاجتماعي: ` : `Social Media:`}
              <strong>
                {isArabic
                  ? `مقالات فيسبوك الفورية`
                  : `Facebook Instant Articles`}
              </strong>
            </ListOfFeatures>
            <ListOfFeatures isArabic={isArabic}>
              <strong>
                {isArabic
                  ? `جوجل ايه ام بي، أخبار جوجل، آبل نيوز`
                  : `Google AMP, Google News, Apple News`}
              </strong>
            </ListOfFeatures>
          </PlatformFeatures>
          <PlatformFeatures>
            <ListOfFeatures isArabic={isArabic}>
              <strong>
                {isArabic
                  ? `الفرز / الترتيب التحريري: `
                  : `Editorial Sorting/Ranking:`}
              </strong>
              {isArabic
                ? `في الصفحة الرئيسية وصفحة القسم`
                : `on Home and Section pages`}
            </ListOfFeatures>
            <ListOfFeatures isArabic={isArabic}>
              <strong>
                {isArabic ? `الترتيب التلقائي: ` : `Auto-Ranking:`}
              </strong>
              {isArabic
                ? `المقالات الشائعة، الأكثر شيوعًا`
                : `Trending articles, most popular`}
            </ListOfFeatures>
            <ListOfFeatures isArabic={isArabic}>
              <strong>
                {isArabic ? `دفع الإخطارات: ` : `Push Notifications:`}
              </strong>
              {isArabic
                ? `دفع الرسائل إلى تطبيقات آي أو اس / أندرويد`
                : `push messages to ios/Android apps`}
            </ListOfFeatures>
            <ListOfFeatures isArabic={isArabic}>
              {isArabic ? `التحليلات: ` : `Analytics:`}
              <strong>
                {isArabic ? `أداء المحتوى` : `Content Performance`}
              </strong>
            </ListOfFeatures>
            <ListOfFeatures isArabic={isArabic}>
              <strong>
                {isArabic ? `البحث والفهرسة: ` : `Search & Indexing:`}{" "}
              </strong>
              {isArabic ? `البحث المرن` : `Elastic-search`}
            </ListOfFeatures>
            <ListOfFeatures isArabic={isArabic}>
              <strong>
                {isArabic ? `مجموعات القصة: ` : `Story Collections:`}
              </strong>
              {isArabic ? `الأكوام والحزم` : `stacks, bundles`}
            </ListOfFeatures>
            <ListOfFeatures isArabic={isArabic}>
              <strong>{isArabic ? `تحسين محركات البحث: ` : `SEO:`}</strong>
              {isArabic
                ? `البيانات الوصفية ودعم الكلمات الرئيسية`
                : `meta-data & keyword support`}
            </ListOfFeatures>
            <ListOfFeatures isArabic={isArabic}>
              <strong>{isArabic ? `الأقسام: ` : `Sections:`}</strong>
              {isArabic ? `أقسام قابلة للتكوين` : `Configurable sections`}
            </ListOfFeatures>
            <ListOfFeatures isArabic={isArabic}>
              <strong>{isArabic ? `التنقل: ` : `Navigation:`}</strong>
              {isArabic
                ? `إنشاء قائمة قابلة للتكوين`
                : `Configurable Menu creation`}
            </ListOfFeatures>
            <ListOfFeatures isArabic={isArabic}>
              <strong>
                {isArabic
                  ? `دعم الرسائل الإخبارية عبر البريد الإلكتروني عبر ميل شيمب / ماندريل`
                  : `Email Newsletter`}
              </strong>
              {isArabic ? `` : `support via Mailchimp/Mandrill`}
            </ListOfFeatures>
            <ListOfFeatures isArabic={isArabic}>
              <strong>{isArabic ? `تطبيق الويب التقدمي: ` : `PWA:`} </strong>
              {isArabic
                ? `تم تمكين تطبيق الويب التقدمي للموضوع القياسي`
                : `Progressive web app enabled for standard theme`}
            </ListOfFeatures>
            <ListOfFeatures isArabic={isArabic}>
              <strong>
                {isArabic ? `ملفات تعريف المؤلف: ` : `Author Profiles:`}{" "}
              </strong>
              {isArabic ? `"متابعة" المؤلفين` : `"Follow" authors`}
            </ListOfFeatures>
            <ListOfFeatures isArabic={isArabic}>
              <strong>
                {isArabic
                  ? `تتوفر واجهات برمجة التطبيقات على https://developers.quintype.com/docs`
                  : `APIs available at- https://developers.quintype.com/docs`}
              </strong>
            </ListOfFeatures>
          </PlatformFeatures>
        </FeaturesWrapper>
      </TermsWrapper>
    </div>
  );
}

export default TermsAndConditions;
