import styled from "styled-components";

export const TopContainer = styled.div`
padding: 0 20px;
 @media (min-width: ${props => props.theme.breakPoints.desktop}) {
 padding:  48px  0;
 }
`
export const Container = styled.div`
  padding: 24px 24px;
  max-width: ${props => props.theme.containerWidth};
  background-image: ${props => (props.bg ? props.bg : "none")};
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    display: grid;
    margin: 0 auto;
    grid-template-columns: repeat(12, 1fr);
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    grid-column: 2/13;
    flex-direction: row;
    align-items: center;
    padding: 0;
  }
`;

export const ContentContainer = styled.div`
  order: 0;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    flex-basis: 50%;
    margin-top: 15px;
    order: 1;
  }
`;

export const ImageContainer = styled.div`
  order: -1;
  text-align: center;

  @media (min-width: 400px) and (max-width: 1023px) {
    max-width: 100%;
    width: 400px;
    margin: 0 auto;

  }

  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    flex-basis: 50%;
    display: block;
    padding: 0 10px;
    order: 2;
    margin:0;
  }
`;

export const Image = styled.img`
  max-width: 100%;
`;

export const ButtonContainer = styled.div`
  margin-top: 40px;
  @media (max-width: ${props => props.theme.breakPoints.tablet}) {
    text-align: center;
  }
`;
