import styled from "styled-components";

export const AccordionContainer = styled.div`
  font-family: ${(props) => props.theme.fontFamily.primary};
  color: ${(props) => props.theme.color.gray2};
  font-weight: ${(props) => props.theme.fontWeight.regular};
  margin-top: 72px;
  padding: 0 20px;

  @media (min-width: ${(props) => props.theme.breakPoints.desktop}) {
    max-width: ${(props) => props.theme.containerWidth};
    padding: 0 80px;
  }
`;

export const HeadlineContainer = styled.div`
  text-align: ${(props) => (props.isArabic ? `right` : `center`)};
  @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
    padding-bottom: 24px;
  }
`;

export const Heading = styled.h2`
  font-size: 20px;
  color: ${(props) => props.theme.color.gray2};
  margin: 0.67em 0;
`;

export const ContentContainer = styled.div`
  padding-bottom: 30px;
`;
