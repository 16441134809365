import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import SocialLink from "../social-links/index";
import { socialLinkData } from "../data";
import {
  Container,
  InnerContainer,
  Nav,
  ListContainer,
  ListItem,
  ToolTipTitle,
  DeskTopLinkItem,
  DesktopHeader,
} from "./style";
import { QuintypeLogo } from "../../icons/index";
import { LogoLink } from "../mobile-view-container/style";

import ToolTip from "./tool-tip/index";
import Toggle from "../toggle-switch";

function DesktopViewContainer(props) {
  const isArabic = useSelector((state) => state);
  const [turnOff, setTurnoff] = useState(true);
  const [thankyou, setThankyou] = useState(false);
  const [navbarData, setNavbarData]  = useState(props.navBarData);
  const path = window.location.pathname;
  useEffect(() => {
    if (
      path === "/case-study/bloombergquint" ||
      path === "/case-study/swarajya" ||
      path === "/case-study/fortune-india" ||
      path === "/case-study/thequint" ||
      path === "/case-study/bettors-insider" ||
      path === "/case-study/vikatan" ||
      path === "/case-study/gto-publishing" ||
      path === "/case-study/prabhat-khabar" ||
      path === "/case-study/e-sakal" ||
      path === "/case-study/matter-news" ||
      path === "/case-study/mimorelia" ||
      path === "/case-study/mimorelia-spanish-version" ||
      path === "/case-study/karjalainen"
    ) {
      // alert("turn of is arabic");
      setTurnoff(false);
    }
  }, [path]);

  useEffect(()=>{
    if(path === "/thank-you")
    {
      setThankyou(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      const data = props.navBarData.filter((data)=>data.url !== "/schedule-demo")
      setNavbarData(data);
    }
  },[]);

  return (
    <DesktopHeader isIntersecting={props.isIntersecting}>
      <Container>
        <InnerContainer>
          <LogoLink title="Home page" to="/">
            <QuintypeLogo />
          </LogoLink>
          <Nav>
            <ListContainer>
              {isArabic && turnOff
                ? props.arabicnavBarData.map((data) => {
                    if (data.type === "link")
                      return (
                        <ListItem key={data.name}>
                          <DeskTopLinkItem title={data.name} to={data.url}>
                            {data.name}
                          </DeskTopLinkItem>
                        </ListItem>
                      );
                    return (
                      <ListItem key={data.name}>
                        <ToolTipTitle>{data.name}</ToolTipTitle>

                        <ToolTip list={data.listItems} />
                      </ListItem>
                    );
                  })
                : navbarData.map((data) => {
                    if (data.type === "link")
                      return (
                        <ListItem key={data.name}>
                          <DeskTopLinkItem title={data.name} to={data.url}>
                            {data.name}
                          </DeskTopLinkItem>
                        </ListItem>
                      );
                    return (
                      <ListItem key={data.name}>
                        <ToolTipTitle>{data.name}</ToolTipTitle>

                        <ToolTip list={data.listItems} />
                      </ListItem>
                    );
                  })}
                  {thankyou && <ListItem>
                      <DeskTopLinkItem title="Free Trial" to="/sign-up">
                        Free Trial
                      </DeskTopLinkItem>
                    </ListItem>}
                   
              <Toggle />
            </ListContainer>
          </Nav>
        </InnerContainer>
        {!props.isIntersecting && (
          <SocialLink target="desktop" data={socialLinkData} />
        )}
      </Container>
    </DesktopHeader>
  );
}

DesktopViewContainer.propTypes = {
  navBarData: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string,
    }),
    PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string,
      listItems: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          url: PropTypes.string,
        })
      ),
    })
  ),
  isIntersecting: PropTypes.bool,
};

export default DesktopViewContainer;
