import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";

import PrimaryButton from "../../../shared/primaryButton";
import SecondaryButton from "../../../shared/secondaryButton";

import {
  TopContainer,
  ProductIntro,
  Logo,
  ProductName,
  ProductDescriptionContainer,
  LogoWrapper,
  ProductInfo,
  ProductButtonImageContainer,
  ButtonContainer,
  ImageContainer,
  Image,
} from "./style";

import H1 from "../../../shared/h1/index";
import H2 from "../../../shared/h2/index";
import Para from "../../../shared/para-reg/index";

function TopComponent(props) {
  const [showModal, updateModalState] = useState(false);
  return (
    <TopContainer>
      <LogoWrapper>
        <ProductIntro>
          <ProductName>
            {props.data.logo.url ? (
              <Logo src={props.data.logo.url} alt={props.data.logo.alt} />
            ) : null}
            <H2>{props.data.name} </H2>
          </ProductName>
          <ProductDescriptionContainer>
            <H1>{props.data.headline}</H1>
          </ProductDescriptionContainer>
        </ProductIntro>
      </LogoWrapper>
      <ProductInfo>
        <Para> {props.data.info}</Para>
      </ProductInfo>
      <ProductButtonImageContainer>
        <ButtonContainer>
          {props.data.firstButtonContent.linkType === "internal" ? (
            <Link to={props.data.firstButtonContent.link}>
              <PrimaryButton>
                {props.data.firstButtonContent.text}
              </PrimaryButton>
            </Link>
          ) : (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={props.data.firstButtonContent.link}
            >
              <PrimaryButton>
                {props.data.firstButtonContent.text}
              </PrimaryButton>
            </a>
          )}
          {props.data.secondButtonContent.required && (
            <SecondaryButton onClick={() => updateModalState(true)}>
              {props.data.secondButtonContent.text}
            </SecondaryButton>
          )}
        </ButtonContainer>
        <ImageContainer>
          <Image
            data-src={props.data.image.url}
            alt="Let the Content Renaissance begin."
          />
        </ImageContainer>
      </ProductButtonImageContainer>
      <ModalVideo
        channel="youtube"
        isOpen={showModal}
        rel={0}
        autoplay={true}
        videoId={props.data.secondButtonContent.videoId}
        onClose={() => updateModalState(false)}
      />
    </TopContainer>
  );
}

TopComponent.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    headline: PropTypes.string,
    info: PropTypes.string,
    "free-trial": PropTypes.shape({
      link: PropTypes.string,
      title: PropTypes.string,
      text: PropTypes.string,
    }),
    "watch-video": PropTypes.shape({
      link: PropTypes.string,
      title: PropTypes.string,
      text: PropTypes.string,
    }),
  }),
};

export default TopComponent;
