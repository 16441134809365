import styled from "styled-components";


export const Container = styled.div`
  background-color: ${props => props.theme.color.gray8};
  font-family: ${props => props.theme.fontFamily.primary};
  color: ${props => props.theme.color.gray2};
  font-weight: ${props => props.theme.fontWeight.regular};
  margin: 120px 0;
  padding: 40px;

  @media (min-width: ${props => props.theme.breakPoints.tablet}) {  
    padding: 40px 80px;
    margin-bottom: 40px;
  }
`;

export const HeadlineContainer = styled.div`
  text-align: center;
  padding-bottom: 40px;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {  
    padding-bottom: 40px;
  }
`;

export const Heading = styled.h2`
    text-align: center;
    font-size:20px;
    color:${props => props.theme.color.gray2};
    padding: 40px 0;
`

export const BenefitsWrapper = styled.div`
  text-align: center;
`;

export const BenefitWrapper = styled.div`
    display: block;
    @media (min-width: ${props => props.theme.breakPoints.desktop}) {
        display: inline-block;
        width: 33.33%;
    }
`;

export const Benefit = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    padding: 0 40px;
`

export const Image = styled.img`
  display: block;
  margin: auto;
  max-width: 100%;
`;

export const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`;

export const EmptyBlock = styled.div`
    width: 100px;
    height: 100px;
    background-color: ${props => props.theme.color.green1};
`;