import styled from "styled-components"
import {Link} from "react-router-dom"

export const TelLink = styled.a`
text-decoration: underline;
color: ${props => props.theme.color.green1};
`
export const NormalLink = styled(Link)`
color: ${props => props.theme.color.green1};
`
export const ParaContainer = styled.div`
 margin-bottom: 16px;
`