export const freeTrialData = {
  title: "Sign up for a free trial",
  content: "Get full access to the Bold CMS with Ahead Front-end framework.",
  listData: [
    "Check out our cardified editor",
    "Try out content workflows",
    "Validate content with our SEO scores",
    "Try auto posting on Social Media",
    "Get help from live support within the editor",
    "View content organization styles",
  ],
};

export const rnFreeTrialData = {
  title: "Sign up for a free trial today",
  content:
    "Launch your app today and get full access to the Bold CMS and React native app.",
  listData: [
    "Real time push notifications",
    "In-app purchases for easy online purchases",
    "High uptime - 99.73%",
    "Better readability features to increase retention",
    "Offline access for bookmarked pages",
    "Understand audience better with analytics",
  ],
};
