export const clientAwards = {
  headline:
    "World's most successful digital publishing companies trust Quintype to serve content to billions of content consumers.                   ",

  firstRowlogos: [
    {
      url:
        "https://gumlet.assettype.com/quintype-website/2019-12/e410a432-32d0-4aac-94a4-b27f1d2b978c/Client_15.png",
      alt: "Fortune india"
    },

    {
      url:
        "https://gumlet.assettype.com/quintype-website/2019-12/3163b395-6987-4d61-8c7f-9fb0ff4c2229/Client_14.png",
      alt: "Bloomberg quint"
    },

    {
      url:
        "https://gumlet.assettype.com/quintype-website/2019-12/16dfdad4-5b86-4a82-b2ff-0665227335fc/Vikatan_logo_English__1_.svg",
      alt: "Vikatan"
    },

    {
      url:
        "https://www.quintype.com/karjalainen.svg",
      alt: "Karjalainen"
    },
    {
      url:
        "https://gumlet.assettype.com/quintype-website/2019-12/a194b215-f712-4280-acfd-4c3f6bcbae1c/Client_19.png",
      alt: "Freepress journal"
    }
  ],

  secondRowLogos: [
    {
      url:
        "https://gumlet.assettype.com/quintype-website/2019-12/4b0e9d2b-4317-4ce2-92e0-2b0da654f632/Client_18.png",
      alt: "the quint"
    },
    {
      url:
        "https://www.quintype.com/sakal.jpg",
      alt: "Sakal Media Group"
    },
    {
      url:
        "https://gumlet.assettype.com/quintype-website/2019-12/912fc8de-0c32-4e3c-8903-eb280cadd495/afaqs.svg",
      alt: "afaqs"
    },
    {
      url:
        "https://images.assettype.com/quintype-website/2020-09/a659941f-1093-4bb7-a73e-d81d0a110e46/prothomalo.svg",
      alt: "Prothom Alo"
    }
  ],

  "read-more": {
    text: "View all success stories",
    url: "/case-study",
    title: "case study page"
  }
};
export const quinAwards = {
  headline:
    "Awards and accolades for our solutions comes from putting our customers first",

  logos: [
    {
      url:
        "https://www.quintype.com/Award1.svg",
      alt: "CMSCritic Choice Awards"
    },

    {
      url:
        "https://www.quintype.com/Award3.svg",
      alt: "Social media engagement Award"
    },

    {
      url:
        "https://www.quintype.com/Award4.svg",
      alt: "Reader revenue initiative Award"
    },

    {
      url:
        "https://www.quintype.com/Award-10.svg",
      alt: "wan infra award for best in reader revenue initiaive"
    }
  ],

  "read-more": {
    text: "Check our trophy cabinet",
    url: "https://blog.quintype.com/people/awards",
    title: "All awards"
  }
};
export const contentWithImage = {
  headline: "How many features make for absolute freedom to publish?",
  text:
    "We think like content creators, which is why we make technology work for you and not vice versa. Our tools ready you for everything - publishing, business, and even rapid tech evolution. So that your focus is your content. And, on creating an impact! We bring you every feature that gives you absolute freedom to publish what you like, how you like.",
  imageUrl:
    "https://gumlet.assettype.com/quintype-website/2019-12/996decc0-56f5-45dc-9a26-5b456955d219/Bold_Solution.svg",
  isButtonPresent: false,
  alt: "freedom to publish"
};

export const contentWithButton = {
  headline: "If it was easier to publish, wouldn’t a lot more stories be told?",
  text:
    "We have you covered, end-to-end. If you have a specific requirement, chances are we already have that product or feature. Or, it’s underway. And, hey, if you need a custom design, we’ll develop it!",
  imageUrl:
    "https://gumlet.assettype.com/quintype-website/2019-12/fcae94ec-6529-44ec-82eb-0c7154358703/Contact_Us_02.svg",
  alt: "Covers all use case",
  isButtonPresent: true,
  buttonData: {
    buttonText: "Schedule Demo",
    buttonUrl: "/schedule-demo"
  }
};

export const belowTopComponentData = {
  headline:
    "How can you start a renaissance if you are too caught up figuring tech?",
  text:
    "Quintype gives you the freedom to publish, distribute and monetize content sans the tech hiccups. We have a suite of products to take care of every tiny detail. Unleash the power of your content on our platform.",
  imageUrl:
    "https://gumlet.assettype.com/quintype-website/2020-01/d08a5e7e-c13e-4192-b917-bba44efb4ca5/figuring_tech.svg",
  isButtonPresent: false,
  alt: "Start the Renaissance Content"
};
