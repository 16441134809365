/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useState,useMemo } from "react";
import axios from "axios";
import useForm from "react-hook-form";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import countryList from 'react-select-country-list'
import {
  getHTMLStringwithLSdata,
  getLocalStorageData,
  getReffereUrl
} from "../../utils/local-storage";
import { publisherData } from "../../config/publisher";
import {
  TextField,
  EmailField,
  TextAreaField,
  CheckBox
} from "../form-controls";
import loader from "../icons/loader.svg";
import Select from "../form-controls/select/index";
import { PhoneInputField } from "../form-controls/phone-input/index";
import {
  Wrapper,
  ContentWrapper,
  FormWrapper,
  Form,
  Content,
  List,
  Item,
  ButtonWrapper,
  Message,
  TitleContainer,
  SuccesPhoneLink,
} from "./style";

import { getDemoData } from "./data";
import { arabicgetDemoData } from "./arabic-data";
import PrimaryButton from "../shared/primaryButton";

import H1 from "../shared/h1/index";
import Para from "../shared/para-reg/index";

const { MANDRILL_API_KEY } = publisherData;

const decisionTagName = "decision";
const pageTagName = "pageviews";
const countryTagName = "country";

export default function GetDemo() {
  const { register, handleSubmit, errors, setValue, clearError } = useForm();
  const [showSuccessMessage, setSuccessMessage] = useState(false);
  const [emailBeingSent, updateEmailBeingSent] = useState(false);
  const [phoneNumber, updatePhoneNumber] = useState("");
  const [pageView, updatePageView] = useState(null);
  const [decision, updateDecison] = useState(null);
  const [country,setCountry] = useState(null);
  const isArabic = useSelector((state)=>state);
  const history = useHistory();
  const {GA_Client_ID,fc_medium,fc_campaign,fc_landing,fc_referrer,fc_source,country_code} = gaconnector.getCookieValues();
  const onSubmit = async data => {
    updateEmailBeingSent(true);
    const {
      message,
      businessEmail,
      companyName,
      contact_via_phone,
      contact_via_email,
      currentCms,
      decision,
      fullName,
      pageviews,
      phoneNumber,
    } = data;

    const utmData = getLocalStorageData();
    const referrerUrl = getReffereUrl();
    // const utmPresentableData = getHTMLStringwithLSdata(utmData);

    // const phoneMessage = contact_via_phone ? "Yes" : "No";
    // const emailMessage = contact_via_email ? "Yes" : "No";
    // const otherMessage = message ? message : "";
    // const marketingInsights = utmPresentableData.join("");
    // const {source,medium,campaign,term} = typeof utmData !== 'undefined' ? utmData[utmData.length -1] : {source:"",medium:"",campaign:"",term:""};
    let source = "";
    let medium = "";
    let campaign = "";
    let term = "";

    if( utmData)
    {
      source = utmData.source || "";
      medium = utmData.medium || "";
      campaign = utmData.campaign || "";
      term = utmData.term || "";
    }
    let customMessage = `Message- ${message}, Current CMS- ${currentCms}, How close are you to making your decision- ${decision}`;
    const listData = {
      email_address: businessEmail,
      merge_fields: {
        FNAME: fullName,
        PHONE: phoneNumber,
        MERGE6: companyName,
        MERGE7: pageviews,
        MERGE8: customMessage,
        MERGE9: source,
        MERGE10: medium,
        MERGE11: campaign,
        MMERGE12: term,
        FORMURL: "https://www.quintype.com/schedule-demo",
        REFURL: referrerUrl,
        FC_SOURCE: fc_source,
        FC_MEDIUM: fc_medium,
        FC_CAMPAIG: fc_campaign,
        FC_LP: fc_landing,
        FC_REF: fc_referrer,
        GA_CID: GA_Client_ID,
        COUNTRY_IP: gaconnector.getCookieValues().country,
        LEAD_TYPE: "Schedule Demo"
      },
      status: "subscribed",
    };
    // pass form data to mailchimp
    console.log("listdata", listData);
    axios
      .post("/subscribe", listData)
      .then((res) => {
      console.log(res)
      if(res.status === 200){
        updateEmailBeingSent(false);
        setSuccessMessage(true);
        history.push('/thank-you',{ data: { message: 'Thank You for requesting a live demo!' } });
      }

    })
      .catch(async(err) => {
        const text = err.response.data.response.text
        const {title} = JSON.parse(text);
        console.log("Error:", err.response.data.response.text)
        console.log("title", JSON.parse(text));
        if(title === "Member Exists")
        {
          axios.put("/update",listData).then((res)=>{
            if(res.status === 200)
            {
              updateEmailBeingSent(false);
              setSuccessMessage(true);
              history.push('/thank-you',{ data: { message: 'Thank You for requesting a live demo!' } });
            }
          }).catch(async(e)=> {
            console.log("err", e.response)
            try {
              const response = await axios({
              method: "post",
              url: "https://mandrillapp.com/api/1.0/messages/send-template.json",
              data: {
              key: MANDRILL_API_KEY,
              template_name: "self-service-attempted-to-sign-up",
              template_content: [
                {
                  name: "main",
                  content: `<div>Name: ${fullName}</div><div>Email: ${businessEmail}</div><div>Mobile: ${phoneNumber}</div><div>Company: ${companyName}</div><div>Page Views: ${pageviews}</div><div>Current CMS: ${currentCms ||
                    "nil"}</div><div>How close to making decision: ${decision}</div> <div>Preferred Contact method:</div><div>Other data: </div>
                     ${`utm_sorce:-${source}, utm_medium:-${medium}, campaign:-${campaign}, term:-${term}, <br><div>GA Client ID: ${GA_Client_ID || ""} | First Click Medium: ${fc_medium || ""} | First Click Campaign: ${fc_campaign || ""} | First Click Landing Page: ${fc_landing || ""} | First Click Referrer: ${fc_referrer || ""} | First Click Source: ${fc_source || ""} | Country Name: ${country_code} </div` }
                    `
                }
              ],
              message: {
                text: "",
                subject: `Schedule demo - ${companyName}`,
                from_email: "sales@quintype.com",
                from_name: "Sales",
                to: [
                  {
                    email: "sales@quintype.com",
                    type: "to"
                  }
                ]
              }
             }
            });
            if (response.status === 200) {
            updateEmailBeingSent(false);
            // setSuccessMessage(true);
            history.push('/thank-you',{ data: { message: 'Thank You for requesting a live demo!' } });
            }
        } catch (e) {
          updateEmailBeingSent(false);
        }

          });
        }
        updateEmailBeingSent(false);
    
  });

    
  };

  React.useEffect(() => {
    register({ name: "phoneNumber" }, { required: true });
    register({ name: pageTagName }, { required: true });
    register({ name: decisionTagName }, { required: true });
  }, [register]);

  const buttonContent = emailBeingSent ? (
    <img src={loader} alt="loader" />
  ) : showSuccessMessage ? (
    "DONE"
  ) : (
    "SUBMIT"
  );

  const { title, content, items, pageviews, contentlistData } = getDemoData;
  const countryOptions = useMemo(() => countryList().getData(), []);
  const { arabictitle, arabiccontent, arabicitems, arabicpageviews, arabiccontentlistData} = arabicgetDemoData;
  
  const buttonDisabledState = emailBeingSent
    ? true
    : showSuccessMessage
    ? true
    : false;

  return (
    <Wrapper>
      <ContentWrapper>
        <TitleContainer>
          <H1 small={true}>{isArabic ? arabictitle : title}</H1>
        </TitleContainer>
        <Content>
          <Para>{isArabic ? arabiccontent : content}</Para>
        </Content>
        <List>
          {isArabic ? arabiccontentlistData.map((data, index) => {
            return <Item key={index}><Para>{data}</Para></Item>;
          }):contentlistData.map((data, index) => {
            return <Item key={index}><Para>{data}</Para></Item>;
          })}
        </List>
      </ContentWrapper>
      <FormWrapper>
        <Form id="scheduledemo" onSubmit={handleSubmit(onSubmit)}>
          <TextField
            label= {isArabic ? "الاسم الكامل":"Full Name"}
            name="fullName"
            register={register}
            required
            errors={errors}
          />
          <EmailField
            label= {isArabic ? "البريد الإلكترني للعمل" : "Business Email"}
            name="businessEmail"
            register={register}
            required
            errors={errors}
          />
          <PhoneInputField
            name="phoneNumber"
            country="in"
            label={isArabic ? "رقم الهاتف" : "Phone Number"}
            value={phoneNumber}
            onChange={text => {
              setValue("phoneNumber", text);
              updatePhoneNumber(text);
              clearError("phoneNumber");
            }}
            errors={errors}
          />
           {/* <Select
            label="Choose your country"
            onChange={(value) => {
              clearError(countryTagName);
              setValue(countryTagName, value.label);
              setCountry(value);
            }}
            value={country}
            options={countryOptions}
            errors={errors}
            name={countryTagName}
          /> */}
          <TextField
            label= {isArabic ? "اسم الشركة": "Company Name"}
            name="companyName"
            register={register}
            required
            errors={errors}
          />

          <Select
            label={isArabic ? "مشاهدات الصفحة" : "Pageviews"}
            onChange={value => {
              clearError(pageTagName);
              setValue(pageTagName, value.value);
              updatePageView(value);
            }}
            value={pageView}
            options={isArabic ? arabicpageviews : pageviews}
            errors={errors}
            name={pageTagName}
          />
          <TextField
            label={isArabic ? "نظام إدارة المحتوى الحالي" : "Current CMS"}
            name="currentCms"
            register={register}
            errors={errors}
          />
          <Select
            label={isArabic ? "ما مدى قربك من اتخاذ قرارك" : "How close are you to making your decision"}
            onChange={value => {
              clearError(decisionTagName);
              setValue(decisionTagName, value.value);
              updateDecison(value);
            }}
            value={decision}
            options={isArabic ? arabicitems : items}
            errors={errors}
            name={decisionTagName}
          />

          <TextAreaField label={isArabic ? "رسالة" : "Message"} register={register} name="message" />
          {/* <div>
          <CheckBox label={isArabic ? "طريقة الإتصال المفضلة" :"Preferred contact method"} register={register} />
          </div> */}
         
          <br />
          <ButtonWrapper>
            <PrimaryButton disabled={buttonDisabledState}>
              {isArabic ? "إرسال" : buttonContent}
            </PrimaryButton>
          </ButtonWrapper>
          {Object.keys(errors).length > 0 && (
            <Message type="error">
              Oops! Please fill the mandatory fields. Some information filled is
              invalid.
            </Message>
          )}
          {showSuccessMessage && (
            <Message id="success" type="success">
              Thank you! Our product specialist will contact you within a business day. You can also reach us by phone:
              <br />
              Vineet Panjabi, CSO
              <SuccesPhoneLink href="tel:+919899469945">
                +91 98994 69945
              </SuccesPhoneLink>{" "}
              <br />
              Chad Hussain, VP International Sales
              <SuccesPhoneLink href="tel:+00971552369241">
               +00971552369241
              </SuccesPhoneLink>
            </Message>
          )}
        </Form>
      </FormWrapper>
    </Wrapper>
  );
}