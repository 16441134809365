/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect,useMemo } from "react";
import axios from "axios";
import useForm from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import countryList from 'react-select-country-list'
import { emailRecipientData } from "../../config/publisher";
import {
  getHTMLStringwithLSdata,
  getLocalStorageData,
  getReffereUrl
} from "../../utils/local-storage";
import { publisherData } from "../../config/publisher";
import { TextField, EmailField, TextAreaField } from "../form-controls";
import { PhoneInputField } from "../form-controls/phone-input/index";
import loader from "../icons/loader.svg";

import {
  Wrapper,
  ContentWrapper,
  FormWrapper,
  Form,
  ButtonWrapper,
  Message,
  TitleContainer,
  SuccesPhoneLink
} from "../get-demo/style";
import Select from "../form-controls/select/index";
import { TelLink, NormalLink, ParaContainer } from "./style";
import { contactUsData } from "./data";
import { arabiccontactUsData } from "./arabic-data";
import PrimaryButton from "../shared/primaryButton";

import H1 from "../shared/h1/index";
import Para from "../shared/para-reg/index";

const { MANDRILL_API_KEY } = publisherData;

export default function ContactUs() {
  const isArabic = useSelector((state) => state);
  const { title, content, departmentOptions } = contactUsData;
  // eslint-disable-next-line no-unused-vars
  const { arabictitle, arabiccontent, arabicdepartmentOptions } =
    arabiccontactUsData;
  const { register, handleSubmit, errors, setValue, clearError } = useForm();
  const [showSuccessMessage, setSuccessMessage] = useState(false);
  const [emailBeingSent, updateEmailBeingSent] = useState(false);
  const [phoneNumber, updatePhoneNumber] = useState("");
  const [departmentValue, updateDepartmentValue] = useState("Choose");
  const [country,setCountry] = useState(null);
  const countryTagName = "country";
  const history = useHistory();
  const {GA_Client_ID,fc_medium,fc_campaign,fc_landing,fc_referrer,fc_source,country_code} = gaconnector.getCookieValues();
  
  useEffect(() => {
    register({ name: "phoneNumber" }, { required: true });
    register({ name: "department" }, { required: true });
  }, [register]);

  const onSubmit = async (data) => {
    updateEmailBeingSent(true);
    const { businessEmail, fullName, message, department,companyName } = data;

    let recepientEmailAddress = emailRecipientData[department];
    const utmData = getLocalStorageData();
    const referrerUrl = getReffereUrl();
    // const utmPresentableData = getHTMLStringwithLSdata(utmData);
    // const otherMessage = message ? message : "";
    // const marketingInsights = utmPresentableData.join("");
    let source = "";
    let medium = "";
    let campaign = "";
    let term = "";
    if( utmData)
    {
      source = utmData.source || "";
      medium = utmData.medium || "";
      campaign = utmData.campaign || "";
      term = utmData.term || "";
    }
    let customMessage = `Message- ${message}, Department- ${department}`

    const listData = {
      email_address: businessEmail,
      merge_fields: {
        FNAME: fullName,
        PHONE: phoneNumber,
        MERGE6: companyName,
        MERGE7: "Less than 5 Million",
        MERGE8: customMessage,
        MERGE9: source,
        MERGE10: medium,
        MERGE11: campaign,
        MERGE12: term,
        FORMURL: "https://www.quintype.com/contact-us",
        REFURL: referrerUrl,
        FC_SOURCE: fc_source,
        FC_MEDIUM: fc_medium,
        FC_CAMPAIG: fc_campaign,
        FC_LP: fc_landing,
        FC_REF: fc_referrer,
        GA_CID: GA_Client_ID,
        COUNTRY_IP: gaconnector.getCookieValues().country,
        LEAD_TYPE: "Contact Us"
      },
      status: "subscribed",
    };

    console.log("listdata", listData);
    axios
      .post("/subscribe", listData)
      .then((res) => {
        console.log(res)
        if(res.status === 200)
        {
          updateEmailBeingSent(false);
          setSuccessMessage(true);
         
        }
      })
      .catch(async(err) => {
        console.log("Error:", err);
        updateEmailBeingSent(false);
        const text = err.response.data.response.text
        const {title} = JSON.parse(text);
        if(title === "Member Exists")
        {
          axios.put("/update",listData).then((res)=>{
            if(res.status === 200)
            {
              updateEmailBeingSent(false);
              setSuccessMessage(true);
              history.push('/thank-you',{ data: { message: 'Thank You for contact us!' } });
            }
          }).catch(async (e)=>{
            console.log("err",e.response);
            try {
              const response = await axios({
                method: "post",
                url: "https://mandrillapp.com/api/1.0/messages/send-template.json",
                data: {
                  key: MANDRILL_API_KEY,
                  template_name: "self-service-attempted-to-sign-up",
                  template_content: [
                    {
                      name: "main",
                      content: `<div>Name: ${fullName}</div><div>Email: ${businessEmail}</div><div>Mobile: ${phoneNumber}</div><div>Department: ${department} </div>
                        <div>Other data: </div>
                             ${`utm_source:- ${source}, utm_medium:- ${medium},  campaign:- ${campaign} , term:-${term}, <br><div>GA Client ID: ${GA_Client_ID || ""} | First Click Medium: ${fc_medium || ""} | First Click Campaign: ${fc_campaign || ""} | First Click Landing Page: ${fc_landing || ""} | First Click Referrer: ${fc_referrer || ""} | First Click Source: ${fc_source || ""} | Country Name: ${country_code} </div`}
                            `,
                    },
                  ],
        
                  message: {
                    text: "",
                    subject: `Contact Us- ${fullName} ${businessEmail}`,
                    from_email: "sales@quintype.com",
                    from_name: "Sales",
                    to: [
                      {
                        email: recepientEmailAddress,
                        type: "to",
                      },
                    ],
                  },
                },
              });
              if (response.status === 200) {
                updateEmailBeingSent(false);
                // setSuccessMessage(true);
                history.push("/thank-you",{ data: { message: 'Thank you for contacting us! ' } });
              }
            } catch (e) {
              updateEmailBeingSent(false);
            }
          })
        }
       
    });
      
    
  };

  // const countryOptions = useMemo(() => countryList().getData(), []);
  const buttonContent = emailBeingSent ? (
    <img src={loader} alt="loader" />
  ) : showSuccessMessage ? (
    "DONE"
  ) : (
    "SUBMIT"
  );

  const buttonDisabledState = emailBeingSent
    ? true
    : showSuccessMessage
    ? true
    : false;

  return (
    <Wrapper>
      <ContentWrapper>
        <TitleContainer>
          <H1 small={true}>{isArabic ? arabictitle : title}</H1>
        </TitleContainer>
        <ParaContainer>
          <Para>{isArabic ? arabiccontent : content}</Para>
        </ParaContainer>
        <ParaContainer>
          <Para>
            <strong>{isArabic ? `هند` : `Let's talk`}</strong>
            <br />
            {isArabic
              ? `مدينة بنغالورو، ولاية كارنا تاكا`
              : `Vineet Panjabi, CSO`}
            <br />
            {isArabic ? (
              <>
                رقم الهاتف:
                <TelLink href="tel:+919899469945">+91 98994 69945</TelLink>
              </>
            ) : (
              <>
                Phone:
                <TelLink href="tel:+919899469945">+91 98994 69945</TelLink>
              </>
            )}
          </Para>
        </ParaContainer>
        <Para>
          <strong>{isArabic ? `الولايات المتحدة الأمريكية` : ``}</strong>
          <br />
          {isArabic
            ? `مدينة سان ماتيو، ولاية كاليفورنيا`
            : `Chad Hussain, VP International Sales`}
          <br />
          {isArabic ? (
            <>
              رقم الهاتف:
              <TelLink href="tel:+00971552369241">+00971552369241</TelLink>
            </>
          ) : (
            <>
              <>
                Phone:
                <TelLink href="tel:+00971552369241">+00971552369241</TelLink>
              </>
            </>
          )}
        </Para>
        {isArabic ? (
          <>
            <Para>
              انقر<NormalLink to="/schedule-demo"> هنا</NormalLink> للحصول على
              عرض توضيحي لمنتجاتنا
            </Para>
          </>
        ) : (
          <>
            <Para>
              Click <NormalLink to="/schedule-demo">here</NormalLink> to get a
              demo of our products.
            </Para>
          </>
        )}
      </ContentWrapper>
      <FormWrapper>
        <Form id="contact" onSubmit={handleSubmit(onSubmit)}>
          <TextField
            label={isArabic ? `الاسم بالكامل` : `Full Name`}
            name="fullName"
            register={register}
            required
            errors={errors}
          />
          <EmailField
            label={
              isArabic
                ? `عنوان البريد الإلكتروني الخاص بالعمل`
                : `Business Email`
            }
            name="businessEmail"
            register={register}
            required
            errors={errors}
          />
          <PhoneInputField
            name="phoneNumber"
            country="in"
            label={isArabic ? `رقم الهاتف` : `Phone Number`}
            value={phoneNumber}
            onChange={(text) => {
              setValue("phoneNumber", text);
              updatePhoneNumber(text);
              clearError("phoneNumber");
            }}
            errors={errors}
          />
          {/* <Select
            label="Choose your country"
            onChange={(value) => {
              clearError(countryTagName);
              setValue(countryTagName, value.label);
              setCountry(value);
            }}
            value={country}
            options={countryOptions}
            errors={errors}
            name={countryTagName}
          /> */}
          <TextField
            label="Company Name"
            name="companyName"
            register={register}
            required
            errors={errors}
          />
          <Select
            label={isArabic ? `القسم` : `Department`}
            onChange={(value) => {
              clearError("department");
              setValue("department", value.value);
              updateDepartmentValue(value);
            }}
            options={departmentOptions}
            errors={errors}
            name="department"
            value={departmentValue}
          />

          <TextAreaField
            label={isArabic ? `رسالة` : `Message`}
            register={register}
            name="message"
          />

          <br />
          <ButtonWrapper>
            <PrimaryButton disabled={buttonDisabledState}>
              {isArabic ? `إرسال أو تقديم` : buttonContent}
            </PrimaryButton>
          </ButtonWrapper>
          {Object.keys(errors).length > 0 && (
            <Message type="error">
              Oops! Please fill the mandatory fields. Some information filled is
              invalid.
            </Message>
          )}
          {showSuccessMessage && (
            <Message id="success" type="success">
              Thank you! We will get back to you within 1 business day. You can also reach us by phone:
              <br />
              Vineet Panjabi, CSO
              <SuccesPhoneLink href="tel:+919899469945">
                +91 98994 69945
              </SuccesPhoneLink>{" "}
              <br />
              Chad Hussain, VP International Sales
              <SuccesPhoneLink href="tel:+00971552369241">
               +00971552369241
              </SuccesPhoneLink>
            </Message>
            
          )}
        </Form>
      </FormWrapper>
    </Wrapper>
  );
}
