import styled from "styled-components";

export const AboutPageWrapper = styled.div`
  max-width: ${props => props.theme.containerWidth};
  padding: 80px 24px;
  margin: 0 auto;
  @media (max-width: ${props => props.theme.breakPoints.tablet}) {
    padding: 32px 24px;
  }
`;

export const OuterContainer = styled.div`
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }
`;

export const InnerWrapper = styled.div`
  grid-column: 2/12;
`;

export const Container = styled.div`
`;

export const TitleContainer = styled.div`

  margin: 0 0 24px 0;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    margin: 0 0 32px 0;
  }
`;

export const AboutPageContent = styled.div`
  margin: 20px 0 0 0;
`;

export const Image = styled.img`
  padding: 0 0 0 25px;
`;

export const Heading = styled.h2`
  font-family: ${props => props.theme.fontFamily.primary};
  font-size: ${props => props.theme.fontSize.s};
  font-weight: ${props => props.theme.fontWeight.bold};
  line-height: ${props => props.theme.lineHeight.lh3};
  letter-spacing: 1px;
  text-align: center;
  color: ${props => props.theme.color.gray2};
  margin: 50px 0 24px 0;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    font-size: ${props => props.theme.fontSize.xxxl};
    letter-spacing: 1.6px;
    margin: 130px 0 80px 0;
  }
`;

export const Members = styled.div`
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
    margin: 0 auto;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const TeamPageMobile = styled.div`
  margin: 0 0 40px 0;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    display: none;
  }
`;

export const TeamMemberImage = styled.div`
  margin: 0 25px 0 0;
`;

export const MemberImage = styled.img`
  object-fit: cover;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    width: 200px;
    height: 200px;
  }
`;

export const TeamMemberName = styled.h2`
  font-family: ${props => props.theme.fontFamily.primary};
  font-size: ${props => props.theme.fontSize.s};
  font-weight: ${props => props.theme.fontWeight.bold};
  line-height: ${props => props.theme.lineHeight.lh3};
  letter-spacing: 1px;
  color: ${props => props.theme.color.gray2};
  text-transform: uppercase;
  padding-bottom: 10px;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    font-size: ${props => props.theme.fontSize.l};
  }
`;

export const TeamMemberPosition = styled.h2`
  font-family: ${props => props.theme.fontFamily.primary};
  font-size: ${props => props.theme.fontSize.xxs};
  font-weight: ${props => props.theme.fontWeight.bold};
  line-height: ${props => props.theme.lineHeight.lh3};
  color: ${props => props.theme.color.black};
  padding: 0 0 12px 0;
  text-transform: uppercase;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    font-size: ${props => props.theme.fontSize.xs};
  }
`;

export const TeamMemberDescription = styled.p`
  font-family: ${props => props.theme.fontFamily.primary};
  font-size: ${props => props.theme.fontSize.s};
  line-height: ${props => props.theme.lineHeight.lh3};
  color: ${props => props.theme.color.gray2};
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    font-size: ${props => props.theme.fontSize.m};
    grid-area: 2/2/3/3;
  }
`;

export const TeamSocialDetails = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 8px 0 0 0;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    justify-content: center;
    grid-area: 2/1/2/2;
    margin: 36px 0 0 0;
  }
`;

export const SocialLink = styled.a`
  margin: 0 25px 0 0;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    margin: 0 40px 0 0;
  }
`;

export const TeamPageDesktop = styled.div`
  display: none;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    display: flex;
    margin: 0 0 72px 0;
  }
`;

export const MemberDetails = styled.div`
  margin: 0 0 0 30px;
`;
