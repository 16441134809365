import styled from "styled-components";

export const Label = styled.label`
  font-size: ${props => props.theme.fontSize.s};
  color: ${props => props.theme.color.black};
  padding-bottom: 8px;
  display: block;
  font-family: ${props => props.theme.fontFamily.primary};
  font-weight: ${props => props.theme.fontWeight.bold};
`;

export const Input = styled.input`
  color: #000;
  border-radius: 3px;
  width: 100%;
  display: block;
  border: 1px solid;
  border-color: ${props =>
    props.error
      ? props => props.theme.color.red
      : props => props.theme.color.gray5};
  padding: 14px;
  margin-bottom: 24px;
  font-size: ${props => props.theme.fontSize.xs};
  font-family: ${props => props.theme.fontFamily.primary};
  color: ${props => props.theme.color.black};

  &:focus {
    box-shadow: none;
    outline: none;
  }
`;

export const Select = styled.select`
  color: #000;
  border-radius: 3px;
  width: 100%;
  display: block;
  border: 1px solid;
  border-color: #e3e3e3;
  padding: 8px;
  margin-bottom: 24px;
  height: 40px;
  background-color: white;
  -webkit-appearance: none;
  font-family: ${props => props.theme.fontFamily.primary};
  font-size: ${props => props.theme.fontSize.m};
`;

export const Option = styled.option``;

export const TextArea = styled.textarea`
width: 100%;
  font-size: ${props => props.theme.fontSize.xs};
  min-height: 200px;
  color: ${props => props.theme.color.black};
  padding: 14px;
  font-family: ${props => props.theme.fontFamily.primary};
  border-radius: 3px;
  border: solid 1px ${props => props.theme.color.gray5};
  margin-bottom: 24px;
  /* @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    
  } */
`;

export const CheckboxInput = styled.input`
  margin-right: 14px;
`;

export const Container = styled.label`
  margin-right: 48px;
`;
