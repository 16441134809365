import React from "react";
import { useSelector } from "react-redux";
import TopComponent from "./shared/top-component";
import ImageWithContent from "./shared/image-with-content";
import ImageWithContentAltType from "./shared/image-with-content-alt-type";
import ClientReview from "./shared/client-review";
import TestimonialSolutions from "./shared/testimonial-solutions";
import { data } from "./data";
import { arabicdata } from "./arabic-data";
import { monetizeTestimonialSolutions } from "./data";
import { arabicmonetizeTestimonialSolutions } from "./arabic-data";

function MonetizeContent() {
  const isArabic = useSelector((state) => state);
  return (
    <React.Fragment>
      <div dir={isArabic ? "rtl" : "ltr"} lang={isArabic ? `ar` : `en`}>
        <TopComponent
          data={
            isArabic
              ? arabicdata.monetize_content["top-component"]
              : data.monetize_content["top-component"]
          }
        />
        <ImageWithContentAltType
          data={
            isArabic
              ? arabicdata.monetize_content["image-with-content2"]
              : data.monetize_content["image-with-content2"]
          }
        />
        <ClientReview
          data={
            isArabic
              ? arabicdata.monetize_content.review
              : data.monetize_content.review
          }
        />
        <ImageWithContent
          data={
            isArabic
              ? arabicdata.monetize_content["image-with-content"]
              : data.monetize_content["image-with-content"]
          }
        />
        <TestimonialSolutions
          data={
            isArabic
              ? arabicmonetizeTestimonialSolutions
              : monetizeTestimonialSolutions
          }
        />
      </div>
    </React.Fragment>
  );
}

export default MonetizeContent;
