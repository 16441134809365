import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { useSelector } from "react-redux";

import TopComponent from "./shared/top-component";
import ImageWithContent from "./shared/image-with-content";
import ImageWithContentAltType from "./shared/image-with-content-alt-type";
import ClientReview from "./shared/client-review";
import ContentWithImage from "./shared/content-with-image";
import { data } from "./data";
import { arabicdata } from "./arabic-data";

import { lastImageComentComponentData } from "./data";
import { arabiclastImageComentComponentData } from "./arabic-data";
function SmallPublishers() {
  const isArabic = useSelector((state) => state);

  const themeContext = useContext(ThemeContext);
  return (
    <React.Fragment>
      <div dir={isArabic ? "rtl" : "ltr"} lang={isArabic ? `ar` : `en`}>
        <TopComponent
          data={
            isArabic
              ? arabicdata.small_publishers["top-component"]
              : data.small_publishers["top-component"]
          }
        />
        <ImageWithContent
          data={
            isArabic
              ? arabicdata.small_publishers["image-with-content"]
              : data.small_publishers["image-with-content"]
          }
        />
        <ClientReview
          data={
            isArabic
              ? arabicdata.small_publishers.review
              : data.small_publishers.review
          }
        />
        <ImageWithContentAltType
          data={
            isArabic
              ? arabicdata.small_publishers["image-with-content2"]
              : data.small_publishers["image-with-content2"]
          }
        />
        <ClientReview
          data={
            isArabic
              ? arabicdata.small_publishers.review2
              : data.small_publishers.review2
          }
        />
        <ImageWithContent
          data={
            isArabic
              ? arabicdata.small_publishers["image-with-content3"]
              : data.small_publishers["image-with-content3"]
          }
        />
        <ContentWithImage
          bg={themeContext.color.background}
          data={
            isArabic
              ? arabiclastImageComentComponentData
              : lastImageComentComponentData
          }
        />
      </div>
    </React.Fragment>
  );
}

export default SmallPublishers;
