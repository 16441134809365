import { useEffect } from "react";
import FontFaceObserver from "fontfaceobserver";
import PropTypes from "prop-types";
import { allFonts } from "./generic/theme";

function FontFaceObserverComp({ updateFontsLoaded }) {

  useEffect(() => {
    const fonts = allFonts.map(font => new FontFaceObserver(font));
    Promise.all(fonts.map(font => font.load())).then(updateFontsLoaded);
  }, []);
  return null;
}

FontFaceObserverComp.propTypes = {
  updateFontsLoaded: PropTypes.func
};

export default FontFaceObserverComp;
