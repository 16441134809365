/* eslint-disable no-undef */
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import useForm from "react-hook-form";
import { useSelector } from "react-redux";
import {
  getHTMLStringwithLSdata,
  getLocalStorageData,
  getHTMLStringwithGaConnectordata,
  getReffereUrl
} from "../../../../utils/local-storage";
import { domainNameCleaner } from "../../../../utils/domainNameCleaner";
import ControlledTextField from "../controlled-text-field/index";
import { TextField, EmailField } from "../../../form-controls";
import CheckBox from "../checbox/index";
import { PhoneInputField } from "../../../form-controls/phone-input/index";
import loader from "../../../icons/loader.svg";
import {
  FormWrapper,
  Form,
  SuccessLink,
  Message,
  SuccesPhoneLink
} from "../../../get-demo/style";
import {
  AccountNamePara,
  AccountNameSpan,
  TrialButtonWrapper,
} from "../../style";

import PrimaryButton from "../../../shared/primaryButton";

export default function FreeTrialForm(props) {
  const isRNFreeTrial = props.rnFreeTrial;
  const isArabic = useSelector((state) => state);
  const { register, handleSubmit, errors, setValue, clearError, setError } =
    useForm();
  const [showSuccessMessage, setSuccessMessage] = useState(false);
  const [emailBeingSent, updateEmailBeingSent] = useState(false);
  const [accountName, updateAccountName] = useState("");
  const [domainCheckLoader, setDomainCheckLoader] = useState(false);
  const [domainAvailability, setDomainAvailability] = useState(null);
  const [phoneNumber, updatePhoneNumber] = useState("");
  const {GA_Client_ID,fc_medium,fc_campaign,fc_landing,fc_referrer,fc_source,country_code} = gaconnector.getCookieValues();
  const currentAccountName = useRef("");
  currentAccountName.current = accountName;

  useEffect(() => {
    register({ name: "phoneNumber" }, { required: true });
    register({ name: "accountName" }, { required: true });
  }, [register]);

  const sendMailToSales = (
    name,
    email,
    mobile,
    company,
    domain,
    marketingData
  ) => {
    axios
      .post("/auth/attemptToSignUp", {
        name,
        email,
        mobile,
        company,
        domain,
        other: marketingData,
        isRNFreeTrial,
      })
      .then((response) => {
        if (response && response.data && response.data.result === "pass") {
        }
      });
  };

  const sendVerificationMail = (name, email, mobile, company, domain) => {
    axios
      .post("/auth/generateHash", {
        name,
        email,
        mobile,
        company,
        domain,
      })
      .then((response) => {
        if (response && response.data && response.data.result === "pass") {
          updateEmailBeingSent(false);
          setSuccessMessage(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  

  const onSubmit = async (data) => {
    if (domainAvailability !== true) {
      setError("accountName", "unavailable", "user name unavailable");
      return;
    }

    updateEmailBeingSent(true);
    const { fullName, businessEmail, companyName, phoneNumber, accountName } =
      data;

      const utmData = getLocalStorageData();
      const utmPresentableData = utmData && getHTMLStringwithLSdata(utmData);
      let marketingInsights = utmPresentableData;
      marketingInsights = marketingInsights + getHTMLStringwithGaConnectordata(gaconnector.getCookieValues());
      console.log("marketting insights",marketingInsights);
      const referrerUrl = getReffereUrl();
      let source = "";
      let medium = "";
      let campaign = "";
      let term = "";

      if( utmData)
      {
        source = utmData.source || "";
        medium = utmData.medium || "";
        campaign = utmData.campaign || "";
        term = utmData.term || "";
      }
      const pageviews = "Less than 5 Million";
      const customMessage = `Message`;

      const listData = {
        email_address: businessEmail,
        merge_fields: {
          FNAME: fullName,
          PHONE: phoneNumber,
          MERGE6: companyName,
          MERGE7: pageviews,
          MERGE8: customMessage,
          MERGE9: source,
          MERGE10: medium,
          MERGE11: campaign,
          MMERGE12: term,
          FORMURL: "https://www.quintype.com/sign-up",
          REFURL: referrerUrl,
          FC_SOURCE: fc_source,
          FC_MEDIUM: fc_medium,
          FC_CAMPAIG: fc_campaign,
          FC_LP: fc_landing,
          FC_REF: fc_referrer,
          GA_CID: GA_Client_ID,
          COUNTRY_IP: gaconnector.getCookieValues().country,
          ACCOUNT: accountName,
          LEAD_TYPE: "Sign Up"
        },
        status: "subscribed",
      };
    console.log("listData",listData); 
    axios.post("/subscribe",listData).then((res)=>{
      console.log("response",res);
    }).catch((err)=>{
      console.log("error", err);
      const text = err.response.data.response.text
      const {title} = JSON.parse(text);
      if(title === "Member Exists")
      {
        axios.put("/update",listData).then((res)=>{
          if(res.status === 200)
          {
            updateEmailBeingSent(false);
            setSuccessMessage(true);
            
          }
        }).catch(async (e)=>{
          console.log('err',e.response);
          sendMailToSales(
            fullName,
            businessEmail,
            phoneNumber,
            companyName,
            accountName,
            marketingInsights
          );
        })
      }
     
    })
    
    sendVerificationMail(
      fullName,
      businessEmail,
      phoneNumber,
      companyName,
      accountName
    );
  };

  const domainAvailabilityCheck = (value) => {
    value = String(value);
    setDomainCheckLoader(true);
    if (value.length > 0) {
      axios
        .get(`/api/v1/publisher/check?domain=${value.toLowerCase()}`)
        .then((res) => {
          if (currentAccountName.current === value) {
            setDomainCheckLoader(false);
            setDomainAvailability(!res.data.exists ? true : false);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setDomainAvailability(null);
      setDomainCheckLoader(false);
    }
  };

  const buttonContent = emailBeingSent ? (
    <img src={loader} alt="loader" />
  ) : showSuccessMessage ? (
    "DONE"
  ) : (
    "SUBMIT"
  );

  const buttonDisabledState = emailBeingSent
    ? true
    : showSuccessMessage
    ? true
    : false;

  return (
    <FormWrapper>
      <Form id="freetrial" onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label={isArabic ? `الاسم الأول` : `Full Name`}
          name="fullName"
          register={register}
          required
          errors={errors}
        />
        <EmailField
          label={isArabic ? `عنوان البريد الإلكتروني الرسمي` : `Business Email`}
          name="businessEmail"
          register={register}
          required
          errors={errors}
        />

        <TextField
          label={isArabic ? `اسم الشركة` : `Company Name`}
          name="companyName"
          register={register}
          required
          errors={errors}
        />
        <PhoneInputField
          name="phoneNumber"
          country="in"
          label={isArabic ? `رقم الهاتف` : `Phone Number`}
          value={phoneNumber}
          onChange={(text) => {
            setValue("phoneNumber", text);
            updatePhoneNumber(text);
            clearError("phoneNumber");
          }}
          errors={errors}
        />

        <ControlledTextField
          label={isArabic ? `اسم الحساب` : `Account Name`}
          name="accountName"
          value={accountName}
          domainAvailability={domainAvailability}
          domainCheckLoader={domainCheckLoader}
          onChange={(event) => {
            let { value } = event.target;
            //manually clearing errors for controlled components
            if (
              errors["accountName"] &&
              Object.keys(errors["accountName"]).length > 0
            ) {
              clearError("accountName");
            }
            value = domainNameCleaner(value);
            setValue("accountName", value);
            updateAccountName(value);
            domainAvailabilityCheck(value);
          }}
          errors={errors}
        />
        <AccountNamePara>
          <AccountNameSpan>
            {!isArabic && accountName.trim() === ""
              ? "account-name"
              : accountName}
          </AccountNameSpan>
          {isArabic ? `اسم الحساب -----` : `.madrid.quintype.io`}
        </AccountNamePara>
        <p>
          <AccountNameSpan>{!isArabic && `Note`}</AccountNameSpan>
          {isArabic
            ? `ملحوظة: يجب أن يحتوي فقط على ما يلي: "حروف أبجدية" "أرقام" أو "رموز"`
            : `: Should only
          contain characters: 'A-z,' '1-9' or '-'`}
        </p>
        <CheckBox
          errors={errors}
          label="I agree to the privacy policy"
          name="privacy"
          register={register}
        />

        <TrialButtonWrapper>
          <PrimaryButton disabled={buttonDisabledState}>
            {isArabic ? `إرسال` : buttonContent}
          </PrimaryButton>
        </TrialButtonWrapper>
        {Object.keys(errors).length > 0 && (
          <Message type="error">
            Oops! Please fill the mandatory fields. Some information filled is
            invalid.
          </Message>
        )}
        {showSuccessMessage && (
          <Message id="success" type="success">
            Thank you for signing up.
            <br />
            An activation link has been sent to your registered email address.
            Click to verify. If you haven't received the activation link, please
            <SuccessLink alt="contact us page" to="/contact-us">
              contact us
            </SuccessLink>{" "}
            .
            <br />
            You can also reach us by phone:
            <br/>
            Vineet Panjabi, CSO
            <SuccesPhoneLink href="tel:+919899469945">
              +91 98994 69945
            </SuccesPhoneLink>{" "}
            <br />
            Chad Hussain, VP International Sales
            <SuccesPhoneLink href="tel:+00971552369241">
            +00971552369241
            </SuccesPhoneLink>
          </Message>
        )}
      </Form>
    </FormWrapper>
  );
}
