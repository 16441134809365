import React from "react";
import PropTypes from "prop-types";

import {
  MainContainer,
  HeadlineContainer,
  FeatureContainer,
  FeatureItem,
  Image,
  SubHeadline,
  Description,
  Content
} from "./style";

import H2 from "../../../shared/h2/index";

function Features({ data }) {
  return (
    <MainContainer>
      {data.headline && (
        <HeadlineContainer>
          <H2>{data.headline}</H2>
        </HeadlineContainer>
      )}
      <FeatureContainer>
        {data.feature.map((item, index) => {
          return (
            <FeatureItem key={index}>
              <Image src={item.url} alt={item.alt}></Image>
              <Content>
                <SubHeadline>{item.title}</SubHeadline>
                <Description>{item.description}</Description>
              </Content>
            </FeatureItem>
          );
        })}
      </FeatureContainer>
    </MainContainer>
  );
}

Features.propTypes = {
  data: PropTypes.shape({
    headline: PropTypes.string,
    feature: PropTypes.array
  })
};

export default Features;
