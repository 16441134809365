export const arabicfreeTrialData = {
  arabictitle: "قم بالتسجيل للحصول على فترة تجريبية مجانية",
  arabiccontent: "احصل على وصول كامل إلى بولد سي إم إس مع إطار تطوير أمامي",
  arabiclistData: [
    "راجع محررنا المعتمد",
    "قم بتجربة إطارات عمل محتوانا",
    "قم بالتحقق من المحتوى بواسطة تقييمات تحسين محركات البحث الخاص بنا",
    "جرب النشر التلقائي على مواقع التواصل الاجتماعي",
    "احصل على المساعدة من الدعم الحي داخل المحرر",
    "اطلع على أساليب تنظيم المحتوى",
  ],
};

export const rnFreeTrialData = {
  title: "Sign up for a free trial today",
  content:
    "Launch your app today and get full access to the Bold CMS and React native app.",
  listData: [
    "Real time push notifications",
    "In-app purchases for easy online purchases",
    "High uptime - 99.73%",
    "Better readability features to increase retention",
    "Offline access for bookmarked pages",
    "Understand audience better with analytics",
  ],
};
