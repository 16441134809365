import React from "react";
import { PrimaryButtonContainer, PrimaryLinkContainer,  LinkItem  } from "./style";

function PrimaryButton(props) {
  const {disabled} =props

  if(props.isExternal) {
    return <LinkItem   href={props.link} title={props.title}  target="_blank">{props.children}</LinkItem> 
  }
  else if(props.link ) {
    return  <PrimaryLinkContainer  to={props.link} title={props.title} >{props.children}</PrimaryLinkContainer>
  }
  return <PrimaryButtonContainer disabled={disabled} className="influ2--goal">{props.children}</PrimaryButtonContainer>
}

export default PrimaryButton;
