import React from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { SEO_TAGS } from "../SEO";

function Meta() {
  const { pathname } = useLocation();
  const seo = SEO_TAGS[pathname] || SEO_TAGS["/"];
  return seo ? (
    <Helmet>
      <title>{seo.title}</title>
      <meta name="title" content={seo.title} />
      <meta name="description" content={seo.description} />
      {seo.og && seo.og.title && (
        <meta property="og:title" content={seo.og.title} />
      )}
      {seo.og && seo.og.site_name && (
        <meta property="og:site_name" content={seo.og.site_name} />
      )}
      {seo.og && seo.og.url && <meta property="og:url" content={seo.og.url} />}
      {seo.og && seo.og.description && (
        <meta property="og:description" content={seo.og.description} />
      )}
      {seo.og && seo.og.type && (
        <meta property="og:type" content={seo.og.type} />
      )}
      {seo.og && seo.og.image && (
        <meta property="og:image" content={seo.og.image} />
      )}
      {seo.twitter && seo.twitter.card && (
        <meta property="twitter:card" content={seo.twitter.card} />
      )}
      {seo.twitter && seo.twitter.site && (
        <meta property="twitter:site" content={seo.twitter.site} />
      )}
      {seo.twitter && seo.twitter.title && (
        <meta property="twitter:title" content={seo.twitter.title} />
      )}
      {seo.twitter && seo.twitter.description && (
        <meta
          property="twitter:description"
          content={seo.twitter.description}
        />
      )}
      {seo.twitter && seo.twitter.image && (
        <meta property="twitter:image" content={seo.twitter.image} />
      )}
      {seo.structuredData && (
        <script type="application/ld+json">
          {JSON.stringify(seo.structuredData)}
        </script>
      )}
      <meta name="google-site-verification" content="VnwNd5u5ObKMx5toYSyXZoFkP6l9re6HtQyM7IRmXYw" />
    </Helmet>
  ) : null;
}

export default Meta;
