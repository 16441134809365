export const contactUsData = {
  title: "Have questions? Need help?",
  content:
    "Whether you are already a publisher on Quintype or are exploring our offerings, reach our team to learn more.",
  departmentOptions: [
    { label: "Human Resources", value: "Human Resources" },
    { label: "Sales", value: "Sales" },
    { label: "Marketing", value: "Marketing" },
    { label: "Customer Support", value: "Customer Support" },
    { label: "Partnerships", value: "Partnerships" }
  ]
};
