export const data = {
  bold: {
    "top-component": {
      name: "BOLD",
      headline: "A headless CMS that understands your content.",
      info:
        "Digital publishing has been stuck in the Dark Ages. Be successful in the modern media space with Bold CMS - An empowering solution to create, curate, distribute content effortlessly. Starting at $100/month* (based on bandwidth, additional setup fee)",
      firstButtonContent: {
        text: "Start Free Trial",
        link: "/sign-up",
        title: "free trial",
        linkType: "internal",
      },
      logo: {
        url:
          "https://gumlet.assettype.com/quintype-website/2019-11/23e1f88f-a05f-4b44-ad19-b98eac4de35a/bold.svg",
        alt: "logo",
      },
      secondButtonContent: {
        required: true,
        videoId: "Gls7beGW77c",
        text: "Watch Demo",
        title: "watch demo",
      },
      image: {
        url:
          "https://gumlet.assettype.com/quintype-website/2019-12/a2afb724-7961-4de1-8941-35633e7a1f8f/Bold_Product_page.svg",
        alt: "bold illustration",
      },
    },

    "image-with-content": [
      {
        headline: "Organized workspace and rich editing experience.",
        features: [
          {
            headline: "Live Preview",
            content:
              "See how your content looks before you publish it. Live. As you create it.",
          },
          {
            headline: "Content Collections ",
            content:
              "Go beyond sections and tags and organize content with automations and rules.",
          },
        ],
        url:
          "https://gumlet.assettype.com/quintype-website/2022-09/a54010e0-3958-4049-ba67-1c43d4e461d6/BOLD_Workspace.png",
        alt: "workspace",
        "free-trial": {
          text: "Start Free Trial",
          link: "/sign-up",
          title: "free trial",
        },
      },

      {
        headline: "Create once, publish anywhere.",
        features: [
          {
            headline: "Omni-channel Distribution",
            content:
              "Your content is ready to be discovered across search, social, mobile and voice devices with configurable settings for each distribution channel.",
          },
          {
            headline: "Better SEO",
            content:
              "It is no longer just meta. Get blazing fast load times, AMP, beat lighthouse benchmarks and validate your on-page SEO within our editor. ",
          },
        ],
        url:
          "https://gumlet.assettype.com/quintype-website/2022-09/8fa6adcd-f72d-401a-a4a5-6d99a870450d/BOLD_SEO.png",
        alt: "workspace",
        "free-trial": {
          text: "Start Free Trial",
          link: "/sign-up",
          title: "free trial",
        },
      },

      {
        headline: "Deliver better digital experiences.",
        features: [
          {
            headline: "In-depth Analytics",
            content:
              "Augment your existing analytics stack with relevant first-party data about your content and audience so that you can make smarter decisions.",
          },
          {
            headline: "Personalization",
            content:
              "Match your audience with your best content with our machine-learning algorithms that deliver the right pieces of content to the right reader. ",
          },
        ],
        url:
          "https://gumlet.assettype.com/quintype-website/2022-09/7b39d914-1d04-4d69-a3bd-83eab3026232/BOLD_Analytics.png",
        alt: "workspace",
        "free-trial": {
          text: "Start Free Trial",
          link: "/sign-up",
          title: "free trial",
        },
      },
    ],

    features: {
      headline:
        "All the content liberation tools you have wished for. And, all those you haven’t yet.",
      feature: [
        {
          title: "Better Media Delivery",
          description: "No more awkwardly cropped images on different screens!",
          url:
            "https://gumlet.assettype.com/quintype-website/2019-11/832eed88-f9cf-4f2c-877c-41699e9774fa/Media_Delivery.svg",
          alt: "Better Media Delivery",
        },
        {
          title: "Live Blogging",
          description:
            "Update stories in real-time and automatically reverse order on completion.",
          url:
            "https://gumlet.assettype.com/quintype-website/2019-11/f39087ac-b251-439a-b6ea-f38b4576b9c7/Live_Blog.svg",
          alt: "Live Blogging",
        },
        {
          title: "Teams",
          description:
            "Create teams of authors with access to specific sections.",
          url:
            "https://gumlet.assettype.com/quintype-website/2019-11/b1d4dff0-5abb-4079-949b-10585ba4ad95/Teams.svg",
          alt: "Teams",
        },
        {
          title: "Geo Tagging",
          description: "Organize stories based on locations.",
          url:
            "https://gumlet.assettype.com/quintype-website/2019-11/aa7ad015-0eb6-46b6-be32-97c3507006cf/Geo_Tagging.svg",
          alt: "Geo Tagging",
        },
        {
          title: "Customizable Workflows",
          description: "Make Bold work for your current newsroom workflow!",
          url:
            "https://gumlet.assettype.com/quintype-website/2019-11/cec75a7c-6a11-471a-bfc0-44064224fc0e/Customizable_Workflow.svg",
          alt: "Customizable Workflows",
        },
        {
          title: "Efficient Categorization",
          description:
            "Organize content with tags, attributes, entities and sections.",
          url:
            "https://gumlet.assettype.com/quintype-website/2019-11/6e5046ef-758a-41a1-8f81-864a6ca4bd8f/Efficient_Organization.svg",
          alt: "Efficient Organizations",
        },
        {
          title: "SEO Validations",
          description: "Validate your on-page SEO with our recommendations.",
          url:
            "https://gumlet.assettype.com/quintype-website/2019-11/38835c70-1f47-4e75-8da5-c656f6f6c5d1/SEO.svg",
          alt: "SEO Validations",
        },
        {
          title: "Multi-lingual Support",
          description: "Supports over 150+ international languages.",
          url:
            "https://gumlet.assettype.com/quintype-website/2019-11/a3660218-3e72-4186-964c-38b5117e0132/Multilingual.svg",
          alt: "Multi-lingual support",
        },
      ],
    },
    pricing: {
      headline: "PRICING",
      description:
        "Quintype wants to give you the freedom to publish sans the tech hiccups. We have a suite of products to take care of every tiny detail. Yes, every! Now, unleashing the power of content is in your hands.",
      plans: [
        {
          title: "Bold CMS",
          plan: "From $100/month*",
          icon: {
            url:
              "https://gumlet.assettype.com/quintype-website/2019-12/7f957131-ecf7-4fbd-b3a5-a07c04363f26/group_14.svg",
            description: "headless cms",
          },
          features: [
            "Ahead Page Builder included",
            "Unlimited Content",
            "Unlimited Users",
            "99.95% uptime",
            "Secure Hosting on AWS Cloud",
            "Daily Backups",
            "Content Analytics",
            "* Pricing based on bandwidth",
            "* Additional setup fee"
          ],
        },
        {
          title: "Enterprise",
          plan: "Custom",
          icon: {
            url:
              "https://gumlet.assettype.com/quintype-website/2019-12/c7dc9eba-1a06-43aa-ac70-c6e5608ae3cf/group_9.svg",
            description: "Headless cms + Front End",
          },
          features: [
            "Bold CMS plus",
            "Custom Front-end Design",
            "Native CDN Integration",
            "DDoS Protection",
            "Custom Integrations",
            "Auto Scaling Infrastructure",
            "Dedicated Account Manager",
            "Front-end Code Samples",
            "Page Load Optimization",
            "API Documentation",
            "24/7 Support",
            "WCAG 2.0 Support and more",
          ],
        },
      ],
      "read-more": {
        link: "/schedule-demo",
        title: "GET QUOTE",
        text: "GET QUOTE",
      },
    },
    review: {
      headline: "Way more than a bug and issue tracker",
      content:
        "“We used the headless CMS to launch our digital platform in 2016. From CMS to paywall solutions, Quintype manages the full tech stack for us. The platform is easy to use and customize for our requirements.”",
      designation: "Amarnath Govindarajan, Digital Head",
      "image-url":
        "https://gumlet.assettype.com/quintype-website/2019-12/db2d7899-378b-4c89-aac3-f6b52163ea8c/swarajya_29.svg",
      alt: "Swarajya",
      features: [
        {
          headline: "Fully Managed SaaS CMS",
          description:
            "Updates, automations, backups are all taken care of on your behalf.",
        },
        {
          headline: "API-first Headless CMS",
          description:
            "Our well documented REST APIs provides you with total control on the front-end.",
        },
        {
          headline: "Secure Enterprise Cloud",
          description:
            "Complete with DDoS protection, SSL, caching and auto-scaling infrastructure.",
        },
      ],
    },
    faqs: {
      headline: "FAQs",
      faq: [
        {
          question: "What is headless CMS",
          answer:
            "A Headless CMS is a content repository that delivers content to any platform or device via API. The intended consumption of this content is by devices in a raw structured format, such as HTML or JSON, and isn't meant for human consumption until rendered on the end device.",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [],
        },
        {
          question: "What is Bold CMS",
          answer:
            "Bold is a hosted, headless CMS SaaS application that empowers publishers to create, curate and distribute their content across modern devices and screens effortlessly through structured APIs using any technology stack.",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [],
        },
        {
          question: "Is Bold CMS a website creation tool?",
          answer:
            "If you are building a website you can use Bold to manage its content. Bold is a content management platform where you can update the content of your website, a mobile app or any other platform that displays content. We save you the time and hassle to create your own backend to manage the content, and it makes it easier to actually generate a website or app.",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [],
        },
        {
          question: "How can I import my existing data into Bold CMS?",
          answer:
            "We have migrated content from most common CMS like WP, Drupal, and Joomla. We also support migration from other custom CMS. We have a migrator app that automates the process of importing data from other CMS systems into Bold CMS.",
          linkText: "Learn more about the migrator here",
          linkType: true,
          additionalLink: "https://developers.quintype.com/migrator/",
          additionalPoints: [],
        },
        {
          question: "How secure is Bold CMS?",
          answer:
            "Bold CMS is very secure guaranteeing a 99.5% uptime. All apps are dockerized and run on Kubernetes with auto scaling enabled to handle scale. Further all applications are behind CDN. We use Fastly and/or Cloudflare as our CDN providers. With the enterprise plan provided by Cloudflare/Fastly we are able to provide:",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [
            "Level 3 and Level 7 DDOS protection (No matter the size and frequency of the DDOS attack)",
            "Web Application Firewall with WAF rules for the threats that apply to the large portion of our customers maintained and applied automatically.",
          ],
        },
        {
          question: "How are backups performed? What is the backup frequency?",
          answer:
            "We have integrated a daily backup system with multi-zone replication, which means Amazon RDS automatically creates a primary DB Instance and synchronously replicates the data to a standby instance in a different Availability Zone (AZ). We also take manual backup of the data to another AWS account once a day.",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [],
        },
        {
          question: "Control of content and apprehension for hosting?",
          answer:
            "Content belongs to the publishers and Quintype does not own the content in any form. ",
          linkText: "View our terms and conditions.",
          linkType: false,
          additionalLink: "/privacy-policy",
          additionalPoints: [],
        },
        {
          question: "Do you provide ongoing technical support?",
          answer:
            "We have 24X7 chat support available within the editor. We can help you with the features and functionalities of Bold CMS and our products. However, we do not provide technical support for the front end management. We have partnered with few technology services to help you with Front End management.",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [],
        },
        {
          question: "What resources or training do you offer?",
          answer:
            "Once you onboard, our team will have a live session on how to get going on our headless CMS.  We also have help documentation available at the following link.",
          linkText: "help documentation",
          linkType: true,
          additionalLink: "https://help.quintype.com/",
          additionalPoints: [],
        },
        {
          question: "Are there custom pricing options available?",
          answer:
            "Yes, Custom pricing options are available for enterprise users and others.",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [],
        },
        {
          question: "Do you offer a free trial of all features?",
          answer:
            "Yes,  We offer a 14-day free trial offer for you to access all our product features.",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [],
        },
      ],
    },
  },
  ahead: {
    "top-component": {
      name: "AHEAD",

      headline: "Powerful presentation for your powerful content.",
      logo: {
        url:
          "https://gumlet.assettype.com/quintype-website/2019-11/761d8dcd-eca2-4fef-8282-03fd714cb1cf/ahead.svg",
        alt: "logo",
      },
      info:
        "Get a powerful presentation for your powerful content. Focus on your content strategy and not be constrained by technology. Ahead is a complete package for growing content-first publishers.",
      firstButtonContent: {
        text: "Start Free Trial",
        link: "/sign-up",
        title: "free trial",
        linkType: "internal",
      },
      secondButtonContent: {
        required: false,
        text: "Watch video",
        title: "watch-video",
      },
      image: {
        url:
          "https://gumlet.assettype.com/quintype-website/2020-01/bf382eb1-12df-4cf4-b450-e097122ed608/Ahead_06.svg",
        alt: "ahead illustration",
      },
    },

    "image-with-content": [
      {
        headline: "Tell your stories better, or create a news website",
        features: [
          {
            content:
              "Engage your audience with our growing list of story formats. Perfect for long, short or visual stories.",
          },
        ],
        url:
          "https://gumlet.assettype.com/quintype-website/2019-12/ff430d9b-f695-4500-b58e-f633221ca9d4/Ahead_2.png",
        alt: "workspace",
        "free-trial": {
          text: "Start Free Trial",
          link: "/sign-up",
          title: "free trial",
        },
      },

      {
        headline: "Customizable page builder",
        features: [
          {
            headline: "",
            content:
              "Experiment and discover your brand with our row based design system that gives you the flexibility to choose, alter and order your home page.",
          },
        ],
        url:
          "https://gumlet.assettype.com/quintype-website/2019-12/031ea97e-c95f-4d59-b232-a53b553eb5cd/Ahead_1.png",
        alt: "workspace",
        "free-trial": {
          text: "Start Free Trial",
          link: "/sign-up",
          title: "free trial",
        },
      },
      {
        headline: "Integrate with your tech stack",
        features: [
          {
            headline: "",
            content:
              "Connects with industry standard analytics, data measurement and maketing tools for accurate data collection and audience engagement.",
          },
        ],
        url:
          "https://gumlet.assettype.com/quintype-website/2019-12/92220f14-76de-4d80-adf7-eae1a6cac736/Ahead_3.png",
        alt: "workspace",
        "free-trial": {
          text: "Start Free Trial",
          link: "/sign-up",
          title: "free trial",
        },
      },
      {
        headline: "Monetize your content",
        features: [
          {
            headline: "",
            content:
              "Monetize quickly through popular ad networks, subscriptions or donations with our built-in support for Accesstype",
          },
        ],
        url:
          "https://gumlet.assettype.com/quintype-website/2022-09/a238a459-8ba2-4b21-ac28-c342b148476b/PB_and_AT.png",
        alt: "workspace",
        "free-trial": {
          text: "Start Free Trial",
          link: "/sign-up",
          title: "free trial",
        },
      },
    ],
    features: {
      headline: "Way more than a bug and issue tracker",
      feature: [
        {
          title: "Fully Managed Service",
          description:
            "Updates, security, backups are all taken care of on your behalf.",
          url: "https://dummyimage.com/75x75/f0f0f0/fff",
          alt: "Better Media Delivery",
        },
        {
          title: "Future-proof",
          description:
            "Our team regularly updates layouts, features and integrations that will keep your website way ahead of the pack.",
          url: "https://dummyimage.com/75x75/f0f0f0/fff",
          alt: "future-proof CMS",
        },
        {
          title: "Migrate Effortlessly",
          description:
            "Your data is safe and smoothly transferred to Bold with our advanced migration engine.",
          url: "https://dummyimage.com/75x75/f0f0f0/fff",
          alt: "Migration",
        },
      ],
    },

    review: {
      content:
        "“Quintype's minimalistic and easy-to-use UI/UX for visitors is a big plus point. The backend is easy to use and has been specifically designed keeping content publishers in mind.”",
      designation: "Shruty Bhattacharyya, Digital Manager",
      "image-url":
        "https://images.assettype.com/quintype-website/2020-03/6967c460-24ec-45eb-8f87-b2ab7577fced/East_Mojo_Logo_01.svg",
      alt: "eastmojo",
      features: [
        {
          headline: "Fully Managed Service",
          description:
            "Updates, security, backups are all taken care of on your behalf.",
        },
        {
          headline: "Future proof",
          description:
            "Our team regularly updates layouts, features and integrations that will keep your website way ahead of the pack.",
        },
        {
          headline: "Migrate Effortlessly ",
          description:
            "Your data is safe and smoothly transferred to Bold with our advanced migration engine.",
        },
      ],
    },

    faqs: {
      headline: "FAQs",
      faq: [
        {
          question: "What is Ahead?",
          answer:
            "Ahead is the front-end framework for our Bold CMS which provides powerful presentation for your powerful content. Growing digital publishers often grapple with technology and tech is often a hurdle than an enabler. Ahead makes it easy for digital publishers to build their own riveting online presence with a customizable pagebuilder.",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [],
        },
        {
          question: "What is Page Builder?",
          answer:
            "Page Builder is a layer on top of Ahead that allows publishers to experiment with different layouts and visual styles with a no-code interface. The page builder layouts are light on Javascript and built with best practices in web performance.",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [],
        },
        {
          question: "What is the difference b/w Ahead and Page Builder?",
          answer:
            "Ahead is the front end framework for our Bold CMS that provides a powerful presentation for your powerful content. Page Builder is built on Ahead and helps you customize your front end with row based design systems with no-code.",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [],
        },
        {
          question:
            "How long does it take to build a website with a Ahead and Page Builder?",
          answer: "New websites can go live within 2 weeks.",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [],
        },
        {
          question: "Do I need a page builder for Bold CMS?",
          answer:
            "Not required, if you have a strong web development team, you can build a custom front-end. This solution is most useful for small to medium sized content creators.",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [],
        },
        {
          question: "Does page builder work on any cms?",
          answer:
            "No, The pagebuilder is most compatible with Bold CMS at the moment.",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [],
        },
        {
          question: "Is page builder independent of Bold CMS?",
          answer:
            "No, Page Builder and Bold CMS are cohesive to each other as Bold is a headless CMS and Pagebuilder works on top of the CMS as a-head to the CMS. Pagebuilder allows you to customize the website layout and add widgets, ad slots or stories based on your preference.",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [],
        },
        {
          question:
            "Who should use page builder? / Should I use a page builder?",
          answer:
            "Page builder is perfect for business owners, bloggers, publishers, designers, and basically everyone else who wants to customize their website design without any coding knowledge.",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [],
        },
        {
          question: "How much does it cost?",
          answer:
            "Pagebuilder and Ahead comes along with the Bold CMS and pricing starts at $100",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [],
        },
      ],
    },
  },
  metype: {
    "top-component": {
      name: "METYPE",
      headline: "Get people talking.",

      logo: {
        url:
          "https://gumlet.assettype.com/quintype-website/2019-11/97f7cf02-0e7d-446a-943f-7acd485043f7/metype.svg",
        alt: "logo",
      },
      info:
        "An audience engagement solution that inspires better conversations. Readers become followers. Involved audiences grow into communities.",
      firstButtonContent: {
        text: "Learn More",
        link: "https://www.metype.com",
        title: "Visit metype.com",
        linkType: "external",
      },
      secondButtonContent: {
        required: true,
        text: "Watch Video",
        videoId: "yFuS_cBhUqA",
        link: "/video",
        title: "watch-video",
      },
      image: {
        url:
          "https://gumlet.assettype.com/quintype-website/2020-01/05fdff3e-8f80-4562-ad5e-22ed629c98f3/Metype_01.svg",
        alt: "metype illustration",
      },
    },
    trialData: {
      headline: "Get started for free",
      content:
        "Metype can integrate with any front-end. Get started with your free account at metype.com",
      buttonData: {
        text: "Learn More",
        link: "https://www.metype.com",
        title: "visit metype.com",
        isExternal: true,
      },
    },

    "image-with-content": [
      {
        headline: "Audience Engagement",
        features: [
          {
            content:
              "Host meaningful conversations that will seek to create a community that will ignite insights through different perspectives.",
          },
        ],
        url:
          "https://gumlet.assettype.com/quintype-website/2019-12/e23fe513-c94b-4f90-94b5-f23b83f9b794/Metype_1.png",
        alt: "metype commenting widget",
        "free-trial": {
          text: "Learn More",
          link: "https://www.metype.com",
          title: "Visit metype.com",
          isExternal: true,
        },
      },
      {
        headline: "AI-driven Moderation",
        features: [
          {
            content:
              "Do not let the trolls free. Our AI driven moderation helps publishers moderate comments that are toxic.",
          },
        ],
        url:
          "https://gumlet.assettype.com/quintype-website/2019-12/c4759269-fe35-40d1-bb54-386016081f34/Metype_2.png",
        alt: "workspace",
        "free-trial": {
          text: "Learn More",
          link: "https://www.metype.com",
          title: "visit metype.com",
          isExternal: true,
        },
      },
      {
        headline: "Customizable Configurations",
        features: [
          {
            content:
              "Configure and customize metype widgets to suit your style of expression.",
          },
        ],
        url:
          "https://gumlet.assettype.com/quintype-website/2019-12/786e81da-f29e-48cc-bd4f-021ec82f6ccd/Metype_3.png",
        alt: "workspace",
        "free-trial": {
          text: "Learn More",
          link: "https://www.metype.com",
          title: "visit metype.com",
          isExternal: true,
        },
      },
    ],
    review: {
      headline: "Get started with a free account today",
      content:
        "Metype helps our team to engage with our young audience while ensuring high quality conversations through their toxicity meter. ",
      designation: "Amarnath Govindarajan, Digital Head",
      "image-url":
        "https://gumlet.assettype.com/quintype-website/2019-12/db2d7899-378b-4c89-aac3-f6b52163ea8c/swarajya_29.svg",
      alt: "Swarajya",
      features: [
        {
          headline: "Single Sign-on",
          description:
            "Integrate with your own technology stack to for seamless authentication.",
        },
        {
          headline: "Add engagement widgets in a jiffy",
          description:
            "Pick a widget, add the javascript code to your website and you are live!",
        },
        {
          headline: "You own your content",
          description:
            "You have complete ownership of any content posted on your properties.",
        },
      ],
    },
    faqs: {
      headline: "FAQs",
      faq: [
        {
          question: "What is metype?",
          answer:
            "Metype is an audience engagement solution for publishers who want to encourage meaningful conversations with their community through user-generated content. Metype integrates with popular CMS solutions through a Javascript embed and can integrate with third-party authentication tools using our Single Sign-On.The core features of Metype are:",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [
            "User Generated Content",
            "Story Comments",
            "Story Reactions",
            "Live Feeds",
            "Bookmarks",
          ],
        },
        {
          question: "How much does it cost to use Metype?",
          answer:
            "Metype is free to use for most sites ( like personal blogs, non-profits, education). You can use the paid version to remove ads from the widget or if the bandwidth is more than a million pageviews.",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [],
        },
        {
          question: "What platforms do you support?",
          answer:
            "Metype supports a variety of platforms. Check out our installation process for WordPress, Blogger with more along the way!",
          linkText: "Click here",
          linkType: true,
          additionalLink:
            "https://developers.quintype.com/metype/#install-metype-on-wordpress",
          additionalPoints: [],
        },
        {
          question: "What will happen to the existing comments on my site?",
          answer: "You can opt to migrate your comments at an additional cost.",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [],
        },
        {
          question: "Do Metype comments stop Spam?",
          answer:
            "Yes, our AI engine will recognize negative keywords you would like to avoid on your website and push those comments for review.",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [],
        },
        {
          question: "Are comments available on pages as well as in posts?",
          answer: "Yes, you can add Metype to any pages and posts as well.",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [],
        },
        {
          question: "Do you offer single-sign-on (SSO)?",
          answer:
            "Single Sign-On (SSO) is currently available to users with metype.",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [],
        },
        {
          question: "Will my comments be indexable by google?",
          answer: "Yes, Your comments will be indexed by Google.",
          linkText: "",
          linkType: false,
          additionalLink: "",
          additionalPoints: [],
        },
        {
          question: "What effect does Metype have on my site’s performance?",
          answer:
            "Metype loads asynchronously, so it won’t affect the rest of your page performance. For more detail, please see our help doc about page load performance.",
          linkText: "here",
          linkType: true,
          additionalLink:
            "https://developers.quintype.com/metype/#18-oct-2018-release-notes-metype",
          additionalPoints: [],
        },
      ],
    },
  },
  accesstype: {
    "top-component": {
      name: "ACCESSTYPE",
      headline: "Robust content monetization platform.",
      logo: {
        url:
          "https://gumlet.assettype.com/quintype-website/2019-11/96390937-5b53-44b0-9ecc-5353241e885e/group_14.svg",
        alt: "logo",
      },
      info:
        "Still chasing the pennies-per-click model? Generate predictable reader revenue with Accesstype - An intelligent subscription management platform to tap into the hidden strength of subscriptions.",
      firstButtonContent: {
        text: "Learn More",
        link: "/contact-us",
        title: "contact us page",
        linkType: "internal",
      },
      secondButtonContent: {
        required: true,
        videoId: "y-nTXl6QmTs",
        text: "Watch Video",
        link: "/video",
        title: "watch-video",
      },
      image: {
        url:
          "https://gumlet.assettype.com/quintype-website/2019-12/238e2d96-57eb-4ce0-a0e1-4937f3e06354/Accesstype.svg",
        alt: "metype illustration",
      },
    },

    "image-with-content": [
      {
        headline: "Metered access",
        features: [
          {
            content:
              "Metered access ensures your content entices new subscribers. Easy to setup access levels right from the editor on Bold CMS makes it easy to configure metered access to your readers.",
          },
        ],
        url:
          "https://gumlet.assettype.com/quintype-website/2022-09/a84091cb-e34c-4d8f-ae59-32af0ed63dfe/Paywall_White.png",
        alt: "workspace",
        "free-trial": {
          text: "Learn More",
          link: "/contact-us",
          title: "contact us page",
          isExternal: false,
        },
      },
      {
        headline: "Intuitive reporting and flexible integrations",
        features: [
          {
            content:
              "View, filter and export your subscriber information, debug transactions with attempt logs and more. Integrates quickly with your existing tech stack with REST APIs.",
          },
        ],
        url:
          "https://gumlet.assettype.com/quintype-website/2019-12/6a0fb7c7-2f3d-4709-8798-3ab682ae4f5b/Atype_Screenshot_Report.png",
        alt: "workspace",
        "free-trial": {
          text: "Learn More",
          link: "/contact-us",
          title: "contact us page",
          isExternal: false,
        },
      },
      {
        headline: "Quick integrations and transparent fee structure",
        features: [
          {
            content:
              "Accesstype can integrate with most popular CMS systems in the market today and comes with a transparent fee starting at $99.",
          },
        ],
        url:
          "https://gumlet.assettype.com/quintype-website/2019-12/dd0e4b29-025c-49e6-b8fc-41af1234fed3/Atype__Integration_25.png",
        alt: "workspace",
        "free-trial": {
          text: "Learn More",
          link: "/contact-us",
          title: "contact us page",
          isExternal: false,
        },
      },
    ],
    review: {
      headline:
        "Paywall and Subscription solutions for modern content publishers",
      content:
        "Monetization in our business is constantly evolving and Accesstype helped us experiment with multiple subscription models to find the right balance. We would recommend Accesstype for anyone looking to monetize their content beyond advertising.",
      designation: " - Ankit Dikshit, Digital Marketing",
      "image-url":
        "https://gumlet.assettype.com/quintype-website/2019-12/3163b395-6987-4d61-8c7f-9fb0ff4c2229/Client_14.png",
      alt: "BloombergQuint",
      features: [
        {
          headline: "Single Sign-on",
          description:
            "Integrate with your own technology stack to for seamless authentication.",
        },
        {
          headline: "Save money on every transaction",
          description:
            "Pay a transparent flat fee based on volume, not percentages on every subscription.",
        },
        {
          headline: "Multi-language and Multi-currency ready",
          description:
            "Take your monetization strategy across the globe. Accept local curency. In your language.",
        },
      ],
    },
  },
  react_native_mobile: {
    "top-component": {
      name: "React Native App for Content Publishers",
      headline: "Content driven Mobile Apps for digital publishers",
      info:
        "Our native apps for iOS and Android provide easy configuration, with seamless integration with our Bold CMS. Launch your own content app starting at $500/mo.",
      firstButtonContent: {
        text: " Schedule Demo",
        link: "/schedule-demo",
        title: " Schedule Demo",
        linkType: "internal",
      },
      logo: {
        url: "",
        alt: "logo",
      },
      secondButtonContent: {
        required: true,
        text: "Start Free Trial",
        link: "/rn-free-trial",
        title: "free trial",
        linkType: "internal",
      },
      image: {
        url:
          "https://images.assettype.com/quintype-website/2020-12/aabf48f1-4f9c-40d2-9f32-242b40bb7639/Accesstype_14.png",
        alt: "react native mobile app",
      },
    },

    "image-with-content": [
      {
        headline: "Launch your native mobile app with Bold CMS",
        features: [
          {
            headline: "",
            content:
              "Save yourself the time and trust our team to take your digital publication to the mobile-first audience. Sync content to your website and mobile app from a single CMS. Plug and play with zero maintenance and no tech overhead.",
          },
        ],
        url:
          "https://gumlet.assettype.com/quintype-website/2022-09/8e5422bd-4ded-48a2-b75c-0cdb2fc67192/BOLD_Story_Preview.png",
        alt: "workspace",
        "free-trial": {
          text: " Schedule Demo",
          link: "/schedule-demo",
          title: " Schedule Demo",
        },
      },

      {
        headline: "Audience engagement taken a click closer",
        features: [
          {
            headline: "",
            content:
              "For those who work around the clock to reach audiences across the globe, here’s an easy solution to launching a react native mobile app. With superior user experience, connect with your audience at an integral level.",
          },
        ],
        url:
          "https://gumlet.assettype.com/quintype-website/2022-09/93e5a55f-c516-4867-a92c-06b518d7bca5/Push_Notification.png",
        alt: "workspace",
        "free-trial": {
          text: " Schedule Demo",
          link: "/schedule-demo",
          title: " Schedule Demo",
        },
        points: [
          "Push notifications",
          "Live Video Streaming",
          "Quick - Like, lighting quick. Because we know you value your audience",
          "Single sign on - For a seamless experience, enable user login with your own tech stack.",
          "FCM Integration",
          "Multiple advertisement slots flexibility",
        ],
      },

      {
        headline: "Revenue Generation tailored for you",
        features: [
          {
            headline: "",
            content: "",
          },
        ],
        url:
          "https://gumlet.assettype.com/quintype-website/2022-09/c77616d8-be7f-4240-8771-44ccb93bb931/Ahead.png",
        alt: "workspace",
        "free-trial": {
          text: " Schedule Demo",
          link: "/schedule-demo",
          title: " Schedule Demo",
        },
        points: [
          "Ad integration - get sponsored content and  advertisements across your app. ",
          "Subscriptions/paywalls - Setting up a paywall has never been easier, configure metered, soft or hard paywalls!",
          "Smooth web experience with our Bold CMS, specifically made for today’s digital publishers.",
        ],
      },

      {
        headline: "Cost Benefits to suit all publishers",
        features: [
          {
            headline: "",
            content:
              "Our pre-built framework helps you save time and money in building your own content app.",
          },
        ],
        url:
          "https://gumlet.assettype.com/quintype-website/2019-12/238e2d96-57eb-4ce0-a0e1-4937f3e06354/Accesstype.svg",
        alt: "workspace",
        "free-trial": {
          text: " Schedule Demo",
          link: "/schedule-demo",
          title: "CTA: Schedule Demo",
        },
        points: [
          "Affordable - No more extra bills on hiring tech teams or regular maintenance checks",
          "Zero maintenance - native mobile app development can be a cumbersome task for publishers, with quintype, you can leave your worries to the experts.",
        ],
      },
    ],

    features: {
      headline: "Other Highlights",
      feature: [
        {
          title: "High up time",
          description:
            "99.73% uptime so you don't subject your users to app crash!",
          url:
            "https://gumlet.assettype.com/quintype-website/2020-12/f0907322-bec7-44ed-9384-99777058cf98/Feature_Icons_1.svg",
          alt: "react native mobile app",
        },
        {
          title: "In-app Purchases for android",
          description: "Get your users easy transactions ",
          url:
            "https://gumlet.assettype.com/quintype-website/2020-12/fc7c0828-56d1-4866-8275-78cf7b2e474a/Feature_Icons_2.svg",
          alt: "react native mobile app",
        },
        {
          title: "Frequently updated apps",
          description:
            "Don’t bother keeping up with the updates, we’ve got you covered.",
          url:
            "https://gumlet.assettype.com/quintype-website/2020-12/eaf21f19-4988-4595-9e87-f8d22c601970/Feature_Icons_3.svg",
          alt: "react native mobile app",
        },
        {
          title: "User Customizable font size",
          description: "To suit every single reader",
          url:
            "https://gumlet.assettype.com/quintype-website/2020-12/df05a5ad-20a0-4af6-8f78-5ed1d056f7f7/Feature_Icons_4.svg",
          alt: "react native mobile app",
        },
        {
          title: "Understand your audience",
          description: "Google analytics support",
          url:
            "https://gumlet.assettype.com/quintype-website/2020-12/a543d067-38a1-4c4c-bb81-230d6065a2b8/Feature_Icons_5.svg",
          alt: "react native mobile app",
        },
        {
          title: "Read later",
          description: "Bookmarking for offline access",
          url:
            "https://gumlet.assettype.com/quintype-website/2020-12/e9b9e480-ed65-41b4-9336-408a7c59c789/Feature_Icons_6.svg",
          alt: "react native mobile app",
        },
        {
          title: "Dark Mode",
          description: "For more screen time and to assist readability",
          url:
            "https://images.assettype.com/quintype-website/2020-12/8813e2ed-3496-44ec-b790-8d7e55257706/Feature_Icons_7.svg",
          alt: "react native mobile app",
        },
        {
          title: "Offline Mode",
          description:
            "We understand netizens and their need to go off the radar every once in a while. Coming Soon.",
          url:
            "https://images.assettype.com/quintype-website/2020-12/27d4069b-2728-4d40-bd63-140c497ff5de/Feature_Icons_8.svg",
          alt: "react native mobile app",
        },
      ],
    },

    review: {
      headline: "Way more than a bug and issue tracker",
      content:
        "“We used the headless CMS to launch our digital platform in 2016. From CMS to paywall solutions, Quintype manages the full tech stack for us. The platform is easy to use and customize for our requirements.”",
      designation: "Amarnath Govindarajan, Digital Head",
      "image-url":
        "https://gumlet.assettype.com/quintype-website/2019-12/db2d7899-378b-4c89-aac3-f6b52163ea8c/swarajya_29.svg",
      alt: "Swarajya",
      features: [
        {
          headline: "Fully Managed SaaS CMS",
          description:
            "Updates, automations, backups are all taken care of on your behalf.",
        },
        {
          headline: "API-first Headless CMS",
          description:
            "Our well documented REST APIs provides you with total control on the front-end.",
        },
        {
          headline: "Secure Enterprise Cloud",
          description:
            "Complete with DDoS protection, SSL, caching and auto-scaling infrastructure.",
        },
      ],
    },
  },
};
