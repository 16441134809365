import React from "react";

import { Label } from "../../../form-controls/style";
import { ControlledInput, InputWrapper, AvailabityText } from "./style";
import spinner from "../../../icons/green-spinner.svg";

export default function ControlledTextField({
  label,
  name,
  errors,
  onChange,
  value,
  domainAvailability,
  domainCheckLoader
}) {
  const availabitlityText = domainCheckLoader ? (
    <img src={spinner} alt="spinner" />
  ) : typeof domainAvailability === "object" ? null : domainAvailability ? (
    "Avaliable"
  ) : (
    "Unavailable"
  );

  const availabilityTextColor= domainAvailability? 'green': 'red';
  return (
    <>
      <Label>{label}</Label>
      <InputWrapper>
        <ControlledInput
          name={name}
          value={value}
          error={errors[name]}
          onChange={onChange}
        />
        <AvailabityText color={availabilityTextColor}  >{availabitlityText}</AvailabityText>
      </InputWrapper>
    </>
  );
}
