import styled from "styled-components";
import { Link } from "react-router-dom";

export const SuccesPhoneLink = styled.a`
  color: ${props => props.theme.color.green1};
  text-decoration: underline;
`;

export const TitleContainer = styled.div`
margin-bottom: 40px;
`

export const SuccessLink = styled(Link)`
  display: inline-block;
  color: ${props => props.theme.color.green1};
  text-decoration: underline;
  margin-left: 4px;
`;

export const Wrapper = styled.section`
  padding: 0 24px;
  @media (min-width: ${props => props.theme.breakPoints.tablet}) {
    max-width: ${props => props.theme.containerWidth};
    padding: 120px 24px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 24px;
  }
`;

export const ContentWrapper = styled.div`
  grid-column: 1/7;
`;

export const FormWrapper = styled.div`
  grid-column: 8/13;
  margin-top: 16px;
  @media (min-width: ${props => props.theme.breakPoints.tablet}) {
    margin-top: 0;
  }
`;


export const Content = styled.div`
  padding-bottom: 30px;
  @media (min-width: ${props => props.theme.breakPoints.tablet}) {
    padding-bottom: 40px;
  }
`;
export const List = styled.ul``;

export const Item = styled.li`
  list-style-type: none;
  padding: 0 0 16px 0;
   display: flex;
   align-items: center;
  &::before {
    content: "\\2022";
    padding-right: 10px;
    color: ${props => props.theme.color.green1};
    font-size: 30px;
    font-weight: 900;
  }
`;

export const Form = styled.form`
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: ${props => props.theme.color.white};
  padding: 42px 24px;
`;

export const ButtonWrapper = styled.div`
  text-align: center;
  margin-top: 70px;
`;

export const DropDownContainer = styled.div`
  position: relative;
  &::after {
    content: "\\25BC";
    display: block;
    position: absolute;
    top: 72%;
    transform: translateY(-50%);
    right: 15px;
  }
`;

export const Message = styled.p`
  font-size: ${props =>
    props.type === "error" ? props.theme.fontSize.s : props.theme.fontSize.l};
  color: ${props => {
    if (props.type === "success") return props.theme.color.green1;
    if (props.type === "error") return props.theme.color.red;
  }};
  font-family: ${props => props.theme.fontFamily.primary};
  line-height: ${props => props.theme.lineHeight.lh3};
  margin-top: 32px;
`;
