import { createStore } from "redux";

const initialState = false;

const changeLanguae = (state = initialState, action) => {
  switch (action.type) {
    case "active":
      return (state = action.payload);
    case "deactive":
      return (state = action.payload);
    default:
      return state;
  }
};


const store = createStore(
  changeLanguae,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;