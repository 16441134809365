import React, { useState } from "react";
import { title, subTitle, integrations } from "./data";
import {
  IntegrationWrapper,
  InnerWrapper,
  Title,
  CustomSelect,
  IntegrationFilter,
  Select,
  IntegrationList,
  Item,
  Option
} from "./style";
import IntegrationCardComponent from "../integrations/shared/index";

function IntegrationsPage() {
  const categories = [
    "all",
    "create",
    "distribute",
    "engage",
    "monetize",
    "manage"
  ];

  const [selectCategory, UpdateCategory] = useState("all");

  return (
    <IntegrationWrapper>
      <InnerWrapper>
        <Title>{title}</Title>
        <Title>{subTitle}</Title>
        <IntegrationFilter>
          <CustomSelect>
            <Select
              value={selectCategory}
              onChange={e => {
                const value = e.target.value;
                UpdateCategory(value);
              }}
            >
              {categories.map((category, index) => {
                return (
                  <Option value={category} key={index}>
                    {category}
                  </Option>
                );
              })}
            </Select>
          </CustomSelect>
          <IntegrationList>
            {categories.map((category, index) => {
              return (
                <Item
                  onClick={() => {
                    UpdateCategory(category);
                  }}
                  key={index}
                  selectCategory={selectCategory === category}
                >
                  {category}
                </Item>
              );
            })}
          </IntegrationList>
          <IntegrationCardComponent
            data={integrations}
            selectCategory={selectCategory}
          />
        </IntegrationFilter>
      </InnerWrapper>
    </IntegrationWrapper>
  );
}

export default IntegrationsPage;
