import React, { useState } from "react";
import { ThemeProvider } from "styled-components";
import FontFaceObserver from "./font-face-observer";

import { initialTheme, themeWithFonts } from "./generic/theme";
import App from "./app-main";

export default function Main() {
  const [fontsLoaded, updateFontsLoaded] = useState(false);
  const theme = fontsLoaded? themeWithFonts: initialTheme;

  const fontsUpdated =() => {
      updateFontsLoaded(true)
  }

  return (
    <>
      <FontFaceObserver updateFontsLoaded={fontsUpdated} />
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </>
  );
}
