import React from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import { Label } from "../style";
import { Container } from "./style.js";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    //!important  to override library hover styles of select
    border: state.selectProps.error
      ? "1px solid #f65a5b !important "
      : "1px solid #e3e3e3 !important",
    minHeight: "45px",
  }),
  container: (provided, state) => ({
    ...provided,
    fontFamily:
      '"Montserrat","-apple-system, BlinkMacSystemFont, “Segoe UI”, “Roboto”, “Oxygen”,“Ubuntu”, “Cantarell”, “Fira Sans”, “Droid Sans”, “Helvetica Neue”, sans-serif"',
    fontSize: "14px",
  }),
  indicatorSeparator: (provided, state) => ({
    display: "none",
  }),
};

export default function ReactSelect({
  options,
  onChange,
  label,
  errors,
  name,
  value,
}) {
  const isArabic = useSelector((state) => state);
  return (
    <>
      <Label>{label}</Label>
      <Container>
        <Select
          options={options}
          onChange={onChange}
          value={value}
          placeholder={isArabic ? `الاختيار` : `Choose`}
          error={errors[name]}
          styles={customStyles}
        />
      </Container>
    </>
  );
}
