import styled from "styled-components";

export const Title = styled.h1`
  font-family: ${props => props.theme.fontFamily.primary};
  font-weight: ${props => props.theme.fontWeight.bold};
  font-size: ${props =>
    props.small ? props.theme.fontSize.xl : props.theme.fontSize.xxxl};
  color: ${props => props.theme.color.typeRegular};
  margin: 0;
  line-height: ${props => props.theme.lineHeight.lh1};
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    font-size: ${props =>
      props.small ? props.theme.fontSize.sjumbo : props.theme.fontSize.giga};
  }
`;
