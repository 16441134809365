import React from "react";
import { useSelector } from "react-redux";
import TopComponent from "./shared/top-component";
import ImageWithContent from "./shared/image-with-content";
import ImageWithContentAltType from "./shared/image-with-content-alt-type";
import ClientReview from "./shared/client-review";
import TestimonialSolutions from "./shared/testimonial-solutions";
import { data } from "./data";
import { arabicdata } from "./arabic-data";
import { trafficTestimonialSolutions } from "./data";
import { arabictrafficTestimonialSolutions } from "./arabic-data";

function IncreaseTraffic() {
  const isArabic = useSelector((state) => state);
  return (
    <React.Fragment>
      <div dir={isArabic ? "rtl" : "ltr"} lang={isArabic ? `ar` : `en`}>
        <TopComponent
          data={
            isArabic
              ? arabicdata.to_increase_traffic["top-component"]
              : data.to_increase_traffic["top-component"]
          }
        />
        <ImageWithContent
          data={
            isArabic
              ? arabicdata.to_increase_traffic["image-with-content"]
              : data.to_increase_traffic["image-with-content"]
          }
        />
        <ClientReview
          data={
            isArabic
              ? arabicdata.to_increase_traffic.review
              : data.to_increase_traffic.review
          }
        />
        <ImageWithContentAltType
          data={
            isArabic
              ? arabicdata.to_increase_traffic["image-with-content2"]
              : data.to_increase_traffic["image-with-content2"]
          }
        />
        <ClientReview
          data={
            isArabic
              ? arabicdata.to_increase_traffic.review2
              : data.to_increase_traffic.review2
          }
        />
        <ImageWithContentAltType
          data={
            isArabic
              ? arabicdata.to_increase_traffic["image-with-content3"]
              : data.to_increase_traffic["image-with-content3"]
          }
        />
        <ImageWithContent
          data={
            isArabic
              ? arabicdata.to_increase_traffic["image-with-content4"]
              : data.to_increase_traffic["image-with-content4"]
          }
        />
        <TestimonialSolutions
          data={
            isArabic
              ? arabictrafficTestimonialSolutions
              : trafficTestimonialSolutions
          }
        />
      </div>
    </React.Fragment>
  );
}

export default IncreaseTraffic;
