import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { socialLinkData } from "../../data";
import {
  NavBarContainer,
  LogoContainer,
  CloseButton,
  MainContainer,
  Nav,
  ListContainer,
  ListItem,
  LinkItem
} from "./style";
import { LogoLink } from "../style";
import { QuintypeLogo, CloseIcon } from "../../../icons/index";
import SocialLink from "../../social-links/index";
import ExpandingList from "../expanding-list/index"
import Toggle from "../../toggle-switch";

function NavBar({ hideNav, showNav, navBarData }) {
  useEffect(() => {
    showNav
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "visible");
  }, [showNav]);
  return (
    <NavBarContainer showNav={showNav}>
      <LogoContainer>
        <LogoLink to="/" title="Home page">
          <QuintypeLogo />
        </LogoLink>
        <CloseButton
          onClick={() => {
            hideNav();
          }}
        >
          <CloseIcon />
        </CloseButton>
      </LogoContainer>
      <MainContainer>
        <Nav>
          <ListContainer>
            {navBarData.map(data => {
              if (data.type === "link")
                return (
                  <ListItem key={data.name}>
                    <LinkItem title={data.name} to={data.url}>
                      {data.name}
                    </LinkItem>
                  </ListItem>
                );
              return (
                <ListItem key={data.name}>
                  <ExpandingList listName={data.name} list={data.listItems} />
                </ListItem>
              );
            })}
            <ListItem><Toggle /></ListItem>
            
          </ListContainer>
        </Nav>
      </MainContainer>
      <SocialLink data={socialLinkData} />
    </NavBarContainer>
  );
}

NavBar.propTypes = {
  hideNav: PropTypes.func,
  showNav: PropTypes.bool
};

export default NavBar;