import React, { useState } from "react";
import {
  AccordionContainer,
  Container,
  Wrapper,
  Heading,
  HeadlineContainer,
  ContentContainer,
  DropDown,
  FeatureWrapper,
  Feature,
  LinkItem,
  Span,
} from "./style";
import H2 from "../../../shared/h2/index";
import Para from "../../../shared/para-alt/index";
import ArrowLink from "../../../shared/arrow-link/index";

const Accordion = ({ data }) => {
  const [clicked, setClicked] = useState(false);

  const toggle = (index) => {
    if (clicked === index) {
      return setClicked(null);
    }
    setClicked(index);
  };

  return (
    <AccordionContainer>
      <Container>
        <HeadlineContainer>
          <H2>{data.headline}</H2>
        </HeadlineContainer>
        {data.faq.map((item, index) => (
          <div key={index}>
            <ContentContainer key={index}>
              <Wrapper
                onClick={() => {
                  toggle(index);
                }}
              >
                <Heading>{item.question}</Heading>
                <Span>{clicked === index ? "−" : "+"}</Span>
              </Wrapper>
              {clicked === index ? (
                <DropDown>
                  <Para>{item.answer}</Para>
                  {item.linkText === "" ? null : (
                    <LinkItem>
                      <ArrowLink
                        isExternal={item.linkType}
                        url={item.additionalLink}
                      >
                        {item.linkText}
                      </ArrowLink>
                    </LinkItem>
                  )}
                  {item.additionalPoints.length !== null ? (
                    <FeatureWrapper>
                      {item.additionalPoints.map((point, index) => {
                        return <Feature key={index}>{point}</Feature>;
                      })}
                    </FeatureWrapper>
                  ) : null}
                </DropDown>
              ) : null}
            </ContentContainer>
          </div>
        ))}
      </Container>
    </AccordionContainer>
  );
};

export default Accordion;
