import {
  ROOT,
  BOLD,
  AHEAD,
  REACT_NATIVE_MOBILE_APP,
  ACCESSTYPE,
  METYPE,
  CONSULTING,
  ABOUT,
  PARTNERS,
  CASESTUDY,
  GUIDES,
  ESSENCE,
  CONTACTUS,
  GETDEMO,
  TRIALSIGNUP,
  BOLD_CMS_DEMO,
  SOLUTIONS,
  INCREASETRAFFIC,
  MONETIZECONTENT,
  DEVELOPERS,
  MAGAZINE_PUBLISHERS,
} from "./utils/RouteNames";

const SEO_TAGS = {
  [ROOT]: {
    title: "Digital Publishing Solutions | Online Publishing Platform | Quintype",
    description:
      "Quintype is among the best online & digital publishing platforms to simplify content creation, distribution, audience engagement and monetization at scale.",
    og: {
      title: "Digital Publishing Solutions | Online Publishing Platform | Quintype",
      site_name: "Quintype",
      url: "https://www.quintype.com",
      description:
        "Quintype empowers digital publishers with SaaS solutions that solves tech challenges in content management, audience engagement and monetization.",
      type: "website",
      image:
        "https://gumlet.assettype.com/quintype-website/2019-12/9570c4ea-8a41-419b-99c8-5d30310bf1b6/IconsArtboard_1_copy_6.svg",
    },
    twitter: {
      card: "summary_large_image",
      site: "@Quintype Bot_inc",
      title: "Digital publishing solutions for new media organizations",
      description:
        "Quintype empowers digital publishers with SaaS solutions that solves tech challenges in content management, audience engagement and monetization. Get a free demo.",
      image:
        "https://gumlet.assettype.com/quintype-website/2019-12/9570c4ea-8a41-419b-99c8-5d30310bf1b6/IconsArtboard_1_copy_6.svg",
    },
    structuredData: {
      "@context": "http://schema.org",
      "@type": "Organization",
      name: "Quintype",
      url: "https://www.quintype.com",
      logo: "https://gumlet.assettype.com/quintype-website/2020-01/8991b033-85d6-4b27-9012-c05543e1d454/clip_5_copy__1_.svg",
      foundingDate: "2014",
      address: {
        "@type": "PostalAddress",
        streetAddress: "#29, 3rd Floor, Old Airport Road",
        addressLocality: "Murgesh Palya",
        addressRegion: "Bangalore",
        postalCode: "560017",
        addressCountry: "INDIA",
      },
      contactPoint: [
        {
          "@type": "ContactPoint",
          contactType: "customer support",
          telephone: "+919019218218",
          areaServed: "Global",
          email: "sales@quintype.com",
        },
      ],
      sameAs: [
        "https://www.facebook.com/Quintypetechnologies",
        "https://twitter.com/quintype_inc",
        "https://www.linkedin.com/company/quintype/",
        "https://plus.google.com/109975125732886477523",
      ],
    },
  },
  [METYPE]: {
    title: "Metype: Audience engagement solution, Commenting tool",
    description:
      "Metype is a complete audience engagement solution that enables meaningful conversations on your stories. AI-driven moderation tools for handling toxic comments.",
    og: {
      title: "Metype: Audience engagement solution, Commenting tool",
      site_name: "Quintype",
      url: "https://www.quintype.com/products/metype",
      description:
        "Metype is a complete audience engagement solution that enables meaningful conversations on your stories. AI-driven moderation tools for handling toxic comments.",
      type: "Product",
      image:
        "https://gumlet.assettype.com/quintype-website/2019-12/f1bbe538-e58a-4a2f-b4bf-c109d7fc4f7d/Metype__1_.svg",
    },
    twitter: {
      card: "summary_large_image",
      site: "@Quintype_inc",
      title: "Metype: Audience engagement solution, Commenting tool",
      description:
        "Metype is a complete audience engagement solution that enables meaningful conversations on your stories. AI-driven moderation tools for handling toxic comments.",
      image:
        "https://gumlet.assettype.com/quintype-website/2019-12/f1bbe538-e58a-4a2f-b4bf-c109d7fc4f7d/Metype__1_.svg",
    },
    structuredData: {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: "Quintype",
          item: "https://www.quintype.com/",
        },
        {
          "@type": "ListItem",
          position: 2,
          name: "Products - Metype",
          item: "https://www.quintype.com/products/metype",
        },
      ],
    },
  },
  [ACCESSTYPE]: {
    title: "Accesstype - Robust Content Monetization Platform",
    description:
      "Accesstype from Quintype is an Intelligent subscription management platform that allows media organizations to monetize their content through new revenue models.",
    og: {
      title: "Accesstype - Robust Content Monetization Platform",
      site_name: "Quintype",
      url: "https://www.quintype.com/products/accesstype",
      description:
        "Accesstype from Quintype is an Intelligent subscription management platform that allows media organizations to monetize their content through new revenue models.",
      type: "Product",
      image:
        "https://gumlet.assettype.com/quintype-website/2019-12/238e2d96-57eb-4ce0-a0e1-4937f3e06354/Accesstype.svg",
    },
    twitter: {
      card: "summary_large_image",
      site: "@Quintype_inc",
      title: "Accesstype - Robust Content Monetization Platform",
      description:
        "Accesstype from Quintype is an Intelligent subscription management platform that allows media organizations to monetize their content through new revenue models.",
      image:
        "https://gumlet.assettype.com/quintype-website/2019-12/238e2d96-57eb-4ce0-a0e1-4937f3e06354/Accesstype.svg",
    },
    structuredData: {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: "Quintype",
          item: "https://www.quintype.com/",
        },
        {
          "@type": "ListItem",
          position: 2,
          name: "Products - Accesstype",
          item: "https://www.quintype.com/products/accesstype",
        },
      ],
    },
  },
  [BOLD]: {
    title: "Headless CMS | Best Content Management System | BOLD ",
    description:
      "The best headless cms platform to create, manage, and publish content on any digital platform. Bold is an API-first content management system designed for digital publishers.",
    og: {
      title: "Headless CMS | Best Content Management System | BOLD ",
      site_name: "Quintype",
      url: "https://www.quintype.com/products/bold",
      description:
        "The best headless cms platform to create, manage, and publish content on any digital platform. Bold is an API-first content management system designed for digital publishers.",
      type: "Product",
      image:
        "https://gumlet.assettype.com/quintype-website/2019-12/a2afb724-7961-4de1-8941-35633e7a1f8f/Bold_Product_page.svg",
    },
    twitter: {
      card: "summary_large_image",
      site: "@Quintype_inc",
      title: "Headless CMS | Best Content Management System | BOLD ",
      description:
        "The best headless cms platform to create, manage, and publish content on any digital platform. Bold is an API-first content management system designed for digital publishers.",
      image:
        "https://gumlet.assettype.com/quintype-website/2019-12/a2afb724-7961-4de1-8941-35633e7a1f8f/Bold_Product_page.svg",
    },
    structuredData: {
      "@context": "http://schema.org",
      "@type": "Product",
      name: "BOLD",
      image:
        "https://gumlet.assettype.com/quintype-website/2019-11/23e1f88f-a05f-4b44-ad19-b98eac4de35a/bold.svg?w=700&dpr=1.0",
      description:
        "Bold CMS - An empowering solution to create, curate, distribute content effortlessly.",
      brand: {
        "@type": "Brand",
        name: "Quintype",
      },
      offers: {
        "@type": "Offer",
        price: "99",
        priceCurrency: "USD"
      },
      review: {
        "@type": "Review",
        author: {
          "@type": "Person",
          name: "Amarnath Govindarajan, Digital Head",
        },
        reviewBody:
          "We used the headless CMS to launch our digital platform in 2016. From CMS to paywall solutions, Quintype manages the full tech stack for us. The platform is easy to use and customize for our requirements.",
      },
    },
  },
  [AHEAD]: {
    title:
      "Create News Website | A Hyper-Customizable CMS Page Builder | AHEAD.",
    description:
      "Experiment and discover your brand with our row based design system that gives you the flexibility to choose, alter and order your website. ",
    og: {
      title:
        "Create News Website | A Hyper-Customizable CMS Page Builder | AHEAD.",
      site_name: "Quintype",
      url: "https://www.quintype.com/products/ahead",
      description:
        "Ahead is one of the best CMS page builder that helps you to create fascinating content or news websites and deliver a content experience that is unique to your business. Start a free trial.",
      type: "Product",
      image:
        "https://gumlet.assettype.com/quintype-website/2019-12/f96908f7-7e17-43de-83a7-af84ecb57caa/ahead_product.png",
    },
    twitter: {
      card: "summary_large_image",
      site: "@Quintype_inc",
      title:
        "Create News Website | A Hyper-Customizable CMS Page Builder | AHEAD.",
      description:
        "Ahead is the front-end framework for our Bold CMS that empowers digital publishers to tell better stories and deliver a content experience that is unique to their digital business.Sign up for a free trial.",
      image:
        "https://gumlet.assettype.com/quintype-website/2019-12/f96908f7-7e17-43de-83a7-af84ecb57caa/ahead_product.png",
    },
    structuredData: {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: "Quintype",
          item: "https://www.quintype.com/",
        },
        {
          "@type": "ListItem",
          position: 2,
          name: "Products - Ahead",
          item: "https://www.quintype.com/products/ahead",
        },
      ],
    },
  },
  [REACT_NATIVE_MOBILE_APP]: {
    title:
      "Mobile apps for digital content publishers - iOS and Android - Quintype",
    description:
      "Create dedicated iOS and Android mobile apps for digital publishers. Give a more intuitive and engaging experience to your readers on mobile. Contact Now!",
    og: {
      title:
        "Mobile apps for digital content publishers - iOS and Android - Quintype",
      site_name: "Quintype",
      url: "https://www.quintype.com/products/ahead",
      description:
        "Create dedicated iOS and Android mobile apps for digital publishers. Give a more intuitive and engaging experience to your readers on mobile. Contact Now!",
      type: "Product",
      image:
        "https://images.assettype.com/quintype-website/2020-11/4193c9e9-b1f6-456b-8634-862fe733f090/React_Native___Fold_1.png",
    },
    twitter: {
      card: "summary_large_image",
      site: "@Quintype_inc",
      title:
        "Mobile apps for digital content publishers - iOS and Android - Quintype",
      description:
        "Create dedicated iOS and Android mobile apps for digital publishers. Give a more intuitive and engaging experience to your readers on mobile. Contact Now!",
      image:
        "https://images.assettype.com/quintype-website/2020-11/4193c9e9-b1f6-456b-8634-862fe733f090/React_Native___Fold_1.png",
    },
    structuredData: {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: "Quintype",
          item: "https://www.quintype.com/",
        },
        {
          "@type": "ListItem",
          position: 2,
          name: "Products - Ahead",
          item: "https://www.quintype.com/products/ahead",
        },
      ],
    },
  },
  [CONSULTING]: {
    title: "Consulting services for digital publishing",
    description:
      "We offer consulting services on design, SEO and technology to develop strong digital experiences for your brand.",
    og: {
      title: "Consulting services for digital publishing",
      site_name: "Quintype",
      url: "https://www.quintype.com/consulting",
      description:
        "We offer consulting services on design, SEO and technology to develop strong digital experiences for your brand.",
      type: "website",
      image:
        "https://gumlet.assettype.com/quintype-website/2019-12/fcae94ec-6529-44ec-82eb-0c7154358703/Contact_Us_02.svg",
    },
    twitter: {
      card: "summary_large_image",
      site: "@Quintype_inc",
      title: "Consulting services for digital publishing",
      description:
        "We offer consulting services on design, SEO and technology to develop strong digital experiences for your brand.",
      image:
        "https://gumlet.assettype.com/quintype-website/2019-12/fcae94ec-6529-44ec-82eb-0c7154358703/Contact_Us_02.svg",
    },
    structuredData: {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: "Quintype",
          item: "https://www.quintype.com/",
        },
        {
          "@type": "ListItem",
          position: 2,
          name: "Consulting",
          item: "https://www.quintype.com/consulting",
        },
      ],
    },
  },
  [ABOUT]: {
    title: "About Quintype",
    description:
      "Our team at Quintype focuses on solving technology challenges for complex digital publishing needs.",
    og: {
      title: "About Quintype",
      site_name: "Quintype",
      url: "https://www.quintype.com/about",
      description:
        "Quintype was founded in California, USA and has a presence in Delhi and Bangalore in India. Quintype's products are currently used by over 200 publishers across the world to deliver seamless content experiences over a billion readers every month.",
      type: "Product",
      image:
        "https://gumlet.assettype.com/quintype-website/2019-12/fcae94ec-6529-44ec-82eb-0c7154358703/Contact_Us_02.svg",
    },
    twitter: {
      card: "summary_large_image",
      site: "@Quintype_inc",
      title: "About Quintype",
      description:
        "Quintype was founded in California, USA and has a presence in Delhi and Bangalore in India. Quintype's products are currently used by over 200 publishers across the world to deliver seamless content experiences over a billion readers every month.",
      image:
        "https://gumlet.assettype.com/quintype-website/2019-12/fcae94ec-6529-44ec-82eb-0c7154358703/Contact_Us_02.svg",
    },
    structuredData: {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: "Quintype",
          item: "https://www.quintype.com/",
        },
        {
          "@type": "ListItem",
          position: 2,
          name: "About",
          item: "https://www.quintype.com/about",
        },
      ],
    },
  },
  [PARTNERS]: {
    title: "Our Partners",
    description: "Build your next big project on Quintype with our partners.",
    og: {
      title: "Our Partners",
      site_name: "Quintype",
      url: "https://www.quintype.com/partners",
      description:
        "Looking for a development partner to implement your next project? Our development partners enable you to successfully build, implement and manage your digital presence using our products. Find a trusted partner on this page to discuss your project.",
      type: "website",
      image:
        "https://gumlet.assettype.com/quintype-website/2019-12/fcae94ec-6529-44ec-82eb-0c7154358703/Contact_Us_02.svg",
    },
    twitter: {
      card: "summary_large_image",
      site: "@Quintype_inc",
      title: "Our Partners",
      description:
        "Looking for a development partner to implement your next project? Our development partners enable you to successfully build, implement and manage your digital presence using our products. Find a trusted partner on this page to discuss your project.",
      image:
        "https://gumlet.assettype.com/quintype-website/2019-12/fcae94ec-6529-44ec-82eb-0c7154358703/Contact_Us_02.svg",
    },
    structuredData: {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: "Quintype",
          item: "https://www.quintype.com/",
        },
        {
          "@type": "ListItem",
          position: 2,
          name: "Partners",
          item: "https://www.quintype.com/partners",
        },
      ],
    },
  },
  [CASESTUDY]: {
    title: "#PoweredbyQuintype Case Studies",
    description:
      "Quintype's suite of products help digital media organizations across the world create, distribute and monetize their content better. See how some of our customers are solving their digital publishing challenges with Quintype's products.",
    og: {
      title: "#PoweredbyQuintype Case Studies",
      site_name: "Quintype",
      url: "https://www.quintype.com/case-study",
      description:
        "Quintype's suite of products help digital media organizations across the world create, distribute and monetize their content better. See how some of our customers are solving their digital publishing challenges with Quintype's products.",
      type: "website",
      image:
        "https://images.assettype.com/quintype-website/2019-12/0fcfdfc8-79d4-419b-89f1-8b3cb45bfb98/CaseStudy_Banner.png",
    },
    twitter: {
      card: "summary_large_image",
      site: "@Quintype_inc",
      title: "#PoweredbyQuintype Case Studies",
      description:
        "Quintype's suite of products help digital media organizations across the world create, distribute and monetize their content better. See how some of our customers are solving their digital publishing challenges with Quintype's products.",
      image:
        "https://images.assettype.com/quintype-website/2019-12/0fcfdfc8-79d4-419b-89f1-8b3cb45bfb98/CaseStudy_Banner.png",
    },
    structuredData: {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: "Quintype",
          item: "https://www.quintype.com/",
        },
        {
          "@type": "ListItem",
          position: 2,
          name: "Case Studies",
          item: "https://www.quintype.com/case-study",
        },
      ],
    },
  },
  "/case-study/bloombergquint": {
    title: "Case study: BloombergQuint",
    description:
      "This case study showcases how Quintype helped BloombergQuint achieve a tremendous yearly growth of 300% and over million users, implement new revenue models on their website, and engage their readers. #poweredbyQuintype",
    og: {
      title: "Case Study: BloombergQuint",
      site_name: "Quintype",
      url: "https://www.quintype.com/case-study/bloombergquint",
      description:
        "This case study showcases how Quintype helped BloombergQuint achieve a tremendous yearly growth of 300% and over million users, implement new revenue models on their website, and engage their readers. #poweredbyQuintype",
      type: "website",
      image:
        "https://images.assettype.com/quintype-website/2019-12/df510ce1-9b15-40f8-b524-884ece763049/CaseStudy_BQ.png",
    },
    twitter: {
      card: "summary_large_image",
      site: "@Quintype_inc",
      title: "Case Study: BloombergQuint",
      description:
        "This case study showcases how Quintype helped BloombergQuint achieve a tremendous yearly growth of 300% and over million users, implement new revenue models on their website, and engage their readers. #poweredbyQuintype",
      image:
        "https://images.assettype.com/quintype-website/2019-12/df510ce1-9b15-40f8-b524-884ece763049/CaseStudy_BQ.png",
    },
    structuredData: {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: "Quintype",
          item: "https://www.quintype.com/",
        },
        {
          "@type": "ListItem",
          position: 2,
          name: "Case Studies",
          item: "https://www.quintype.com/case-study",
        },
        {
          "@type": "ListItem",
          position: 3,
          name: "BloombergQuint",
          item: "https://www.quintype.com/case-study/bloombergquint",
        },
      ],
    },
  },
  "/case-study/fortune-india": {
    title: "Case study: Fortune India",
    description:
      "Learn how quintype's Bold CMS helps organize print and digital content increasing editorial efficiency.",
    og: {
      title: "Case Study: Fortune India",
      site_name: "Quintype",
      url: "https://www.quintype.com/case-study/fortune-india",
      description:
        "Learn how quintype's Bold CMS helps organize print and digital content increasing editorial efficiency.",
      image:
        "https://images.assettype.com/quintype-website/2020-09/c3af8501-3233-4ae0-a780-e737a4dbf88b/CaseStudy_Fortune_min__1_.png",
    },
    twitter: {
      card: "summary_large_image",
      site: "@Quintype_inc",
      title: "Case Study: Fortune India",
      description:
        "Learn how quintype's Bold CMS helps organize print and digital content increasing editorial efficiency.",
      image:
        "https://images.assettype.com/quintype-website/2020-09/c3af8501-3233-4ae0-a780-e737a4dbf88b/CaseStudy_Fortune_min__1_.png",
    },
    structuredData: {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: "Quintype",
          item: "https://www.quintype.com/",
        },
        {
          "@type": "ListItem",
          position: 2,
          name: "Case Studies",
          item: "https://www.quintype.com/case-study",
        },
        {
          "@type": "ListItem",
          position: 3,
          name: "BloombergQuint",
          item: "https://www.quintype.com/case-study/fortune-india",
        },
      ],
    },
  },
  "/case-study/swarajya": {
    title: "Case study: Swarajyamag",
    description:
      "Quintype has given Swarajya the platform to boost itself and get on a growth trajectory with subscriptions, engagement and flexibility altogether in today's information packed digital space. Download the case study to learn how. #poweredbyQuintype",
    og: {
      title: "Case Study: Swarajya",
      site_name: "Quintype",
      url: "https://www.quintype.com/case-study/swarajya",
      description:
        "Quintype has given Swarajya the platform to boost itself and get on a growth trajectory with subscriptions, engagement and flexibility altogether in today's information packed digital space. Download the case study to learn how. #poweredbyQuintype",
      type: "website",
      image:
        "https://images.assettype.com/quintype-website/2019-12/41a7316d-a312-4e19-b79a-629f11f1b35d/CaseStudy_Swarajya.png",
    },
    twitter: {
      card: "summary_large_image",
      site: "@Quintype_inc",
      title: "Case Study: Swarajya",
      description:
        "Quintype has given Swarajya the platform to boost itself and get on a growth trajectory with subscriptions, engagement and flexibility altogether in todayís information packed digital space. Download the case study to learn how. #poweredbyQuintype",
      image:
        "https://images.assettype.com/quintype-website/2019-12/41a7316d-a312-4e19-b79a-629f11f1b35d/CaseStudy_Swarajya.png",
    },
    structuredData: {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: "Quintype",
          item: "https://www.quintype.com/",
        },
        {
          "@type": "ListItem",
          position: 2,
          name: "Case Studies",
          item: "https://www.quintype.com/case-study",
        },
        {
          "@type": "ListItem",
          position: 3,
          name: "Swarajya",
          item: "https://www.quintype.com/case-study/swarajya",
        },
      ],
    },
  },
  "/case-study/thequint": {
    title:
      "TheQuint: Enabling a 4x YoY growth of India's largest millennial focused digital publisher.",
    description:
      "TheQuint had an explosive 4X year on year growth with Quintype. TheQuint is India's largest millennial focused, digital-only news publisher. Within 4 years into the system they have grown 16X times. Learn how Quintype enabled the team to grow over the years. #poweredbyQuintype",
    og: {
      title:
        "Case Study: TheQuint - Enabling a 4x YoY growth of India's largest millennial focused digital publisher.",
      site_name: "Quintype",
      url: "https://www.quintype.com/case-study/thequint",
      description:
        "TheQuint had an explosive 4X year on year growth with Quintype. TheQuint is India's largest millennial focused, digital-only news publisher. Within 4 years into the system they have grown 16X times. Learn how Quintype enabled the team to grow over the years. #poweredbyQuintype",
      type: "website",
      image:
        "https://images.assettype.com/quintype-website/2019-12/a6d54eb1-e1ba-4e0f-9b58-8d6fa755b6d0/CaseStudy_TQ.png",
    },
    twitter: {
      card: "summary_large_image",
      site: "@Quintype_inc",
      title: "Case Study: TheQuint",
      description:
        "TheQuint had an explosive 4X year on year growth with Quintype. TheQuint is India's largest millennial focused, digital-only news publisher. Within 4 years into the system they have grown 16X times. Learn how Quintype enabled the team to grow over the years. #poweredbyQuintype",
      image:
        "https://images.assettype.com/quintype-website/2019-12/a6d54eb1-e1ba-4e0f-9b58-8d6fa755b6d0/CaseStudy_TQ.png",
    },
    structuredData: {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: "Quintype",
          item: "https://www.quintype.com/",
        },
        {
          "@type": "ListItem",
          position: 2,
          name: "Case Studies",
          item: "https://www.quintype.com/case-study",
        },
        {
          "@type": "ListItem",
          position: 3,
          name: "TheQuint",
          item: "https://www.quintype.com/case-study/thequint",
        },
      ],
    },
  },
  "/case-study/karjalainen": {
    title:
      "Karjalainen Website Traffic Growth Success Story | Quintype",
    description:
      "Know how Karjalainen saw a 68% increase in monthly pageviews with Quintype's news publishing platform despite having a hard paywall.",
    og: {
      title:
        "Karjalainen Website Traffic Growth Success Story | Quintype",
      site_name: "Quintype",
      url: "https://www.quintype.com/case-study/karjalainen",
      description:
        "Know how Karjalainen saw a 68% increase in monthly pageviews with Quintype's news publishing platform despite having a hard paywall",
      type: "website",
      image:
        "https://images.assettype.com/quintype-website/2023-02/5f45b2fb-8150-407a-a7e4-6c9d9f187ef9/karjalainen_success_story.png",
    },
    twitter: {
      card: "summary_large_image",
      site: "@Quintype_inc",
      title: "Karjalainen",
      description:
        "Know how Karjalainen saw a 68% increase in monthly pageviews with Quintype's news publishing platform despite having a hard paywall.",
      image:
        "https://images.assettype.com/quintype-website/2023-02/5f45b2fb-8150-407a-a7e4-6c9d9f187ef9/karjalainen_success_story.png",
    },
    structuredData: {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: "Quintype",
          item: "https://www.quintype.com/",
        },
        {
          "@type": "ListItem",
          position: 2,
          name: "Case Studies",
          item: "https://www.quintype.com/case-study",
        },
        {
          "@type": "ListItem",
          position: 3,
          name: "karjalainen",
          item: "https://www.quintype.com/case-study/karjalainen",
        },
      ],
    },
  },
  [GUIDES]: {
    title: "Guides and Resources - Quintype",
    description:
      "Our collection of guides and resources will help you supercharge your business with our products to deliver better content experiences to your readers.",
    og: {
      title: "Guides and Resources - Quintype",
      site_name: "Quintype",
      url: "https://www.quintype.com/guides",
      description:
        "Our collection of guides and resources will help you supercharge your business with our products to deliver better content experiences to your readers.",
      type: "website",
      image:
        "https://images.assettype.com/quintype-website/2019-12/29d84452-2e06-4284-9f83-4a4cbb08869d/Guide_Cover.png",
    },
    twitter: {
      card: "summary_large_image",
      site: "@Quintype_inc",
      title: "Guides and Resources - Quintype",
      description:
        "Our collection of guides and resources will help you supercharge your business with our products to deliver better content experiences to your readers.",
      image:
        "https://images.assettype.com/quintype-website/2019-12/29d84452-2e06-4284-9f83-4a4cbb08869d/Guide_Cover.png",
    },
    structuredData: {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: "Quintype",
          item: "https://www.quintype.com/",
        },
        {
          "@type": "ListItem",
          position: 2,
          name: "Guides and Resources",
          item: "https://www.quintype.com/guides",
        },
      ],
    },
  },
  [ESSENCE]: {
    title: "essence from Quintype",
    description:
      "Essence is initiated to share insights into the working of news organizations.",
    og: {
      title: "essence from Quintype",
      site_name: "Quintype",
      url: "https://www.quintype.com/essence",
      description:
        "Essence is initiated to share insights into the working of news organizations.",
      type: "website",
      image:
        "https://quintype-website.quintype.com/media-library/upload/Essence_banner.png",
    },
    twitter: {
      card: "summary_large_image",
      site: "@Quintype_inc",
      title: "essence from Quintype",
      description:
        "Essence is initiated to share insights into the working of news organizations.",
      image:
        "https://quintype-website.quintype.com/media-library/upload/Essence_banner.png",
    },
    structuredData: {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: "Quintype",
          item: "https://www.quintype.com/",
        },
        {
          "@type": "ListItem",
          position: 2,
          name: "essence from Quintype",
          item: "https://www.quintype.com/essence",
        },
      ],
    },
  },
  [CONTACTUS]: {
    title: "Contact Us: SaaS company in Bangalore, Quintype",
    description:
      "Whether you are already a publisher on Quintype or are exploring our offerings, reach our team to learn more. Contact us.",
    og: {
      title: "Contact Us",
      site_name: "Quintype",
      url: "https://www.quintype.com/contact-us",
      description:
        "Whether you are already a publisher on Quintype or are exploring our offerings, reach our team to learn more. Contact us.",
      type: "website",
      image:
        "https://gumlet.assettype.com/quintype-website/2019-12/fcae94ec-6529-44ec-82eb-0c7154358703/Contact_Us_02.svg",
    },
    twitter: {
      card: "summary_large_image",
      site: "@Quintype_inc",
      title: "Contact Us",
      description:
        "Whether you are already a publisher on Quintype or are exploring our offerings, reach our team to learn more. Contact us.",
      image:
        "https://gumlet.assettype.com/quintype-website/2019-12/fcae94ec-6529-44ec-82eb-0c7154358703/Contact_Us_02.svg",
    },
    structuredData: {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: "Quintype",
          item: "https://www.quintype.com/",
        },
        {
          "@type": "ListItem",
          position: 2,
          name: "Contact Us",
          item: "https://www.quintype.com/contact-us",
        },
      ],
    },
  },
  [GETDEMO]: {
    title: "Schedule Demo",
    description:
      "Create, manage, distribute and monetize content better. Our solutions for digital publishing have helped media organizations like yours do more with less. Contact us.",
    og: {
      title: "Schedule Demo",
      site_name: "Quintype",
      url: "https://www.quintype.com/schedule-demo",
      description:
        "Create, manage, distribute and monetize content better. Our solutions for digital publishing have helped media organizations like yours do more with less. Contact us.",
      type: "website",
      image:
        "https://images.assettype.com/quintype-website/2019-12/40d83988-d7f8-4e94-9349-6234201a0c9f/Bold_Screenshots_SEO.png",
    },
    twitter: {
      card: "summary_large_image",
      site: "@Quintype_inc",
      title: "Schedule Demo",
      description:
        "Create, manage, distribute and monetize content better. Our solutions for digital publishing have helped media organizations like yours do more with less. Contact us.",
      image:
        "https://images.assettype.com/quintype-website/2019-12/40d83988-d7f8-4e94-9349-6234201a0c9f/Bold_Screenshots_SEO.png",
    },
    structuredData: {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: "Quintype",
          item: "https://www.quintype.com/",
        },
        {
          "@type": "ListItem",
          position: 2,
          name: "Schedule Demo",
          item: "https://www.quintype.com/schedule-demo",
        },
      ],
    },
  },
  [BOLD_CMS_DEMO]: {
    title: "Bold CMS - Overview and Demo",
    description:
      "Create, manage, distribute and monetize content better. Our solutions for digital publishing have helped media organizations like yours do more with less. Contact us.",
    og: {
      title: "Bold CMS - Overview and Demo",
      site_name: "Quintype",
      url: "https://www.quintype.com/schedule-demo",
      description:
        "Create, manage, distribute and monetize content better. Our solutions for digital publishing have helped media organizations like yours do more with less. Contact us.",
      type: "website",
      image:
        "https://images.assettype.com/quintype-website/2019-12/40d83988-d7f8-4e94-9349-6234201a0c9f/Bold_Screenshots_SEO.png",
    },
    twitter: {
      card: "summary_large_image",
      site: "@Quintype_inc",
      title: "Bold CMS - Overview and Demo",
      description:
        "Create, manage, distribute and monetize content better. Our solutions for digital publishing have helped media organizations like yours do more with less. Contact us.",
      image:
        "https://images.assettype.com/quintype-website/2019-12/40d83988-d7f8-4e94-9349-6234201a0c9f/Bold_Screenshots_SEO.png",
    },
    structuredData: {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: "Quintype",
          item: "https://www.quintype.com/",
        },
        {
          "@type": "ListItem",
          position: 2,
          name: "Bold CMS - Overview and Demo",
          item: "https://www.quintype.com/schedule-demo",
        },
      ],
    },
  },
  [TRIALSIGNUP]: {
    title: "Get Free access to Bold CMS | Quintype",
    description:
      "Sign up for a 14-day unrestricted free trial of Quintype. Experience the seamless content creation process and simplified website management.",
    og: {
      title: "Start your free trial now",
      site_name: "Quintype",
      url: "https://www.quintype.com/sign-up",
      description:
        "Sign up for a 14-day unrestricted free trial of Quintype. Experience the seamless content creation process and simplified website management.",
      type: "article",
      image: "",
    },
    twitter: {
      card: "summary_large_image",
      site: "@quintype_inc",
      url: "https://www.quintype.com/sign-up",
      title: "Start your Free Trial",
      description:
        "Sign up for a 14-day unrestricted free trial of Quintype. Experience the seamless content creation process and simplified website management.",
    },
    structuredData: {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: "Quintype",
          item: "https://www.quintype.com/",
        },
        {
          "@type": "ListItem",
          position: 2,
          name: "Free Trial",
          item: "https://www.quintype.com/sign-up",
        },
      ],
    },
  },
  [SOLUTIONS]: {
    title: "Digital Publishing Platform for New and Small Media Publishers",
    description:
      "Quintype offers solutions for publishers to start and grow their digital business. You can access all the features and flexibility of an enterprise-level publishing platform at affordable costs",
    og: {
      title: "Digital Publishing Platform for New and Small Media Publishers",
      site_name: "Quintype",
      url: "https://www.quintype.com/solutions/new-and-modern-publishers",
      description:
        "Quintype offers solutions for publishers to start and grow their digital business. You can access all the features and flexibility of an enterprise-level publishing platform at affordable costs",
      type: "article",
      image: "",
    },
    twitter: {
      card: "summary_large_image",
      site: "@quintype_inc",
      url: "https://www.quintype.com/solutions/new-and-modern-publishers",
      title: "Digital Publishing Platform for New and Small Media Publishers",
      description:
        "Quintype offers solutions for publishers to start and grow their digital business. You can access all the features and flexibility of an enterprise-level publishing platform at affordable costs",
    },
    structuredData: {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: "Quintype",
          item: "https://www.quintype.com/",
        },
        {
          "@type": "ListItem",
          position: 2,
          name: "Digital Publishing Platform for New and Small Media Publishers",
          item: "https://www.quintype.com/solutions/new-and-modern-publishers",
        },
      ],
    },
  },
  [INCREASETRAFFIC]: {
    title: "Solutions for digital publishers  to increase traffic | Quintype",
    description:
      "Quintype offers a suite of tools to help publishers increase their traffic to their website efficiently and effortlessly. We offer a 14-day free trial.",
    og: {
      title: "Solutions for digital publishers  to increase traffic | Quintype",
      site_name: "Quintype",
      url: "https://www.quintype.com/solutions/to-increase-traffic",
      description:
        "Quintype offers a suite of tools to help publishers increase their traffic to their website efficiently and effortlessly. We offer a 14-day free trial.",
      type: "article",
      image: "",
    },
    twitter: {
      card: "summary_large_image",
      site: "@quintype_inc",
      url: "https://www.quintype.com/solutions/to-increase-traffic",
      title: "Solutions for digital publishers  to increase traffic | Quintype",
      description:
        "Quintype offers a suite of tools to help publishers increase their traffic to their website efficiently and effortlessly. We offer a 14-day free trial.",
    },
    structuredData: {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: "Quintype",
          item: "https://www.quintype.com/",
        },
        {
          "@type": "ListItem",
          position: 2,
          name: "Solutions for digital publishers  to increase traffic | Quintype",
          item: "https://www.quintype.com/solutions/to-increase-traffic",
        },
      ],
    },
  },
  [MONETIZECONTENT]: {
    title: "Content monetization tools for digital publishers",
    description:
      "Quintype provides solutions for digital publishers to generate revenue through your content. Learn more about managing your subscribers and premium content",
    og: {
      title: "Content monetization tools for digital publishers",
      site_name: "Quintype",
      url: "https://www.quintype.com/solutions/monetize-content",
      description:
        "Quintype provides solutions for digital publishers to generate revenue through your content. Learn more about managing your subscribers and premium content",
      type: "article",
      image: "",
    },
    twitter: {
      card: "summary_large_image",
      site: "@quintype_inc",
      url: "https://www.quintype.com/solutions/monetize-content",
      title: "Content monetization tools for digital publishers",
      description:
        "Quintype provides solutions for digital publishers to generate revenue through your content. Learn more about managing your subscribers and premium content",
    },
    structuredData: {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: "Quintype",
          item: "https://www.quintype.com/",
        },
        {
          "@type": "ListItem",
          position: 2,
          name: "Content monetization tools for digital publishers",
          item: "https://www.quintype.com/solutions/monetize-content",
        },
      ],
    },
  },
  [DEVELOPERS]: {
    title: "Bold CMS for Developers | Quintype",
    description:
      "Bold CMS is an enterprise headless CMS that gives the freedom and power to developers to extend their CMS and build their own front-end. ",
    og: {
      title: "Bold CMS for Developers | Quintype",
      site_name: "Quintype",
      url: "https://www.quintype.com/solutions/for-developers",
      description:
        "Bold CMS is an enterprise headless CMS that gives the freedom and power to developers to extend their CMS and build their own front-end. ",
      type: "article",
      image: "",
    },
    twitter: {
      card: "summary_large_image",
      site: "@quintype_inc",
      url: "https://www.quintype.com/solutions/for-developers",
      title: "Bold CMS for Developers | Quintype",
      description:
        "Bold CMS is an enterprise headless CMS that gives the freedom and power to developers to extend their CMS and build their own front-end.",
    },
    structuredData: {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: "Quintype",
          item: "https://www.quintype.com/",
        },
        {
          "@type": "ListItem",
          position: 2,
          name: "Cms for developers",
          item: "https://www.quintype.com/",
        },
        {
          "@type": "ListItem",
          position: 3,
          name: "enterprise headless cms",
          item: "https://www.quintype.com/solutions/for-developers",
        },
      ],
    },
  },
  [MAGAZINE_PUBLISHERS]: {
    title: "CMS platform for Digital Magazine Publishers",
    description:
      "Explore the Quintype’s Bold CMS designed for new and enterprise magazine publishers. Start a 14-day free trial.",
    og: {
      title: "CMS platform for Digital Magazine Publishers",
      site_name: "Quintype",
      url: "https://www.quintype.com/magazine-publishers-app",
      description:
        "Explore the Quintype’s Bold CMS designed for new and enterprise magazine publishers. Start a 14-day free trial.",
      type: "article",
      image: "",
    },
    twitter: {
      card: "summary_large_image",
      site: "@quintype_inc",
      url: "https://www.quintype.com/magazine-publishers-app",
      title: "CMS platform for Digital Magazine Publishers",
      description:
        "Explore the Quintype’s Bold CMS designed for new and enterprise magazine publishers. Start a 14-day free trial.",
    },
    structuredData: {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: "Quintype",
          item: "https://www.quintype.com/",
        },
        {
          "@type": "ListItem",
          position: 2,
          name: "Cms for developers",
          item: "https://www.quintype.com/",
        },
        {
          "@type": "ListItem",
          position: 3,
          name: "enterprise headless cms",
          item: "https://www.quintype.com/magazine-publishers-app",
        },
      ],
    },
  },
};

export { SEO_TAGS };
