import styled from "styled-components";

export const Container = styled.section`
  padding: 40px 0;
  font-family: ${props => props.theme.fontFamily.primary};
  font-weight: ${props => props.theme.fontWeight.bold};
  color: ${props => props.theme.color.gray2};
  max-width: 1140px;
  margin: auto;
`;

export const InnerContainer = styled.div`
  padding: 0 24px;
  text-align: center;

  @media (min-width: ${props => props.theme.breakPoints.tablet}) {
    max-width: ${props => props.theme.containerWidth};
    margin: auto;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }
`;

export const HeadlineContainer = styled.div`
  grid-column: 3/11;
  margin: auto;
  text-align: center;
  padding-bottom: 32px;
`;

export const ImageContainer = styled.div`
  grid-column: 1/13;
  text-align: center;
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: 1fr;
  @media (min-width: ${props => props.theme.breakPoints.tablet}) {
    padding: 32px;
  }
`;

export const Image = styled.img`
  width: 100%;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    width: 90%;
  }
`;
