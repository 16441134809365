import React from "react";
import { useSelector } from "react-redux";
import {
  AccordionContainer,
  Heading,
  HeadlineContainer,
  ContentContainer,
} from "./style";
import H2 from "../../../shared/h2/index";
import Para from "../../../shared/para-alt/index";

const Accordion = ({ data }) => {
  const isArabic = useSelector((state) => state);
  return (
    <AccordionContainer>
      <HeadlineContainer isArabic={isArabic}>
        <H2>{data.headline}</H2>
      </HeadlineContainer>
      {data.faq.map((item, index) => (
        <ContentContainer key={index}>
          <Heading>{item.question}</Heading>
          <Para>{item.answer}</Para>
        </ContentContainer>
      ))}
    </AccordionContainer>
  );
};

export default Accordion;
