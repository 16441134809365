import React from "react";

import { Container, Text } from "./style";
export default function CheckBox({ name, register, text }) {
  return (
    <Container>
      <input name={name} type="checkbox" ref={register} />
      <Text>{text}</Text>
    </Container>
  );
}
