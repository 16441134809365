import { pixelToEmConverter } from "../utils/pixel-to-em-converter";

const systemFont =
  "-apple-system, BlinkMacSystemFont, “Segoe UI”, “Roboto”, “Oxygen”,“Ubuntu”, “Cantarell”, “Fira Sans”, “Droid Sans”, “Helvetica Neue”, sans-serif";

export const allFonts =['aktiv-grotesk'];

const defaultFontSize = 16;

export const initialTheme = {
  containerWidth :'1140px',
  color: {
    background:
      "linear-gradient(to bottom, rgba(235, 246, 244, 0.6), rgba(199, 214, 211, 0.14) 61%)",
    gray1: "#8f9695",
    gray2: "#242424",
    gray3: "#828383",
    gray4: "#f8f8f8",
    gray5: "#e3e3e3",
    gray6: "#f6f6f6",
    gray7: "#d8d8d8",
    gray8: "#f9f9f9",

    green1: "#44d7b6",
    green2: "#268382",

    white: "#fff",
    black: "#343e3c",
    black1: "rgba(0 0 0, 0.85)",
    black2: '#515151',
    typeRegular: '#242424',
    typeSubtle: '#282828',
    whitesmoke: "#f5f5f5",
    red: "#f65a5b",
  },

  fontFamily: {
    primary: systemFont,
    fallback: systemFont
  },

  fontWeight: {
    bold: 700,
    regular: 400
  },

  fontSize: {
    baseFontSize: `${defaultFontSize}px`,
    xxs: pixelToEmConverter(12),
    xs: pixelToEmConverter(14),
    s: pixelToEmConverter(16),
    m: pixelToEmConverter(18),
    l: pixelToEmConverter(20),
    xl: pixelToEmConverter(24),
    xxl: pixelToEmConverter(28),
    xxxl: pixelToEmConverter(30),
    sjumbo: pixelToEmConverter(48),
    jumbo: pixelToEmConverter(60),
    giga: pixelToEmConverter(64),
    tera: pixelToEmConverter(80)
  },

   lineHeight : {
    lh1: 1.2,
    lh2: 1.3,
    lh3: 1.4,
    lh4: 1.6,
    lh5: 1.7,
    lh6: 2,
    lh7: 2.5,
  },

  letterSpacing: {
    ls1: "1.5px",
  },

  zIndex: {
    minusOne: -1,
    zero: 0,
    one: 1,
    two: 2,
    three: 3,
    four: 4,
    five: 5,
    six:6

  },

  shadow: {
    one: "0 2px 20px 0 rgba(177, 177, 177, 0.5)",
    two: "0 0 10px 1px rgba(0, 0, 0, 0.1)",
    three: "0px 11px 9px -3px rgba(235,235,235,1)",
    four: "0 2px 6px 0 rgba(177, 177, 177, 0.5)"
  },

  breakPoints: {
    tablet: "768px",
    desktop: "1024px"
  }
};

export const themeWithFonts = {
  ...initialTheme,
  fontFamily: {
    primary: 'aktiv-grotesk',
    fallback: systemFont
  }
}
