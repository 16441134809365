import React from "react";
import { useSelector } from "react-redux";
import TopComponentAltType from "./shared/top-component-alt-type";
import ImageWithContent from "./shared/image-with-content";
import ImageWithContentAltType from "./shared/image-with-content-alt-type";
import ClientReviewWithIcons from "./shared/client-review-with-icons";

import { data } from "./data";
import { arabicdata } from "./arabic-data";

function ForDevelopers() {
  const isArabic = useSelector((state) => state);
  return (
    <React.Fragment>
      <div dir={isArabic ? "rtl" : "ltr"} lang={isArabic ? `ar` : `en`}>
        <TopComponentAltType
          data={
            isArabic
              ? arabicdata.for_developers["top-component"]
              : data.for_developers["top-component"]
          }
        />
        <ImageWithContent
          data={
            isArabic
              ? arabicdata.for_developers["image-with-content"]
              : data.for_developers["image-with-content"]
          }
        />
        <ClientReviewWithIcons
          data={
            isArabic
              ? arabicdata.for_developers.review1
              : data.for_developers.review1
          }
        />
        <ImageWithContentAltType
          data={
            isArabic
              ? arabicdata.for_developers["image-with-content2"]
              : data.for_developers["image-with-content2"]
          }
          imageTopMargin="20px"
        />
        <ClientReviewWithIcons
          data={
            isArabic
              ? arabicdata.for_developers.review2
              : data.for_developers.review2
          }
        />
        <ImageWithContent
          data={
            isArabic
              ? arabicdata.for_developers["image-with-content3"]
              : data.for_developers["image-with-content3"]
          }
        />
        <ImageWithContentAltType
          data={
            isArabic
              ? arabicdata.for_developers["image-with-content4"]
              : data.for_developers["image-with-content4"]
          }
        />
      </div>
    </React.Fragment>
  );
}

export default ForDevelopers;
