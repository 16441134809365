import styled, { css } from "styled-components";

export const Container = styled.div``;

export const ExpandingListContainer = styled.ul`
  max-height: 0;
  list-style-type: none;
  display: none;
  transition: all 0.5s ease-in;
  ${props => {
    return (
      props.expand &&
      css`
        max-height: 1000px;
        display: block;
        padding: 16px 21px 0 21px;
      `
    );
  }}
`;

export const Img = styled.img`
 width: 18px;
 margin-left: 10px;
`

export const ExpandingListName = styled.div`
  padding: 12px 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${props =>
    props.expand ? props.theme.color.green1 : props.theme.color.gray3};
  font-family: ${props => props.theme.fontFamily.primary};
  cursor: pointer;
`;

// make the margin bottom baically (21 - 12 (padding of ExpandingListName))
export const ExpandingListLinkItemContainer = styled.li`
  :not(:last-child) {
    margin-bottom: 9px;
  }
`;
