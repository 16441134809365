import React from "react";
import {
  AboutPageWrapper,
  OuterContainer,
  InnerWrapper,
  SocialLink,
  TitleContainer,
  Container,
  AboutPageContent,
  Heading,
  Members,
  TeamPageMobile,
  Wrapper,
  TeamMemberImage,
  TeamMemberName,
  TeamMemberPosition,
  TeamMemberDescription,
  TeamSocialDetails,
  TeamPageDesktop,
  MemberDetails,
  MemberImage,
} from "./style";

import {ImageContainer} from '../case-study/style'

import H1 from '../shared/h1/index'
import Para from '../shared/para-reg/index'
import { title, description, info, teamMembersdata } from "./data";
import Facebook from "./icons/facebook";
import Linkedin from "./icons/linkedin";
import Twitter from "./icons/twitter";

function AboutPage() {
  return (
    <>
    <ImageContainer bgUrl={"https://images.assettype.com/quintype-website/2020-01/a50b9ab1-78ae-4d1b-9806-9ae81e578061/teamHero_a3464435.jpg"} />
    <AboutPageWrapper>
      <OuterContainer>
        <InnerWrapper>
          <Container>
            <div>
              <TitleContainer><H1>{title}</H1></TitleContainer>
              <AboutPageContent><Para>{description}</Para></AboutPageContent>
              <AboutPageContent><Para>{info}</Para></AboutPageContent>
            </div>
          </Container>
        </InnerWrapper>
      </OuterContainer>
      <Heading>Team</Heading>
      <Members>
        {teamMembersdata.map((data, index) => {
          const SocialLogin = () => {
            return (
              <>
                {data.fblink && (
                  <SocialLink
                    href={data.fblink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Facebook />
                  </SocialLink>
                )}

                {data.twiterlink && (
                  <SocialLink
                    href={data.twiterlink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Twitter />
                  </SocialLink>
                )}

                {data.linkedinlink && (
                  <SocialLink
                    href={data.linkedinlink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Linkedin />
                  </SocialLink>
                )}
              </>
            );
          };
          return (
            <div key={index}>
              <TeamPageMobile>
                <Wrapper>
                  <TeamMemberImage>
                    <MemberImage src={data.memberImage} alt={data.memberName} />
                  </TeamMemberImage>
                  <div>
                    <TeamMemberName>{data.memberName}</TeamMemberName>
                    <TeamMemberPosition>
                      {data.memberPosition}
                    </TeamMemberPosition>
                  </div>
                </Wrapper>
                <TeamMemberDescription>
                  {data.memberDescription}
                </TeamMemberDescription>
                <TeamSocialDetails>
                  <SocialLogin />
                </TeamSocialDetails>
              </TeamPageMobile>
              <TeamPageDesktop>
                <div>
                  <TeamMemberImage>
                    <MemberImage src={data.memberImage} alt={data.memberName} />
                  </TeamMemberImage>
                  <TeamSocialDetails>
                    <SocialLogin />
                  </TeamSocialDetails>
                </div>
                <MemberDetails>
                  <TeamMemberName>{data.memberName}</TeamMemberName>
                  <TeamMemberPosition>{data.memberPosition}</TeamMemberPosition>
                  <TeamMemberDescription>
                    {data.memberDescription}
                  </TeamMemberDescription>
                </MemberDetails>
              </TeamPageDesktop>
            </div>
          );
        })}
      </Members>
    </AboutPageWrapper>
    </>
  );
}

export default AboutPage;
