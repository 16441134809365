import React from "react";

import Icon from "./icon/icon";

import { InternalLink, ExternalLink} from "./style";

export default function ArrowLink({ isExternal, children, url }) {
  return (
    <>
      {!isExternal ? (
        <InternalLink to={url}>
         {children} <Icon width={24} height={24} />
        </InternalLink>
      ) : (
        <ExternalLink target="_blank" rel="noopener noreferrer" href={url}>
          {children} <Icon width={24} height={24} />
        </ExternalLink>
      )}
    </>
  );
}
