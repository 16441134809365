import React from "react";
import PhoneInput from "react-phone-input-2";
import "../../../../node_modules/react-phone-input-2/lib/style.css";

import { Label } from "../../form-controls/style";
import "./style.css";

function PhoneInputField({ onChange, label, name, country, errors,value }) {
  const error = errors[name]
  const containerClass = "react-tel-input react-phone-input-container-mb-cs"
  const inputClass = error? 'react-phone-input-width-cs react-phone-input-red-cs ': 'react-phone-input-width-cs'
  const dropDownClass = error? 'react-phone-input-dropdown-cs': ''

  return (
    <>
      <Label>{label}</Label>
      <PhoneInput
        label={label}
        name={name}
        value={value}
        country={country}
        onChange={onChange}
        inputProps={{
          required: true
        }}
        inputClass={inputClass}
        containerClass={containerClass}
        buttonClass	={dropDownClass}
      />
    </>
  );
}

export { PhoneInputField };
