export const caseStudydata = {
  headline: "Case Studies",
  subheadline: "#PoweredbyQuintype",
  text:
    "Our suite of products help digital media organizations across the world to create, distribute and monetize their content better.",
  bgUrl:
    "https://gumlet.assettype.com/quintype-website/2019-12/0fcfdfc8-79d4-419b-89f1-8b3cb45bfb98/CaseStudy_Banner.png",
  items: [
    {
      url:
        "https://gumlet.assettype.com/quintype-website/2019-12/df510ce1-9b15-40f8-b524-884ece763049/CaseStudy_BQ.png",
      title:
        "BloombergQuint: Delivering multiplatform solutions for the leading business and finance portal in India.",
      link: "/case-study/bloombergquint",
      alt: "BloombergQuint CMS Case Study",
      linkType: "internal"
    },
    {
      url:
        "https://gumlet.assettype.com/quintype-website/2019-12/41a7316d-a312-4e19-b79a-629f11f1b35d/CaseStudy_Swarajya.png",
      title:
        "#Swarajya: Implementing reader revenue strategies for success in subscriptions.",
      link: "/case-study/swarajya",
      alt: "Swarajya reader revenue case study",
      linkType: "internal"
    },
    {
      url:
        "https://images.assettype.com/quintype-website/2020-09/c3af8501-3233-4ae0-a780-e737a4dbf88b/CaseStudy_Fortune_min__1_.png",
      title:
        "Fortune India: Empowering their editorial team to boost newsroom efficiency.",
      link: "/case-study/fortune-india",
      alt: "Fortune India case study",
      linkType: "internal"
    },
    {
      url:
        "https://gumlet.assettype.com/quintype-website/2019-12/38e25488-8d0d-4d69-b38f-c8f0612a94c4/CaseStudy_TQ.png",
      title:
        "TheQuint: Enabling a 4x YoY growth of India's largest millennial focused digital publisher.",
      link: "/case-study/thequint",
      alt: "TheQuint digital publishing success case study",
      linkType: "internal"
    },
    {
      url:
        "https://images.assettype.com/quintype-website/2021-04/64929faa-aa5c-4554-bafe-7296b6b4580b/Crds_01.jpg",
      title:
        "Delaware Valley’s #1 sports website partners with Quintype for a digital transformation.",
      link: "/case-study/bettors-insider",
      alt: "Delaware Valley case study",
      linkType: "internal"
    },
    {
      url:
        "https://images.assettype.com/quintype-website/2021-04/01669891-da8b-4e00-871d-af5b67823474/CaseStudy_Vikatan_new.png",
      title:
        "Enabling Vikatan's accelerated growth to serve the Next Billion Users with Quintype.",
      link: "/case-study/vikatan",
      alt: "Vikatan Case Study",
      linkType: "internal"
    },
    {
      url:
        "https://images.assettype.com/quintype-website/2021-05/2152bbd9-a27f-45e5-9578-49d0e814f8a5/gto_Crds_03.png",
      title:
        "Learn how Quintype facilitates GTO publishing to succeed in a digital world",
      link: "/case-study/gto-publishing",
      alt: "GTO Case Study",
      linkType: "internal"
    },
    {
      url:
        "https://gumlet.assettype.com/quintype-website/2020-12/10e32674-c887-404c-9619-9df535cb4937/CaseStudy_Prabhat_Khabar_34.png",
      title:
        "Prabhat Khabar: Driving innovation and growth for a regional publisher.",
      link: "/case-study/prabhat-khabar",
      alt: "Prabhat Khabar digital publishing success case study",
      linkType: "internal"
    },
    {
      url:
        "https://images.assettype.com/quintype-website/2021-08/aca68d40-d72d-40cd-bf40-98c67d9dc6d3/Untitled_2_02.png",
      title:
        "Sakal: Empowering journalists with mobile reporting for faster publishing.",
      link: "/case-study/e-sakal",
      alt: "E-Sakal Case Stuy",
      linkType: "internal"
    },
    {
      url:
        "https://images.assettype.com/quintype-website/2021-09/65073c88-cdb2-41aa-8336-ca2b44a79147/matter_news_Crds_04.png",
      title:
        "Grey Matter Media enjoy 5x growth and manage their website with zero code",
      link: "/case-study/matter-news",
      alt: "Matter News Case Stuy",
      linkType: "internal"
    },
    {
      url: "https://images.assettype.com/quintype-website/2021-11/04d61f7c-b05e-4317-b0fa-02618f03c2dc/CASE_STUDY_mi_morelia_09.png",
      title:
        "Quicker upgrades and better traffic with Bold CMS",
      link: "/case-study/mimorelia",
      alt: "MiMorelia Case Stuy",
      linkType: "internal"
    },
    {
      url: "https://images.assettype.com/quintype-website/2021-12/79078c41-0006-491c-80ec-2d8db98f2b25/Untitled_design_26.jpg",
      title:
        "MiMorelia (Spanish Version) : Quicker upgrades and better traffic with Bold CMS",
      link: "/case-study/mimorelia-spanish-version",
      alt: "MiMorelia Case Stuy",
      linkType: "internal"
    },
    {
      url: "https://images.assettype.com/quintype-website/2021-12/915924a5-2dd0-4a78-944b-9cb2f80cfe55/CASE_STUDY_karjalainen_09.png",
      title:
        "Empowering publishers who are moving from a print to digital approach with Bold CMS",
      link: "/case-study/karjalainen",
      alt: "Karjalainen Case Stuy",
      linkType: "internal"
    },
  ],
};

export const guideData = {
  headline: "Guides and Resources",
  text:
    "Our collection of guides and resources will help you supercharge your business with our products to deliver better content experiences to your readers",
  bgUrl:
    "https://gumlet.assettype.com/quintype-website/2019-12/29d84452-2e06-4284-9f83-4a4cbb08869d/Guide_Cover.png",
  items: [
    {
      url:
        "https://gumlet.assettype.com/quintype-website/2019-12/34a3844e-cb82-452c-ab7e-72fa2fa744e4/Guides_20.png",
      title: "E-book: How SEO is managed on Bold CMS",
      link:
        "https://gumlet.assettype.com/quintype-website/2019-12/0fb1479d-cbfb-49b7-8ad4-a9c0c147ac3c/SEO_on_Bold_CMS.pdf",
      alt: " seo ebook",
      linkType: "external",
    },
    {
      url:
        "https://gumlet.assettype.com/quintype-website/2019-12/bf6d7c71-4ba4-4565-abb2-49a13ebdae0b/Guides_23.png",
      title: "Video: Getting Started with our Malibu Framework for Bold CMS",
      link: "https://www.youtube.com/watch?v=BkolUAXkPwo",
      alt: "malibu framework for bold cms",
      linkType: "external",
    },
    {
      url:
        "https://gumlet.assettype.com/quintype-website/2020-12/9a09ba9f-286a-4754-8869-f057552ce869/Untitled_design.png",
      title: "Webinar: Programmatic Ads 101: Stop leaving money on the table",
      link:
        "https://www.quintype.com/blog/webinar/stop-leaving-money-on-the-table",
      alt: "learn how to monetize content",
      linkType: "external",
    },
    {
      url:
        "https://gumlet.assettype.com/quintype-website/2020-12/9a09ba9f-286a-4754-8869-f057552ce869/Untitled_design.png",
      title: "Webinar: Levers That Publishers Can Pull To Grow Their Traffic",
      link:
        "https://www.quintype.com/blog/webinar/increase-traffic-by-6x-in-180-days-quintype-and-izooto",
      alt: "learn how to grow traffic",
      linkType: "external",
    },
    {
      url:
        "https://gumlet.assettype.com/quintype-website/2020-12/9a09ba9f-286a-4754-8869-f057552ce869/Untitled_design.png",
      title: "Webinar: Reader Revenues:the audience perspective",
      link:
        "https://www.quintype.com/blog/webinar/reader-revenues-the-audience-perspective",
      alt: "reader revenues : the audience perspective",
      linkType: "external",
    },
    {
      url:
        "https://gumlet.assettype.com/quintype-website/2020-12/9a09ba9f-286a-4754-8869-f057552ce869/Untitled_design.png",
      title: "Webinar: Getting ready for Core Web Vitals 2021",
      link:
        "https://www.quintype.com/blog/webinar/getting-ready-for-core-web-vitals",
      alt: "getting ready for core web vitals 2021",
      linkType: "external",
    },
    {
      url:
        "https://gumlet.assettype.com/quintype-website/2020-12/9a09ba9f-286a-4754-8869-f057552ce869/Untitled_design.png",
      title: "Webinar: Latest Trends in Digital Publishing in Africa",
      link:
        "https://www.quintype.com/blog/webinar/latest-trends-in-digital-publishing-in-africa",
      alt: "latest trends in digital publishing in africa",
      linkType: "external",
    },
    {
      url:
        "https://gumlet.assettype.com/quintype-website/2020-12/9a09ba9f-286a-4754-8869-f057552ce869/Untitled_design.png",
      title: "Webinar: Insights on Google's December Core Update 2020",
      link:
        "https://www.quintype.com/blog/webinar/insights-on-googles-december-2020-core-update",
      alt: "insights on google's december core update 2020",
      linkType: "external",
    },
  ],
};

export const essenceData = {
  headline: "essence from Quintype",
  text:
    "Essence is initiated to share insights into the working of news organizations.",
  bgUrl:
    "https://gumlet.assettype.com/quintype-website/2019-12/89d063c7-586f-45d1-bd96-55dfdbd0bf9a/Essence_banner.png",
  items: [
    {
      url:
        "https://gumlet.assettype.com/quintype-website/2019-11/4ed4d1dc-3b43-42f5-991f-af2cec75d4ac/essence_E01_PART1.jpg",
      title: "Online Journalism & How to Monetize Content: Episode 1 - Part 1",
      link: "https://www.youtube.com/watch?v=10l4Ziu5VjY",
      alt: "Online Journalism",
      linkType: "external",
    },
    {
      url:
        "https://gumlet.assettype.com/quintype-website/2019-11/ce250a61-80b8-48ac-a489-d7f40b347ede/essence_E01_PART2.jpg",
      title: "Digital Revenue Strategies for Regional News: Episode 1 - Part 2",
      link: "https://www.youtube.com/watch?v=ciQZpvmdSAc",
      alt: "Reader Revenue strategies",
      linkType: "external",
    },
    {
      url:
        "https://gumlet.assettype.com/quintype-website/2019-11/1cdb4480-3f29-495c-ad9a-d180a3a5262b/essence_E03.jpg",
      title: "Role of Citizen Journalism in Newsrooms: Episode 1 - Part 3",
      link: "https://www.youtube.com/watch?v=FoyCSaeJuGs",
      alt: "Citizen Journalism",
      linkType: "external",
    },
  ],
};
