module.exports = {
  ROOT: "/",
  ABOUT: "/about",
  SERVICES: "/services",
  CAREERS: "/careers",
  PRODUCTS: "/products",
  BOLD: "/products/bold",
  AHEAD: "/products/ahead",
  ACCESSTYPE: "/products/accesstype",
  METYPE: "/products/metype",
  REACT_NATIVE_MOBILE_APP: "/products/react-native-news-app-ios-android",
  TRIALSIGNUP: "/sign-up",
  RN_TRIALSIGNUP: "/rn-free-trial",
  TERMS: "/(terms|terms-of-service)",
  INTEGRATIONS: "/integrations",
  TRIALTHANKYOU: "/trial-thank-you",
  CONFIRMSIGNUP: "/confirm-signup",
  CAMPAIGNS: "/campaigns/cms-comparison",
  PRIVACYPOLICY: "/privacy-policy",
  CASESTUDY: "/case-study",
  PARTNERS: "/partners",
  CONTACTUS: "/contact-us",
  GETDEMO: "/schedule-demo",
  ESSENCE: "/essence",
  COMPARISON: "/comparison",
  INDIVIDUALCASESTUDY: "/case-study/:id",
  GUIDES: "/guides",
  CONSULTING: "/consulting",
  BOLD_CMS_DEMO: "/bold-cms-demo",
  SOLUTIONS: "/solutions/new-and-modern-publishers",
  INCREASETRAFFIC: "/solutions/to-increase-traffic",
  MONETIZECONTENT: "/solutions/monetize-content",
  DEVELOPERS: "/solutions/for-developers",
  MAGAZINE_PUBLISHERS: "/solutions/magazine-publishers-app",
  THANKYOU: "/thank-you",
};
