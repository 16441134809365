import React from "react";
import { useSelector } from "react-redux";
import TopComponentAltType from "./shared/top-component-alt-type";
import ImageWithContent from "./shared/image-with-content";
import ImageWithContentAltType from "./shared/image-with-content-alt-type";
import ClientAwards from "./shared/client-awards";
import Accordion from "./shared/accordion";
import Benefits from "./shared/benefits";

import { data } from "./data";
import { arabicdata } from "./arabic-data";

function MagazinePublishers() {
  const isArabic = useSelector((state) => state);
  return (
    <React.Fragment>
      <div dir={isArabic ? "rtl" : "ltr"} lang={isArabic ? `ar` : `en`}>
        <TopComponentAltType
          data={
            isArabic
              ? arabicdata.magazine_publishers["top-component"]
              : data.magazine_publishers["top-component"]
          }
        />
        <Benefits
          data={
            isArabic
              ? arabicdata.magazine_publishers["benefits"]
              : data.magazine_publishers["benefits"]
          }
        />
        <ImageWithContentAltType
          data={
            isArabic
              ? arabicdata.magazine_publishers["image-with-content"]
              : data.magazine_publishers["image-with-content"]
          }
          imageTopMargin="-50px"
          listStyle="disc"
        />
        <ImageWithContent
          data={
            isArabic
              ? arabicdata.magazine_publishers["image-with-content2"]
              : data.magazine_publishers["image-with-content2"]
          }
          listStyle="disc"
        />
        <ClientAwards
          data={
            isArabic
              ? arabicdata.magazine_publishers["client-awards"]
              : data.magazine_publishers["client-awards"]
          }
        />
        {isArabic ? (
          <right>
            <Accordion
              data={
                isArabic
                  ? arabicdata.magazine_publishers["faqs"]
                  : data.magazine_publishers["faqs"]
              }
            />
          </right>
        ) : (
          <Accordion
            data={
              isArabic
                ? arabicdata.magazine_publishers["faqs"]
                : data.magazine_publishers["faqs"]
            }
          />
        )}
      </div>
    </React.Fragment>
  );
}

export default MagazinePublishers;
