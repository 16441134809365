import React from "react";
import PropTypes from "prop-types";

import {
  Container,
  InnerContainer,
  HeadlineContainer,
  Images,
  Item,
  LinkItem,
} from "./style";

import ArrowLink from "../../shared/arrow-link/index";
import H2 from "../../shared/h2/index";

function AwardsAndPublisherLogos({ data }) {
  return (
    <Container>
      <InnerContainer>
        <HeadlineContainer>
          <H2> {data.headline}</H2>
        </HeadlineContainer>
        <Images>
          {data.logos.map((logo, index) => (
            <Item key={index}>
              <img src={logo.url} alt={logo.alt} loading="lazy" />
            </Item>
          ))}
        </Images>
        {/* <LinkItem>
          <ArrowLink
            isExternal={true}
            url={data["read-more"].url}
          >
            {data["read-more"].text}
          </ArrowLink>
        </LinkItem> */}
      </InnerContainer>
    </Container>
  );
}

AwardsAndPublisherLogos.propTypes = {
  data: PropTypes.shape({
    headline: PropTypes.string,
    logo: PropTypes.array,
    "read-more": PropTypes.shape({
      url: PropTypes.string,
      title: PropTypes.string,
      text: PropTypes.string,
    }),
  }),
};

export default AwardsAndPublisherLogos;
