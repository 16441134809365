export const arabicgetDemoData = {
    arabictitle: "توقف عن محاربة التكنولوجيا",
    arabiccontent:
      "إنشاء وإدارة وتوزيع واستثمار المحتوى بشكل أفضل. ساعدت حلولنا للنشر الرقمي المؤسسات الإعلامية مثل مؤسستك على إنجاز المزيد باستخدام موارد أقل. قم بجدولة عرض توضيحي من منصتنا وتعلم كيف يمكنك:",
    arabicpageviews: [
      { label: "أقل من 5 مليون", value: "less than 5 million" },
      { label: "5 - 10 مليون", value: "5-10 million" },
      { label: "10 - 25 مليون", value: "10-25 million" },
      { label: " 25 - 50 مليون", value: "25-50 mllion" },
      { label: " + 50 مليون", value: "50+ million" },
    ],
    arabicitems: [
      {
        label: "استكشاف خيارات نظام إدارة المحتوى",
        value: "Exploring CMS options",
      },
      { label: "أبحث عن عرض", value: "Looking for a demo" },
      { label: "مستعد للتغيير", value: "Ready to switch" },
    ],
    arabiccontentlistData: [
      "إدارة المحتوى بشكل أفضل",
      "زيادة الكفاءة التحريرية",
      "تقليل التكاليف التكنولوجية",
      "زيادة الوصول إلي المحتوى",
      "التعرف علي استراتيجيات عوائد القارئ",
      "تحسين تفاعل الجمهور",
    ],
  };