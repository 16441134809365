import { Twitter, Linkedin, Youtube, Facebook } from "../icons/index";

const productList = [
  { name: "Bold", url: "/products/bold", type: "internal" },
  { name: "Ahead", url: "/products/ahead", type: "internal" },
  { name: "Metype", url: "/products/metype", type: "internal" },
  { name: "Accesstype", url: "/products/accesstype", type: "internal" },
  {
    name: "Content Mobile Apps",
    url: "/products/react-native-news-app-ios-android",
    type: "internal",
  },
];

const resourcesList = [
  { name: "Blog", url: "https://www.quintype.com/blog", type: "external" },
  { name: "Case Studies", url: "/case-study", type: "internal" },
  { name: "Integrations", url:"/integrations", type: "internal" },
  { name: "Essence", url: "/essence", type: "internal" },
  { name: "Guides", url: "/guides", type: "internal" },
  {
    name: "Developer Docs",
    url: "https://developers.quintype.com/",
    type: "external",
  },
  {
    name: "Press Release",
    url: "https://www.quintype.com/blog/press-release",
    type: "external",
  },
  {
    name: "Awards",
    url: "https://www.quintype.com/blog/people/awards",
    type: "external",
  },
];

const solutionsList = [
  {
    name: "New and Modern Publishers",
    url: "/solutions/new-and-modern-publishers",
    type: "internal",
  },
  {
    name: "Magazine Publishers",
    url: "/solutions/magazine-publishers-app",
    type: "internal",
  },
  {
    name: "To Increase Traffic",
    url: "/solutions/to-increase-traffic",
    type: "internal",
  },
  {
    name: "Monetize your content",
    url: "/solutions/monetize-content",
    type: "internal",
  },
  {
    name: "For Developers",
    url: "/solutions/for-developers",
    type: "internal",
  },
];

export const socialLinkData = [
  {
    name: "Twitter",
    Icon: Twitter,
    url: "https://twitter.com/quintype_inc/",
  },
  {
    name: "Linkedin",
    Icon: Linkedin,
    url: "https://www.linkedin.com/company/quintype/",
  },
  {
    name: "Youtube",
    Icon: Youtube,
    url: "https://www.youtube.com/channel/UCWBqw8zwqMZjsZxBhA5xjsw",
  },
  {
    name: "Facebook",
    Icon: Facebook,
    url: "https://www.facebook.com/Quintypetechnologies/",
  },
];

export const navBarData = [
  {
    type: "link",
    name: "Home",
    url: "/",
  },
  {
    type: "list",
    name: "Products",
    listItems: productList,
  },

  {
    type: "list",
    name: "Resources",
    listItems: resourcesList,
  },

  {
    type: "list",
    name: "Solutions",
    listItems: solutionsList,
  },

  {
    type: "link",
    name: "Schedule Demo",
    url: "/schedule-demo",
  },
];
