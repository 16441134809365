import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import quotes from "../../images/quotes.png";

import {
  MainContainer,
  InnerContiner,
  Content,
  ReviewItem,
  Designation,
  ImageContainer,
  Image,
  IconContainer,
  Icon,
  Slice,
  Container,
  FeatureHeadline,
  FeatureContent,
} from "./style";

function ClientReviewWithIcons({ data }) {
  const isArabic = useSelector((state) => state);
  return (
    <MainContainer>
      <InnerContiner>
        <ReviewItem isArabic={isArabic}>
          <IconContainer>
            <Icon src={quotes} alt={data.alt}></Icon>
          </IconContainer>
          <Content isArabic={isArabic}>{data.content}</Content>
          <Container>
            <IconContainer>
              <Slice src={quotes} alt={data.alt}></Slice>
            </IconContainer>
            <ImageContainer>
              <Designation>{data.designation}</Designation>
              <Image src={data["image-url"]} alt={data.alt}></Image>
            </ImageContainer>
          </Container>
        </ReviewItem>
        <ReviewItem isArabic={isArabic}>
          {data.features.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <FeatureHeadline>{item.headline}</FeatureHeadline>
                <FeatureContent>{item.description}</FeatureContent>
              </React.Fragment>
            );
          })}
        </ReviewItem>
      </InnerContiner>
    </MainContainer>
  );
}

ClientReviewWithIcons.propTypes = {
  data: PropTypes.shape({
    headline: PropTypes.string,
    content: PropTypes.string,
    designation: PropTypes.string,
    "image-url": PropTypes.string,
    alt: PropTypes.string,
    features: PropTypes.array,
  }),
};

export default ClientReviewWithIcons;
