import styled from "styled-components";

export const Container = styled.div`
  margin-top: 80px;
  text-align: center;
`;

export const Input = styled.input`
  outline: ${props =>
    props.error ? ` 2px solid ${props.theme.color.red}` : `none`};
`;

export const Text = styled.span`
  display: inline-block;
  font-size: ${props => props.theme.fontSize.xs};
  margin-left: 10px;
`;
