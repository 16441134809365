import React from "react";
import {
  ComparisonWrapper,
  Heading,
  Title,
  Table,
  ComparisionTable,
  Image,
  MonetizationTable,
  Text,
  Link,
  TableHead,
  TableData
} from "./style";
import {
  comparison,
  comparisonTableData,
  contentrows,
  monetizationrows,
} from "./data";

function Comparison() {
  return (
    <ComparisonWrapper>
      <Heading>{comparison.headline}</Heading>
      <Heading>{comparison.company}</Heading>
      <Title>{comparison.title}</Title>
      <ComparisionTable>
        <Table>
          <thead>
            <tr>
              {comparisonTableData.columns.map((column, index) => {
                return <TableHead key={index}>{column}</TableHead>;
              })}
            </tr>
          </thead>
          {contentrows.map((item, index) => {
            return (
              <tbody key={index}>
                <tr>
                  <TableData>{item.Service}</TableData>
                  <TableData>
                    {typeof item.Quintype === "boolean" ? (
                      item.Quintype ? (
                        <Image src={comparison.icon} alt={"tick"} />
                      ) : (
                        ""
                      )
                    ) : (
                      item.Quintype
                    )}
                  </TableData>
                  <TableData>
                    {typeof item.Cue === "boolean" ? (
                      item.Cue ? (
                        <Image src={comparison.icon} alt={"tick"} />
                      ) : (
                        ""
                      )
                    ) : (
                      item.Cue
                    )}
                  </TableData>
                  <TableData>
                    {typeof item.WordPress === "boolean" ? (
                      item.WordPress ? (
                        <Image src={comparison.icon} alt={"tick"} />
                      ) : (
                        ""
                      )
                    ) : (
                      item.WordPress
                    )}
                  </TableData>
                  <TableData>
                    {typeof item.KenticoCloud === "boolean" ? (
                      item.KenticoCloud ? (
                        <Image src={comparison.icon} alt={"tick"} />
                      ) : (
                        ""
                      )
                    ) : (
                      item.KenticoCloud
                    )}
                  </TableData>
                  <TableData>
                    {typeof item.Drupal === "boolean" ? (
                      item.Drupal ? (
                        <Image src={comparison.icon} alt={"tick"} />
                      ) : (
                        ""
                      )
                    ) : (
                      item.Drupal
                    )}
                  </TableData>
                  <TableData>
                    {typeof item.Aem === "boolean" ? (
                      item.Aem ? (
                        <Image src={comparison.icon} alt={"tick"} />
                      ) : (
                        ""
                      )
                    ) : (
                      item.Aem
                    )}
                  </TableData>
                </tr>
              </tbody>
            );
          })}
        </Table>
      </ComparisionTable>
      <Title>{comparison.monetizeTitle}</Title>
      <MonetizationTable>
        <Table>
          <thead>
            <tr>
              {comparisonTableData.columns.map((column, index) => {
                return <TableHead key={index}>{column}</TableHead>;
              })}
            </tr>
          </thead>
          {monetizationrows.map((item, index) => {
            return (
              <tbody key={index}>
                <tr>
                  <TableData>{item.Service}</TableData>
                  <TableData>
                    {typeof item.Quintype === "boolean" ? (
                      item.Quintype ? (
                        <Image src={comparison.icon} alt={"tick"} />
                      ) : (
                        ""
                      )
                    ) : (
                      item.Quintype
                    )}
                  </TableData>
                  <TableData>
                    {typeof item.Cue === "boolean" ? (
                      item.Cue ? (
                        <Image src={comparison.icon} alt={"tick"} />
                      ) : (
                        ""
                      )
                    ) : (
                      item.Cue
                    )}
                  </TableData>
                  <TableData>
                    {typeof item.WordPress === "boolean" ? (
                      item.WordPress ? (
                        <Image src={comparison.icon} alt={"tick"} />
                      ) : (
                        ""
                      )
                    ) : (
                      item.WordPress
                    )}
                  </TableData>
                  <TableData>
                    {typeof item.KenticoCloud === "boolean" ? (
                      item.KenticoCloud ? (
                        <Image src={comparison.icon} alt={"tick"} />
                      ) : (
                        ""
                      )
                    ) : (
                      item.KenticoCloud
                    )}
                  </TableData>
                  <TableData>
                    {typeof item.Drupal === "boolean" ? (
                      item.Drupal ? (
                        <Image src={comparison.icon} alt={"tick"} />
                      ) : (
                        ""
                      )
                    ) : (
                      item.Drupal
                    )}
                  </TableData>
                  <TableData>
                    {typeof item.Aem === "boolean" ? (
                      item.Aem ? (
                        <Image src={comparison.icon} alt={"tick"} />
                      ) : (
                        ""
                      )
                    ) : (
                      item.Aem
                    )}
                  </TableData>
                </tr>
              </tbody>
            );
          })}
        </Table>
      </MonetizationTable>
      <Text>
        {comparison.text}
        <Link title={comparison.link} href={comparison.url} target="_blank"> {comparison.link}</Link> with us
      </Text>
    </ComparisonWrapper>
  );
}

export default Comparison;
