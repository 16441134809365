import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import TagManager from "react-gtm-module";
import {Provider} from "react-redux";
import store from "./store";

import { publisherData } from "../src/config/publisher";
import Main from "./main";

const {gtmId} = publisherData
const tagManagerArgs = { gtmId };

TagManager.initialize(tagManagerArgs);

ReactDOM.hydrate(
  <Provider store={store && store}>
    <Router>
      <Main />
    </Router>
  </Provider>,
  document.getElementById("root")
);