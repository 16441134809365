import { Twitter, Linkedin, Youtube, Facebook } from "../icons/index";

const productList = [
  { name: "بولد", url: "/products/bold", type: "internal" },
  { name: "حزمة أهيد", url: "/products/ahead", type: "internal" },
  { name: "برنامج ماي تايب", url: "/products/metype", type: "internal" },
  { name: "برنامج اكسيس تايب", url: "/products/accesstype", type: "internal" },
  {
    name: "تطبيقات المحتوى للجوال",
    url: "/products/react-native-news-app-ios-android",
    type: "internal",
  },
];

const resourcesList = [
  { name: "مدونة", url: "https://www.quintype.com/blog", type: "external" },
  { name: "دراسات الحالة", url: "/case-study", type: "internal" },
  {
    name: "خبر صحفى",
    url: "https://www.quintype.com/blog/press-release",
    type: "external",
  },
];

const solutionsList = [
  {
    name: "ناشرون جدد وعصريون",
    url: "/solutions/new-and-modern-publishers",
    type: "internal",
  },
  {
    name: "ناشرو المجلات",
    url: "/solutions/magazine-publishers-app",
    type: "internal",
  },
  {
    name: "لزيادة الحركة ",
    url: "/solutions/to-increase-traffic",
    type: "internal",
  },
  {
    name: "استثمر المحتوى الخاص بك",
    url: "/solutions/monetize-content",
    type: "internal",
  },
  {
    name: "خاص للمطور",
    url: "/solutions/for-developers",
    type: "internal",
  },
];

export const socialLinkData = [
  {
    name: "Twitter",
    Icon: Twitter,
    url: "https://twitter.com/quintype_inc/",
  },
  {
    name: "Linkedin",
    Icon: Linkedin,
    url: "https://www.linkedin.com/company/quintype/",
  },
  {
    name: "Youtube",
    Icon: Youtube,
    url: "https://www.youtube.com/channel/UCWBqw8zwqMZjsZxBhA5xjsw",
  },
  {
    name: "Facebook",
    Icon: Facebook,
    url: "https://www.facebook.com/Quintypetechnologies/",
  },
];

export const arabicnavBarData = [
  {
    type: "link",
    name: "الصفحة الرئيسية",
    url: "/",
  },
  {
    type: "list",
    name: "المنتجات",
    listItems: productList,
  },

  {
    type: "list",
    name: "الموارد",
    listItems: resourcesList,
  },

  {
    type: "list",
    name: "الحلول",
    listItems: solutionsList,
  },

  {
    type: "link",
    name: "جدولة عرض",
    url: "/schedule-demo",
  },
];
