import React from "react";
import {
    Container,
    Heading,
    HeadlineContainer,
    BenefitsWrapper,
    BenefitWrapper,
    Benefit,
    ButtonContainer,
    Image,
} from "./style";

import PrimaryButton from "../../../shared/primaryButton";
import H2 from "../../../shared/h2/index";
import { Link } from "react-router-dom";

const Benefits = ({ data }) => {

    return (
        <Container>
            <HeadlineContainer>
                <H2>{data.headline}</H2>
            </HeadlineContainer>
            <BenefitsWrapper>
                {data.benefit.map((item, index) => (
                    <BenefitWrapper>
                        <Benefit key={index}>
                            <Image src={item.imageUrl} height="100px" width="100px" alt={item.alt}></Image>
                            <Heading>{item.text}</Heading>
                        </Benefit>
                    </BenefitWrapper>
                ))}
            </BenefitsWrapper>
            <ButtonContainer>
                <Link to={data.buttonContent.link}>
                    <PrimaryButton>{data.buttonContent.text}</PrimaryButton>
                </Link>
            </ButtonContainer>
        </Container>
    );
};

export default Benefits;
