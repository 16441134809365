import styled from "styled-components";
import { Link } from "react-router-dom";

export const Header = styled.header`
   padding: 14px 24px;
   top:0;
  z-index: ${props => props.theme.zIndex.three};
  background: ${props => props.theme.color.white};
  box-shadow: ${props =>
    props.isIntersecting ? "0 2px 30px 0 rgba(0,0,0,.2)" : "none"};
  position: ${props => (props.isIntersecting ? "sticky" : "static")};
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    display: none;
  }
`

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

`;

export const HamburgerIconContainer = styled.div`
  margin-top: 10px;
`;

export const LogoLink = styled(Link)`
  display: inline-block;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    margin-right: 48px;
  }
`;
