import styled from "styled-components";
export const Wrapper = styled.div`
 max-width: ${props => props.theme.containerWidth};
    margin: 0 auto;
    padding: 0;
  @media (max-width: ${props => props.theme.breakPoints.desktop}) {
   padding: 0 24px;
  }
`;

export const TitleContainer = styled.div`
  margin: 0 0 20px 0;


`;

export const HeadingContainer = styled.div`

  margin: 20px 0 0 0;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    margin: 30px 0 0 0;
  }
`;


export const Description = styled.div`
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
  }
`;

export const Details = styled.div`
  margin: 15px 0;
`;


export const TopContainer = styled.div`
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    grid-template-columns: repeat(12,1fr);
    margin: 50px 0 0 0;
  }
`;
