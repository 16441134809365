export const title = "Why can’t anyone be a publisher?";

export const description =
  "That one question drives everything we do, every day. Our endeavor is to seek design answers to digital publishing questions and turn them into a multitude of small yet very important features in our products. Writers, editors, designers, tech folks, and just about anyone should find our products effortless to understand and apply. We wouldn’t want anyone to reject a radical idea because tech can’t accommodate it.";

export const info =
  "The digital world is waiting for everyone to tell their compelling stories. For all the restless, hungry voices out there, we want to be the tech-edge in the hastily changing world. The small and the large, the new and the old, the news and the special interest stories will all have the power of the same advantages. There will be no better time like today to develop, scale, reach and impact. There will be equality, liberty, and fraternity for all in the new age of publishing. Every worthy story will be told.";

export const teamMembersdata = [
  {
    memberName: "Chirdeep Shetty",
    memberPosition: "CHIEF EXECUTIVE OFFICER",
    memberImage:
      "https://gumlet.assettype.com/quintype-website/2022-08/fe3a977d-eb4c-4600-b85a-e45b1b88cd08/Chirdeep.jpeg",
    memberDescription:
      "Chirdeep has extensive experience in building and managing teams focused on delivering large scale cloud based platforms with a keen eye for product design and predictive analytics. Prior to joining Quintype, he used to lead multiple delivery teams at ThoughtWorks.",
    fblink: "https://www.facebook.com/chirdeep.shetty",
    twiterlink: "https://twitter.com/chirdeepshetty",
    linkedinlink: "https://www.linkedin.com/in/chirdeep-shetty-11b60343/",
  },
  {
    memberName: "Vineet Panjabi",
    memberPosition: "Vice President – Sales",
    memberImage:
      "https://gumlet.assettype.com/quintype-website/2019-11/76a282bf-6f9d-4825-8c7f-9296c498bbf8/Vineet.png",
    memberDescription:
      "Vineet is the Vice President of Sales at Quintype where he drives business across national and overseas markets. He has over a decade of experience in building and executing effective sales program for mobile, internet and SaaS companies. Prior to Quintype, Vineet drove enterprise and channel sales strategy for OYO Rooms and Cvent Inc.",
    fblink: "https://www.facebook.com/v2.panjabi",
    twiterlink: "https://twitter.com/vineetpanjabi",
    linkedinlink: "https://in.linkedin.com/in/vineetpanjabi",
  },
  {
    memberName: "Ramalingam S",
    memberPosition: "Vice President - Quality",
    memberImage:
      "https://images.assettype.com/quintype-website/2020-07/381aa9fd-dc6f-4efb-9495-803effe58cbe/Ram_min.png",
    memberDescription:
      "Ram has over 15 years of experience in Quality and Test automation with a range of domain expertise across some of the best Product, Service and Consulting organizations. Prior to joining Quintype, he used to be a lead consultant at Thoughtworks and engaged with quite a number of enterprise clients in terms of Quality Assurance and Digital transformation.",
    fblink: "",
    twiterlink: "",
    linkedinlink:
      "https://www.linkedin.com/in/ramalingam-sankarasubramanian-42013b5/",
  },

  {
    memberName: "Anish Balan",
    memberPosition: "Head of Delivery & Partnerships",
    memberImage:
      "https://images.assettype.com/quintype-website/2020-07/b93da08d-5cb2-4804-a264-098e3165688b/Anish_min.png",
    memberDescription:
      "Anish has over 20 years of experience in the technology industry. Started as a web developer and then moved to project management and client services. He is heading Delivery and Partnerships at Quintype. He holds a master’s degree in Journalism and Mass Communication.",
    fblink: "",
    twiterlink: "",
    linkedinlink: "https://www.linkedin.com/in/anishbalan/",
  },

  {
    memberName: "Ramsharan Gorur Jayaraman",
    memberPosition: "Vice President - Technology",
    memberImage:
      "https://images.assettype.com/quintype-website/2020-07/1662a65d-f939-4d27-ac33-1108fa4fa64c/Ramsharan_min.png",
    memberDescription:
      "Ramsharan has built multiple systems across different spectrums like Local Commerce, Content Publishing and Health Care. As a tech enthusiast, he is always on the lookout on how tech can solve real world problems and make people's lives easier. He loves sports and an ardent fan of Liverpool FC.",
    fblink: "",
    twiterlink: "",
    linkedinlink:
      "https://www.linkedin.com/in/ramsharan-gorur-jayaraman-a5827651/",
  },

  {
    memberName: "Pushkaraj Phule",
    memberPosition: "Vice President - Marketing",
    memberImage:
      "https://images.assettype.com/quintype-website/2023-04/e45593cf-8e53-400b-ad0c-a075dc2807d1/pushkaraj_phule.jpg",
    memberDescription:
      "Pushkaraj has over 14 years of experience driving growth in the SaaS space through integrated marketing. Before joining Quintype, he worked with Ameyo, Netcore Solutions, Housing.com, and Newgen Software Inc. He is an engineering graduate from VJTI and MBA from XIMR. In his free time, he loves to read, meditate and spend time with his family.",
    fblink: "",
    twiterlink: "https://twitter.com/ppushkaraj",
    linkedinlink: "https://www.linkedin.com/in/pushkarajphule/",
  }
];
