import React from "react";
import { GlobalStyle } from "./components/global-styles";
import { useLocation } from "react-router-dom";

import Header from "./components/shared/header/index";
import Routing from "./Routing";
import ScrollToTop from "./utils/ScrollToTop";
import MetaTags from "./utils/Meta";
import  Footer from "./components/shared/footer";


function App() {
  const {pathname} = useLocation()
  return (
    <>
      <ScrollToTop pathname={pathname} />
      <GlobalStyle />
      <MetaTags />
      <div className="App">
        <Header key={pathname}/>
        <Routing />
        <Footer />
      </div>
    </>
  );
}

export default App;
