import React from "react";

const Linkedin = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 17 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>LinkedIn</title>

      <defs></defs>
      <g
        id="Linkedin"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="home"
          transform="translate(-1286.000000, -5647.000000)"
          fill="#535B6C"
        >
          <path
            d="M1303,5665 L1299.35635,5665 L1299.35635,5658.50221 C1299.35635,5656.86991 1298.82933,5655.75593 1297.51045,5655.75593 C1296.50366,5655.75593 1295.90451,5656.50667 1295.64092,5657.23288 C1295.5448,5657.4925 1295.52006,5657.85386 1295.52006,5658.21727 L1295.52006,5665 L1291.87518,5665 C1291.87518,5665 1291.92427,5653.99487 1291.87518,5652.85466 L1295.5197,5652.85466 L1295.5197,5654.57621 C1295.51231,5654.58909 1295.50271,5654.60297 1295.49575,5654.61545 L1295.5197,5654.57621 C1296.00391,5653.74984 1296.86866,5652.56933 1298.80424,5652.56933 C1301.20228,5652.56929 1303,5654.30521 1303,5658.03579 L1303,5665 Z M1288.06249,5647 C1286.81558,5647 1286,5647.90626 1286,5649.09802 C1286,5650.26381 1286.79198,5651.19757 1288.01411,5651.19757 L1288.03846,5651.19757 C1289.30951,5651.19757 1290.1,5650.26398 1290.1,5649.09802 C1290.07604,5647.90626 1289.30951,5647 1288.06249,5647 L1288.06249,5647 L1288.06249,5647 Z M1286.21647,5665 L1289.85997,5665 L1289.85997,5652.85466 L1286.21647,5652.85466 L1286.21647,5665 L1286.21647,5665 L1286.21647,5665 Z"
            id="LinkedIn"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default Linkedin;
