export const individualCaseStudyData = {
  bloombergquint: {
    title: "Case study: BloombergQuint",
    content:
      "Quintype used cutting-edge technology, auto-scaling, and artificial intelligence to help BloombergQuint manage, distribute and monetize their content. This case study showcases how Quintype helped BloombergQuint achieve a tremendous yearly growth of 300% and over million users, implement new revenue models on their website, and engage their readers.",
    pdf:
      "https://gumlet.assettype.com/quintype-website/2019-11/2695fdcb-2b47-449e-8f25-876de77f9b21/bloombergquint_case_study.pdf",
  },
  swarajya: {
    title: "Case study: Swarajyamag",
    content:
      "Quintype has given Swarajya the platform to boost itself and get on a growth trajectory with subscriptions, engagement and flexibility altogether in today’s information packed digital space. Download the case study to learn how.",
    pdf:
      "https://gumlet.assettype.com/quintype-website/2019-11/1882777e-f3f2-4150-a5ce-0ae4a887ff10/Swarajya_Reader_Revenue_Case_Study.pdf",
  },
  "fortune-india": {
    title: "Case study: Fortune India",
    content:
      "Fortune India commands a vast print readership across India and they wanted to carry forward their print legacy to an omni-channel digital environment. To do this, Fortune India needed a strong CMS that would enable its editorial team to boost newsroom efficiency and help them deliver outstanding web experience. Quintype undertook the challenge and helped Fortune India become the country’s one-stop destination for all that matters in the world of business improving editorial efficiency by 20%",
    pdf:
      "https://gumlet.assettype.com/quintype-website/2020-06/6036ab11-aa98-4613-856b-03665df0b2cd/Fortune_India_case_study.pdf",
  },
  thequint: {
    title: "Case study: TheQuint",
    content:
      "TheQuint had an explosive 4X year on year growth with Quintype. TheQuint is India's largest millennial focused, digital-only news publisher. Within 4 years into the system they have grown 16X times. Learn how Quintype enabled the team to grow over the years.",
    pdf:
      "https://gumlet.assettype.com/quintype-website/2019-12/f8fbead2-2f85-434e-9bf3-a8faafe92cad/TheQuint_CaseStudy_final.pdf",
  },
  "bettors-insider": {
    title:
      "Delaware Valley’s #1 sports website partners with Quintype for a digital transformation",
    content:
      "Quintype enabled the Bettors Insider team with powerful tools to meet the end-to-end needs of content creation and monetization. The team was able to accelerate the speed to market within 2 weeks and witnessed 4X growth in traffic. Download the case study!",
    pdf:
      "https://quintype-dropbox.s3-accelerate.amazonaws.com/quintype-website.quintype.com/2021-04-22/918/Bettors_Insider___Case_Study__1_.pdf",
  },
  vikatan: {
    title:
      "Enabling Vikatan's accelerated growth to serve the Next Billion Users with Quintype",
    content:
      "South India’s leading media powerhouse, Vikatan moved on from its legacy tech systems to Quintype to see a massive 200% organic traffic growth and 5X times increase in engagement on the website. Download the case study!",
    pdf:
      "https://quintype-dropbox.s3-accelerate.amazonaws.com/quintype-website.quintype.com/2021-04-22/1029/Vikatan_Case_Study_FINAL.pdf",
  },
  "gto-publishing": {
    title:
      "Learn how Quintype facilitates GTO publishing to succeed in a digital world",
    content:
      "Quintype empowered a small team of automotive enthusiasts to run a digital business with a powerful publishing platform. They were able to manage the website layouts internally and were able to jump their traffic and engagement by 2X.",
    pdf:
      "https://quintype-dropbox.s3-accelerate.amazonaws.com/quintype-website.quintype.com/2021-04-30/281/GTO_case_sttudy___small.pdf",
  },
  "prabhat-khabar": {
    title:
      "Quintype empowers a leading regional publisher to drive innovation and growth",
    content:
      "Prabhat Khabar was successfully able to scale its digital ambitions with Bold CMS from Quintype. Within 30 days of launch, the page views increased by over 35% visitors from search and social channels and went on to grow over 6X in the next 2 quarters. Download the case study!",
    pdf:
      "https://quintype-dropbox.s3-accelerate.amazonaws.com/quintype-website.quintype.com/2021-04-22/757/Case_Study___Prabhat_Khabar__1_.pdf",
  },
  "e-sakal": {
    title:
      "Case study: e-Sakal",
    content:
      "The Sakal Media Group is the largest independently owned media business in Maharashtra, India. Esakal workflow is print first and then digital. They wanted a CMS that could power both their print and digital content effectively. This is why they turned to Bold. Quintype took the challenge head on and helped e-Sakal enjoy a 99.5% uptime, with a 30% increase in efficiency since their transition to the Bold platform.",
    pdf:
      "https://quintype-dropbox.s3-accelerate.amazonaws.com/quintype-website.quintype.com/2021-08-18/1967/CASE_STUDY_eSAKAL__pdf_compressed.pdf",
  },
  "matter-news": {
    title:
      "Grey Matter Media enjoy 5x growth and manage their website with zero code",
    content:
      "Matter News is the digital publication of Grey Matter Media, a nonprofit news organization based in and dedicated to Columbus. Our team got down in the dirt and found out that their page speed was very low which affected the average reader’s experience on their website.",
    pdf:
      "https://quintype-dropbox.s3-accelerate.amazonaws.com/quintype-website.quintype.com/2021-09-16/402/edited_CASE_STUDY_matter_news.pdf",
  },
  "mimorelia": {
    title:
      "Quicker upgrades and better traffic with Bold CMS",
    content:
      "The Mexican publisher, MiMorelia has seen a 60% increase in their traffic since Onboarding Quintype’s headless CMS. Learn how they did this in less than two months.",
    pdf: 
      "https://quintype-dropbox.s3-accelerate.amazonaws.com/quintype-website.quintype.com/2021-12-02/1725/mi_morella.pdf"
  },
  "mimorelia-spanish-version": {
    title:
      "MiMorelia (Spanish Version) : Quicker upgrades and better traffic with Bold CMS",
    content:
      "The Mexican publisher, MiMorelia has seen a 50% increase in their traffic since Onboarding Quintype’s headless CMS. Learn how they did this in less than two months. Here's the Spanish version for the same.",
    pdf: 
      "https://quintype-dropbox.s3-accelerate.amazonaws.com/quintype-website.quintype.com/2021-12-15/1988/Spanish.pdf"
  },
  "karjalainen": {
    title:
      "Empowering publishers who are moving from a print to digital approach with Bold CMS",
    content:
      "Karjalainen is the leading news site in the Northern Karelia region of Finland. When they decided to move from print to digital, they were on the look out for a platform that was scalable and flexible. With the Bold CMS, they’ve been able to transform their digital publishing experience. Karjalainen has witnessed an increase in traffic, easy third party integration, and improved workflow.  Read the case study to learn more.",
    pdf: 
      "https://quintype-dropbox.s3-accelerate.amazonaws.com/quintype-website.quintype.com/2021-12-10/45/CASE_STUDY_Ikarjalainen.pdf"
  },
};
