import React, { useState } from "react";

import {
  Container,
  ExpandingListContainer,
  ExpandingListName,
  ExpandingListLinkItemContainer,
  Img
} from "./style";
import { LinkItem, ExternalLinkItem } from "../nav-bar/style";

function ExpandingList({ list, listName }) {
  const [expand, toggleExpansion] = useState(false);
  return (
    <Container>
      <ExpandingListName
        expand={expand}
        onClick={() => {
          toggleExpansion(expand => !expand);
        }}
      >
        {listName}{" "}
        {expand ? (
          <Img
            alt="up arrow"
            src="https://gumlet.assettype.com/quintype-website/2019-11/99c07878-8f93-4c52-8934-2e4f9df49efd/Arrow1_active.svg"
          />
        ) : (
          <Img
            alt="down arrow"
            src="https://gumlet.assettype.com/quintype-website/2019-11/0e920a26-e92e-4edc-ad8d-8110c6b5a2b0/Arrow2.svg"
          />
        )}
      </ExpandingListName>
      <ExpandingListContainer expand={expand}>
        {list.map((item, index) => {
          return (
            <ExpandingListLinkItemContainer key={index}>
              {item.type === "internal" ? (
                <LinkItem to={item.url}>{item.name}</LinkItem>
              ) : (
                <ExternalLinkItem
                  target="_blank"
                  rel="noopener noreferrer"
                  href={item.url}
                >
                  {item.name}
                </ExternalLinkItem>
              )}
            </ExpandingListLinkItemContainer>
          );
        })}
      </ExpandingListContainer>
    </Container>
  );
}

export default ExpandingList;
