import styled from "styled-components";

import { LinkItem } from "../mobile-view-container/nav-bar/style";
import { ToolTipContainer } from "./tool-tip/styles";
import { Header } from "../mobile-view-container/style";

export const DesktopHeader = styled(Header)`
  display: none;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    display: block;
    margin-top: 16px;
  }
`;

export const Container = styled.div`
  max-width: ${props => props.theme.containerWidth};
  margin: 0 auto;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const ListContainer = styled.ul`
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ListItem = styled.li`
  position: relative;
  font-family: ${props => props.theme.fontFamily.primary};
  font-size: ${props => props.theme.fontSize.s};
  font-weight: ${props => props.theme.fontWeight.bold};
  &:not(:last-child) {
    margin-right: 30px;
  }
  :hover ${ToolTipContainer} {
    display: block;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DeskTopLinkItem = styled(LinkItem)`
  :hover {
    color: ${props => props.theme.color.green1};
  }
`;

export const ToolTipTitle = styled.div`
  color: ${props => props.theme.color.gray3};
  cursor: pointer;
  ${ListItem}:hover & {
    color: ${props => props.theme.color.green1};
  }
`;

export const Nav = styled.nav``;
