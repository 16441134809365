import React from "react";
import {
  Wrapper,
  ContentWrapper,
  List,
  Item,
  TitleContainer,
} from "../get-demo/style";
import { NormalLink } from "../contact-us/style";
import { ListTitle } from "./style";

import Form from "./shared/form/index"
import { rnFreeTrialData } from "./data";

import H1 from "../shared/h1/index";
import Para from "../shared/para-reg/index";

export default function RNFreeTrial() {

  const { title, content, listData } = rnFreeTrialData;

  return (
    <Wrapper>
      <ContentWrapper>
        <TitleContainer>
          <H1 small={true}>{title}</H1>
        </TitleContainer>
        <Para>{content}</Para>
        <ListTitle>
          <Para>Be sure to try out - </Para>
        </ListTitle>
        <List>
          {listData.map((data, index) => {
            return (
              <Item key={index}>
                <Para>{data}</Para>
              </Item>
            );
          })}
        </List>
        <Para>
          To help you create your app today, we are happy to organize a 1-on-1 with
          you and show how we help publishers create, distribute and monetize their
          content better.{" "}
          <NormalLink alt="schedule demo page" to="/schedule-demo">
            Click here
          </NormalLink>{" "} to schedule a demo. Learn how you can benefit
          from a mobile app and build one in a matter of days with the react native apps.
        </Para>
      </ContentWrapper>
      <Form rnFreeTrial={true}/>
    </Wrapper>
  );
}