import { useEffect } from "react";
import PropTypes from "prop-types"

export default function ScrollToTop({pathname}) {
 
  useEffect(() => {
    window.scrollTo(0, 0);
  },[pathname]);

  return null;
}

ScrollToTop.propTypes = {
  pathname: PropTypes.string
}