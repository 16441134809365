import styled from "styled-components";

export const MainContainer = styled.section`
  padding: 40px 24px;
`;

export const Row = styled.div`
  padding-bottom: 72px;
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
    display: flex;
    grid-column: 1/12;
    text-align: left;
  }
`;

export const ImageContainer = styled.div`
  flex: 1 1 60%;
  order: ${(props) => (props.order ? "2" : "0")};
  margin-top: ${(props) => props.imageTopMargin};
`;

export const Image = styled.img`
  display: block;
  margin: auto;
  max-width: 100%;

  padding-bottom: 32px;

  @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
    padding-right: ${(props) => (props.order ? "0" : "12px")};
    padding-left: ${(props) => (props.order ? "12px" : "0")};
    padding-bottom: 0;
  }
`;

export const Content = styled.div`
  flex: 1 1 40%;
  font-family: ${(props) => props.theme.fontFamily.primary};
  text-align: ${(props) => (props.isArabic ? "right" : "left")};
  @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
    padding-left: ${(props) => (props.order ? "0" : "12px")};
    padding-right: ${(props) => (props.order ? "12px" : "0")};
  }
`;

export const ContentItem = styled.div`
  margin-bottom: 24px;
`;

export const Headline = styled.h3`
  color: ${(props) => props.theme.color.gray2};
  font-size: ${(props) => props.theme.fontSize.m};
  font-family: ${(props) => props.theme.fontFamily.primary};
  line-height: ${(props) => props.theme.lineHeight.lh2};
  padding-bottom: 36px;

  @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
    font-size: ${(props) => props.theme.fontSize.xxl};
    line-height: ${(props) => props.theme.lineHeight.lh2};
    padding-bottom: 40px;
  }
`;

export const HeadlineContainer = styled.div`
  padding-bottom: 36px;
  @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
    padding-bottom: 16px;
  }
`;

export const Feature = styled.h4`
  font-size: ${(props) => props.theme.fontSize.m};
  color: ${(props) => props.theme.color.black};
  padding-bottom: 8px;
`;

export const FeatureContent = styled.div`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-size: ${(props) => props.theme.fontSize.l};
  color: ${(props) => props.theme.color.gray2};
  font-weight: ${(props) => props.theme.fontWeight.regular};
  padding-bottom: 40px;
  line-height: ${(props) => props.theme.lineHeight.lh3};
`;

export const DesktopContainer = styled.div`
  display: none;
  @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
    display: block;
    max-width: ${(props) => props.theme.containerWidth};
    margin: auto;
    padding-top: 120px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }
`;

export const MobileContainer = styled.div`
  text-align: center;
  @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
    display: none;
  }
`;

export const MobileInnerContainer = styled.div`
  margin-bottom: 72px;
`;

export const BulletsWrapper = styled.ul`
  padding-left: 20px;
`;

export const Bullets = styled.li`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-weight: ${(props) => props.theme.fontWeight.regular};
  line-height: ${(props) => props.theme.lineHeight.lh4};
  color: ${(props) => props.theme.color.typeSubtle};
  font-size: ${(props) => props.theme.fontSize.xs};
  text-align: ${(props) => (props.isArabic ? "right" : "left")}
  list-style-type: ${(props) => props.listStyle};
  padding-bottom: 10px;

  @media (min-width: ${(props) => props.theme.breakPoints.desktop}) {
    font-size: ${(props) => props.theme.fontSize.m};
  }
`;
