import styled from "styled-components";

export const NotFoundWrapper = styled.div`
  text-align: center;
  display: block;
  min-height: calc(100vh - 100px);
`;

export const NotFoundImage = styled.div`
 margin: 2em auto;
`

export const Svg = styled.svg`
  height: 150px;
      width: 300px;
      @media (min-width:${props => props.theme.breakPoints.desktop}) {
        height: auto;
        width: auto;

      }
`

export const BigText = styled.h3`
    font-size: 22px;
    font-weight: bold;
    line-height: 1.88;
    @media (min-width:${props => props.theme.breakPoints.desktop}) {
        font-size: 34px;
    }
`

export const RemovedText = styled.p`
 font-size: 16px;
 @media (min-width:${props => props.theme.breakPoints.desktop}) {
    font-size: 22px;
 }

`

export const StyledLink = styled.div`
text-align: center;
margin-top: 16px;
`
