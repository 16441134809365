import React from "react";
import {
  Wrapper,
  TitleContainer,
  Description,
  HeadingContainer,
  Details,
  TopContainer
} from "./style";
import { title, description, consultingData, features } from "./data";
import Features from "../../components/product-page/shared/features";

import H1 from "../shared/h1/index";
import H3 from "../shared/h3/index";
import ParaReg from "../shared/para-reg/index";
import ParaAlt from "../shared/para-alt/index";

function ConsultingPage() {
  return (
    <Wrapper>
      <TopContainer>
        <TitleContainer>
          <H1>{title}</H1>
        </TitleContainer>
        <Description>
          <ParaReg>{description}</ParaReg>
        </Description>
      </TopContainer>
      {consultingData.map((data, index) => {
        return (
          <div key={index}>
            <HeadingContainer>
              <H3>{data.heading}</H3>
            </HeadingContainer>
            <Details>
              <ParaAlt>{data.detailsOne}</ParaAlt>
            </Details>
            <Details>
              <ParaAlt>{data.detailsTwo}</ParaAlt>
            </Details>
          </div>
        );
      })}
      <Features data={features} />
    </Wrapper>
  );
}
export default ConsultingPage;
