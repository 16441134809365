import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

const LinkStyles = css`
  display: inline-block;
  color: ${props => props.theme.color.gray3};
  padding: 12px 12px;
`;

export const NavBarContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => props.theme.color.white};
  padding: 21px;
  z-index: ${props => props.theme.zIndex.minusOne};
  transition: all 0.3s ease-out;
  transform: translateX(100%);
  opacity: 0;
  overflow: auto;
  will-change: transform, opacity;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    display: none;
  }
  ${props =>
    props.showNav &&
    css`
      transform: translateX(0);
      z-index: ${props => props.theme.zIndex.six};
      opacity: 1;
    `}
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 21px;
`;

export const MainContainer = styled.div`
  padding: 21px 21px 0 21px;
  margin-bottom: 32px;
`;

export const CloseButton = styled.button`
  border: 0;
  margin-top: 10px;
  background: none;
  cursor: pointer;
`;

export const Nav = styled.nav``;

export const ListContainer = styled.ul`
  list-style-type: none;
`;

export const ListItem = styled.li`
  font-family: ${props => props.theme.fontFamily.primary};
  font-size: ${props => props.theme.fontSize.s};
  font-weight: ${props => props.theme.fontWeight.bold};
  &:not(:last-child) {
    margin-bottom: 5px;
  }
`;

export const LinkItem = styled(Link)`
  ${LinkStyles}
`;

export const ExternalLinkItem = styled.a`
  ${LinkStyles}
`;
