import tick from "../comparison/tick.png";

export const comparison = {
  headline: "Compare the top CMS platforms with",
  company: "Quintype",
  title: "Content Creation",
  monetizeTitle: "Distribution and Monetization",
  icon: tick,
  text: "Want to learn more ?",
  link: "Schedule a demo",
  url: "/shedule-a-demo"

};
export const comparisonTableData = {
  columns: [
    "Service",
    "Quintype",
    "Cue",
    "WordPress",
    "Kentico Cloud",
    "Drupal",
    "AEM"
  ]
};

export const contentrows = [
  {
    Service: "Customisable Editorial Workflow",
    Quintype: true,
    Cue: true,
    WordPress: true,
    KenticoCloud: true,
    Drupal: false,
    Aem: "limited"
  },
  {
    'Service': 'Amazon Polly Integration',
    'Quintype': true,
    'Cue': 'plugin required',
    'WordPress': false,
    'KenticoCloud': false,
    'Drupal': false,
    'Aem': false
  },
{
    'Service': 'Image Optimization',
    'Quintype': true,
    'Cue': true,
    'WordPress': true,
    'KenticoCloud': true,
    'Drupal': true,
    'Aem': true
  },
{
    'Service': 'Breaking News Alerts',
    'Quintype': true,
    'Cue': false,
    'WordPress': false,
    'KenticoCloud': false,
    'Drupal': false,
    'Aem': false
  },
{
    'Service': 'Granular Access Management',
    'Quintype': true,
    'Cue': 'plugin required',
    'WordPress': 'plugin required',
    'KenticoCloud': true,
    'Drupal': true,
    'Aem': true
  },
{
    'Service': 'AMP Visual Stories',
    'Quintype': true,
    'Cue': 'plugin required',
    'WordPress': 'plugin required v4.8+',
    'KenticoCloud': false,
    'Drupal': false,
    'Aem': true
  },
{
    'Service': 'Built-in Analytics',
    'Quintype': true,
    'Cue': 'plugin required',
    'WordPress': false,
    'KenticoCloud': false,
    'Drupal': false,
    'Aem': true
  },
{
    'Service': 'Scheduled Publishing',
    'Quintype': true,
    'Cue': true,
    'WordPress': true,
    'KenticoCloud': true,
    'Drupal': true,
    'Aem': true
  },
{
    'Service': 'Live Blogs',
    'Quintype': true,
    'Cue': 'plugin required',
    'WordPress': 'plugin required',
    'KenticoCloud': false,
    'Drupal': true,
    'Aem': false
  },
{
    'Service': 'Live story preview',
    'Quintype': true,
    'Cue': true,
    'WordPress': 'plugin required',
    'KenticoCloud': true,
    'Drupal': true,
    'Aem': true
  },
{
    'Service': 'Content Syndication',
    'Quintype': true,
    'Cue': false,
    'WordPress': 'plugin required',
    'KenticoCloud': false,
    'Drupal': 'plugin required',
    'Aem': true
  },
{
    'Service': 'User Generated Content',
    'Quintype': true,
    'Cue': false,
    'WordPress': false,
    'KenticoCloud': false,
    'Drupal': false,
    'Aem': true
  },
{
    'Service': 'Page layout manager',
    'Quintype': true,
    'Cue': false,
    'WordPress': false,
    'KenticoCloud': false,
    'Drupal': false,
    'Aem': true
  },
{
    'Service': 'Story versioning',
    'Quintype': true,
    'Cue': false,
    'WordPress': 'plugin required',
    'KenticoCloud': true,
    'Drupal': false,
    'Aem': true
  },
{
    'Service': 'AI-based keyword recommendations',
    'Quintype': true,
    'Cue': false,
    'WordPress':false,
    'KenticoCloud': false,
    'Drupal': false,
    'Aem': false
  }
];



export const monetizationrows = [{
  'Service': 'Subscription and Paywall',
  'Quintype': true,
  'Cue': false,
  'WordPress':  'plugin required',
  'KenticoCloud': false,
  'Drupal':  'plugin required',
  'Aem': false
},
{
  'Service': 'RSS Feed management',
  'Quintype': true,
  'Cue': 'plugin required',
  'WordPress':  true,
  'KenticoCloud': false,
  'Drupal': true,
  'Aem': false
},
{
  'Service': 'Audience Engagement',
  'Quintype': true,
  'Cue': false,
  'WordPress': 'plugin required',
  'KenticoCloud': false,
  'Drupal': false,
  'Aem': true
},
{
  'Service': 'Custom RSS Feeds',
  'Quintype': true,
  'Cue': false,
  'WordPress': false,
  'KenticoCloud': false,
  'Drupal': false,
  'Aem': false
},
{
  'Service': 'Fb Instant Articles',
  'Quintype': true,
  'Cue': 'plugin required',
  'WordPress': 'plugin required',
  'KenticoCloud': true,
  'Drupal': 'plugin required',
  'Aem': false
},
{
  'Service': 'AMP',
  'Quintype': true,
  'Cue': 'plugin required',
  'WordPress': 'plugin required',
  'KenticoCloud': false,
  'Drupal': 'plugin required',
  'Aem': true
},
{
  'Service': 'PWA',
  'Quintype': true,
  'Cue': false,
  'WordPress': false,
  'KenticoCloud': false,
  'Drupal': 'plugin required',
  'Aem': true
},
{
  'Service': 'Custom Ad slots',
  'Quintype': true,
  'Cue': false,
  'WordPress': 'plugin required',
  'KenticoCloud': false,
  'Drupal':false,
  'Aem': false
},
{
  'Service': 'Auto-post to Social Networks',
  'Quintype': true,
  'Cue': true,
  'WordPress': 'plugin required',
  'KenticoCloud': false,
  'Drupal':true,
  'Aem': true
},
{
  'Service': 'Push Notifications',
  'Quintype': true,
  'Cue': true,
  'WordPress': 'plugin required',
  'KenticoCloud': false,
  'Drupal':true,
  'Aem': true
}
]
