import React from "react";

import { IntegrationCardContainer, Card, Image, DisplayName } from "./style";

function filterData(data, category) {
  if (category === "all") return data;
  return data.filter(item => (item.category === category ? true : false));
}

export default function IntegrationCard({ data, selectCategory }) {
  return (
    <IntegrationCardContainer>
      {filterData(data, selectCategory).map(item => {
        return (
          <Card>
            <div>
              <Image src={item.image} alt={item.displayName}/>
            </div>
            <DisplayName>{item.displayName}</DisplayName>
          </Card>
        );
      })}
    </IntegrationCardContainer>
  );
}
