import styled, { css } from "styled-components";

export const IntegrationWrapper = styled.div`
  padding: 0 24px;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    max-width: ${props => props.theme.containerWidth};
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    margin: 0 auto;
  }
`;

export const InnerWrapper = styled.div`
  grid-column: 2/12;
`;

export const Title = styled.h1`
  font-family: ${props => props.theme.fontFamily.primary};
  font-size: ${props => props.theme.fontSize.xxxl};
  font-weight: ${props => props.theme.fontWeight.bold};
  line-height: ${props => props.theme.lineHeight.lh1};
  color: ${props => props.theme.color.gray2};
  text-align: center;
  margin: 10px 0;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    font-size: ${props => props.theme.fontSize.jumbo};
  }
`;

export const IntegrationFilter = styled.div`
  text-align: center;
`;

export const CustomSelect = styled.div`
  position: relative;
  max-width: 240px;
  margin: 0  auto 20px auto;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    display: none;
  }
  &::after {
    content: '\\25BC';
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
  }
`;

export const Select = styled.select`
  display: block;
  width: 100%;
  padding: 10px;
  margin: 0 0 50px 0;
  font-family: ${props => props.theme.fontFamily.primary};
  font-size: ${props => props.theme.fontSize.l};
  font-weight: ${props => props.theme.fontWeight.bold};
  color: ${props => props.theme.color.gray2};
  -webkit-appearance: none;
  background-color: ${props => props.theme.color.white};
  text-transform: capitalize;
  border-radius: 3px;
  border-color: ${props => props.theme.color.green1};
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    display: none;
  }
`;

export const Option = styled.option`
`;

export const IntegrationList = styled.ul`
  display: none;
  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    display: inline-flex;
    list-style: none;
    padding: 50px 0;
  }
`;

export const Item = styled.li`
  font-family: ${props => props.theme.fontFamily.primary};
  font-size: ${props => props.theme.fontSize.l};
  font-weight: ${props => props.theme.fontWeight.bold};
  padding: 0 20px 5px;
  color: ${props => props.theme.color.gray2};
  position: relative;
  transition: all 0.1 ease;
  cursor: pointer;
  text-transform: capitalize;
  ${props =>
    props.selectCategory &&
    css`
      color: ${props => props.theme.color.green1};
    `};
`;

