import styled from "styled-components"


export const Container = styled.div`
margin-top: 8px;
`

export const Text = styled.span`
display: inline-block;
margin-left: 8px;
`