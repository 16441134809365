export const arabiccontactUsData = {
  arabictitle: "هل لديك أسئلة؟ أو تحتاج مساعدة؟",
  arabiccontent:
    "سواء كنت ناشرًا بالفعل على كوينتايب أو لازلت تستكشف عروضنا، تواصل مع فريقنا لمعرفة المزيد.",
  arabicdepartmentOptions: [
    { label: "Human Resources", value: "Human Resources" },
    { label: "Sales", value: "Sales" },
    { label: "Marketing", value: "Marketing" },
    { label: "Customer Support", value: "Customer Support" },
    { label: "Partnerships", value: "Partnerships" },
  ],
};
