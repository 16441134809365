import React from "react";
import { SecondaryButtonContainer, SecondaryLinkContainer,LinkItem } from "./style";

function SecondaryButton(props) {
  const {onClick} = props;
    if(props.isExternal) {
    return <LinkItem   href={props.link} title={props.title}  target="_blank">{props.children}</LinkItem> 
  }
  else if(props.link ) {
    return  <SecondaryLinkContainer  to={props.link} title={props.title} >{props.children}</SecondaryLinkContainer>
  }
  return <SecondaryButtonContainer onClick={onClick}>{props.children}</SecondaryButtonContainer>
}

export default SecondaryButton;
