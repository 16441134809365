import React from "react";
import { useSelector } from "react-redux";
import {
  Wrapper,
  ContentWrapper,
  List,
  Item,
  TitleContainer,
} from "../get-demo/style";
import { NormalLink } from "../contact-us/style";
import { ListTitle } from "./style";

import Form from "./shared/form/index";
import { freeTrialData } from "./data";
import { arabicfreeTrialData } from "./arabic-data";
import H1 from "../shared/h1/index";
import Para from "../shared/para-reg/index";

export default function FreeTrial() {
  const isArabic = useSelector((state) => state);
  const { title, content, listData } = freeTrialData;
  const { arabictitle, arabicontent, arabiclistData } = arabicfreeTrialData;
  
  return (
    <Wrapper>
      <ContentWrapper>
        <TitleContainer>
          <H1 small={true}>{isArabic ? arabictitle : title}</H1>
        </TitleContainer>
        <Para>{isArabic ? arabicontent : content}</Para>
        <ListTitle>
          <Para>{isArabic ? `تأكد من تجربته` : `Be sure to try out -`} </Para>
        </ListTitle>
        <List>
          {isArabic
            ? arabiclistData.map((data, index) => {
                return (
                  <Item key={index}>
                    <Para>{data}</Para>
                  </Item>
                );
              })
            : listData.map((data, index) => {
                return (
                  <Item key={index}>
                    <Para>{data}</Para>
                  </Item>
                );
              })}
        </List>
        {isArabic ? (
          <Para>
            بينما يمنحك العرض التوضيحي لنظام إدارة المحتوى نظرة عامة جيدة على
            نظام إدارة المحتوى، يسعدنا تنظيم لقائك أحدا آخر مباشرة وإظهار كيفية
            مساعدة الناشرين على إنشاء محتوياتهم وتوزيعها وتحقيق الدخل منها بشكل
            أفضل.
            <NormalLink alt="schedule demo page" to="/schedule-demo">
              انقر هنا
            </NormalLink>{" "}
            لتحديد موعد العرض التوضيحى
          </Para>
        ) : (
          <Para>
            While a demo of the CMS gives you a good overview of the Bold CMS,
            we are happy to organize a 1-on-1 with you and show how we help
            publishers create, distribute and monetize their content better.
            <NormalLink alt="schedule demo page" to="/schedule-demo">
              Click here
            </NormalLink>{" "}
            to schedule a demo.
          </Para>
        )}
      </ContentWrapper>
      <Form />
    </Wrapper>
  );
}
