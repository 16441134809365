import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

const linkStyles = css`
  display: block;
  padding: 12px 0;
  color: ${props => props.theme.color.gray3};
  :hover {
    color: ${props => props.theme.color.green1};
  }
`;

export const ToolTipContainer = styled.div`
  position: absolute;
  display: none;
`;

export const InnerContainer = styled.ul`
  position: relative;
  min-width: 200px;
  top: 20px;
  padding: 12px 16px;
  list-style-type: none;
  background-color: ${props => props.theme.color.white};
  box-shadow: ${props => props.theme.shadow.four};
  z-index: ${props => props.theme.zIndex.four};
  &::before {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    display: inline-block;
    top: -10px;
    left: 20px;
    border-style: none solid solid solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent
      ${props => props.theme.color.whitesmoke} transparent;
  }
`;

export const ToolTipItem = styled.li``;

export const ToolTipLink = styled(Link)`
  ${linkStyles}
`;

export const ToolTipExternalLink = styled.a`
  ${linkStyles}
`;
