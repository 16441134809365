import React from "react";

import {Svg} from './style'

function Icon({width,height}) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <path
        fill="#44D7B6"
        d="M12.214 20c.238 0 .44-.08.607-.24l6.929-6.675A.782.782 0 0020 12.5a.782.782 0 00-.25-.585l-6.929-6.674A.843.843 0 0012.214 5c-.238 0-.44.08-.607.24l-.786.758a.688.688 0 00-.267.585c.011.229.1.424.267.584l4.286 3.957H4.857c-.238 0-.44.08-.607.24a.782.782 0 00-.25.586v1.1c0 .23.083.425.25.585.167.16.369.241.607.241h10.25l-4.286 3.957a.846.846 0 00-.267.584.688.688 0 00.267.585l.786.757c.167.16.37.241.607.241z"
      ></path>
    </Svg>
  );
}

export default Icon;
